import { Component, OnInit, Input } from '@angular/core';
import { JobAds } from 'src/app/class/job-ads';
import { CompanyInfo } from 'src/app/class/company-info';
import { I18n } from 'src/app/i18n/i18n';
import { makeid, SelectOptionData, dbKeys, JobApplication, openSimpleMessageDialog, isCvFileTypeAllowed } from 'src/app/class/common-objects.enum';
import { AngularFireUploadTask, AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserInfo } from 'src/app/class/user-info';
import { MatDialog } from '@angular/material/dialog';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';

@Component({
  selector: 'app-apply-job-parts',
  templateUrl: './apply-job-parts.component.html',
  styleUrls: ['./apply-job-parts.component.less']
})
export class ApplyJobPartsComponent implements OnInit {
  thisFormId = `ApplyJobPartsForm_${makeid(20)}`;
  allowFileSize = 10 * 1024 * 1024;
  percentage: Observable<number>;
  private key_storage_user_uploaded_cv = 'user_uploaded_cv';
  downloadURL = '';
  displayForm = false;
  jwtHelper = new JwtHelperService();
  formData = {
    name: '',
    phoneNumber: '',
    email: '',
    cvUrl: ''
  };
  metadata;
  @Input() mainJob: {
    job: JobAds,
    companyInfo: CompanyInfo;
  } = null;
  i18n = new I18n();
  isApplySuccess = false;
  currentUser: any = null;


  isApplyOpenLogged = false
  applyLoggedId = ''
  isApplyFinishLogged = false
  constructor(
    public storage: AngularFireStorage,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public dialog: MatDialog,
    private loggingUtils: LoggingUtilsService,
  ) {
    console.log(this.mainJob);
  }
  async displayFormFunction() {
    this.displayForm = true;
    if (!this.isApplyOpenLogged) {
      const result = await this.loggingUtils.log(LoggingKey.job_submition_filling_and_submitted, this.mainJob.job.id)
      if (result &&typeof result == 'string' ){
        this.isApplyOpenLogged = true;
        this.applyLoggedId = result;
      } 
    }
  }
  ngOnInit() {
    this.prefillData();
  }
  async prefillData() {
    this.afAuth.authState.subscribe(async user => {
      this.currentUser = user;
      if (user && user.uid) {
        let token = await user.getIdToken();
        let dToken = this.jwtHelper.decodeToken(token);

        if (!dToken.isAdmin && !dToken.isCompany) {
          let result = await this.firestore.firestore.collection(dbKeys.user_info).doc(user.uid).get();
          console.log(result.data());
          if (result && result.exists) {
            let d: UserInfo = <UserInfo>result.data();
            console.log(d);
            this.formData.name = d.fullname;
            this.formData.email = user.email;
            let newNumber = user.phoneNumber.replace('+852', '');
            if (newNumber && newNumber.length == 8) this.formData.phoneNumber = newNumber;
            this.formData.cvUrl = await this.storage.ref(d.cvURL).getDownloadURL().toPromise();
          }
        }
      }
    });
  }
  async onSubmit() {
    let form = <HTMLFormElement>document.getElementById(this.thisFormId);
    console.log(form);
    if (form.reportValidity()) {
      if (!this.formData.cvUrl?.length) {
        return openSimpleMessageDialog(this.dialog, `${this.i18n.get('job_application_failed')} ${this.i18n.get('job_application_failed_no_cv')}`);
      }
      if (this.mainJob && this.mainJob.job.id) {
        let uid = '';
        if (this.currentUser && this.currentUser.uid) {
          let token = await this.currentUser.getIdToken();
          let dToken = this.jwtHelper.decodeToken(token);
          if (!dToken.isAdmin && !dToken.isCompany) {
            uid = this.currentUser.uid;
          }
        }
        let application1: JobApplication = {
          id: '',
          job_id: this.mainJob.job.id,
          status: 'pending',
          user_id: uid,
          name: this.formData.name,
          phoneNumber: this.formData.phoneNumber,
          email: this.formData.email,
          cvURL: this.formData.cvUrl
        };
        try {
          let result = await this.firestore.collection(dbKeys.job_application).add(application1);
          if (result) {
            await result.update({
              id: result.id
            });
          }
          openSimpleMessageDialog(this.dialog, this.i18n.get('job_application_success'));
          this.isApplySuccess = true;
          if (!this.isApplyFinishLogged) {
            const loggedRecord = await this.loggingUtils.logFinished(this.applyLoggedId, true, result.id)
            if (loggedRecord) this.isApplyFinishLogged = true;
          }
        } catch (e) {
          console.error(e);
          openSimpleMessageDialog(this.dialog, `${this.i18n.get('job_application_failed')} ${e.message}`);
        }
      }
    }
  }
  public async uploadFile(event: any): Promise<void> {
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target;
      const file = target.files[i];
      console.log(file);
      if (file.size > this.allowFileSize) {
        alert(this.i18n.get('err_msg_file_too_large'));
        return;
      } else if (!isCvFileTypeAllowed(file.type)) {
        alert(this.i18n.get('err_msg_disallow_file_type'));
        return;
      }
      try {
        let now = new Date();
        const path = `${this.key_storage_user_uploaded_cv}/temp/${now.toISOString()}_${makeid(18)}_${file.name}`;
        const fileRef: AngularFireStorageReference = this.storage.ref(
          path
        );
        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        this.percentage = task.percentageChanges();
        let result = await task.snapshotChanges().toPromise();
        let dlURL = await fileRef.getDownloadURL().toPromise();
        console.log(result);
        console.log(dlURL);
        this.downloadURL = dlURL;
        this.metadata = result.metadata;
        this.formData.cvUrl = dlURL;
      } catch (e) {
        openSimpleMessageDialog(this.dialog, e.message);
        console.error(e);
      }
    }
  }
}
