import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
// import * as ClassicEditor from 'ckeditor5-build-classic-with-font';
import { CKEditorUploadAdapter, CKEFontColors, CKEDefaultToolbar } from 'src/app/class/CKEditorUploadCommonAdapter';
import { I18n } from 'src/app/i18n/i18n';
import { i18n_locate } from 'src/app/i18n/i18n_const';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { TheContent } from 'src/app/class/the-content';
import { dbKeys, openSimpleMessageDialog } from 'src/app/class/common-objects.enum';
import { MatDialog } from '@angular/material/dialog';
import * as ClassicEditor from 'src/ckeditor/ckeditor';

@Component({
  selector: 'app-admin-content',
  templateUrl: './admin-content.component.html',
  styleUrls: ['./admin-content.component.less']
})
export class AdminContentComponent implements OnInit {
  i18n = new I18n()
  get ckeLanguage() {
    if (this.i18n.currentLocate == i18n_locate.en) {
      return 'en'
    } else {
      return 'zh'
    }
  }

  public header: string = 'Content'
  private supportedContentTypes: Map<string, {header: string}> = new Map([
    ['privacy', {
      header: 'Privacy Policy',
    }],
    ['terms', {
      header: 'Terms of Use',
    }],
  ])

  public ckeditor;
  public ckeConfig = {
    language: this.ckeLanguage,
    uiColor: '#AADC6E',
    fontColor: CKEFontColors,
    fontBackgroundColor: CKEFontColors,
    toolbar: CKEDefaultToolbar
  }

  public content: TheContent = {
    title: '',
    content: '',
    id: '',
    createAt: null,
    updateAt: null,
  }
  
  get editorContent() {
    return this.content.content
  }
  set editorContent(v) {
    this.content.content = v
  }

  private currentUser: firebase.User = null
  private isEdit: boolean = false;

  constructor(
    public storage: AngularFireStorage,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    public dialog: MatDialog,
  ) {
    this.ckeditor = ClassicEditor;
  }

  ngOnInit() {
    for (let [key, params] of this.supportedContentTypes) {
      if (location.pathname.endsWith(key)) {
        this.header = params.header;
        this.content.id = key;
      }
    }

    this.auth.authState.subscribe(user => {
      if (user && user.uid) {
        this.currentUser = user
      } else {
        this.currentUser = null
      }
    })

    this.firestore.collection(dbKeys.content).doc(this.content.id).get().subscribe(snapshot => {
      if (snapshot && snapshot.exists) {
        this.isEdit = true;
        const data = snapshot.data()
        if (data) {
          this.content = <TheContent>data
          this.content.createAt = (<firebase.firestore.Timestamp>data.createAt).toDate()
          this.content.updateAt = (<firebase.firestore.Timestamp>data.updateAt).toDate()
        }
      }
    })
  }

  public TheUploadAdapterPlugin(eventData) {
    const s = this
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new CKEditorUploadAdapter(loader, s.storage);
    };
  }

  public async saveContent() {
    if (this.currentUser) {
      try {
        if (!this.content.title) throw {message: 'Title cannot be blank'};
        if (!this.content.content || this.content.content.trim().length === 0) throw {message: 'Content cannot be Empty'};

        if (!this.isEdit) this.content.createAt = new Date()
        this.content.updateAt = new Date()
        const ref = await this.firestore.collection(dbKeys.content).doc(this.content.id).set(this.content)
        openSimpleMessageDialog(this.dialog, '新增成功！\nAdded Success!')
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `操作失敗\nFailed.\nCODE: ${e.message}`)
      }
    }
  }

}
