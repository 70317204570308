import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { I18n } from "src/app/i18n/i18n";

@Component({
  selector: 'app-actions-dialog',
  templateUrl: './actions-dialog.component.html',
  styleUrls: ['./actions-dialog.component.less']
})
export class ActionsDialogComponent implements OnInit {
  i18n = new I18n();
  message = ''
  actionItems: ActionsDialogActionItem[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    public dialogRef: MatDialogRef<ActionsDialogComponent>
  ) {
    if (data && data.message) {
      this.message = data.message

      console.log(data)

      if (data.actionItems && data.actionItems.length) {
        this.actionItems = data.actionItems;
      }
    } else {
      dialogRef.close()
    }
  }

  ngOnInit() {

  }
  onNoClick() {
    this.dialogRef.close()
  }

  actionItemClicked(item: ActionsDialogActionItem) {
    if (item.callback) {
      item.callback();
    }
  }
}

export interface ActionsDialogActionItem {
  callback: Function,
  text: string,
  isPrimary?: boolean,
}
