import { Pipe, PipeTransform } from '@angular/core';
import { TheAdvisor } from "src/app/class/the-advisor";
import { I18n } from 'src/app/i18n/i18n';

@Pipe({
  name: 'advisorAppointmentFormat'
})
export class AdvisorAppointmentFormatPipe implements PipeTransform {
  i18n = new I18n()

  transform(advisor: TheAdvisor): string {
    let formats = [];

    for (let k of ['phone_call', 'face_to_face','zoom_call']) {
      if (advisor[k]) {
        formats.push(this.i18n.get(k))
      }
    }

    return formats.join(', ');
  }

}
