<mat-card class="blogCard" (click)="toArticle()">
  <div class="upper">
    <img
      mat-card-image
      class="cover"
      [src]="blog.coverURL"
      *ngIf="blog.coverURL"
    />
  </div>
  <div class="content">
    <div>
      <span mat-card-title class="title">{{ blog.title }}</span>
    </div>
    <div flex>
      <div flex-item="grow-1" flex="align-start"><span class="category" [ngClass]="{'eventInfo': blog.category=='event_news', 'ca': isInCareerAdvisor, careerInfo: blog.category==='career_advisor'}">{{thisCategoryName}}</span></div>
      <div class="date" flex-item="justify-end">
        {{ blog.createAt | articleDate }}
      </div>
    </div>
  </div>
</mat-card>
