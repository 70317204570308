<app-main-page-slider [pageType]="silderPageType"></app-main-page-slider>

<div class="container-wrap allContentOuter">
    <div class="main container allContentInner">
        <p class="firstIntroText">
            現為全港小學、中學及大專院校推行「職涯規劃」教育服務，我們可安排曾在各行業擔任管理層、即將或剛退休的新中年，出任「職場顧問」分享個別行業前景，以及事業發展經驗，啟發青年人思考，並且逐步探索個人的職涯規劃。
        </p>

        <div class="ttInfoSection">
            <div class="items">
                <div class="item">
                    <span class="left">
                        目標
                    </span>
                    <span class="right">
                        協助同學探索個人的職涯規劃，為他們建立事業抱負和正面的工作價值觀
                    </span>
                </div>
                <div class="item">
                    <span class="left">
                        對象
                    </span>
                    <span class="right">
                        全港小學、中學及大專院 學生
                    </span>
                </div>
                <div class="item">
                    <span class="left">
                        時間
                    </span>
                    <span class="right">
                        每場1.5 - 2 小時
                    </span>
                </div>
                <div class="item">
                    <span class="left">
                        形式
                    </span>
                    <span class="right">
                        實體 / 視像
                    </span>
                </div>
            </div>
        </div>

        <div class="lowFormSection cFormSection">
            <div class="LFTitle">
                <span class="t">中一至中三</span>
            </div>
            <table class="contentTable">
                <tr>
                    <td>主題</td>
                    <td>服務內容</td>
                </tr>
                <tr>
                    <td>「職」「興」狂想</td>
                    <td>與同學探討興趣與職業的關係，從而讓同學思索如何發掘自己的興趣。</td>
                </tr>
                <tr>
                    <td>職業D.I.S.C.自我測試</td>
                    <td>透過D.I.S.C.理論，與同學初步分析各性格和學科的匹配程度，讓同學加深個人了解，並探索出自己最適合的工作類型。</td>
                </tr>
                <tr>
                    <td>到校職業分享會 </td>
                    <td>透過活動，讓同學初步了解行業現況及發展的資訊，並認識投身其行業的路徑和條件，及早思索自身未來職涯規劃。</td>
                </tr>
                <tr>
                    <td>創業苦與樂分享會</td>
                    <td>透過活動及分享，讓同學初步了解創業應具備的知識、技能及工作態度；藉嘉賓職涯規劃和心路歷程的分享 ，啟發同學思考個人職業和夢想。</td>
                </tr>
                <tr>
                    <td>工作體驗</td>
                    <td>藉工作體驗活動，讓同學初步了解實際工作情況，讓同學探索興趣及對自身狀況進行調整，及早為人生及職涯發展作出規劃。</td>
                </tr>

            </table>
        </div>




        <div class="highFormSection cFormSection">
            <div class="LFTitle">
                <span class="t">中四至中六</span>
            </div>
            <table class="contentTable">
                <tr>
                    <td>主題</td>
                    <td>服務內容</td>
                </tr>
                <tr>
                    <td>自我探索</td>
                    <td>如何了解自己個人興趣和取向、培養個人價值，與同學探討為將來就業作準備的方法，並認識培養良好的職場操守和工作習慣的要訣。在升學、求職過程中可以事半功倍。                    </td>
                </tr>

                <tr>
                    <td>踏入職場攻略</td>
                    <td>與同學探討踏入職場及選擇合適工作時的要點，讓同學初步認識尋找工作途徑、 預備履歷、面試過程重點、電郵電話等通訊禮儀，為離開校園邁向下一個階段作好準備。</td>
                </tr>

                <tr>
                    <td>職業D.I.S.C.自我測試</td>
                    <td>透過D.I.S.C.理論，與同學初步分析各性格和學科的匹配程度，讓同學加深個人了解，並探索出適合自己的工作類型，反思並重新訂定自己的求學擇業目標。</td>
                </tr>

                <tr>
                    <td>到校職業分享會 </td>
                    <td>透過活動，讓同學掌握行業現況及最新發展的資訊，並認識投身其行業的路徑和條件，及早思索自身未來職涯規劃。</td>
                </tr>

                <tr>
                    <td>創業苦與樂分享會</td>
                    <td>透過活動及分享，讓同學初步了解創業應具備的知識、技能工及作態度；藉嘉賓職涯規劃和心路歷程的分享 ，啟發同學思考個人職業和夢想。</td>
                </tr>

                <tr>
                    <td>工作體驗</td>
                    <td>藉工作實習活動，讓同學初步了解實際工作的情況，從而讓同學探索興趣及對自身狀況進行調整，及早為職涯發展作出規劃。</td>
                </tr>

            </table>
        </div>
<br><hr><br>
        <div class="bottomContact">
            <a href="mailto:info@happy-retired.org?subject=查詢「職涯規劃」教育服務&body=查詢「職涯規劃」教育服務。">
            <div class="left btn">
                    按此以電子郵件查詢
            </div>
        </a>

        <a href="https://api.whatsapp.com/send?phone=85266865812&text=查詢「職涯規劃」教育服務">
            <div class="right btn">
                    按此 WhatsApp查詢
            </div>

        </a>
        </div>
    </div>
</div>