import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { ImageSlide, dbKeys, openSimpleMessageDialog, enumImagesSildeType } from 'src/app/class/common-objects.enum';
import { MatDialog } from '@angular/material/dialog';
import { AddMainpageDialogSliderComponent } from './add-mainpage-dialog-slider/add-mainpage-dialog-slider.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatSlideToggle, MatSlideToggleChange, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-mainpage-slider-edit',
  templateUrl: './admin-mainpage-slider-edit.component.html',
  styleUrls: ['./admin-mainpage-slider-edit.component.less']
})
export class AdminMainpageSliderEditComponent implements OnInit {
  errorMessage = ''
  notifyMessage = ''
  i18n = new I18n()
  pageType: enumImagesSildeType = enumImagesSildeType.homepage

  get enumTypeArray() {
    const arr: enumImagesSildeType[] = []
    for (var enumMember in enumImagesSildeType) {
      arr.push(<enumImagesSildeType>enumMember)
    }

    return arr

  }
  onSelectChange(v:string){
    this.reloadTable()
  }
  
  arrImageSlides: Array<ImageSlide> = []
  constructor(
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    private snackbar:MatSnackBar,
  ) { }

  ngOnInit() {
    this.reloadTable()
  }
  updateRecordDialog(id: string) {
    console.log(id)
    let dialogRef = this.dialog.open(AddMainpageDialogSliderComponent, {
      data: {
        id: id
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadTable()
      }
    })
  }
  addRecordDialog() {
    let dialogRef = this.dialog.open(AddMainpageDialogSliderComponent, {
      data: {
        pageType: this.pageType,
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadTable()
      }
    })
  }
  async reloadTable() {
    this.arrImageSlides = []
    let results = await this.firestore.firestore.collection(dbKeys.mainpage_slider)
      .where('type', '==', this.pageType)
      .get()
    if (results && results.docs) {
      console.log(results.docs)
      for (let doc of results.docs) {
        if (doc && doc.exists && doc.data()) {
          let d: ImageSlide = <ImageSlide>doc.data()
          if (d) {
            d.imageURL = await this.storage.ref(d.imageURL).getDownloadURL().toPromise()
            if (!d.id) {
              d.id = doc.id
            }
            this.arrImageSlides.push(d)
          }
        }
      }
    }
  }
  async removeSlide(id: string) {
    try {
      let remove = await this.firestore
        .collection(dbKeys.mainpage_slider)
        .doc(id)
        .delete()
      openSimpleMessageDialog(this.dialog, "remmove success!")
      this.reloadTable()
    } catch (e) {
      openSimpleMessageDialog(this.dialog, `remove failed: ${e.message}`)
    }
  }
  sortData(target) {

  }

  onVisibleChange(slide:ImageSlide){
    if(slide?.id){

    this.firestore.collection(dbKeys.mainpage_slider)
    .doc(slide.id)
    .update({
      visible: !slide.visible
    })
    .then(()=>{
      this.snackbar.open(this.i18n.translate.action_success)
    })
    .catch(e=>{
      this.snackbar.open(this.i18n.translate.action_failed+"："+e?.code)
    })
    }
  
  }
}
