import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateTimeText'
})
export class DateTimePipe implements PipeTransform {

  transform(date: Date): any {
    if (!date) {
      return '';
    }

    const dateMoment = moment(date);

    if (!dateMoment.isValid()) {
      return 'N/A';
    }

    return dateMoment.format('YYYY-MM-DD hh:mm a');
  }

}
