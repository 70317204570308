import { Component, OnDestroy, OnInit } from '@angular/core';
import { JobAds } from 'src/app/class/job-ads';
import { I18n } from 'src/app/i18n/i18n';
import { CompanyInfo } from 'src/app/class/company-info';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { dbKeys, cloudFunctionsKeys, unsubscribeAll } from 'src/app/class/common-objects.enum';
import { Router } from '@angular/router';
import { AddUpdateJobAdsComponent } from '../../company/dialog/add-update-job-ads/add-update-job-ads.component';
import { MatDialog } from '@angular/material/dialog';
import { firestore } from 'firebase/app';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Subscription } from 'rxjs';
import CryptoJS from 'crypto-js';
import { stringify } from 'querystring';


@Component({
  selector: 'app-list-pending-jobs',
  templateUrl: './list-pending-jobs.component.html',
  styleUrls: ['./list-pending-jobs.component.less']
})
export class ListPendingJobsComponent implements OnInit, OnDestroy {
  errorMessage = '';
  notifyMessage = '';
  jobs: Map<string, {
    display: boolean,
    job: JobAds;
  }> = new Map();
  companyInfos = new Map<string, CompanyInfo>()
  allJobsSubscription: Subscription = null;
  isRefreshing = false;

  isLoadMore = false;

  get resultCount() {
    if (this.jobs) {
      return Array.from(this.jobs.values()).filter(v => v.display).length;
    } else {
      return 0;
    }
  }
  i18n = new I18n();
  constructor(
    public firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public dialog: MatDialog,
    public afFunctions: AngularFireFunctions
  ) { 
    this.updateAllJobAds();

  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    unsubscribeAll([

      this.allJobsSubscription,
      // ...this.subUpdateCompanyInfos,

    ])
  }

  get orderedJobAds(){
    if(this.jobs && this.jobs.size > 0){
      return    Array.from(this.jobs.values()).sort(function(a, b) {
        var textA = a.job.title.toUpperCase();
        var textB = b.job.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    }
    return []
  }

  lastJobAdsJsonHash = ''
  updateAllJobAds(statusFilter?: string) {
    console.log('calling updateAllJobAds()')
    if (this.allJobsSubscription) {
      this.allJobsSubscription.unsubscribe();
    }

    this.allJobsSubscription = this.firestore.collection<JobAds>(dbKeys.job_ads, (r => {
      let ref: firestore.Query = r
      if (statusFilter) {
        ref = ref.where('status', '==', statusFilter)
      }
      ref = ref.orderBy('title', 'asc')
      // .orderBy('status', 'asc')
      if (!this.isLoadMore) {
        ref = ref.limit(50)
      }

      return ref;
    }
    ))
   .valueChanges()
      .subscribe((result) => {
      
        const jsonHash =  CryptoJS.MD5(JSON.stringify(result));
        if(this.lastJobAdsJsonHash && this.lastJobAdsJsonHash == jsonHash){
          // json same so skip
          return;
        }else{
          this.lastJobAdsJsonHash = jsonHash
          console.log('last v hash are ', JSON.stringify(result))
          console.log('last v hash are ', jsonHash)
        }
        console.log('calling updateAllJobAds subscription',result)
        this.jobs.clear()
        if (result && result.length > 0) {
          for (let job of result) {

            // let snap = await this.firestore.collection(dbKeys.company_info).doc<CompanyInfo>(job.creator_uid).get().toPromise();
            // if (snap.exists) {
            // let companyInfo: CompanyInfo = <CompanyInfo>snap.data();
            const d: any = job.deadline;
            const d0 = <firestore.Timestamp>d;
            job.deadline = d0?.toDate();
            let o = {
              display: true,
              companyInfo: null,
              job: job,
              application_count: job.application_count,
            };
            // console.log(o);
            this.jobs.set(job.id, o);
            // }
          }
          console.log('all jobs', this.jobs)
          this.updateUpdateCompanyInfo()
        } else {
          this.jobs.clear()
        }
        //sub.unsubscribe()
      });
  }
  // subUpdateCompanyInfos: Subscription[]
  updateUpdateCompanyInfo() {
    if (this.jobs && this.jobs.size > 0) {
      // unsubscribeAll(this.subUpdateCompanyInfos)
      // this.subUpdateCompanyInfos = []
      this.companyInfos.clear()
      const arrToQueryCompany: string[] = []
      for (let [id, obj] of this.jobs) {
        if (arrToQueryCompany.includes(obj.job.creator_uid)) {
          // have so do nothing
        } else {
          arrToQueryCompany.push(obj.job.creator_uid)
        }
      }
      for (const companyId of arrToQueryCompany) {
        console.log('wowow1', 't')
        if (companyId) {
          console.log('wowow1', 'x')
          const sub = this.firestore.collection(dbKeys.company_info)
            .doc<CompanyInfo>(companyId).valueChanges()
            .toPromise()
          .then(v => {
              if (v) {
                console.log('wowow1', 'y')
                this.companyInfos.set(companyId, v)
              }
            })
          // this.subUpdateCompanyInfos.push(sub)
        }

      }
    }
  }
  getCompanyInfo(id: string) {
    // console.log(this.companyInfos)
    return this.companyInfos?.get(id) || null
  }
  async approveJob(jobId: string) {
    this.errorMessage = '';
    this.notifyMessage = '';
    try {
      let jobRef = this.firestore.collection(dbKeys.job_ads).doc(jobId);
      let job = await jobRef.get().toPromise();
      if (job.exists) {
        await jobRef.update({
          status: 'active'
        });
      }
      this.notifyMessage = this.i18n.get('notify_msg_action_success');
      //this.updateAllJobAds()
    } catch (e) {
      console.error(e);
      this.errorMessage = this.i18n.get(`error_msg_action_failed`) + e.message;
    }
  }
  async setActiveJob(jobId: string) {
    this.errorMessage = '';
    this.notifyMessage = '';
    try {
      let jobRef = this.firestore.collection(dbKeys.job_ads).doc(jobId);
      let job = await jobRef.get().toPromise();
      if (job.exists) {
        await jobRef.update({
          status: 'active'
        });
      }
      this.notifyMessage = this.i18n.get('notify_msg_action_success');
      //this.updateAllJobAds()
    } catch (e) {
      console.error(e);
      this.errorMessage = this.i18n.get(`error_msg_action_failed`) + e.message;
    }
  }
  async setDeactiveJob(jobId: string) {
    this.errorMessage = '';
    this.notifyMessage = '';
    try {
      let jobRef = this.firestore.collection(dbKeys.job_ads).doc(jobId);
      let job = await jobRef.get().toPromise();
      if (job.exists) {
        await jobRef.update({
          status: 'deactive'
        });
      }
      this.notifyMessage = this.i18n.get('notify_msg_action_success');
      //this.updateAllJobAds()
    } catch (e) {
      console.error(e);
      this.errorMessage = this.i18n.get(`error_msg_action_failed`) + e.message;
    }
  }
  onStatusFilterChange(value: string) {
    if (value && value != null) {
      this.filterByStatus(value);
    } else if (value == 'all') {
      this.filterByStatus(null);
    }
  }
  goToThisJobApplication(id: string) {
    this.router.navigate(['admin', 'jobApplications', id]);
  }

  openUpdateDialog(job: JobAds) {
    if (!job) { return job; }
    let ref = this.dialog.open(AddUpdateJobAdsComponent, {
      data: {
        initJob: job
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.notifyMessage = this.i18n.get('notify_msg_created_pending_approval');
        //this.updateAllJobAds()
      }
    });
  }
  filterByStatus(newStatus: string) {

    // return this.updateAllJobAds(newStatus || null)
    Array.from(this.jobs.values()).forEach((v, k) => { this.jobs[k].display = false; });
    if (newStatus && newStatus != 'all') {
      for (let i in this.jobs) {

        if (this.jobs[i].job.status == newStatus) {
          this.jobs[i].display = true;
        }
      }
    } else if (newStatus == 'all') {
      this.jobs.forEach((v, k) => { this.jobs[k].display = true; });
    }
  }

  async reloadJobApplicationCount() {
    if (this.isRefreshing) return;
    this.isRefreshing = true;
    const calltable = this.afFunctions.httpsCallable(cloudFunctionsKeys.updateAllJobApplicationCount);
    calltable({}).subscribe(
      async (next) => {
        this.isRefreshing = false;
        // return this.updateAllJobAds();
      },
      async (error) => {
        this.isRefreshing = false;
        console.log(error);
      }
    );
  }
}
