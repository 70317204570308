import { IndustryCatItem } from './../../../class/IndustryCatItem';
import { Component, OnInit } from '@angular/core';
import { I18n } from '../../../i18n/i18n';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { dbKeys, openSimpleMessageDialog, openSimpleYesDialog, getIndustryNameByCurrentLocate } from '../../../class/common-objects.enum';
import { MatDialog } from '@angular/material';
import { SimpleMessageDialogComponent } from '../../../universal-dialog/simple-message-dialog/simple-message-dialog.component';
import { IfStmt } from '@angular/compiler';
import { i18n_locate } from 'src/app/i18n/i18n_const';

@Component({
  selector: 'app-add-edit-industry-categories',
  templateUrl: './add-edit-industry-categories.component.html',
  styleUrls: ['./add-edit-industry-categories.component.less']
})
export class AddEditIndustryCategoriesComponent implements OnInit {
  i18n = new I18n()
  _parentId = 'top'
  get parentId(){
    if(this._parentId == 'top'){
      return null 
    }else{
      return this._parentId
    }
  }
  selectCats: Array<IndustryCatItem> = []
  currentCats: Array<IndustryCatItem> = null
  newIndustry: IndustryCatItem = {
    id: null,
    en_name: '',
    hant_name: '',
    parent_id: null
  }
  constructor(
    public afFirestore: AngularFirestore,
    public dialog: MatDialog
  ) {
    this.refreshSelectListSubscription()
    this.refreshListSubscription(null)
  }

  ngOnInit() {
  }
  subSelectList0 :Subscription = null
  refreshSelectListSubscription() {
    if (this.subSelectList0) {
      try {
        this.subSelectList0.unsubscribe()
      } catch (e) {
        console.error(e)
      }
    }

    this.subSelectList0 = this.afFirestore.collection(dbKeys.map_industry_cats, (ref=>{
      return ref.where('parent_id', '==', null)
    }))
      .valueChanges().subscribe(d => {
        console.log(d)
        if (d) {
          this.selectCats = []
          for (let o of d) {
            const anyO: any = <any>o
            const industry = <IndustryCatItem>anyO
            if (industry && industry.id) {
              this.selectCats.push(industry)
            }
          }
        }
      })
  }
  listSubscription0: Subscription = null
  refreshListSubscription(parentCatId: string) {
    if (this.listSubscription0) {
      try {
        this.listSubscription0.unsubscribe()
      } catch (e) {
        console.error(e)
      }
    }

    this.listSubscription0 = this.afFirestore.collection(dbKeys.map_industry_cats, ref=>{
      return ref.where('parent_id', '==', parentCatId)
    })
      .valueChanges().subscribe(d => {
        if (d) {
          this.currentCats = []
          for (let o of d) {
            const anyO: any = <any>o
            const industry = <IndustryCatItem>anyO
            if (industry && industry.id) {
              this.currentCats.push(industry)
            }
          }
        }
      })
  }
  async onAddIndustry() {
    const industry = this.newIndustry
    industry.parent_id = this.parentId
    if (!industry.hant_name) {
      openSimpleMessageDialog(this.dialog, "請輸入中文名稱\nPlease Enter hant name")
    } else if (!industry.en_name) {
      openSimpleMessageDialog(this.dialog, "請輸入英文名稱\nPlease Enter eng name")
    } else {
      try {
        const ref = await this.afFirestore.collection(dbKeys.map_industry_cats).add(industry)
        if (ref && ref.id) {
          await this.afFirestore.collection(dbKeys.map_industry_cats)
            .doc(ref.id)
            .update({
              id: ref.id
            })
          this.clearNewIndustry()
        }
        openSimpleMessageDialog(this.dialog, "新增成功！\nAdded!")
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `發生錯誤！\nError!\n${e.message}`)
      }
    }
  }
  onSelectChange(){
    console.log(this.parentId)
    if(this.parentId && this.parentId != 'top'){
      this.refreshListSubscription(this.parentId)
    }else{
      this.refreshListSubscription(null)
    }
  }
  async onUpdateIndustry(industry: IndustryCatItem) {
    if (!industry.hant_name) {
      openSimpleMessageDialog(this.dialog, "請輸入中文名稱\nPlease Enter hant name")
    } else if (!industry.en_name) {
      openSimpleMessageDialog(this.dialog, "請輸入英文名稱\nPlease Enter eng name")
    } else {
      try {
        await this.afFirestore.collection(dbKeys.map_industry_cats)
          .doc(industry.id)
          .update(industry)
        openSimpleMessageDialog(this.dialog, "修改成功！\nAdded!")
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `發生錯誤！\nError!\n${e.message}`)
      }
    }
  }
  async onRemoveIndustry(industry: IndustryCatItem) {
    openSimpleYesDialog(this.dialog, "是否真的要移除改分類？", async () => {
      try {

        await this.afFirestore.collection(dbKeys.map_industry_cats)
          .doc(industry.id).delete()
        openSimpleMessageDialog(this.dialog, "移除成功！\nAdded!")
      } catch (e) {

        console.error(e)
        openSimpleMessageDialog(this.dialog, `發生錯誤！\nError!\n${e.message}`)
      }
    })
  }
  clearNewIndustry() {
    this.newIndustry.id = null
    this.newIndustry.en_name = ''
    this.newIndustry.hant_name = ''
  }
  _getIndustryNameByCurrentLocate = getIndustryNameByCurrentLocate
}
