import { Component, OnInit } from '@angular/core';
import { enumImagesSildeType } from 'src/app/class/common-objects.enum';
import { I18n } from 'src/app/i18n/i18n';
import { i18n_locate } from 'src/app/i18n/i18n_const';

@Component({
  selector: 'app-ca-homepage',
  templateUrl: './ca-homepage.component.html',
  styleUrls: ['./ca-homepage.component.less']
})
export class CaHomepageComponent implements OnInit {
  i18n = new I18n()
  constructor() { }

  ngOnInit() {
  }

  get isHant(){
    console.log('current locale', this.i18n.currentLocate)
    return this.i18n.currentLocate == i18n_locate.hant
  }
  get pageType(){
    return enumImagesSildeType.ca_homepage
  }
}
