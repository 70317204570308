import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys } from 'src/app//class/common-objects.enum';
import { TheArticle } from 'src/app//class/the-article';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { checkIsInCareerAdvisor } from 'src/app/class/common-objects.enum';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';

@Component({
  selector: 'app-ca-blogs',
  templateUrl: './ca-blogs.component.html',
  styleUrls: ['./ca-blogs.component.less']
})
export class CaBlogsComponent implements OnInit {
  i18n = new I18n()
  articles: Array<TheArticle> = null
  featureArticles: Map<string, Map<string, TheArticle>> = new Map()

  getFeatureArticlesArray(categoryKey: string) {
    let categoryArticles: Map<string, TheArticle> = this.featureArticles.get(categoryKey)
    return Array.from(categoryArticles.values()).sort((a, b) => b.createAt.getTime() - a.createAt.getTime());
  }

  get isCaarticles(){
    if(this.router?.url.startsWith('/ca/article')){
      return true
    }
    return false;
  }
  constructor(
    public firestore: AngularFirestore,
    public router: Router,
    private logger: LoggingUtilsService,
    public activatedRoute: ActivatedRoute
  ) { }

  currentCategory = ''
  get isFeturePage() {
    if (this.currentCategory && this.currentCategory != '') {
      return false
    } else {
      return true
    }
  }
  get keyCollection() {
    const url = this.router.url
    if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_blog_articles
    } else {
      if (url.startsWith('/sportArticle')) {
        return dbKeys.sport_articles
      } else if (url.startsWith('/sportRewine')) {
        return dbKeys.sport_rewines
      } else if (url.startsWith('/sportRevision')) {
        return dbKeys.sport_revisions
      } else {
        return dbKeys.blog_articles
      }
    }
  }

  get thisTitle(){

    const url = this.router.url
    if (checkIsInCareerAdvisor()) {
      return `網上課室 | Online Classroom`
    } else {
      if (url.startsWith('/sportArticle')) {
        return '樂動文章 | Golden Sport Articles'
      } else if (url.startsWith('/sportRewine')) {
        return '樂動活動回顧 | Event Review'
      } else if (url.startsWith('/sportRevision')) {
        return `訓練課程重温 | Training Course Revision`
      } else {
        return `博客 | Blog`
      }
    }
  }
  get keyNavPrefix() {
    return this.keyNavPrefixSingle + 's'
  }

  get keyNavPrefixSingle() {
    const url = this.router.url
    if (checkIsInCareerAdvisor()) {
      return 'ca/article'
    } else {
      if (url.startsWith('/sportArticle')) {
        return 'sportArticle'
      } else if (url.startsWith('/sportRewine')) {
        return 'sportRewine'
      } else if (url.startsWith('/sportRevision')) {
        return 'sportRevision'
      } else {
        return 'article'
      }
    }
  }

  get keyNavPrefixMulti() {
    const url = this.router.url
    if (checkIsInCareerAdvisor()) {
      return 'ca/articles'
    } else {
      if (url.startsWith('/sportArticle')) {
        return 'sportArticles'
      } else if (url.startsWith('/sportRewine')) {
        return 'sportRewines'
      } else if (url.startsWith('/sportRevision')) {
        return 'sportRevisions'
      } else {
        return 'articles'
      }
    }
  }
  articleCategoryLink(categoryKey) {
    return `/${this.keyNavPrefixMulti}/category/${categoryKey}`
  }

  ngOnInit() {
    const nCategory = this.activatedRoute.snapshot.paramMap.get('category')
    if (nCategory) this.currentCategory = nCategory

    if (this.isFeturePage) {
      this.updateFeatureArticles()
    } else {
      this.updateArticles(this.currentCategory)
    }
  }

  async onCategoriesChange() {
    if (this.currentCategory && this.currentCategory.length) {
      this.router.navigate([this.keyNavPrefix, 'category', this.currentCategory])
      this.logger.log(LoggingKey.article_category_change, this.currentCategory)

    } else {
      this.router.navigate([this.keyNavPrefix])
    }
    this.updateArticles(this.currentCategory)
  }

  subscriptionArticles: Subscription = null
  updateArticles(category: string) {
    if (this.subscriptionArticles != null) {
      this.subscriptionArticles.unsubscribe()
      this.subscriptionArticles = null
    }

    this.subscriptionArticles = this.firestore.collection(this.keyCollection, (r => {
      let ref = r.where('status', '==', 'active')
      if (category && category != '_all') {
        ref = ref.where('category', '==', category)
      }
      return ref.orderBy('createAt', 'desc')
    }))
      .valueChanges().subscribe(values => {
        if (values && values.length > 0) {
          this.articles = []
          values.forEach(data => {
            const d: any = data
            const article: TheArticle = d
            article.createAt = (<firebase.firestore.Timestamp>d.createAt).toDate()
            article.updateAt = (<firebase.firestore.Timestamp>d.updateAt).toDate()
            this.articles.push(article)
          })
        } else {
          this.articles = []
        }

      })
  }
  async updateFeatureArticles() {
    this.mapArticleCategories.forEach((catName, catId) => {
      this.featureArticles.set(catId, null)
      this.firestore.collection(this.keyCollection, (rawRef => {
        let ref = rawRef.where('status', '==', 'active')
          .where('category', '==', catId)
          .orderBy('createAt', 'desc')
          .limit(3)
        return ref
      })).get().toPromise()
        .then(d => {
          if (d && !d.empty && d.size > 0 && d.docs) {
            const mapArticles = new Map<string, TheArticle>()
            for (const snap of d.docs) {
              if (snap && snap.exists) {
                const data = snap.data()
                if (data && data.id) {
                  const d: any = data
                  const article: TheArticle = d
                  article.createAt = (<firebase.firestore.Timestamp>d.createAt).toDate()
                  article.updateAt = (<firebase.firestore.Timestamp>d.updateAt).toDate()
                  mapArticles.set(article.id, article)
                }
              }
            }
            this.featureArticles.set(catId, mapArticles)
            console.log(this.featureArticles)
          } else {
            this.featureArticles.set(catId, (new Map()))
          }
        }).catch(e => {
          console.error(e)
          this.featureArticles.set(catId, (new Map()))
        })
    })
  }
  get mapArticleCategories() { 
    const url = this.router.url
    if (url.startsWith('/sportArticle')) {
    return this.i18n.translate.map_sport_article_categories
  } else if (url.startsWith('/sportRewine')) {
    return this.i18n.translate.map_sport_rewine_categoies
  } else if (url.startsWith('/sportRevision')) {
    return this.i18n.translate.map_sport_revision_categories
  }else{
    return this.i18n.getStringMap(checkIsInCareerAdvisor() ? 'map_ca_blog_categories' : 'map_blog_categories')
  }
  }


  get isInCareerAdvisor() {
    return checkIsInCareerAdvisor();
  }

  hideForNotOriginal(){
    const url = this.router.url
    if(url.startsWith('/ca/article')){
      return false;
    }else if(url.startsWith('/article')){
      return false;
    }

    return true;
  }
}
