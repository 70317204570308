import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { checkIsInCareerAdvisor } from 'src/app/class/common-objects.enum';

@Pipe({
  name: 'articleCategory'
})
export class ArticleCategoryPipe implements PipeTransform {
  i18n = new I18n()

  transform(value: string): string {
    return this.i18n.getStringMap(checkIsInCareerAdvisor()? 'map_ca_blog_categories' : 'map_blog_categories').get(value)
  }

}
