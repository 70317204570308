import { LanguageInterface } from '../language-interface';
import { TheAdvisorBookingRecordStatus } from '../../class/theAdvisorBooking';
import { CompanyUserPermissionEnumItem } from '../../class/CompanyUserPermission';
import { AdminPermissionEnumItem } from 'src/app/class/AdminPermission';
import { enumWhatsappTemplates } from 'src/app/class/WhatsappTemplatesEnumKeys';

export const En: LanguageInterface = {
    appname: "HRCA",
    login: "Login",
    captial_or: "OR",

    firebaseError: new Map([
        ["auth/user-disabled", "Account is disabled, maybe waiting approval or banned by admin."]
    ]),
    err_msg_enter_your_data: "Enter your data",
    err_msg_enter_your_fullname: 'Enter your fullname',
    err_msg_invalid_fullname: 'Invalid fullname',
    err_msg_select_your_worked_years: 'Select your worked years',
    err_msg_select_job_type: 'Select your last job type',
    err_msg_selct_job_title: 'Select your last job title',
    err_msg_invalid_job_title: 'Invalid job title',
    err_msg_enter_your_work_date_range: 'Enter your worked date range',
    err_msg_select_edu_level: 'Select your education level',
    err_msg_invalid_expect_salary: 'Invalid expect salary',
    err_msg_choose_privacy_setting: 'choose your privacy setting',
    err_msg_file_too_large: 'File too large',
    err_msg_disallow_file_type: 'Disallow file type',
    input_user_info_title: 'Your User Info',
    input_user_info_subtitle: 'Enter your information to optomize browsing experience.',
    input_select_worked_years: 'Select worked years',
    input_worked_year_prefix: 'worked',
    input_worked_year_over_prefix: 'worked over',
    input_worked_year_postfix: 'years',
    select_edu_level: "Select your education level",
    map_edu_level: new Map([
        ["0below_f5", "Below F.5"],
        ["1f5_hkcee", "F.5 / HKCEE"],
        ["2f6_dsc", "F.6 / DSE"],
        ["3f7_hkal", "F.7 / HKAL"],
        ["4certificate", "Certificate"],
        ["5diploma", "Diploma"],
        ["6adgree_hd", "Associate Degree / Higher Diploma"],
        ["7bsc", "Bachelor"],
        ["8over_bsc", "Master or above"]
    ]),
    select_job_type: "Select your job type",
    map_job_type: new Map([
        ["accounting", "Accounting"],
        ["Adminstraion", "Adminstraion"],
        ["banking", "Bank/Finance"],
        ["catering_hospitality", "Catering/Hospitality"],
        ["construction_property", "Construction/Property"],
        ["design", "Design"],
        ["digital_web_mobile", "Digital/Web/Mobile"],
        ["education_training", "Education/Training"],
        ["engineering", "Engineering"],
        ["government_social_services", "Government/Social Services"],
        ["health_fitness_beauty_care", "Health/Fitness/Beauty Care"],
        ["human_resources", "Human Resources"],
        ["it", "Information Technology"],
        ["insurance", "Insurance"],
        ["legal_professional_services", "Legal/Professionall Services"],
        ["logistices_transportation", "Logistics/Transportion"],
        ["management", "Management"],
        ["manufacturing", "Manufacturing"],
        ["pr", "Marketing/Public Relations"],
        ["media_advertising", "Media Advertising"],
        ["medical_services", "Medical Services"],
        ["merchandising_purchasing", "Merchanding/Purchasing"],
        ["qc_qa", "Quality Control/Quality Assurance"],
        ["sales_cs", "Sales/Business Development/Customer Services"],
        ["science_lab_research", "Science/Lab/Research"],
        ["security_property_management_safety_control", "Security/Property Management"],
        ["travel_tourism", "Travel/Tourism"],
        ["secretarial_or_administration", "Secretarial/Administration"],
        ['zother', 'Others']
    ]),
    show_your_profile_question: "Show your profiles to employer anonymously?",
    map_profile_privacy: new Map([
        ["public", "Yes, please public."],
        ["private", "No, Keep it private."]
    ]),
    your_cv_optional_title: "Your CV (Optional)",
    download_updated_cv: "Download Updated CV",
    btn_update: "Update",
    update_cv_limit_description: "*File Type: PDF/DOC/DOCX/JPG/PNG; Size Limit: 10MB",
    work_from: "Work From",
    work_to: "To",
    last_job_title: " Most current/lastest Job Exp. (Required)",
    menu_profiles: "Profiles",
    menu_logout: "Logout",
    menu_about: "About HRCA",
    menu_our_visions: "Our Visions",
    menu_our_services: "Our Services",
    menu_job_matching: "Talent Pool",
    menu_carrer_advisor: "Career Advisory Service",
    menu_events: "Events",
    map_event_types: new Map([
        ["recruitment_talk", "Recruitment Workshop"],
        ["career_talk", "Career Talk"],
        ["volunteer_activities", "Volunteer Activities"],
    ]),
    menu_blog: "Blog",
    sign_in: "Sign In",
    forget_password: "Forget Password",
    register: "Register",
    placeholder_mobile_phone: "Mobile Phone Number",
    placeholder_one_time_password: "One-Time Password",
    get_code: "Get Code",
    btn_phone_login: "Phone Login",
    login_with_email: "Login With Email",
    register_with_email: "Register with Email",
    use_phone_number_login: "Use Phone Number",
    placeholder_email_address: "E-Mail Address",
    placeholder_password: "Password",
    placeholder_repeat_password: "Repeat Password",
    forget_password_question: "Forget Password?",
    reset_password: "Reset Password",
    back: " Back",
    admin_management: "Admin Action",
    list_all_admins_action: "Admin List",
    nothing_to_show: "No results",
    loading: "Loading……",
    list_admin_title: "List of Admins",
    captial_UID: "UID",
    email: "E-Mail",
    email_verified: "E-Mail Verified",
    create_time: "Create Time",
    last_sign_in: "Last Sign-In",
    remove_this_account: "Remove this account",
    add: "Add",
    add_admin_title: "Add new admin",
    error_msg_invalid_email_address: "Invalie E-Mail address.",
    error_msg_please_enter_password: "Please enter password",
    error_msg_please_enter_confirm_password: "Please enter passowrd again",
    error_msg_password_mismatch: "password mismatch",
    error_msg_invalid_company_name: "Invalid Employer name",
    error_msg_invalid_company_desc: "Invalid Employer Description",
    error_msg_comapny_desc_too_short: "Employer Description too short.",
    notify_msg_verify_mail_sent: "Confirmation mail is sent to this E-Mail inbox。",
    notify_msg_admin_create_reset_mail_sent: "Create success, reset password mail is sent to this email inbox.",
    reload: "Reload",
    business_user_user: "Employer Register",
    placeholder_company_name: "Employer Name",
    placeholder_company_desc: "Employer Description",
    your_company_logo_title: "Employer Logo (Optional)",
    company_info_submitted_wait_approved: "Data reciveved! Please wait the approval.",
    list_all_companies_action: "Companies List",
    is_approved_question: "Approve?",
    action_approve: "Approve",
    name: "Name",
    business_corner: "Employer Corner",
    my_job_ads_action: "My Job Ads",
    list_company_title: "List of Company",
    placeholder_job_ads_title: "Title",
    placeholder_job_ads_duties: "Key responsibilities",
    placeholder_job_ads_requirements: "Requirements",
    placeholder_job_ads_description: "Description",
    placeholder_job_ads_short_description: "Short Description",
    placeholder_job_ads_position: "Position",
    placeholder_job_ads_location: "Location",
    placeholder_job_ads_education: "Education",
    placeholder_job_ads_salary: "Salary",
    placeholder_job_ads_welfare: "Welfare",
    placeholder_job_ads_workday: "Hours/Workdays",
    placeholder_job_ads_deadline: "Post until",
    placeholder_job_ads_metadata: "Metadata",
    placeholder_job_ads_is_application_hidden: 'Is Web Application hidden?',
    notify_msg_created_pending_approval: "Job Ads created/updated and pending the approval.",
    job_table_title: "Job Title",
    job_table_status: "Status",
    job_table_position: "Position",
    job_table_deadline: "Show until",
    menu_job_ads: "Job-Matching Service",
    menu_list_all_job_ads: "List all",
    list_pending_jobs_action: "List All Jobs",
    job_table_creator_uid: "Employer UID",
    job_table_creator_name: "Employer Name",
    job_table_job_id: "Job ID",
    job_table_job_title: "Job Title",
    job_table_job_position: "Job Position",
    job_table_job_approve: "Approve?",
    error_msg_action_failed: "Action failed.",
    notify_msg_action_success: "Action success.",
    job_table_job_status: "Job Status",
    menu_employee: "Employee",
    menu_member_register: "Member Registration",
    menu_my_account: "My Account",
    menu_cooperation: "Cooperation",
    menu_employments: "Employing",
    menu_ads_question: "Adsvertisement Query",
    menu_contact_us: "Contact Us",
    menu_resoucrces: "Resources",
    menu_latest_news: "Lastest News",
    menu_hot_articles: "Hot Topics",
    menu_latest_event: "Lastest Event",
    topbar_subtitle_hkjc360: "HK Jockey Club 360° Projects",
    submit: "Submit",
    job_table_job_preview: "Preview",
    apply_job: "Apply",
    map_job_location: new Map([
        ["central_and_western_district", "Central&Western"],
        ["eastern_district", "Eastern"],
        ["southern_district", "Southern"],
        ["wan_chai", "Wan Chai"],
        ["kowloon_city", "Kowloon City"],
        ["kwun_tong", "Kwun Tong"],
        ["ssp", "Sham Shui Po"],
        ["wong_tai_sin", "Wong Tai Sin"],
        ["yau_tsim_mong", "Yau Tsim Mong"],
        ["island", "Island District"],
        ["kwai_tsing", "Kwai Tsing"],
        ["north_district", "North District"],
        ["sai_kung", "Sai Kung"],
        ["sha_tin", "Sha Tin"],
        ["tai_po", "Tai Po"],
        ["tsuen_wan", "Tsuen Wan"],
        ["tuen_mun", "Tuen Mun"],
        ["yuen_long", "Yuen Long"],
        ["other", "Others"],
    ]),
    placeholder_job_types: "Job Indutry",
    map_job_employment_forms: new Map([
        ['fulltime', 'Fulltime'],
        ['parttime', 'Part-Time'],
        ['freelance', 'Freelance'],
        ['Project-based', 'Project-based'],
        ['other', 'Others']
    ]),
    placeholder_job_employmentForm: "Employment Forms",
    member_register: "Member Register",
    member_login: "Member Login",
    business_register: "Employer Register",
    business_login: "Employer Login",
    admin_mainapge_slider: "Mainpage Setting",
    job_application_success: "Application was submitted.",
    job_application_failed: "Failed Submitted",
    admin_job_application_list: "Job Application List",
    admin_job_application_count: "Job Application Count",
    job_matching_title: "人才庫 | Talent Pool",
    placeholder_last_job_title: "Recent Job Title",
    placeholder_job_type: "Job Industry",
    placeholder_job_worked_year: "Worked Year",
    placeholder_job_edu_level: "Education Level",
    placeholder_job_expect_salary: "Expected Salary",
    member: "Member",
    need_employee_question: "Need Employment?",
    contact_us: "Contact US",
    admin_ads_banner_list: "Ads Banner",
    active: "Active",
    deactive: "Deactive",
    placeholder_contact_name: "Contact Name",
    placeholder_contact_email: "Contact E-Mail",
    placeholder_contact_message: "Message",
    edit: "Edit",
    menu_mainpage_bottom_items: "Mainpage bottom items",
    list_all_normal_user_action: "List Normal Users",
    alreay_account: "Already registered?",
    placeholder_expect_salary: "Expected salary(Approximate number)",
    placeholder_fullname: "Full Name",
    apply_now: "Apply now",
    placeholder_name: "Name",
    placeholder_phone_number: "Phone Number",
    placeholder_email: "Email Address",
    message_was_uploaded: "Uploaded!",
    button_view_file: "View File",
    placeholder_keywords: "Keywords",
    search: "Search",
    search_history: "Search History",
    update: "Update",
    option_location: "Location",
    option_indutry: "Industry",
    option_employment_way: "Employment...",
    have_account_login_now: "Had account? Login now!",
    menu_admin_job_matching: "Manage Talents",
    membership_number: "Member ID",
    industry: "Industry",
    personal_info: "Overview",
    you_have_not_login: "You should login/register first!",
    you_had_loggged_on: "You are in. You should logout first!",
    view_all: "View All",
    view_more: "View More",
    no_more_results: "No more results.",
    menu_donation_to_us: "Donation",
    my_activities_action: "My Activities",
    map_activity_categories: new Map([
        ['recruitment_talk', 'Interview Tips'],
        ['career_talk_workshop', 'Career Info'],
        ['others', 'Event Review'],


    ]),
    add_activity_title: "Add activity",
    add_activity_placeholder_title: "Title",
    add_activity_placeholder_categories: "Category",
    add_activity_placeholder_location: "Location",
    add_activity_placeholder_address: "Address",
    add_activity_placeholder_description: "Description",
    add_activity_placeholder_price_per_person: "Fee/Person",
    add_activity_placeholder_start_at: "Activity start at",
    add_activity_placeholder_end_at: "Activity end at",
    add_activity_placeholder_application_deadling: "Application Deadline",
    msg_invalid_start_date_and_or_end_date: "Invalid Start Datetime and/or end datetime.",
    msg_deadline_is_later_than_start_date: "Application Deadline is newer than end datetime.",
    msg_start_date_or_end_date_on_pass: "Start date or end date was passed.",
    invalid_start_end_or_deadline: "Invalid start, end or deadling datetime.",
    remove: "Remove",
    menu_display_pending_activities: "List Activities",
    activity_holder: "Activity Holder",
    change_status: " Change Status",
    activity_datetime: "Datetime of activity",
    to: "To",
    from: "From",
    application_deadline_to: "Application deadline",
    activity: "Activity",
    map_list_activities_status: new Map([
        ["can_apply", "Opening Application"],
        ["helding", "On Progress"],
    ]),
    apply_status: "Applications",
    set_to_active: "Set to active",
    set_to_deactive: "Set to deactive",
    set_to_pending: "Set to pending",
    set_to_done: "Set to done",
    view: "View",
    unique_id: "Unique ID",
    leave_unique_id_blank_to_random_id: "Leave Unique ID blank to generate random long id (not suggest)",
    status_filtering: "Filter by status",
    type: "Type",
    click_url: "Click URL",
    company_filtering: "Filter by company",
    user_id: "User ID",
    apply: "Apply",
    homepage_silders: "Homepage Silders",
    contents: "Contents",
    button_text: "Button Text",
    button_url: "Button URL",
    image: "Image",
    donation_amount_with_dest: "Donation Amount in HKD (Must over HKD$10）",
    pay: "Pay",
    powered_by_stripe: "Powered by Stripe with HTTPS",
    know_more: "Know More",
    upload_image: "Upload Image",
    ads_banner_config: "Ads Banner Config",
    ban: "Ban",
    unban: "Unban",
    hongkong_mobile_number: "HK Phone Number",
    credit_debit_card_number: "Credit/Debit Card Number",
    card_holder_fullname: "Cardholder Name",
    expiryDate: "Expiry Date",
    continute: "Continute",
    account_action: "Account Action",
    account_banned: "Account Banned",
    plese_enter_all_information_to_donate: "Plese enter all information.",
    please_enter_valid_hk_mobile_phone_number: "Please enter valid Hong Kong mobile number.",
    please_enter_valid_email_address: "Please enter valid email address",
    please_enter_valid_credit_debit_card_information: "Please enter valid credit / debit card information.",
    please_enter_valid_name: "Please enter your name.",
    please_enter_valid_exp_date: "Please enter valid expiry date.",
    donation_amount_too_low: "Donation amount too low.",
    donation_success: "Donation Success.",
    title_contact_number: "Phone",
    title_address: "Address",
    menu_list_articles: "List Articles",
    add_article: "Add Article",
    title: "Title",
    job_position_apply: "Job Apply",
    add_activity_placeholder_speakers: "Speakers",
    add_activity_placeholder_quota: "Quotas（person)",
    person: 'Person',
    cover_image: "Cover Image",
    created_at: "Created at",
    updated_at: "Updated at",
    post_at: "Post at",
    blog_article_title: "Blog articles",
    update_article: "Edit Article",
    volunteer_recruitment: "Volunteer Recruitment",
    map_blog_categories: new Map([
        ["update_news", "Interviews"],
        ["event_news", "Event news"],
        ["career_advisor", "Career Info"],
        ["job_matching_articles", "Job Matching Articles"],
        ["happy_sporter_articles", "Happy Sporter Articles"],
        ["revivion_sport_course", "Sport Course Revision"],
        ["rewind_sporter_event", "Rewind Happy Sporter Event"],
        ["ca_ca_articles", "CA Articles"],
    ]),
    article_categories: "Article Categories",
    professional_qualification: "Professional Qualification",
    expertise: "Expertise",
    work_experience: "Career History",
    featured_activity: "Featured Activity",
    introduction: "Introduction",
    find_ca: "Get appointment",
    ca_blog: "Online Classroom",
    map_qual_languages: new Map([
        ["chinese", "Cantonese"],
        ["english", "English"],
        ["putonghua", "Putonghua/Mandarin "],
    ]),
    map_it_skills: new Map([
        ["msoffice", "MS Office"],
        ["video_editing", "Video Editing"],
        ["chinese_typing", "Chinese Typing"]
    ]),
    languages: "Languages",
    it_skills: "IT Skills",
    other: "Other",
    work_availability: "Availability",
    list_advisors: "List Advisors",
    add_advisor: "Add Advisor",
    advisor_name: "Advisor Name",
    placeholder_expertise: "Area of Expertise",
    placeholder_duration: "Consultation Duration",
    placeholder_consultion_fee: "Consultation Fee",
    placeholder_working_experience: "Career History",
    placeholder_major_achievements: "Major Achievements",
    placeholder_focus_advice: "Focus Advice",
    minutes: 'minutes',
    locate_language: "System Locate",
    hour: "hours",
    make_appointment: 'Make Appointment',
    update_at: "Update at ",
    book: "Booking",
    mapAdvisorBooking: new Map([
        [TheAdvisorBookingRecordStatus.not_paid, 'Waiting for payment'],
        [TheAdvisorBookingRecordStatus.wait_confirm, 'Waiting for confirmation'],
        [TheAdvisorBookingRecordStatus.confirmed, 'Booking confirmed'],
        [TheAdvisorBookingRecordStatus.refunded, 'Refunded'],
        ['pending_refunded', 'Handling Refund']
    ]),
    expect_book_datetime: "Expect booking Date&Time",
    remarks: "Remarks",
    booking_record: "Appointment Record",
    pay_by_credit_card_for_appointment_fee: "Pay for appointment fee by credit card",
    menu_ca_list_appoint_advisor: "List Appoint-Advisor",
    mark_paid: "Mark as Paid",
    mark_confirmed: "Confirm Appointment",
    mark_refund: "Refund",
    map_register_for: new Map([
        ["find_job", "Finding jobs"],
        ["become_advisor", "Applying for being career advisor (Our team will further contact you.）"],
        ["career_advisor_service", "Making appointment on Career advisory service"]
    ]),
    user_register_for: "Register for",
    choose_your_industry: "Choose your industry",
    all: "All",
    menu_job_industry_cats: "Manage Industry Cats",
    yes: "Yes",
    no: "No",
    parent_industry: "Parent Industry",
    sub_industry: "Sub Industry",
    suitable_position: "Suitable Position(s)",
    interest_to_be_ca: "Are you interested in being our HRCA career advisor? ",
    map_interest_tobe_ca: new Map<string, string>([
        ["interest", "es ( HRCA will contact you for further registration and authentication of bring our Career advisor)"],
        ["not_interest", "No"]
    ]),
    err_msg_choose_interest_ca: "Please tell us the interest of become career advisor。",
    privacy_dclaimer: "Personal Information Collection Statement: The information you provide will be used for the purposes of this recruitment or career advisor service and will be treated in the strictest confidence.",
    map_work_years: new Map([
        ['1', '0-2years'],
        ['2', '3-7years'],
        ['3', '8-10years'],
        ['4', '11-15years'],
        ['5', '16-20years'],
        ['6', '21years or above'],
    ]),
    worked_years: "Worked Year",
    worked_type: "WOrked Type",
    edu_level: "Edu. Level",
    details: "details",
    candidate_summary: "Summary:",
    n: 'n',
    want_employ_this_candidate: "Contact this candidate",
    this_month_activities: "Activity in month",
    map_company_permission: new Map([
        [CompanyUserPermissionEnumItem.company_edit_activity, "Add/Change Activity"],
        [CompanyUserPermissionEnumItem.company_edit_ca_activity, "Add/Change CA Event"],
        [CompanyUserPermissionEnumItem.company_edit_job, "Add/Change Job"],
        [CompanyUserPermissionEnumItem.company_edit_vol_eve_activity, "Add/Change Volu Eve"],
    ]),
    company_zero_permission: "You do not have any permissions, please contanct admins to follow up.",

    permissions: "permissions",
    choose_indutry: "Select Industry",
    select_in_talentpool: "Select Candidate in talentpool",
    though_hrca_touch_employee: "Touch candidate though HRCA",
    view_details: "View Details",
    career_info: "Career Info",
    youth_old_interview: "Young-Old Interview",
    hrca_update_news: "HRCA News",
    know_update_requitment_now: "Know updated recruitment now",
    more: "more",

    author: 'Author',


    main_about_us: 'About Us',

    jobs_latest_title: 'HRCA Latest News',
    jobs_latest_subtitle: 'Selected latest articles and event news',

    comma: ', ',

    map_admin_permission: new Map([
        [AdminPermissionEnumItem.admin_edit_user, "Add/Change User"],
        [AdminPermissionEnumItem.admin_edit_admin, "Add/Change Admin"],
        [AdminPermissionEnumItem.admin_edit_company, "Add/Change Company"],
        [AdminPermissionEnumItem.admin_edit_ads_banner, "Add/Change Ads Banner"],
        [AdminPermissionEnumItem.admin_edit_mainpage, "Add/Change Mainpage"],
        [AdminPermissionEnumItem.admin_edit_job, "Add/Change Job"],
        [AdminPermissionEnumItem.admin_edit_industry, "Add/Change Industry"],
        [AdminPermissionEnumItem.admin_edit_job_matching, "Add/Change Talent"],
        [AdminPermissionEnumItem.admin_edit_activity, "Add/Change Activity"],
        [AdminPermissionEnumItem.admin_edit_article, "Add/Change Article"],
        [AdminPermissionEnumItem.admin_edit_advisor, "Add/Change Advisor"],
        [AdminPermissionEnumItem.admin_edit_ca_article, "Add/Change CA Article"],
        [AdminPermissionEnumItem.admin_edit_ca_activity, "Add/Change CA Activity"],
        [AdminPermissionEnumItem.admin_download_raw_data, "Download Raw data"],
        [AdminPermissionEnumItem.admin_manage_whatsapp_templates, "WhatsApp Templates"],
        [AdminPermissionEnumItem.admin_manage_whatsapp_auto_response, "WhatsApp Auto-Response"],
    ]),
    admin_zero_permission: "You do not have any permissions, please contact the system administrator.",

    map_ca_blog_categories: new Map([
        ["career_info", "Interview Tips "],
        ["event_news", "Event Review"],
        ["career_advisor", "Career Info"],
    ]),
    advisor: "Advisor",
    related_articles: "Related Articles",

    job_result_prefix: "We Have ",
    job_result_suffix: " jobs for you. <br>Select a job to view details.",

    job_select_description: "Select industry, select Candidate in our talentpool & <br>contact the candidates through HRCA",

    apply_job_term_1: 'All the information received will be kept in strict confidence and for all purposes for recruitment or related purposes only.',
    apply_job_term_2: 'If you are not contacted within four weeks of submitting your resume, your application will be retained in our database. And it will be used for other appropriate positions in future after having your permission.',

    footer_contact_us: 'Contact Us',
    footer_know_more: 'Know More',
    footer_about_us: 'About Us',
    footer_terms_of_use: 'Terms of Use',
    footer_privacy_policy: 'Privacy Term',
    footer_follow_us: 'Follow Us',

    footer_tel: 'Tel: ',
    footer_whatsapp: 'WhatsApp: ',
    footer_email: 'Email: ',
    footer_address: 'Unit C, 4/F, Phase 5, Hong Kong Spinners Industrial Building,<br>760-762 Cheung Sha Wan Road, Kowloon, Hong Kong',

    contact_us_title: '聯絡我們 | Contact Us',
    contact_us_header: 'Contact Us >',
    contact_us_content: `
    <ul>
      <li class="contact-li">【Young-old employment related service: Talent search | job advertisements | recruitment talks
      <br><a href="mailto:job@happy-retired.org">job@happy-retired.org</a></li>
      <li class="contact-li">【Young-old development related service】: Career development workshop | volunteers recruitment
      <br><a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li class="contact-li">【Youth Career development related service】: Career advisory services | join our career advisory Team | career talks
      <br><a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li class="contact-li">General Enquiry: <a href="mailto:info@happy-retired.org">info@happy-retired.org</a> </li>
      <li class="contact-li">Media & advertisement: <a href="mailto:info@happy-retired.com">info@happy-retired.com</a> </li>
      <li class="contact-li">[New middle-aged sports related]: Hold physical activities ｜ Become the head of Ledong District
      <br><a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li class="contact-li">Users / Members need support: <a href="https://api.whatsapp.com/send?phone=85266865812" rel="nofollow noreferrer noopener" target="_blank">WhatsApp 6686 5812</a> </li>
    </ul>
    <br>Please feel free to contact us.
    `,
    contact_us_address: 'Address : Unit C, 4/F, Phase 5, Hong Kong Spinners Industrial Building, 760-762 Cheung Sha Wan Road, Kowloon, Hong Kong',

    main_our_service: 'Our Services',
    main_we_provide_1: 'Young-old Profile Accreditation',
    main_we_provide_2: 'Young-old Job Matching',
    main_we_provide_3: 'Young-old Career workshop / <br>Recruiment talk',
    main_we_provide_4: 'The Youth Career advisory service',
    main_we_provide_5: 'Volunteer recruitment',

    main_contact_us: 'Contact Us',
    main_contact_us_content: `
    <ul>
      <li>Job matching / job advertisement / recruitment talk / volunteers recruitment related: <a href="mailto:job@happy-retired.org">job@happy-retired.org</a></li>
      <li>Career talks/ Career advisory services related: <a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li>Happy-Sport enquiry / event / course: <a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li>Users / Members need support: <a href="https://api.whatsapp.com/send?phone=85266865812" rel="nofollow noreferrer noopener" target="_blank">WhatsApp 6686 5812</a> </li>
    </ul>
    <br>Please feel free to contact us.
    `,
    main_about_us_content: `
    <p>HRCA is an approved charitable institution in Hong Kong set up in 2015 with a dedicated online digital platform mainly targeting young-olds aged 45+ and the working youth and providing re-employment service and career advisory service, respectively.</p>
    <p>Our platform provides profile accreditation services and job matching services for the young-olds seeking job opportunities, so as to develop their second career.
    </p>
    <p>On the other hand, we aim to foster the interactions between the young-old and the youth, the young-olds can pass on their rich experiences and networks to young people via our platform and project, providing them with valuable advices in relation to their career path, knowledge of industry and professional development.</p>
    <p>Through our platform, young-olds and the youth can better understand how to establish their life and career goals and leading to the fulfillment of a brilliant life.
    </p>
    `,

    main_project_description: 'HKJC Golden Age Journey Project - Happy Maker 360',
    main_project_description_content: `
    <p>Thanks to the funding granted by The Hong Kong Jockey Club Charities Trust in 2020, HRCA has launched a three-year project named “Jockey Club Golden Age Journey Project - Happy Maker 360” which consists of two components of young-old re-employment service and career advisory service. Under the project, our platform provides more all-rounded information, trainings and workshops to young-old about how to equip themselves so as to develop their second career.</p>
    <p>On the other hand, we also enlarge our Career advisor team and keep promoting our career advisory services to the public and the other platforms. More young-olds joined us as career advisors, who provides professional advices on the youth's career path and workplace, more intergenerational integrations between them, both of their life become more brilliant, meaningful.</p>
    <p>Through our platform and "Jockey Club Golden Age Journey Project - Happy Maker 360”, young-olds can better understand how to establish their retirement life goals by making the most of their wealth of experiences and expertises to contribute to society, thus leading to the fulfillment of a brilliant second life, while the youth might benefit from the interactions with young-olds.</p>`,

    common_close: 'Close',

    jobs_register_member: 'Register as member',
    jobs_submit_cv: 'Submit CV',
    jobs_browse_jobs: 'Browse openning jobs',

    experience_new_company: 'New Employer',
    experience_company: 'Employer',
    experience_details: 'Details',

    register_term_1: 'I agree that Happy-Retired Charity Action Website (HRCA) makes use of my personal information so as to provide information about recruitments of jobs and volunteers, events and career advisory services.',
    register_term_2: 'I confirmed that I have read and agree with the "<a href="/terms" target="_blank">Term of Use</a>" & "<a href="/privacy" target="_blank">Privacy Term</a>" of Happy-Retired Charity Action Website (HRCA).',
    err_reg_form_checkboxes: 'Please check the last two checkboxes to agree the terms to continue.',
    err_reg_form_register_for: 'Please choose at least one of the options for "Register for"',

    menu_join_ca: 'Join our Career Advisor Team',

    all_activities: 'All Activities',
    apply_activity_term_1: 'Events/workshops are only offer to members of Happy-Retired Charity Action Website(HRCA).',
    apply_activity_term_2: 'If you are non-member, we will help you to register as member basing on the above information provided.',
    apply_activity_term_3: '<a href="/terms" target="_blank">"Term of Use"</a> & <a href="/privacy" target="_blank">"Privacy Term"</a> of Happy-Retired Charity Action Website (HRCA)',
    err_apply_activity_form_checkboxes: 'Please check the last two checkboxes to agree the terms to continue.',

    supported_appointment_format: 'Appointment Format',
    phone_call: 'Phone Call',
    face_to_face: 'Face to Face',

    appointment_count: 'Appointment Count',

    menu_talent_search: 'Talent Search Service',

    your_cv_title: 'Your CV',
    job_application_failed_no_cv: 'Please also provide the CV.',

    manage_qna_list: "Manage QnA",

    qna: "Q&A",


    clear_tag: "clear tag",
    tag: "tag",
    visible: "visible",
    content: "content",
    tags: "tags",
    coverImage: "cover image",
    short_question: "Q",
    short_answer: "A",
    left: "left",
    right: "right",
    question: "question",
    answer: "answer",
    add_line: "add line",
    new_tag: "new tag",
    upload_cover: "upload cover",
    cancel: "cancel",
    action_success: "action success",
    action_failed: "action failed",
    please_upload_cover_image: "please upload cover image",
    hidden: "hidden",

    map_whatsapp_templates_key_translates: new Map([
        [enumWhatsappTemplates.confirmation_of_ca_advising_to_advisor, "Confirmation of advising, to advisor."],
        [enumWhatsappTemplates.confirmation_of_ca_advising_to_young, "confirmation of advising, to young."],
        [enumWhatsappTemplates.consultation_done_or_not_to_advisor, "consultation done or not, to advisor."],
        [enumWhatsappTemplates.consultation_done_or_not_to_young, "consultation  done or not, to young."],
        [enumWhatsappTemplates.consultation_is_done_message_to_advisor, "consultation is done message, to advisor."],
        [enumWhatsappTemplates.consultation_not_done_message_to_advisor, "consultation not done or not, to advisor."],
        [enumWhatsappTemplates.done_and_thanks_to_young, "done and thanks, to young."],
        [enumWhatsappTemplates.evaluation_form_to_young, "evaluation form, to young."],
        [enumWhatsappTemplates.reminder_before_few_hour_to_advisor, "reminder before few hours, to advisor."],
        [enumWhatsappTemplates.reminder_before_few_hour_to_young, "reminder before few hour to young, to young."],
        [enumWhatsappTemplates.will_reschedule_question_to_young, "will rechedule question, to young."],
    ]),
    real_job_matching: "Job Matching",
    book_career_advisor: "Book Career Advisor",
    event_rewind: "Event Review",
    trainning_revision: "Training Revision",
    fitness_course: "Customised Courses",
    happy_local_sporter: "Community Leader",
    intro: "Introduction",
    happy_sporter: "Physical Fitness",
    ca_dot_20: "Career Talk • Career Planning • Others",
    ca_online_cla_02: "Interview Tips • Career Info • Event Review",
    about_us: "About us",
    jobMatchingIntro_content1: "Acting as a bridge between employers and young-olds, HRCA strives to provide unique and professional services to all.",
    mission: "Mission",
    jobMatchingIntro_content2: `Our mission is to unlock the value of the young-olds and help them
    maximize their contributions for the greater good of our society. We also strive to foster employer's recognition of the young-olds as we
    believe organizations can benefit tremendously from their wealth of career
    experience, expertise and networks.`,
    job_matching: "Job Matching",
    talent_search: "Talent Search",
    profile_accreditation: "Profile Accreditation",

    map_sport_rewine_categoies: new Map([
        ["event_sharing", "Event Sharing"],
        ["personal_sharing", "Personal Sharing"],
    ]),
    map_sport_revision_categories: new Map([
        ['group_revision', 'Group Event Revision'],
        ['area_leader_revision', 'Leader Event Revistion'],
        ['fitness_training_revision', 'Fitness Training Revision'],
    ]),
    map_sport_article_categories: new Map([
        ["health_express", "Health Express"],
        ["sport_tips", "Sport Tips"],
    ]),

    now_event: "Latest Activities",
    ended_event: "Past Activities",

    map_health_questionire_age: new Map([
        ["below-59", "below 59"],
        ["60-65", "60 to 65"],
        ["66-70", "66 to 70"],
        ["71-75", "71 to 75"],
        ["76-80", "76 to 80"],
        ["81-90", "81 to 90"],
        ["90upper", " 90 up"],
    ]),
    map_health_questionire_sport_time_per_week: new Map([
        ["0-1_times", "0 to 1 times"],
        ['2-4', "2 to 4 times"],
        ['5-6', '5 to 6 times'],
        ['7', 'everyday'],
    ]),
    map_health_questionire_sport_minute_per_time: new Map([
        ['below_15', 'below 15 minutes'],
        ['16-30', '16 to 30 minutes'],
        ['31-60', '31 to 60 minutes'],
        ['60up', '60 minutes up'],
    ]),

    pending: "Pending",
    accepted: "Accepted",
    canceled: "Canceled",

    sport_articles: "GSport Articles",
    sport_revistions: "GSport Revisions",
    sport_rewines: "GSport Rewines",
    sport_courses_edit: "GSport Courses Manage",


    select_good_job_to_get_ca_appoinment_0: "Select the INDUSTRY and one advisor you would like to talk with",
    select_good_job_to_get_ca_appoinment_1: "Click Here! Make Appointment Now!",
    zoom_call: 'Zoom call',
    view_count: "View count",




    employer: "Employer",
    young_olds: "Young-Olds",
    job_matching_about_us_emp_point_0: "Free up time and resources to stay focused on core business activities",
    job_matching_about_us_emp_point_1: "Peace of mind with HRCA as a reliable work partner to find the right match",
    job_matching_about_us_emp_point_2: "Enjoy our one-stop solution with dedicated services and multiple search aids",
    job_matching_about_us_young_point_0: "Unleash your value through getting the right job match",
    job_matching_about_us_young_point_1: "Get profile accreditation for more exposure and employment opportunities",
    job_matching_about_us_young_point_2: "Benefit from our cross-generation skills training and adapt well in the millennial workplace",
    service_scope: "Service Scope",
    job_matching_service: "Job Matching Service",
    talent_search_service: "HRCA Talent Search Service",
    profile_accredition: "Profile Accreditation",
    events: "Events",
    service_scope_desc_0: "Basic matching of  young-olds with jobs according to employer requirements (Full-time/Part-time/Freelance etc)",
    service_scope_desc_1: "Multi-facet talent solutions to help employer identify the best-matching young-olds to meet organizational needs",
    service_scope_desc_2: "Get more employer recognition through profile accreditation and join our database to increase employment opportunities",
    service_scope_desc_3: "A variety of activities to help young-olds well-equipped to re-join the job market (Training/Seminars/Workshops, etc.)",



    target_candidates_title: "Target Candidates",
    target_candidates_point_0: "Young-olds talents at their golden-age",
    target_candidates_point_1: "Professionals who are still active in the job market",
    target_candidates_point_2: "Talents who possess wealth of experience, expertise, knowledge and people network",
    tss_why_title: "Why",
    tss_why_desc_0: "“Old but Gold”!",
    tss_why_desc_1: "Experience and exposure cannot be copied or taught easily",
    tss_why_desc_2: "It takes time and efforts to build teams with the most sought-after knowledge and business relationship",
    tss_why_desc_3: "A well-matched golden-ager can be a great mentor to supplement your existing team",
    hrca_professional_talent_search_team_title: "HRCA’s Professional Talent Search Team",
    hrca_professional_talent_search_team_point_0: "“Old but Gold!",
    hrca_professional_talent_search_team_point_1: "Consists of seasoned golden-age headhunting experts with solid expertise and rich experience",
    hrca_professional_talent_search_team_point_2: "Have proven track-record of successful placements in different business sectors and job functions",
    hrca_professional_talent_search_team_point_3: "Dedicated to help you tackle your talent challenges and meet business objectives",
    hrca_professional_talent_search_team_point_4: "Understand that a successful match encompasses critical skills, relevant experience, specific knowledge, cultural fit, development stage, mindset, attitude, chemistry….just to name a few!  ",
    our_expertise_title: "Our Expertise",
    our_expertise_desc_0: "Business Sectors:  retail, FMCG, F&B, hospitality, banking and finance, telecommunication, information technology, property, construction and engineering, manufacturing    ",
    our_expertise_desc_1: "Job Functions: general management, operations management, finance and accounting, human resources, sales and marketing, quality management, professional services",
    our_strengths_title: "Our Strengths",
    our_strentths_desc_0: "“Old but Gold”！",
    our_strentths_desc_1: "Dedicated team of consultants and research specialists to manage and drive each search assignment to completion and success",
    our_strentths_desc_2: "Acting as your business partner and offering you advice on recruitment strategy",
    our_strentths_desc_3: "Excellent understanding on entrepreneur expectations and client needs",
    our_strentths_desc_4: "Strong people network and diverse search channels",
    contact_us_and_talk_to_our_experts_today: "Contact US and Talk To Our Experts Today!",
    ca_blog_subtitti: "Career Talk • Career Planning • Others",
    service_scopes: "Service Scope",
    main_we_provide_sport: "Fitness Training",
    mainpage_sport_desc: `A new sports program will be launched for the new year. Hong Kong Island, Kowloon, New Territories East and New Territories West will launch sports leadership training, physical fitness courses and group activities, etc. Details will be announced when avliable.`,
    ole_navbar_text: "career planning education(OLE)",
    job_advise_license_number: "License No.",
    map_vol_eve_activity_categories: new Map([
        // ['0Causes', 'Causes'],
        ['1AnimalBenefits', 'Animal Benefits'],
        ['2ElderlyService', 'Elderly Service'],
        ['3Child_Youth', 'Child / Youth'],
        ['4EnvironmentalProtection', 'Environmental Protection'],
        ['5SocialIssue', 'Social Issue'],

    ]),
    my_volunteer_activities:"My Volunteer Activities",
    menu_display_pending_vol_activities:"Volunteer Activities",
    volunteer_activities: "Volunteer Activities",

    happy_miles: "Happy Miles",
    happy_mile_event_and_training: "Event and Trainning",
    happy_mile_age_work: "Happy Age Jobs",
    happy_mile_friendly_employer: "Happy Age Friendly Employer",
    job_self_test:"Self Job Test",
    ended_rewine: "Ended Events",
    happy_mile_event_and_course_intro: "Event & Course",
    happy_mile_employment_event: "Employment Events",
    happy_mile_result_sharing: "Experience Sharing",
    happy_mile_volunteer_pairing: "Volunteer Pairing",
    happy_mile_job_pairing: "Job Pairing",
    happy_mile_elite_pairing: "Elite Pairing",
    happy_mile_happy_age_elite_db: "Telent Pool",
    happy_mile_employer_case_sharing: "Employer Case Sharing",
    happy_mile_event_for_employer: "Events for Employer",
    happy_mile_360_premium_businesss: "360 Premium Business",
};
