<div class="thisBody">
  <div class="header">
    <span (click)="closeDialog()" class="close"> X </span>
    <span class="title">{{ title }}</span>
  </div>

  <br />
  <div>
    <div
      class="alert alert-danger errorMessage"
      role="alert"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <div
      class="alert alert-info errorMessage"
      role="alert"
      *ngIf="notifyMessage"
    >
      {{ notifyMessage }}
    </div>
  </div>
  <mat-dialog-content>
    <form class="example-form" [(id)]="formId" (ngSubmit)="onSave()">
      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("advisor_name") }}</mat-label>
        <input
          matInput
          [placeholder]="i18n.get('advisor_name')"
          name="advisor_name"
          [(ngModel)]="advisor.name"
          [ngModelOptions]="{ standalone: true }"
          required
        />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("industry") }}</mat-label>
        <mat-select
          [(ngModel)]="advisor.industry"
          matNativeControl
          required
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option
            *ngFor="let i of topIndustries | keyvalue"
            [value]="i.key"
            >{{ getIndustryNameById(i.key) }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("sub_industry") }}</mat-label>
        <mat-select
          [(ngModel)]="advisor.subIndustries"
          multiple
          
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option
            *ngFor="let i of subIndustries | keyvalue"
            [value]="i.key"
            >{{ getSubIndustryNameById(i.key) }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_expertise") }}</mat-label>
        <textarea
          matInput
          [placeholder]="i18n.get('placeholder_expertise')"
          name="advisor_expertise"
          [(ngModel)]="advisor.expertise"
          [ngModelOptions]="{ standalone: true }"
          required
        ></textarea>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>{{
          i18n.get("placeholder_duration") + "(" + i18n.get("minutes") + ")"
        }}</mat-label>
        <input
          matInput
          [placeholder]="
            i18n.get('placeholder_duration') + '(' + i18n.get('minutes') + ')'
          "
          name="duration"
          [(ngModel)]="advisor.duration_text"
          [ngModelOptions]="{ standalone: true }"
          required
        />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{
          i18n.get("placeholder_consultion_fee") + "(HKD)"
        }}</mat-label>
        <input
          matInput
          [placeholder]="i18n.get('placeholder_consultion_fee') + '(HKD)'"
          name="fee"
          type="number"
          min="0"
          [(ngModel)]="advisor.fee"
          [ngModelOptions]="{ standalone: true }"
          required
        />
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.translate.placeholder_phone_number}} (whatsapp only)</mat-label>
        <input
          matInput
          [placeholder]="i18n.translate.placeholder_phone_number+ ' (whatsapp only)'"
          name="advisor_phone_whatsapp"
          [(ngModel)]="advisor.whatsappNumber"
          [ngModelOptions]="{ standalone: true }"
          
        />
      </mat-form-field>

      <div flex>
        <mat-form-field class="example-full-width">
          <mat-label>{{ i18n.get("experience_new_company") }}</mat-label>
          <input
            name="experience"
            matInput
            placeholder="{{ i18n.get('experience_new_company') }}"
            [(ngModel)]="experience"
          />
        </mat-form-field>
        <button
          mat-mini-fab
          type="button"
          (click)="addExperience()"
          color="primary"
        >
          +
        </button>
      </div>

      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_working_experience") }}</mat-label>
        <textarea
          matInput
          [placeholder]="i18n.get('placeholder_working_experience')"
          name="advisor_expertise"
          [(ngModel)]="advisor.work_experience"
          [ngModelOptions]="{ standalone: true }"
          required
        ></textarea>
      </mat-form-field>


      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_major_achievements") }}</mat-label>
        <textarea
          matInput
          [placeholder]="i18n.get('placeholder_major_achievements')"
          name="work_exp"
          [(ngModel)]="advisor.achievements"
          [ngModelOptions]="{ standalone: true }"
          required
        ></textarea>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_focus_advice") }}</mat-label>
        <textarea
          matInput
          [placeholder]="i18n.get('placeholder_focus_advice')"
          name="focus_advice"
          [(ngModel)]="advisor.adviceFocus"
          [ngModelOptions]="{ standalone: true }"
          required
        ></textarea>
      </mat-form-field>

      <div flex="column">
        <h5>{{ i18n.get('supported_appointment_format') }}</h5>
        <mat-checkbox color="primary" [(ngModel)]="advisor.phone_call" [ngModelOptions]="{standalone: true}">{{ i18n.get('phone_call') }}</mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="advisor.face_to_face" [ngModelOptions]="{standalone: true}">{{ i18n.get('face_to_face') }}</mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="advisor.zoom_call" [ngModelOptions]="{standalone: true}">{{ i18n.get('zoom_call') }}</mat-checkbox>
      </div>

      <div
        class="dropzone"
        dropZone
        (dropped)="uploadFile($event)"
        [class.hovering]="isHovering"
      >
        <br />

        <h5>{{ i18n.get("cover_image") }}</h5>

        <div class="file">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              (change)="uploadFile($event)"
              accept=".jpg,.jpeg,.png"
            />
          </label>
          <span class="file-cta">
            <span class="file-icon">
              <i class="fa fa-upload"></i>
            </span>
            <span class="file-label">
              <span>
                <br />
                <div *ngIf="percentage | async as pct">
                  <span *ngIf="pct <= 99">
                    <progress
                      class="progress is-info"
                      [value]="pct"
                      max="100"
                    ></progress>

                    {{ pct | number }}%
                  </span>
                  <span *ngIf="pct == 100 && !downloadURL">
                    Loading... | 載入中...
                  </span>
                </div>
              </span>
            </span>
          </span>

          <img class="previewIcon" [src]="downloadURL" *ngIf="downloadURL" />
        </div>
      </div>
      <br /><br />
      <input
        class="btn btn-primary"
        type="submit"
        [value]="i18n.get('submit')"
        [disabled]="isRegBtnLoading"
      />
    </form>
  </mat-dialog-content>
</div>
