import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { firestore } from 'firebase';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { ISportCourse, ISportCourseStatus, ISportCourseType } from 'src/app/class/ISportCourse';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-add-edit-sport-course-dialog',
  templateUrl: './add-edit-sport-course-dialog.component.html',
  styleUrls: ['./add-edit-sport-course-dialog.component.less']
})
export class AddEditSportCourseDialogComponent implements OnInit {
  @ViewChild('thisForm') thisForm: ElementRef<HTMLFormElement>
  i18n = new I18n()
  isLoading = false;
  inId = ''
  get isEdit() {
    return !!this.inId
  }
  localHoldDateTime: Date
  course: ISportCourse = {
    title: '',
    subtitle: '',
    description: '',
    countName: '',
    holdDateTime: firestore.Timestamp.now(),
    holdDateText: '',
    holdTimeText: '',
    location: '',
    comingUpNotice: '',
    status: ISportCourseStatus.active,
    type: ISportCourseType.A
  }

  get allType() {
    console.log(Object.keys(ISportCourseType))
    return Object.keys(ISportCourseType)
  }

  get pickerMode() {
    const width = outerWidth | window.outerWidth | 0
    if (width >= 900) {
      return 'popup'
    } else {
      return 'dialog'
    }
  }
  constructor(
    private afFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<AddEditSportCourseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data && data.id) {
      this.inId = data.id
      console.log(this.inId)
      afFirestore.collection(dbKeys.sport_courses)
        .doc(this.inId)
        .get().toPromise()
        .then(v => {
          if (v && v.exists) {
            this.course = <ISportCourse>v.data()
            this.localHoldDateTime = this.course.holdDateTime.toDate()
          } else {
            dialogRef.close()
          }
        }).catch(e => {
          console.error(e)
          dialogRef.close()
        })
    }
  }

  ngOnInit() {
  }

  onTypeChange(t: ISportCourseType) {
    this.course.type = t;
    switch (t) {
      case ISportCourseType.A: {
        if (!this.course.subtitle) this.course.title = "躍進體能課程(A)"
        if (!this.course.subtitle) this.course.subtitle = "(共4堂,每週一堂)"
        if (!this.course.description) this.course.description = "旨在改善三高及可能引起之糖尿病旨在提升骨或冠心疾病風險。"
        if (!this.course.countName) this.course.countName = '第 N 堂'
        break;
      }

      case ISportCourseType.B: {
        if (!this.course.title) this.course.title = "喜動體能課程(B)"
        if (!this.course.subtitle) this.course.subtitle = "(共4堂,每週一堂)"
        if (!this.course.description) this.course.description = "旨在提升骨骼肌力、預防及減少痛症。"
        if (!this.course.countName) this.course.countName = '第 N 堂'

        break;
      }

    }
  }

  get title() {
    if (this.isEdit) {
      return `EDIT ${this.inId}`
    }
    return "ADD"
  }
  onDateTimeChange(v: Date) {
    console.log(v)
    this.course.holdDateTime = firestore.Timestamp.fromDate(v)
    this.course.holdDateText = `${v.getFullYear()}年${v?.getMonth() + 1}月${v.getDate()}日`
    this.course.holdTimeText = `${v.getHours() + "時"}${v.getMinutes() ? v.getMinutes() + "分" : ''} 開始`
  }
  onSubmit() {
    if (this.thisForm && this.thisForm.nativeElement?.reportValidity()) {
      console.log('ok')
      this.isLoading = true;
      if (this.isEdit) {
        this.afFirestore.collection(dbKeys.sport_courses)
          .doc(this.inId)
          .update(this.course)
          .then(v => {
            this.snackbar.open(`${this.i18n.translate.action_success}(${this.inId})`)
            this.dialogRef.close(this.inId)
            this.isLoading = false;
          }).catch(e => {
            console.error(e)
            this.snackbar.open(`${this.i18n.translate.action_failed}:${e.toString()}`)
            this.isLoading = false;
          })
      } else {
        this.afFirestore.collection(dbKeys.sport_courses)
          .add(this.course)
          .then(v => {
            this.snackbar.open(`${this.i18n.translate.action_success}(${v.id})`)
            this.dialogRef.close(v.id)
            this.isLoading = false;
          }).catch(e => {
            console.error(e)
            this.snackbar.open(`${this.i18n.translate.action_failed}:${e.toString()}`)
            this.isLoading = false;
          })
      }
    }
  }

}
