<div class="thisBody card">
  <div class="flex">
    <div class="left">
      <!--<app-random-ads-banner-w300h250></app-random-ads-banner-w300h250>-->
    </div>
    <div class="flexBottom">
      <div class="flexItem actionItem" *ngFor="let item of bottomListItems" (click)="item.clickCallback()">
        <img [src]="item.img" />
        <div>{{item.text}}</div>
      </div>
    </div>
    <div class="right">
      <app-random-ads-banner-w300h250></app-random-ads-banner-w300h250>
    </div>
  </div>
  <br>
  <app-bottom></app-bottom>
  <br>
</div>