import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material';
import { openActionsDialog, registerButtonGlobalId, submitCvGlobalId } from '../class/common-objects.enum';
import { I18n } from '../i18n/i18n';

@Component({
  selector: 'app-job-maching-intro',
  templateUrl: './job-maching-intro.component.html',
  styleUrls: ['./job-maching-intro.component.less']
})
export class JobMachingIntroComponent implements OnInit {
  showProjectDescription = false
  i18n = new I18n()
  thisImageItems:IThisProvideItem[] =[
    {
      image:'/assets/job_matching_icons/工作配對.png',
      title:()=>this.i18n.translate.job_matching_service,
      content:()=>this.i18n.translate.service_scope_desc_0,
      clickUrl:"/jobs"
    },
    
    {
      image:'/assets/job_matching_icons/專才搜尋.webp',
      title:()=>this.i18n.translate.talent_search_service,
      content:()=>this.i18n.translate.service_scope_desc_1,
      clickUrl:"/talentsearch"
    },
    
    {
      image:'/assets/job_matching_icons/工作履歷認證.png',
      title:()=>this.i18n.translate.profile_accreditation,
      content:()=>this.i18n.translate.service_scope_desc_2,
      clickUrl:"/talentpool",
    },
    
    {
      image:'/assets/job_matching_icons/活動.webp',
      title:()=>this.i18n.translate.events,
      content:()=>this.i18n.translate.service_scope_desc_3,
      clickUrl:"/featureActivities",
    },
    
  ]
  constructor(
    private auth:AngularFireAuth,
    private dialog : MatDialog,
  ) { }

  ngOnInit() {
  }

  openRegisterdialog() {
    const d = document.getElementById(registerButtonGlobalId);
    if (d) {
      d.click();
    }
  }
  goToProfile(){
 
      if (!this.auth.auth.currentUser) {
        openActionsDialog(
          this.dialog,
          this.i18n.get("you_have_not_login"),
          [{
            callback: () => {
              this.dialog.closeAll();
              this.openRegisterdialog();
            },
            text: this.i18n.get('register'),
            isPrimary: true,
          }],
        );
      } else {
        const d = document.getElementById(submitCvGlobalId);
        if (d) {
          d.click();
        }
      }
    
  }
}
interface IThisProvideItem {
  image: string,
  title:()=> string,
  content: ()=>string,
  clickUrl:string,

}