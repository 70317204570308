<div>
  <div
    class="alert alert-danger errorMessage"
    role="alert"
    *ngIf="errorMessage"
  >
    {{ errorMessage }}
  </div>
  <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
    {{ notifyMessage }}
  </div>
</div>
<mat-dialog-content class="form-add-update-job-ads">
  <form
    [(id)]="formId"
    class="scrollable-container"
    onsubmit="return false"
    (submit)="onFormSubmit()"
  >
    <label>{{ i18n.get("placeholder_job_ads_title") }}</label>
    <input
      type="text"
      [(ngModel)]="job.title"
      class="form-control"
      [placeholder]="i18n.get('placeholder_job_ads_title')"
      [ngModelOptions]="{ standalone: true }"
      required
      autofocus=""
    />
    <br />
    <div class="form-group">
      <label>{{ i18n.get("placeholder_job_ads_short_description") }}</label>
      <textarea
        [ngModelOptions]="{ standalone: true }"
        class="form-control rounded-0"
        rows="4"
        [(ngModel)]="job.topDescription"
      ></textarea>
    </div>
    <br />
    <div class="three-mat-div">
      <mat-form-field>
        <mat-label>{{ i18n.get("placeholder_job_types") }}</mat-label>
        <mat-select [(value)]="job.industry" required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let item of jobTypes" [value]="item">
            {{ jobTypeMaps(item) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ i18n.get("placeholder_job_employmentForm") }}</mat-label>
        <mat-select [(value)]="job.employmentForms" required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let item of employmentForms" [value]="item">
            {{ jobEmpFormMaps(item) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ i18n.get("placeholder_job_ads_location") }}</mat-label>
        <mat-select [(value)]="job.location" required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let item of locations" [value]="item">
            {{ jobLocationMaps(item) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <br />
    <div class="form-group">
      <label>{{ i18n.get("placeholder_job_ads_duties") }}</label>
      <div *ngFor="let value of defaultDuties; let i = index">
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          [(ngModel)]="job.duties[i]"
          class="form-control"
          (ngModel)="(job.duties[i])"
          [placeholder]="i18n.get('placeholder_job_ads_duties')"
          [required]="!i"
          autofocus=""
        />
      </div>
    </div>
    <div class="form-group">
      <label>{{ i18n.get("placeholder_job_ads_requirements") }}</label>

      <div *ngFor="let value of defaultRequirements; let i = index">
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          [(ngModel)]="job.requirements[i]"
          class="form-control"
          [placeholder]="i18n.get('placeholder_job_ads_requirements')"
          [required]="!i"
          autofocus=""
        />
      </div>
    </div>
    <div class="form-group">
      <label>{{ i18n.get("placeholder_job_ads_description") }}</label>
      <textarea
        [ngModelOptions]="{ standalone: true }"
        class="form-control rounded-0"
        rows="4"
        [(ngModel)]="job.bottomDescription"
      ></textarea>
    </div>

    <div class="form-group">
      <label>{{ i18n.get("placeholder_job_ads_metadata") }}</label>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fas fa-user-tie"></i
          ></span>
        </div>
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          [(ngModel)]="job.position"
          class="form-control"
          [placeholder]="i18n.get('placeholder_job_ads_position')"
          autofocus=""
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fas fa-school"></i
          ></span>
        </div>
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          [(ngModel)]="job.education"
          class="form-control"
          [placeholder]="i18n.get('placeholder_job_ads_education')"
          autofocus=""
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fas fa-dollar-sign"></i
          ></span>
        </div>
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          [(ngModel)]="job.salary"
          class="form-control"
          [placeholder]="i18n.get('placeholder_job_ads_salary')"
          autofocus=""
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fas fa-gift"></i
          ></span>
        </div>
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          [(ngModel)]="job.welfare"
          class="form-control"
          [placeholder]="i18n.get('placeholder_job_ads_welfare')"
          autofocus=""
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="far fa-clock"></i
          ></span>
        </div>
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          [(ngModel)]="job.workday"
          class="form-control"
          [placeholder]="i18n.get('placeholder_job_ads_workday')"
          autofocus=""
        />
      </div>
    </div>

    <mat-form-field class="full-width" [owlDateTimeTrigger]="jobDeadline">
      <input
        matInput
        class="full-width"
        [placeholder]="i18n.get('placeholder_job_ads_deadline')"
        [owlDateTime]="jobDeadline"
        [(ngModel)]="job.deadline"
        [ngModelOptions]="{ standalone: true }"
        required
      />
      <owl-date-time
        [pickerMode]="pickerMode"
        pickerType="calendar"
        #jobDeadline
      ></owl-date-time>
    </mat-form-field>
    <div class="form-group">
      <mat-slide-toggle
        [(ngModel)]="job.isApplicationHidden"
        [ngModelOptions]="{ standalone: true }"
        >{{
          i18n.get("placeholder_job_ads_is_application_hidden")
        }}</mat-slide-toggle
      >
    </div>

    <input
      type="submit"
      class="btn btn-primary btn-block"
      [value]="i18n.get('submit')"
    />
  </form>

  <br />
</mat-dialog-content>
