<div class="all">
    <h3>Sport course applications of {{inId}}</h3>
    <div class="loading" *ngIf="applications === null">
        <mat-progress-bar mode="buffer">
        </mat-progress-bar>
    </div>
    <div class="nothing" *ngIf="applications && applications.length <= 0">
        {{i18n.translate.nothing_to_show}}
    </div>
    <button (click)="exportCSV()">
        EXPORT CSV
    </button>
    <table id="table0_sport-course-applications-listing.component" *ngIf="applications && applications.length > 0">
        <tr>
            <td>ID</td>
            <td>Name</td>
            <td>Phone</td>
            <td>email</td>
            <td>address</td>
            <td>remarks</td>
            <td>Age</td>
            <td>do sport times per week</td>
            <td>do sport mintutes per time</td>
            <td>do what sport</td>

        </tr>
        <tr *ngFor="let a of applications">
            <td>{{a.id}}</td>
            <td>{{a.name}}</td>
            <td>{{a.phoneNumber}}</td>
            <td>{{a.email}}</td>
            <td>{{a.address}}</td>
            <td>{{a.remarks}}</td>
            <td>{{a.age}}</td>
            <td>{{a.sport_time_per_week}}</td>
            <td>{{a.sport_minute_per_time}}</td>
            <td>{{a.what_sport_do}}</td>
        </tr>
    </table>
</div>