<div class="loading" *ngIf="!course">
    <mat-progress-bar mode="buffer">
    </mat-progress-bar>
</div>
<div class="all" *ngIf="course && course.id">
    <form #form>
    <div class="backButton" routerLink="/sport-courses">
        < {{i18n.translate.back}} </div>
            <div class="courseInfo">
                <h5>課程資料</h5>
                <mat-form-field class="example-full-width">
                    <mat-label>名稱</mat-label>
                    <input matInput placeholder="名稱" name="n" [value]="course.title" readonly>
                </mat-form-field>

                <mat-form-field class="example-full-width" >
                    <mat-label>詳情</mat-label>
                    <textarea matInput placeholder="詳情" style="resize: none;" name="d" [value]="courseDetails.join('\n')"
                        [rows]="courseDetails.length" readonly>
                    </textarea>
                </mat-form-field>
            </div>
            <div class="contactInfo">
                <h5>聯絡資訊</h5>
                <mat-form-field class="example-full-width" >
                    <mat-label>姓名</mat-label>
                    <input matInput placeholder="Name" name="name" [(ngModel)]="application.name" required>
                </mat-form-field>


                <mat-form-field class="example-full-width">
                    <mat-label>電話號碼</mat-label>
                    <input type="number" matInput placeholder="Ex. Pizza" name="phoneNumber" [(ngModel)]="application.phoneNumber" required>
                </mat-form-field>


                <mat-form-field class="example-full-width">
                    <mat-label>電郵地址</mat-label>
                    <input type="email" matInput placeholder="Ex. Pizza"name="email" [(ngModel)]="application.email" required>
                </mat-form-field>


                <mat-form-field class="example-full-width">
                    <mat-label>通訊地址</mat-label>
                    <textarea matInput placeholder="Ex. It makes me feel..." name="address" [(ngModel)]="application.address" [rows]="application.address.split('\n').length" required></textarea>
                </mat-form-field>


                <mat-form-field class="example-full-width">
                    <mat-label>補充信息</mat-label>
                    <textarea matInput placeholder="Ex. It makes me feel..." name="remarks" [(ngModel)]="application.remarks" [rows]="application.remarks.split('\n').length" required></textarea>
                </mat-form-field>


            </div>

            <div class="health_questions">
                <h5>健康問卷</h5>
                <mat-form-field class="example-full-width">
                    <mat-label>年齡</mat-label>
                    <mat-select [(ngModel)]="application.age" name="age" required>
                        <mat-option *ngFor="let v of i18n.translate.map_health_questionire_age|keyvalue"
                            [value]="v.key">
                            {{v.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>每週運動次數</mat-label>
                    <mat-select [(ngModel)]="application.sport_time_per_week" name="times" required> 
                        <mat-option *ngFor="let v of i18n.translate.map_health_questionire_sport_time_per_week|keyvalue"
                            [value]="v.key">
                            {{v.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field class="example-full-width">
                    <mat-label>每次運動幾分鐘</mat-label>
                    <mat-select [(ngModel)]="application.sport_minute_per_time" name="minute" required>
                        <mat-option
                            *ngFor="let v of i18n.translate.map_health_questionire_sport_minute_per_time|keyvalue"
                            [value]="v.key">
                            {{v.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <mat-label>大多數做什麼活動</mat-label>
                    <input matInput placeholder="Ex. Pizza" [(ngModel)]="application.what_sport_do" name="do" required>
                </mat-form-field>
                
            </div>
            <button mat-raised-button color="primary" (click)="onSubmit()">
                {{i18n.translate.submit}}
            </button>
        </form>
    </div>