<div class="all">
    <div class="header">
        <div class="slider">
            <app-main-page-slider [pageType]="silderPageType"></app-main-page-slider>
        </div>
    </div>
    <div class="body">
        <div class="mainTitle">
            <span class="hant">
                賽馬會樂動一族計劃
            </span>
            <br>
            <!-- <span class="en">
            Jockey Club Happy Golden-Sports Project
            </span> -->
        </div>


        <br><br>
        <div class="sponsorList">
            <div class="imgs">
                <!-- <div class="left"> -->

                <!-- <div class="right"> -->
                    <div class="imgItem">
                        <div class="imgItemText">
                            捐助機構
                            <br>
                            Funded By
                        </div>
                        <div class="imgItemImage">
                            <img class="img spimg" src="/assets/sport_intro/sponer-0.png" height="180px"/>
                        </div>
                    </div>
                <!-- </div> -->
                <!-- <div class="right"> -->

                    <div class="imgItem">
                        <div class="imgItemText">
                            主辦機構
                            <br>
                            Organized By
                        </div>
                        <div class="imgItemImage">
                            <img class="img spimg" src="/assets/sport_intro/sponsor_2.png" height="120px"/>
                        </div>
                    </div>


                    <div class="imgItem">
                        <div class="imgItemText">
                            合作夥伴
                            <br>
                            Partnered With
                        </div>
                        <div class="imgItemImage">
                            <img class="img spimg" src="/assets/sport_intro/sponsor_3.png"  height="40px"/>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>

        <div class="block">
            <app-main-title [title]="'簡介 >'"></app-main-title>

            <p>
                不少中外研究均表示新中年保持適量、持之以恆的運動可改善體魄，提升活動能力之餘，更有機會減少中年常見身體毛病。因此，近年越來越多新中年認識運動的好
                處，由零開始、一步一步養成運動習慣。
            </p>
            <p>
                有見及此，由香港賽馬會慈善信託基金捐助、樂活新中年慈善動力(”HRCA”) 主辦的計劃，特別為新中年設計兩款全新體能課程，分別適合想預
                防、改善三高人士，或想提升骨骼肌力及減輕痛症人士、並培養定期恆常運動的習慣。
            </p>
            <p>
                此外，計劃也將於香港區、九龍、新界東、及新界西，招募新中年出任「樂動區長」(”區長”)，區長將於所屬區內，帶領及推動少做運動的同齡人士或長者，由
                零開始，一步步建立恆常運動的習慣， 提升體能，強健體魄。
            </p>
        </div>
        <div class="block">
            <app-main-title [title]="'提供服務 >'"></app-main-title>
            <div class="provides">
                <div class="item" *ngFor="let item of providesObjects">
                    <a [href]="item.url">
                        <img class="image" [src]="item.img" />
                        <div class="text">
                            {{item.text}}
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="block contact-block">
            <app-main-title [title]="'聯絡我們 >'"></app-main-title>
            <p>
                如有任何疑問/查詢，歡迎透過以下方式聯絡我們!
            </p>
            <p>
                電郵:<a href="mailto:info@happy-retired.org">info@happy-retired.org</a>
                <br>
                電話/WhatsApp:<a
                    href="https://api.whatsapp.com/send/?phone=85266865812&text=%E6%88%91%E6%83%B3%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A%E6%9C%89%E9%97%9C+%E8%B3%BD%E9%A6%AC%E6%9C%83%E6%A8%82%E5%8B%95%E4%B8%80%E6%97%8F%E8%A8%88%E5%8A%83">6686
                    5812</a>
                <br>
                地址:香港九龍長沙灣道760號香港紗廠第5期4樓C室
            </p>
        </div>
    </div>
    <br><br>
</div>