<div class="all">
    <div class="header">
        <h3>{{i18n.get('sport_courses_edit')}}</h3>
        <mat-form-field appearance="outline">
            <mat-label>Filtering Status</mat-label>
            <mat-select [(ngModel)]="selectedStatus" (selectionChange)="reloadCourses($event.value)">
               
              <mat-option *ngFor="let s of status" [value]="s">
                {{s}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="openAddChangeDialog()">
            {{i18n.translate.add}}
          </button>
    </div>
    <div class="body">
        <mat-progress-bar
            mode="buffer"
            *ngIf="courses === null">
        </mat-progress-bar>
        <p *ngIf="courses &&courses.length <= 0">
            {{i18n.translate.nothing_to_show}}
        </p>
<table *ngIf="courses && courses.length > 0">
    <tr>
        <td>ID</td>
        <td>TYPE</td>
        <td>TITLE</td>
        <td>SUBTITLE</td>
        <td>DESCRIPTION</td>
        <td>status</td>
        <td>CreateDateTime</td>
        <td>HoldDateTime</td>
        <td>Display_DateTime</td>
        <td>comingUpNotice</td>
        <td>ACTIONS</td>
        <td>View Applications</td>
    </tr>
    <tr *ngFor="let c of courses">
        <td>{{c.id}}</td>
        <td>{{c.type}}</td>
        <td>{{c.title}}</td>
        <td>{{c.subtitle}}</td>
        <td>{{c.description}}</td>
        <td>

            <mat-form-field appearance="none">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="c.status" (selectionChange)="onStatusChange(c.id, $event.value)">
                  <mat-option *ngFor="let s of status" [value]="s">
                    {{s}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </td>
        <td>{{c.createDateTime?c.createDateTime.toDate():''|date: 'short'}}</td>
        <td>{{c.HoldDateTime?c.HoldDateTime.toDate():'' | date: 'short'}}</td>
        <td>{{c.holdDateText}} <br> {{c.holdTimeText}}</td>
        <td>{{c.comingUpNotice}}</td>
        <td>
            <button mat-button (click)="openAddChangeDialog(c.id)">
                EDIT
            </button>
        </td>
        <td>
          <button mat-button [routerLink]="'/admin/sport-course-applications/'+c.id">
              View Applications
          </button>
        </td>
    </tr>
</table>
    </div>
</div>