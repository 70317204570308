<nav class="navbar navbar-expand-md navbar-light bg-navbar-custom" [ngClass]="{ 'ca-navbar': isInCareerAdvisor }">
  <a class="navbar-brand d-md-none w" href="/">{{ i18n.get("appname") }}</a>
  <button class="navbar-toggler w" type="button" data-toggle="collapse" #collapseButton id="collapseButton"
    data-target="#navbarSupportedContent1,#navbarSupportedContent2"
    aria-controls="navbarSupportedContent1 navbarSupportedContent2" aria-expanded="false"
    aria-label="Toggle navigation">
    <span>☰</span>
  </button>
  <div class="d-md-flex d-block w-100 contentThis">
    <div class="collapse navbar-collapse mx-auto w-auto justify-content-center" id="navbarSupportedContent1">
      <div class="navbar-nav">
        <ul class="navbar-nav mr-auto" *ngIf="!isInCareerAdvisor">
          <li class="nav-item">
            <a class="nav-link" (click)="goHome();closeNavIfNotcollapse();">
              {{ i18n.get("menu_about") }}</a>
          </li>


          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{i18n.translate.happy_miles}}
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li class="dropdown-submenu">
            <a class="test dropdown-item" href="#">{{i18n.translate.happy_mile_event_and_training}} ▾</a>
            <ul class="dropdown-menu">
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.intro}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_event_and_course_intro}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_employment_event}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_result_sharing}}</a>
              </li>
            </ul>
          </li>
          <li class="dropdown-submenu">
            <a class="test dropdown-item" href="#">{{i18n.translate.happy_mile_employer_case_sharing}} ▾</a>
            <ul class="dropdown-menu">
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.intro}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_volunteer_pairing}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_job_pairing}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_elite_pairing}}</a>
              </li>
            </ul>
          </li>
          <li class="dropdown-submenu">
            <a class="test dropdown-item" href="#">{{i18n.translate.happy_mile_friendly_employer}} ▾</a>
            <ul class="dropdown-menu">
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.intro}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_happy_age_elite_db}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_employer_case_sharing}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_event_for_employer}}</a>
              </li>
              <li>
                <a href=# class="dropdown-item">{{i18n.translate.happy_mile_360_premium_businesss}}</a>
              </li>
            </ul>
          </li>


      </div>
      </li>



      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ i18n.get("menu_carrer_advisor") }}
        </a>

        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="toCaIntro();closeNavIfNotcollapse();">
            {{i18n.get('intro')}}
          </a>
          <a class="dropdown-item" (click)="toCaAppointment();closeNavIfNotcollapse();">
            {{i18n.get('book_career_advisor')}}
          </a>
          <a class="dropdown-item" (click)="toCaBlog();closeNavIfNotcollapse();">
            {{ i18n.get("ca_blog") }}
          </a>
          <a class="dropdown-item" routerLink="/ca/featureActivities" (click)="closeNavIfNotcollapse();">
            {{i18n.translate.menu_events}}
          </a>
          <a class="dropdown-item" routerLink="/ca/ole" (click)="closeNavIfNotcollapse();">
            {{i18n.translate.ole_navbar_text}}
          </a>
          <a class="dropdown-item" routerLink="/ca/joinCaTeam" (click)="closeNavIfNotcollapse();">
            {{ i18n.get("menu_join_ca") }}
          </a>
          <!-- <div class="dropdown-divider"></div> -->

        </div>
      </li>


      <!--
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{i18n.get('real_job_matching')}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" (click)="true" href="/job-matching-intro">
                {{i18n.translate.intro}}
              </a>
              <a class="dropdown-item" (click)="toJobs();closeNavIfNotcollapse();">
                {{ i18n.get("menu_job_ads") }}
              </a>
              <a class="dropdown-item" (click)="toTalentSearch();closeNavIfNotcollapse();">
                {{ i18n.get("menu_talent_search") }}</a>
              <a class="dropdown-item" (click)="toJobMatching();closeNavIfNotcollapse();">
                {{ i18n.get("menu_job_matching") }}
              </a>
              <a class="dropdown-item" routerLink="/featureActivities" (click)="closeNavIfNotcollapse();">
                {{i18n.translate.menu_events}}
              </a>

            </div>
          </li> -->


      <li class="nav-item dropdown">
        <a class="nav-link " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          {{i18n.translate.job_self_test}}
        </a>
        <!--
          <li class="nav-item dropdown">
            <a class="nav-link " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [routerLink]="'/'+volunteerKeysROUTE">
              {{i18n.translate.volunteer_activities}}
            </a> -->
        <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" (click)="true" [href]="'/'+volunteerKeysROUTE">
                {{i18n.translate.volunteer_activities}}
              </a> -->
        <!-- <a class="dropdown-item" (click)="toJobs();closeNavIfNotcollapse();">
                {{ i18n.get("menu_job_ads") }}
              </a>
              <a class="dropdown-item" (click)="toTalentSearch();closeNavIfNotcollapse();">
                {{ i18n.get("menu_talent_search") }}</a>
              <a class="dropdown-item" (click)="toJobMatching();closeNavIfNotcollapse();">
                {{ i18n.get("menu_job_matching") }}
              </a>
              <a class="dropdown-item" routerLink="/featureActivities" (click)="closeNavIfNotcollapse();">
                {{i18n.translate.menu_events}}
              </a> -->
        <!-- <a class="dropdown-item" routerLink="/articles/category/job_matching_articles">
                {{i18n.translate.menu_blog}}
              </a> -->
        <!-- <div class="dropdown-divider"></div> -->

        <!-- </div> -->
        <!-- </li> -->


      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ i18n.get("happy_sporter") }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLink]="'/sport-intro'" (click)="closeNavIfNotcollapse();">
            {{i18n.get('intro')}}
          </a>
          <a class="dropdown-item" href="/sport-leader-apply" (click)="closeNavIfNotcollapse();">
            {{ i18n.get("happy_local_sporter") }}
          </a>
          <a class="dropdown-item" href="/sport-courses" (click)="closeNavIfNotcollapse();">
            {{i18n.get('fitness_course')}}
          </a>
          <a class="dropdown-item" href="/sportArticles" (click)="closeNavIfNotcollapse();">
            {{i18n.translate.menu_blog}}
          </a>

      <li class="dropdown-submenu">
        <a class="test dropdown-item" href="#">{{i18n.get('trainning_revision')}} ▾</a>
        <ul class="dropdown-menu">
          <li *ngFor="let item of mapSportRevisionCategories|keyvalue"><a [href]="'/sportRevisions/category/'+item.key"
              class="dropdown-item">{{item.value}}</a></li>
        </ul>
      </li>
      <a class="dropdown-item" routerLink="/sportRewines" (click)="closeNavIfNotcollapse();">
        {{i18n.get('event_rewind')}}
      </a>
      <!-- <div class="dropdown-divider"></div> -->

    </div>
    </li>


    <li class="nav-item dropdown">
      <a class="nav-link " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        {{i18n.translate.ended_rewine}}
      </a>

    <li class="nav-item">
      <a class="nav-link" (click)="toContactUs();closeNavIfNotcollapse();">{{
        i18n.get("menu_contact_us")
        }}</a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link" (click)="toDonationPage();closeNavIfNotcollapse();">{{
          i18n.get("menu_donation_to_us")
          }}</a>
      </li> -->
    </ul>

    <ul class="navbar-nav mr-auto" *ngIf="isInCareerAdvisor">
      <li class="nav-item">
        <a class="nav-link" (click)="toCaIntro();closeNavIfNotcollapse();">
          {{ i18n.get("introduction") }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="toCaAppointment();closeNavIfNotcollapse();">
          {{ i18n.get("find_ca") }}
        </a>
      </li>

      <li class="nav-item" *ngIf="isDev">
        <a class="nav-link" routerLink="/ca/qna" (click)="closeNavIfNotcollapse();">
          {{ i18n.get("qna") }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="toCaBlog();closeNavIfNotcollapse();">
          {{ i18n.get("ca_blog") }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/ca/featureActivities" (click)="closeNavIfNotcollapse();">
          {{ i18n.get("menu_events") }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/ca/joinCaTeam" (click)="closeNavIfNotcollapse();">
          {{ i18n.get("menu_join_ca") }}
        </a>
      </li>
    </ul>
    <!--  it is search bar!
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>
    -->
  </div>
  </div>
  <div class="loginNav">
    <app-login-dropdown></app-login-dropdown>
  </div>
  </div>
</nav>
