<div class="banner">
  <div class="image"></div>
  <div flex="column" class="bannerWrap">
    <div flex-item="grow-4">
      <h2>{{i18n.get('contact_us_title')}}</h2>
    </div>
    <div flex-item="grow-1">
    </div>
  </div>
</div>
<div class="container contactUsContainer">
  <div class="row">
    <div class="col-12 header">{{i18n.get('contact_us_header')}}</div>
  </div>
  <div class="row">
    <div class="col-12 content" innerHtml="{{i18n.get('contact_us_content')}}"></div>
  </div>
  <div class="row">
    <div class="col-12">{{i18n.get('footer_tel')}}2833 6755</div>
    <div class="col-12">{{i18n.get('footer_whatsapp')}}6686 5812</div>
    <div class="col-12">Facebook: <a [href]="sns.facebook">HRCA Charity</a></div>
    <div class="col-12">Instagram: <a [href]="sns.instagram">hrca_career</a></div>
    <div class="col-12">{{i18n.get('contact_us_address')}}</div>
  </div>
</div>