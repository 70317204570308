<div class="thisHeaderOuter">
  <div class="thisTitle">
    博客 Blog
    <hr style="width: 120px; background-color: black;" />
  </div>
  <div class="selfDescripeListOuter">
    <div class="selfDescripeList">
      <div class="selfDescripeItem">{{ i18n.get("career_info") }}</div>
      <div class="selfDescripeItem">•</div>
      <div class="selfDescripeItem">{{ i18n.get("youth_old_interview") }}</div>
      <div class="selfDescripeItem">•</div>
      <div class="selfDescripeItem">{{ i18n.get("hrca_update_news") }}</div>
    </div>
  </div>
  <div class="dOuter">
    <span style="display: inline-block;" class="selectFilterOuter">
      <div class="input-group sm-2 selectFilter0JobMatching">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">
            <i class="fas fa-search"></i>
          </label>
        </div>
        <select
          class="form-control"
          id="selectFilter0Article0"
          [(ngModel)]="currentCategory"
          (change)="onCategoriesChange()"
        >
          <option value="">{{ i18n.get("article_categories") }}</option>
          <option value="_all">所有/All</option>
          <option
            *ngFor="let o of mapArticleCategories | keyvalue"
            [value]="o.key"
          >
            {{ o.value }}
          </option>
        </select>
      </div>
    </span>
  </div>
  <div class="underLink">
    <a class="underLinkHref" routerLink="/jobs">
      {{ i18n.get("know_update_requitment_now") }}
      <i class="fas fa-arrow-right"></i>
    </a>
  </div>
</div>
<div class="thisfeatureArticle" *ngIf="isFeturePage">
  <div class="n" *ngIf="featureArticles.size <= 0">
    {{ i18n.get("loading") }}
  </div>
  <div class="articleListOuter" *ngIf="featureArticles.size > 0">
    <div class="catOuter" *ngFor="let cat of mapArticleCategories | keyvalue">
      <div class="catInner">
        <div class="thisListOuter">
          <div class="catTitleOuter">
            <div class="catTitle">{{ cat.value }}</div>
            <div
              class="catEnter btn btn-info"
              [routerLink]="articleCategoryLink(cat.key)"
            >
              {{ i18n.get("more") }} {{ cat.value }}
              <i class="fas fa-arrow-right"></i>
            </div>
          </div>
          <div class="thisList" *ngIf="featureArticles.get(cat.key) == null">
            {{ i18n.get("loading") }}
          </div>

          <div
            class="thisList"
            *ngIf="
              featureArticles.get(cat.key) != null &&
              featureArticles.get(cat.key).size <= 0
            "
          >
            {{ i18n.get("nothing_to_show") }}
          </div>
          <div
            class="thisList"
            *ngIf="
              featureArticles.get(cat.key) != null &&
              featureArticles.get(cat.key).size > 0
            "
          >
            <mat-card
              class="listItem"
              (click)="toArticle(o.key)"
              *ngFor="let o of featureArticles.get(cat.key) | keyvalue"
            >
              <a
                class="itemInner"
                [href]="'/article/' + o.key"
                onclick="return false;"
              >
                <div class="coverContainer">
                  <img
                    mat-card-image
                    class="cover"
                    [src]="
                      o.value.coverURL || '/assets/default-fallback-image.png'
                    "
                    *ngIf="o.value.coverURL"
                  />
                </div>
                <span mat-card-title class="title">{{ o.value.title }}</span>
                <div>
                  <span mat-card-content class="category">{{
                    mapArticleCategories.get(o.value.category)
                  }}</span>
                  <span mat-card-subtitle class="date"
                    ><span *ngIf="o.value.author" class="author"
                      >{{ o.value.author }} </span
                    >{{ dateToDisplayString(o.value.createAt) }}</span
                  >
                </div>
              </a>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="thisBody" *ngIf="!isFeturePage">
  <div class="thisListOuter">
    <div class="thisListInner">
      <div *ngIf="articles === null" style="text-align: center;">
        {{ i18n.get("loading") }}...
      </div>
      <div *ngIf="articles && articles.length <= 0" style="text-align: center;">
        {{ i18n.get("nothing_to_show") }}
      </div>
      <div class="thisList" *ngIf="articles && articles.length > 0">
        <mat-card
          class="listItem"
          *ngFor="let o of articles; index as i"
          (click)="toArticle(o.id)"
        >
          <a
            class="itemInner"
            [href]="'/article/' + o.id"
            onclick="return false;"
          >
            <div class="coverContainer">
              <img
                mat-card-image
                class="cover"
                [src]="o.coverURL || '/assets/default-fallback-image.png'"
                *ngIf="o.coverURL"
              />
            </div>
            <span mat-card-title class="title">{{ o.title }}</span>
            <div>
              <span mat-card-content class="category">{{
                mapArticleCategories.get(o.category)
              }}</span>
              <span mat-card-subtitle class="date">{{
                dateToDisplayString(o.createAt)
              }}</span>
            </div>
          </a>
        </mat-card>
      </div>
    </div>
  </div>
</div>
