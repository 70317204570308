import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, MemberTalentItem, openSimpleMessageDialog } from 'src/app/class/common-objects.enum';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsDialogComponent } from './contact-us-dialog/contact-us-dialog.component';
import { IndustryCatItem } from '../../class/IndustryCatItem';
import { i18n_locate } from 'src/app/i18n/i18n_const';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';

@Component({
  selector: 'app-list-job-matching',
  templateUrl: './list-job-matching.component.html',
  styleUrls: ['./list-job-matching.component.less']
})
export class ListJobMatchingComponent implements OnInit {
  i18n = new I18n()
  catId = ''

  topIndustries: Map<string, IndustryCatItem> = new Map()
  subIndustries: Map<string, IndustryCatItem> = new Map()

  masonryOptions = {
    columnWidth: '.grid-sizer',
  }
get coverUrl(){
  if(this.i18n.currentLocate =='hant'){
    return "/assets/job_matching_icons/Headbanner/Job Matching - Talent Pool 中文 version.png"
  }else{
    return "/assets/job_matching_icons/Headbanner/Job Matching - Talent Pool 英文 version.png"
  }
}
  constructor(
    public firestore: AngularFirestore,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private logger:LoggingUtilsService,
  ) {
    const id = activatedRoute.snapshot.paramMap.get('id')
    if (id) this.catId = id
  }
  
  ngOnInit() {
    this.query = this.query.bind(this)

    this.getRandomTalentItems()
    this.subIndustriesUpdate()

  }
  
  itemsEachPage = 5
  arrTalentItems: Array<MemberTalentItem> = null
  lastDoc: firebase.firestore.QueryDocumentSnapshot = null
  async getRandomTalentItems() {
    let result = await this.firestore.collection(dbKeys.job_matching_items
      , this.query).get().toPromise()
    if (result && result.docs) {
      this.arrTalentItems = []

      if (result.docs.length > 0) {
        for (const doc of result.docs) {
          if (doc && doc.exists) {
            const t = <MemberTalentItem>doc.data()
            this.arrTalentItems.push(t)
            this.lastDoc = doc
          }
        }
      }
    }
    console.log(this.arrTalentItems)
  }
  getIndustryNameById(id: string) {
    const s = this.topIndustries.get(id)
    if (s && s.id) {
      const industry = s
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name
      } else {
        return industry.en_name
      }
    }
    return '其他/Other'

  }

  sub01: Subscription = null
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe()
      } catch (e) { }
    }
    this.firestore.collection(dbKeys.map_industry_cats)
      .valueChanges().subscribe(d => {
        if (d && d.length > 0) {
          for (let o of d) {
            const any0: any = <any>o
            const industry: IndustryCatItem = <IndustryCatItem>any0
            if (industry && industry.id) {
              if (industry.parent_id) {
                this.subIndustries.set(industry.id, industry)
              } else {
                this.topIndustries.set(industry.id, industry)
              }
            }
          }
        }
      })
  }

  query(refRaw) {
    console.log(this)
    let ref = refRaw.orderBy('updatedAt', 'desc')
    if (this.catId && this.catId != '_all') {
      ref = ref.where('map_industries', 'array-contains', this.catId)
    }
    if (this.lastDoc) {
      ref = ref.startAfter(this.lastDoc)
    }
    return ref.limit(this.itemsEachPage)
  }

  async getAllTalentItems() {
    let result: firebase.firestore.QuerySnapshot
    if (this.arrTalentItems === null) {
      this.arrTalentItems = []
    }

    result = await this.firestore.collection(dbKeys.job_matching_items
      , this.query).get().toPromise()

    if (result && result.docs.length > 0) {

      for (const doc of result.docs) {
        if (doc && doc.exists) {
          const t = <MemberTalentItem>doc.data()
          this.arrTalentItems.push(t)
          this.lastDoc = doc
        }
      }
    } else {
      openSimpleMessageDialog(this.dialog, this.i18n.get('no_more_results'))
    }
  }
  onFilteringSelectChange(newValue: string) {
    console.log(newValue)
    if (newValue) {
      this.catId = newValue
      this.logger.log(LoggingKey.telentpool_category_view, newValue)
    }
    this.arrTalentItems = []
    this.lastDoc = null
    this.getRandomTalentItems()

  }
  openContactUsDialog() {
    let ref = this.dialog.open(ContactUsDialogComponent, {})
    ref.afterClosed().subscribe(() => {
      // after closed
    })
  }
  openDeatailDialog(id: string) {
    this.router.navigate(['talentpoolDetails', id])
    return
  }

  hightlightTableChecker(index: number) {
    const isDouble = index % 2 == 0
    if (isDouble) {
      return 'isDoubleBackground'
    } else {
      return ''
    }
  }
}
