<div class="thisBody">
  <div class="thisTitle">
    <h5>{{ i18n.get("menu_display_pending_activities") }}</h5>
  </div>
  <div class="thisContent">
    <div class="statusFilter">
      <mat-form-field>
        <mat-label>{{ i18n.get("status_filtering") }}</mat-label>
        <select
          matNativeControl
          required
          (change)="onStatusFilteringChange($event.target.value)"
        >
          <option value="all">All</option>
          <option selected value="pending">Pending</option>
          <option value="active">Active</option>
          <option value="deactive">Deactive</option>
        </select>
      </mat-form-field>
    </div>
    <div *ngIf="!activities">
      {{ i18n.get("loading") }}
    </div>
    <div *ngIf="activities && activities.length === 0">
      {{ i18n.get("nothing_to_show") }}
    </div>
    <table border="1" *ngIf="activities && activities.length > 0">
      <tr>
        <td>ID</td>
        <td>{{ i18n.get("add_activity_placeholder_title") }}</td>
        <td>{{ i18n.get("job_table_status") }}</td>
        <td>{{ i18n.get("visible") }}</td>
        <td>{{ i18n.get("activity_holder") }}</td>
        <td>{{ i18n.get("add_activity_placeholder_application_deadling") }}</td>
        <td>{{ i18n.get("add_activity_placeholder_start_at") }}</td>
        <td>{{ i18n.get("edit") }}</td>
        <td>{{ i18n.get("view") }}</td>
        <td>{{ i18n.get("featured_activity") }}</td>
        <td>{{ i18n.get("change_status") }}</td>
        <td>{{ i18n.get("apply_status") }}</td>
      </tr>
      <tr *ngFor="let item of activities">
        <td>{{ item.activity.id }}</td>
        <td>{{ item.activity.title }}</td>
        <td>{{ item.activity.status }}</td>
        <td>
          
<mat-slide-toggle [checked]="item.activity.visible" (change)="onVisibleChange(item.activity)">{{i18n.translate.visible}}?</mat-slide-toggle>
        </td>
        <td>{{ item.company.name }}</td>
        <td>{{ item.activity.deadline | dateTimeText }}</td>
        <td>{{ item.activity.startAt | dateTimeText }}</td>
        <td>
          <button
            class="btn btn-light"
            (click)="editActivity(item.activity.id)"
          >
            {{ i18n.get("edit") }}
          </button>
        </td>
        <td>
          <button
            class="btn btn-light"
            (click)="openActivityPreview(item.activity.id)"
          >
            {{ i18n.get("view") }}
          </button>
        </td>
        <td>
          <div
            *ngIf="item.isFeatured !== null && item.isFeatured"
            style="cursor: pointer"
            (click)="setFeaturedStatus(item.activity.id, false)"
          >
            <i class="fas fa-star"></i>
          </div>
          <div
            *ngIf="item.isFeatured !== null && !item.isFeatured"
            style="cursor: pointer"
            (click)="setFeaturedStatus(item.activity.id, true)"
          >
            <i class="far fa-star"></i>
          </div>

          <div *ngIf="item.isFeatured === null">
            <i class="fas fa-spinner"></i>
          </div>
        </td>
        <td>
          <a
            class="btn btn-light"
            *ngIf="item.activity.status == 'pending'"
            (click)="changeStatus(item.activity.id, 'active')"
          >
            {{ i18n.get("set_to_active") }}
          </a>

          <a
            class="btn btn-light"
            *ngIf="item.activity.status == 'deactive'"
            (click)="changeStatus(item.activity.id, 'active')"
          >
            {{ i18n.get("set_to_active") }}
          </a>

          <a
            class="btn btn-light"
            *ngIf="item.activity.status == 'active'"
            (click)="changeStatus(item.activity.id, 'deactive')"
          >
            {{ i18n.get("set_to_deactive") }}
          </a>
        </td>
        <td>
          <span *ngIf="item.activity.status == 'pending'">x</span>
          <a
            class="btn btn-light"
            *ngIf="item.activity.status != 'pending'"
            (click)="toApplicationList(item.activity.id)"
          >
            {{ i18n.get("view") }}
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
