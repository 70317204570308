<div>
  <app-main-title [title]="i18n.get('main_our_service')"></app-main-title>
  <div flex>
    <div flex="column align-center justify-center" flex-item="grow-1">
      <a href='/talentpool'>
        <div flex="align-center justify-center" class="imageWrap"><div><img src="/assets/icone-02.png" /></div></div>
        <div class="text">{{ i18n.get("main_we_provide_1") }}</div>
      </a>
    </div>
    <div flex="column align-center justify-center" flex-item="grow-1">
      <a href='/jobs'>
        <div flex="align-center justify-center" class="imageWrap"><div><img src="/assets/icone-03.png" /></div></div>
        <div class="text">{{ i18n.get("main_we_provide_2") }}</div>
      </a>
    </div>
    <div flex="column align-center justify-center" flex-item="grow-1">
      <a href='/featureActivities'>
        <div flex="align-center justify-center" class="imageWrap"><div><img src="/assets/icone-04.png" /></div></div>
        <div class="text" innerHtml="{{ i18n.get('main_we_provide_3') }}"></div>
      </a>
    </div>
  </div>
  <div flex>
    <div flex="column align-center justify-center" flex-item="grow-1">
      <a (click)="toCaIntro()">
        <div flex="align-center justify-center" class="imageWrap"><div><img src="/assets/icone-05.png" /></div></div>
        <div class="text">{{ i18n.get("main_we_provide_4") }}</div>
      </a>
    </div>
    <div flex="column align-center justify-center" flex-item="grow-1">
      <a routerLink="/sport-intro">
        <div flex="align-center justify-center" class="imageWrap"><div><img src="/assets/icon-sport.png" /></div></div>
        <div class="text">{{ i18n.get("main_we_provide_sport") }}</div>
      </a>
    </div>
    <div flex="column align-center justify-center" flex-item="grow-1">
      <a href='/featureActivities'>
        <div flex="align-center justify-center" class="imageWrap"><div><img src="/assets/icone-06.png" /></div></div>
        <div class="text">{{ i18n.get("main_we_provide_5") }}</div>
      </a>
    </div>
  </div>
</div>
