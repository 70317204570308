import { Component, OnInit, Input } from '@angular/core';
import { MemberTalentItem, textToArray } from 'src/app/class/common-objects.enum';
import { I18n } from 'src/app/i18n/i18n';
import { i18n_locate } from 'src/app/i18n/i18n_const';
import { IndustryCatItem } from 'src/app/class/IndustryCatItem';

@Component({
  selector: 'app-talentpool-card',
  templateUrl: './talentpool-card.component.html',
  styleUrls: ['./talentpool-card.component.less']
})
export class TalentpoolCardComponent implements OnInit {
  i18n = new I18n();
  @Input()
  item: MemberTalentItem;
  @Input()
  subIndustries: Map<string, IndustryCatItem>

  MAX_SUMMARY_DISPLAY: number = 4;

  constructor() { }

  ngOnInit() {
  }

  textToArray(t: string) {
    let array = textToArray(t)

    return textToArray(t)
  }

  getSubIndustryNameById(id: string, locate: i18n_locate): string {
    return this._getSubIndustryNameById(id, locate).trim()
  }
  _getSubIndustryNameById(id: string, locate: i18n_locate): string {
    const s = this.subIndustries.get(id)
    if (s && s.id) {
      const industry = s
      if (locate) {
        if (locate == i18n_locate.hant) {
          return industry.hant_name
        } else {
          return industry.en_name
        }
      } else {
        if (this.i18n.currentLocate == i18n_locate.hant) {
          return industry.hant_name
        } else {
          return industry.en_name
        }
      }
    }
    return '其他/Other'
  }
  arrSubIndustriesToOoList(t: Array<string>, locate: i18n_locate) {
    if (!t || t.length <= 0) {
      return '不適用 N/A'
    }

    const x = []
    for (const o of t) {
      const s = this.getSubIndustryNameById(o, locate)
      x.push(s)
    }
    return x.join(this.i18n.getStringWithLocate('comma', locate) as string)
  }
}
