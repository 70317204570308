import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { MatDialog } from '@angular/material';
import { AddUpdateMyActivityDialogComponent } from './add-update-my-activity-dialog/add-update-my-activity-dialog.component';
import { TheActivity } from 'src/app/class/the-activity';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { dbKeys, openSimpleMessageDialog, volunteerKeys } from 'src/app/class/common-objects.enum';
import { firestore } from 'firebase/app';
import { AddUpdateAdminJobMatchingComponent } from '../../admin/admin-list-job-matching/add-update-admin-job-matching/add-update-admin-job-matching.component';
import { checkIsInCareerAdvisor } from '../../../class/common-objects.enum';

@Component({
  selector: 'app-my-activities',
  templateUrl: './my-activities.component.html',
  styleUrls: ['./my-activities.component.less']
})
export class MyActivitiesComponent implements OnInit {
  i18n = new I18n()
  constructor(
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    public afAuth: AngularFireAuth
  ) { }
  checkInVolunteerEditing() {
    return location.pathname.includes('myVoluEveActivities')
  }
  get keyNameCollection() {
    if (this.checkInVolunteerEditing()) {
      return dbKeys.vol_eve_activities
    } else if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_the_activities
    } else {
      return dbKeys.the_activities
    }

  }
  get prefixListApplication() {
    if (this.checkInVolunteerEditing()) {
      return `/company/list${volunteerKeys}ActivityApplication/`
    } else if (checkIsInCareerAdvisor()) {
      return '/caCompany/listActivityApplication/'
    } else {
      return '/company/listActivityApplication/'
    }
  }
  get caPrefixToDirectLink() {
    if (this.checkInVolunteerEditing()) {
      return '/volu_eve/'
    } else if (checkIsInCareerAdvisor()) {
      return '/ca/listActivities/'
    }

    return '/listActivities/'
  }
  get categories(): Map<string, string> {
    if (this.checkInVolunteerEditing()) {
      return this.i18n.getStringMap('map_vol_eve_activity_categories')
    } else {

      return this.i18n.getStringMap('map_activity_categories')
    }
  }
  get locations(): Map<string, string> {
    return this.i18n.getStringMap('map_job_location')
  }
  getLocationText(key: string) {
    return this.locations[key]
  }
  activities: Array<TheActivity> = null
  ngOnInit() {
    this.updateActivities()
  }
  async updateActivities() {
    const currentUser = this.afAuth.auth.currentUser
    if (currentUser && currentUser.uid) {
      const result = await this.firestore.collection(this.keyNameCollection,
        (ref => ref.where('creator_uid', '==', currentUser.uid)
        )).valueChanges().subscribe(async result => {

          if (result && result.length > 0) {
            this.activities = []
            for (const d of result) {
              if (d) {
                const doc: any = d
                const data = doc
                const activity = <TheActivity>data
                try {
                  activity.createdAt = (doc.createdAt as firestore.Timestamp).toDate()
                  activity.updatedAt = (doc.updatedAt as firestore.Timestamp).toDate()
                  activity.startAt = (doc.startAt as firestore.Timestamp).toDate()
                  activity.endAt = (doc.endAt as firestore.Timestamp).toDate()
                  activity.deadline = (doc.deadline as firestore.Timestamp).toDate()
                } catch (e) {
                  openSimpleMessageDialog(this.dialog, `ERROR|錯誤：${e.toString()}`)
                }
                this.activities.push(activity)
              }
              console.log(this.activities)
            }
          } else {
            this.activities = []
          }
        })
    } else {
      location.replace('/')
    }
  }
  addDialog() {
    const ref = this.dialog.open(AddUpdateMyActivityDialogComponent, {})
    ref.afterClosed().subscribe(result => {

    })
  }
  editActivity(id: string) {
    id = id.trim()
    const ref = this.dialog.open(AddUpdateMyActivityDialogComponent, {
      data: {
        id: id
      }
    })
  }

  async removeActivity(id: string) {
    if (!id) return
    try {
      await this.firestore.collection(this.keyNameCollection).doc(id).delete()
    } catch (e) {
      console.error(e)
      openSimpleMessageDialog(this.dialog, `ERROR|錯誤：${e.message}`)
    }
  }
  stringToDate(str: string): Date | '' {
    if (str) {
      return new Date(str)
    } else {
      return ''
    }
  }
  dateToString(d: Date | ''): string {
    if (d) {
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}, ${d.getHours()}:${d.getMinutes()}`
    }
  }
}
