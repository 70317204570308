import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, UserRecordInMap } from 'src/app/class/common-objects.enum';
import { ILogging, LoggingKey } from 'src/app/utils/logger-utils.service';
import { IDownloadCsvMask } from '../raw-data-download.component';

@Component({
  selector: 'app-dl-raw-articles-category-change-logs',
  templateUrl: './dl-raw-articles-category-change-logs.component.html',
  styleUrls: ['./dl-raw-articles-category-change-logs.component.less']
})
export class DlRawArticlesCategoryChangeLogsComponent implements OnInit {

  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_Blog_Articles_category_search_logs'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.logging,ref=>ref.where('type','==',LoggingKey.article_category_change))
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
              "record_id",
              "category",
              "trigger_datetime",
              "trigger_uid",
            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <ILogging>docs.data()
            if (record && record.uid) {
              arrOutput.push([
                record?.id||'',
                record?.dataId||'',
                record?.createDateTime?.toDate().toLocaleString() || '',
                record?.uid || 'guest',

              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }

}
