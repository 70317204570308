import { Component, OnInit } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { dbKeys, UserRecordInMap, openSimpleMessageDialog } from "src/app/class/common-objects.enum";
import { I18n } from "../../../i18n/i18n";
import { filter } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: "app-list-normal-user-admin",
  templateUrl: "./list-normal-user-admin.component.html",
  styleUrls: ["./list-normal-user-admin.component.less"],
})
export class ListNormalUserAdminComponent implements OnInit {
  userRecords: UserRecordInMap[] = null;
  queryPhoneNumber: number | "" = "";
  i18n = new I18n();
  queryEmail = "";
  filterRules = {
    registerFor: "",
    workYear: "",
    workType: "",
    eduLevel: "",
  };
  constructor(
    public afFunctions: AngularFireFunctions,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getUpdate();
  }
  async getUpdate() {
    console.log(this.filterRules)
    let result = await this.firestore
      .collection(dbKeys.map_users_records, (r) => {
        let ref = <Query>r;
        if (this.filterRules.registerFor) {
          ref = ref.where("registerFor", "==", this.filterRules.registerFor);
        }
        if (this.filterRules.eduLevel) {
          ref = ref.where('educationLevel', '==', this.filterRules.eduLevel)
        }
        if (this.filterRules.workType) {
          ref = ref.where('jobType', '==', this.filterRules.workType)
        }
        if (this.filterRules.workYear) {
          ref = ref.where('workedYears', '==', this.filterRules.workYear)
        }
        return ref.orderBy('displayName');
      })
      .get()
      .toPromise();
    if (result) {
      this.userRecords = [];
      if (result.docs && result.size > 0) {
        for (const doc of result.docs) {
          if (doc && doc.exists) {
            const d: UserRecordInMap = <UserRecordInMap>doc.data();
            this.userRecords.push(d);
          }
        }
      }
    }
    console.log(this.userRecords);
  }
  openViewProfile(uid: string) {
    if (uid) {
      const url = `/admin/showUserProfile/${uid}`;
      open(url);
    }
  }
  async goProfileByPhoneNumber() {
    if (!this.queryPhoneNumber) {
      openSimpleMessageDialog(this.dialog, "Enter Phone Number");
    } else if (this.queryPhoneNumber.toString().length != 8) {
      openSimpleMessageDialog(this.dialog, "invalid phone number.");
    } else {
      const result = await this.firestore
        .collection(dbKeys.map_users_records, (ref) =>
          ref.where("phoneNumber", "==", `+852${this.queryPhoneNumber}`)
        )
        .get()
        .toPromise();
      if (result && result.docs && result.docs.length > 0) {
        let uid = "";
        for (const u of result.docs) {
          if (u.exists) {
            uid = u.data().uid;
            if (uid) {
              break;
            }
          }
        }
        this.openViewProfile(uid);
      } else {
        openSimpleMessageDialog(this.dialog, "user not found.");
      }
    }
  }
  async goProfileByEmail() {
    if (!this.queryEmail) {
      openSimpleMessageDialog(this.dialog, "Enter E-mail");
    } else if (
      !this.queryEmail.includes("@") ||
      !this.queryEmail.includes(".")
    ) {
      openSimpleMessageDialog(this.dialog, "invalid email address.");
    } else {
      const result = await this.firestore
        .collection(dbKeys.map_users_records, (ref) =>
          ref.where("email", "==", this.queryEmail)
        )
        .get()
        .toPromise();
      if (result && result.docs && result.docs.length > 0) {
        let uid = "";
        console.log(result.docs)
        for (const u of result.docs) {
          if (u.exists) {
            uid = u.data().uid;
            if (uid) {
              break;
            }
          }
        }
        this.openViewProfile(uid);
      } else {
        openSimpleMessageDialog(this.dialog, "user not found.");
      }
    }
  }
  onFiltering() {
    this.getUpdate();
  }
}
