import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { JobApplication, dbKeys, openSimpleMessageDialog } from 'src/app/class/common-objects.enum';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { JobAds } from 'src/app/class/job-ads';
import { CompanyInfo } from 'src/app/class/company-info';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-list-job-applications',
  templateUrl: './admin-list-job-applications.component.html',
  styleUrls: ['./admin-list-job-applications.component.less']
})
export class AdminListJobApplicationsComponent implements OnInit {
  errorMessage = ''
  notifyMessage = ''
  companyInfoValue = 'all'
  i18n = new I18n()
  jobs: Array<{
    job: JobAds,
    companyInfo: CompanyInfo
  }> = []

  arrApplications: Array<{
    display: boolean,
    job: JobAds,
    companyInfo: CompanyInfo,
    application: JobApplication,
    hrmarks: string,
  }> = []
  constructor(
    public firestore: AngularFirestore,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public dialog : MatDialog
  ) { }

  ngOnInit() {
    console.log(this.initId)
    this.reloadTable()
  }

  get resultCount() {
    if (this.arrApplications) {
      return this.arrApplications.filter(v => v.display).length
    } else {
      return 0
    }
  }
  async reloadTable() {
    let result = await this.firestore.firestore
    .collection(dbKeys.job_application).where('job_id', '==', this.initId).get()
    if (result && result.docs) {
      const arr: {
        display: boolean;
        job: JobAds;
        companyInfo: CompanyInfo;
        application: JobApplication;
        hrmarks: string;
      }[] = []
      const isInit = this.arrApplications.length <= 0
      for (let doc of result.docs) {
        console.log(doc.data())
        if (doc && doc.exists) {
          let d: JobApplication = <JobApplication>doc.data()
        
          let preJobAds = await this.firestore.collection(dbKeys.job_ads).doc(d.job_id).get().toPromise()
          let jobAds: JobAds = <JobAds>preJobAds.data()
          let preCInfo = await this.firestore.collection(dbKeys.company_info).doc(jobAds.creator_uid).get().toPromise()
          let cInfo: CompanyInfo = <CompanyInfo>preCInfo.data()
          let preHrmarks = await this.firestore.firestore.collection(dbKeys.job_application_hrmarks).doc(d.id).get()
          let hrmarks = ''
          if (preHrmarks.exists) {
            hrmarks = preHrmarks.data().hrmarks
          }
          let o = {
            display: true,
            job: jobAds,
            companyInfo: cInfo,
            application: d,
            hrmarks: hrmarks
          }
          if (isInit) {
            this.arrApplications.push(o)
          }
          arr.push(o)

        }
      }
      this.arrApplications = arr
    }
    console.log(this.arrApplications)

    console.log(this.jobs)
    this.updateJobs()
  }
  updateJobs() {
    const arr: Array<{
      job: JobAds,
      companyInfo: CompanyInfo
    }> = []
    for (let o of this.arrApplications) {
      if (arr.filter(ref => ref.job.id == o.job.id).length <= 0) {
        arr.push({
          job: o.job,
          companyInfo: o.companyInfo
        })
      }
    }
    this.jobs = arr
  }
  get initId() {
    let inId = this.activatedRoute.snapshot.paramMap.get('id')
    if (inId) {
      this.companyInfoValue = inId
    }
    return inId
  }
  async updateHrremarks(id: string, newMarks: string) {
    if (id && newMarks) {
      try {
        let result = await this.firestore.collection(dbKeys.job_application_hrmarks).doc(id).set({
          hrmarks: newMarks
        })
        openSimpleMessageDialog(this.dialog, 'Save success.')
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `failed: ${e.message}`)
      }
    }
  }
  async changeStatus(index: number, id: string, newStatus: "pending" | "done") {
    try {
      await this.firestore.collection(dbKeys.job_application).doc(id).update({
        status: newStatus
      })
      //this.reloadTable()
      openSimpleMessageDialog(this.dialog, 'action success.')
      this.arrApplications[index].application.status = newStatus
     
    } catch (e) {
      console.error(e)
      openSimpleMessageDialog(this.dialog, `fail: ${e.message}`)
    }
  }
  hideAllDisplay() {
    this.arrApplications.forEach((v, k) => { this.arrApplications[k].display = false })
  }
  openUserProfile(uid: string) {
    if (uid) {
      this.router.navigate(['admin', 'showUserProfile', uid])
    }
  }
  onCompanyFilterChange(id: string) {
    if(id=='all'){
      for(const i in this.arrApplications){
        this.arrApplications[i].display = true
      }
      return 
    }
    for (const i in this.arrApplications) {
      if (this.arrApplications[i].job.id == id) {
        this.arrApplications[i].display = true
      } else {
        this.arrApplications[i].display = false
      }
    }
    console.log(this.arrApplications)
  }
}
