import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { TheArticle } from 'src/app/class/the-article';
import { TheAdvisorBookingRecord } from 'src/app/class/theAdvisorBooking';
import { IDownloadCsvMask } from '../raw-data-download.component';


@Component({
  selector: 'app-dl-raw-articles',
  templateUrl: './dl-raw-articles.component.html',
  styleUrls: ['./dl-raw-articles.component.less']
})
export class DlRawArticlesComponent implements OnInit {

  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_Blog_Articles'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.blog_articles)
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
             'article_id',
             'author',               
              'title',
              'category',
              'cover_image',
              'status',

             'createDateTime',
             'content',

            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <TheArticle>docs.data()
            if (record && record.id) {
              arrOutput.push([
                record?.id||'',
                record?.author||'',
                record?.title||'',
                record?.category||'',
                record?.coverURL||'',
                record?.status||'',
                record?.content||'',

                (<firestore.Timestamp>(<any>record?.createAt)).toDate()?.toLocaleString()||'',


              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }
}
