<div class="thisBody">
    <h3>
        <span class="left" (click)="closeThis()">X</span>
        <span class="right">{{initId}}</span>
    </h3>
    <mat-dialog-content>
        <div *ngIf="!talnet" style="text-align: center;">
            {{i18n.get('loading')}}
        </div>
        <div class="thisContent" *ngIf="talnet">
            <div class="singleLine">
                <b class="left">{{i18n.getStringWithLocate('industry', talnet.locate)}}</b>
                <br>
                <span>{{getTopIndustryNameById(talnet.domain) || talnet.domain}}</span>
            </div>
            <br>
            <div class="singleLine">
                <b class="left">{{i18n.getStringWithLocate('expertise', talnet.locate)}}</b>
                <br>
                <ul>
                    <li *ngFor="let item of talnet.expertises">{{getSubIndustryNameById(item)}}</li>
                </ul>
            </div>
            <br>
            <div class="singleLine">
                <b class="left">{{i18n.getStringWithLocate('placeholder_job_employmentForm', talnet.locate)}}</b>
                <br>
                <ul>
                    <li *ngFor="let item of talnet.employment">{{i18n.getStringMapWithLocate('map_job_employment_forms', talnet.locate).get(item) || item}}</li>

                </ul>
            </div>
            <br>
            <div class="singleLine">
                <b class="left">{{i18n.getStringWithLocate('placeholder_job_edu_level', talnet.locate)}}</b>
                <br>
                <span>
                    <ul *ngIf="talnet.academicQualification &&
                    talnet.academicQualification.length &&
                    talnet.academicQualification.length > 0">
                        <li *ngFor="let o of talnet.academicQualification">
                            {{(i18n.getStringMapWithLocate('map_edu_level', talnet.locate).get(o)) || o}}
                            <ul *ngIf="o == '4certificate' && talnet.certDetails">
                              <li *ngFor="let p of textToArray(talnet.certDetails)">{{p}}</li>
                            </ul>
                            <ul *ngIf="o == '5diploma' && talnet.diplomaDetails">
                              <li *ngFor="let p of textToArray(talnet.diplomaDetails)">{{p}}</li>
                            </ul>
                            <ul *ngIf="o == '6adgree_hd' && talnet.hdDetails">
                              <li *ngFor="let p of textToArray(talnet.hdDetails)">{{p}}</li>
                            </ul>
                            <ul *ngIf="o == '7bsc' && talnet.bscDetails">
                              <li *ngFor="let p of textToArray(talnet.bscDetails)">{{p}}</li>
                            </ul>
                            <ul *ngIf="o == '8over_bsc' && talnet.masterDetails">
                              <li *ngFor="let p of textToArray(talnet.masterDetails)">{{p}}</li>
                            </ul>
                        </li>
                    </ul>
                </span>
            </div>
            <div class="singleLine" *ngIf="talnet.professionalQualification">
                <b class="left">{{i18n.getStringWithLocate('professional_qualification', talnet.locate)}}</b>
                <br>
                <span>
                    <ul>
                        <li *ngFor="let item of textToArray(talnet.professionalQualification)">
                            {{item}}
                        </li>
                    </ul>
                </span>
            </div>
            <br>

            <div class="singleLine" *ngIf="talnet.experience">
                <b class="left">{{i18n.getStringWithLocate('work_experience', talnet.locate)}}</b>
                <br>
                <span>
                    <ul>
                        <li *ngFor="let item of textToArray(talnet.experience)">
                            {{item}}
                        </li>
                    </ul>
                </span>
            </div>
            <br>

            <div class="singleLine" *ngIf="talnet.languages && talnet.languages.length>0">
                <b class="left">{{i18n.getStringWithLocate('languages', talnet.locate)}}</b>
                <br>
                <span>
                    <ul>
                        <ng-container *ngFor="let item of talnet.languages">
                            <li *ngIf="item != 'other'">
                                {{i18n.getStringMapWithLocate('map_qual_languages', talnet.locate).get(item)||item}}
                            </li>
                        </ng-container>

                        <ng-container *ngIf="talnet.languages && talnet.languages.includes('other')">
                            <li *ngFor="let item of textToArray(talnet.languages_other)">
                                {{item}}
                            </li>
                        </ng-container>
                    </ul>
                </span>
                <br>
            </div>

            <div class="singleLine" *ngIf="talnet.subIndustries && talnet.subIndustries.length>0">
                <b class="left">{{i18n.getStringWithLocate('suitable_position', talnet.locate)}}</b>
                <br>
                <span>
                    <ul>
                        <ng-container *ngFor="let item of talnet.subIndustries">
                            <li>
                                {{getSubIndustryNameById(item)}}

                            </li>
                        </ng-container>
                    </ul>
                </span>
                <br>
            </div>

            <div class="singleLine" *ngIf="talnet.it_skills && talnet.it_skills.length>0">
                <b class="left">{{i18n.getStringWithLocate('it_skills', talnet.locate)}}</b>
                <br>
                <span>
                    <ul>
                        <ng-container *ngFor="let item of talnet.it_skills">
                            <li *ngIf="item && item!='other'">
                                <span>{{i18n.getStringMapWithLocate('map_it_skills', talnet.locate).get(item)||item}}</span>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="talnet.it_skills && talnet.it_skills.includes('other')">
                            <li *ngFor="let item of textToArray(talnet.it_skills_other)">
                                {{item}}
                            </li>
                        </ng-container>
                    </ul>
                </span>
                <br>
            </div>

            <div class="singleLine" *ngIf="talnet.languages && talnet.languages.length>0">
                <b class="left">{{i18n.getStringWithLocate('work_availability', talnet.locate)}}</b>
                <br>
                <span>
                    {{talnet.availability}}
                </span>
                <br>
            </div>
        </div>
    </mat-dialog-content>
</div>
