import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { IFaqItemTag } from 'src/app/class/IFaqItem';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-select-qna-tag-dialog',
  templateUrl: './select-qna-tag-dialog.component.html',
  styleUrls: ['./select-qna-tag-dialog.component.less']
})
export class SelectQnaTagDialogComponent implements OnInit {
i18n = new I18n()
  tags : IFaqItemTag[] = null
  constructor(
    private afFirestore: AngularFirestore,
    private dialogRef: MatDialogRef<SelectQnaTagDialogComponent>
  ) {

    afFirestore.collection<IFaqItemTag>(dbKeys.faqTags, ref=>
      ref 
      .where('count', '>=' , 0)
      .orderBy('count', 'desc')
      .orderBy('updateDateTime','desc')
      )
      .valueChanges()
      .subscribe(v=>{
        this.tags = v || []
      })
   }

  ngOnInit() {
  }

  tagSeleected(tag: IFaqItemTag){
    if(tag && tag.name){
      this.dialogRef.close(tag.name)
    }
  }


}
