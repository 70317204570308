import { Component, OnInit } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { dbKeys, unsubscribeAll } from 'src/app/class/common-objects.enum';
import { ISportCourse, ISportCourseStatus } from 'src/app/class/ISportCourse';
import { I18n } from 'src/app/i18n/i18n';
import { AddEditSportCourseDialogComponent } from './add-edit-sport-course-dialog/add-edit-sport-course-dialog.component';

@Component({
  selector: 'app-admin-sport-course-edit',
  templateUrl: './admin-sport-course-edit.component.html',
  styleUrls: ['./admin-sport-course-edit.component.less']
})
export class AdminSportCourseEditComponent implements OnInit {
  selectedStatus = 'active'
  courses: ISportCourse[] = null
  i18n = new I18n()
  get status() {
    return [
      'active',
      'deactive',
    ]
  }
  constructor(
    private dialog: MatDialog,
    private afFirestore: AngularFirestore,
    private snackbar :MatSnackBar,
  ) { }

  ngOnInit() {
    this.reloadCourses(this.selectedStatus)
  }

  sub0LoadCourses: Subscription
  reloadCourses(status?: string) {
    console.log(status)
    this.selectedStatus = status
    unsubscribeAll([this.sub0LoadCourses])
    this.sub0LoadCourses = this.afFirestore.collection<ISportCourse>(dbKeys.sport_courses,
      r => {
        let ref = <Query>r
        if (this.selectedStatus) {
          ref = ref.where('status', '==', this.selectedStatus)
        }
        ref = ref.orderBy('createDateTime', 'desc')

        return ref;
      })
      .valueChanges().subscribe(v => {
        this.courses = v
      })
  }
  openAddChangeDialog(id?: string) {
    const d = this.dialog.open(AddEditSportCourseDialogComponent, {
      data: {
        id:id,
      }
    })
  }
  onStatusChange(id: string, v:ISportCourseStatus){
    if(id && v){
      this.afFirestore.collection(dbKeys.sport_courses)
      .doc(id)
      .update({
        status:v
      }).then(_=>{
        this.snackbar.open(this.i18n.translate.action_success)
      }).catch(e=>{
        console.error(e)
        this.snackbar.open(this.i18n.translate.action_failed +`:${e.toString()}`)
      })
    }
  }
}
