import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { makeid, cloudFunctionsKeys } from 'src/app/class/common-objects.enum';
import { I18n } from 'src/app/i18n/i18n';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.less']
})
export class ContactUsDialogComponent implements OnInit {
  fromId = `thisContactUsDialogForm_${makeid(10)}`
  i18n = new I18n()
  name = ''
  email = ''
  message = ''
  phoneNumber = ''
  currentUser : firebase.User = null
  get isSubmitDisabled(){
    if(this.isLoading){
      return 'disabled'
    }else{
      return ''
    }
  }
  isLoading = false
  constructor(
    @Inject(MAT_DIALOG_DATA) data : any,
    public dialogRef : MatDialogRef<ContactUsDialogComponent>,
    public afFunctions : AngularFireFunctions,
    public afAuth : AngularFireAuth
  ) {
    if(data && data.message){
      this.message = data.message
    }
   }

  ngOnInit() {

    const u = this.afAuth.auth.currentUser
      if(u && u.uid){
        if(u.email) this.email = u.email
        if(u.phoneNumber) this.phoneNumber = u.phoneNumber
        if(u.displayName) this.name = u.displayName
      }
  }
  async onSubmit(){
    this.isLoading = true
    const form = <HTMLFormElement> document.getElementById(this.fromId)
    if(form.reportValidity()){
      const result = await this.afFunctions.httpsCallable(cloudFunctionsKeys.sendContactUsMail)({
        name: this.name,
        email: this.email,
        message: this.message,
        phoneNumber: this.phoneNumber
      }).toPromise()
      console.log(result)
      if(result === true){
        alert(this.i18n.get('notify_msg_action_success'))
        this.closeThisDialog()
      }else{
        alert(this.i18n.get('error_msg_action_failed'))
      }
    }
    this.isLoading = false

  }
  contactUsHeaderBlocks : Array<HeaderBlocks> =[
    {
      iconClass: 'fas fa-phone',
      title: this.i18n.get('title_contact_number'),
      subtitle: '+852-28336755'
    },
    {
      iconClass: 'fab fa-whatsapp',
      title: 'Whatsapp',
      subtitle: '+852-66865812'
    },
    {
      iconClass: 'fas fa-map-marker',
      title: this.i18n.get('title_address'),
      subtitle: '長沙灣道香港紗廠第5期4樓C室'
    }
  ]
  closeThisDialog(){
    this.dialogRef.close()
  }

}


interface HeaderBlocks{
  iconClass : string,
  title: string,
  subtitle: string
}
