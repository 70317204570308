import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFireUploadTask, AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { ImageSlide, makeid, dbKeys, enumImagesSildeType } from 'src/app/class/common-objects.enum';
import { I18n } from 'src/app/i18n/i18n';
import { catchError, finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-add-mainpage-bottom-item-dialog',
  templateUrl: './add-mainpage-bottom-item-dialog.component.html',
  styleUrls: ['./add-mainpage-bottom-item-dialog.component.less']
})
export class AddMainpageBottomItemDialogComponent implements OnInit {
  errorMessage = ''
  notifyMessage = ''
  i18n = new I18n()
  imageSlide: ImageSlide = {
    id: '',
    imageURL: '',
    title: '',
    content: '',
    buttonText: '',
    buttonURL: '',
    visible: false,
    type:enumImagesSildeType.homepage
  }
  initId = ''
  get isUpdate() {
    return !!this.initId
  }
  allowFileType = ["image/png", "image/jpeg", "image/jpg"]
  allowFileSize = 10 * 1024 * 1024
  key_storage_mainpage_slider = 'image_storage_mainpage_slider'
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  downloadFilename: string

  // State for dropzone CSS toggling
  isHovering: boolean;
  constructor(
    public dialogRef: MatDialogRef<AddMainpageBottomItemDialogComponent>,
    public storage: AngularFireStorage,
    public firestore: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.id) {
        this.tryInitItem(data.id)
      }
    }
  }
  async tryInitItem(id:string){
    if(id){
      let result = await this.firestore.collection(dbKeys.mainpage_bottom_items).doc(id).get().toPromise()
      if(result && result.exists){
        const obj = <ImageSlide> result.data()
        this.imageSlide = obj 
        this.initId = result.id
        const file = this.storage.ref(obj.imageURL)
        let dlURL = await file.getDownloadURL().toPromise()
        console.log(dlURL)
        if(dlURL){ 
          this.downloadURL = dlURL
        }
        
      }
    }
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close()
  }

  public uploadFile(event: any): void {
    this.errorMessage = ''
    this.notifyMessage = ''
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get('err_msg_file_too_large')
        return
      } else if (!this.allowFileType.includes(file.type)) {
        this.errorMessage = this.i18n.get('err_msg_disallow_file_type')
        return
      }
      try {
        const path = `${this.key_storage_mainpage_slider}/${(new Date().toISOString())}_${makeid(11)}_${file.name}`;

        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        console.log(path)
        this.percentage = task.percentageChanges()
        let sub = task
          .snapshotChanges()
          .pipe(catchError(err => {
            this.errorMessage = err.message
            throw err;
          }))
          .pipe(

            finalize(() => {
              const fileRef: AngularFireStorageReference = this.storage.ref(
                path
              )
              fileRef.getDownloadURL().subscribe(downloadURL => {
                //this.userInfo.cvURL = downloadURL

                this.imageSlide.imageURL = path
                this.downloadURL = downloadURL
                //this.updateDownloadURL(path)
                sub.unsubscribe()
              });
            })
          )
          .subscribe((data) => {
            console.log(data)

          })
      } catch (e) {
        this.errorMessage = e.message
        console.error(e)
      }
    }
  }

  async onSubmit() {
    if (!this.imageSlide.imageURL) {
      alert('請上載背景圖片')
    } else {
      if(this.isUpdate){
        this.onUpdate()
      }else{
        this.onAdd()
      }
    }
  }
  async onUpdate(){

    try {
      let result = await this.firestore.collection(dbKeys.mainpage_bottom_items).doc(this.initId)
      await result.update(this.imageSlide)
      alert('更新成功！')
      this.dialogRef.close(true)
    } catch (e) {
      alert(`更新失敗: ${e.message}`)
    }
  }
  async onAdd(){
    try {
      let result = await this.firestore.collection(dbKeys.mainpage_bottom_items).add(this.imageSlide)
      await result.update({
        id: result.id
      })
      alert('新增成功！')
      this.dialogRef.close(true)
    } catch (e) {
      alert(`新增失敗: ${e.message}`)
    }
  }
}
