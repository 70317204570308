import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { MatDialog } from '@angular/material';
import { AddUpdateAdminJobMatchingComponent } from './add-update-admin-job-matching/add-update-admin-job-matching.component';
import { MemberTalentItem, dbKeys, textToArray, openSimpleMessageDialog } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { IndustryCatItem } from '../../../class/IndustryCatItem';
import { i18n_locate } from 'src/app/i18n/i18n_const';

@Component({
  selector: 'app-admin-list-job-matching',
  templateUrl: './admin-list-job-matching.component.html',
  styleUrls: ['./admin-list-job-matching.component.less']
})
export class AdminListJobMatchingComponent implements OnInit {
  i18n = new I18n()

  topIndustries: Map<string, IndustryCatItem> = new Map()
  subIndustries: Map<string, IndustryCatItem> = new Map()
  constructor(
    public dialog: MatDialog,
    public firestore: AngularFirestore
  ) { }
  talents: MemberTalentItem[] = []

  get mapIndustry() {
    return this.i18n.getStringMap('map_job_type')
  }
  ngOnInit() {
    this.firestore.collection(dbKeys.job_matching_items)
      .valueChanges().subscribe((data: MemberTalentItem[]) => {
        this.handleTalentsUpdate(data)
      })
      this.subIndustriesUpdate()
  }

  sub01: Subscription = null
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe()
      } catch (e) { }
    }
    this.firestore.collection(dbKeys.map_industry_cats)
      .valueChanges().subscribe(d => {
        if (d && d.length > 0) {
          for (let o of d) {
            const any0: any = <any>o
            const industry: IndustryCatItem = <IndustryCatItem>any0
            if (industry && industry.id) {
              if (industry.parent_id) {
                this.subIndustries.set(industry.id, industry)
              } else {
                this.topIndustries.set(industry.id, industry)
              }
            }
          }
        }
      })
  }

  outputIndustry(industry, locate?: i18n_locate) {
    if (!industry || !industry.id) {
      return '其他/Other'
    }
    if (!locate) {
      locate = this.i18n.currentLocate
    }
    return industry[`${locate}_name`]
  }
   getIndustryNameById(id: string, locate?: i18n_locate) {
      const s= this.topIndustries.get(id)
      return this.outputIndustry(s, locate)

  }

  getSubIndustryNameById(id: string, locate?: i18n_locate) {
    const s= this.subIndustries.get(id)
    return this.outputIndustry(s, locate)

}
  handleTalentsUpdate(newTalents: MemberTalentItem[]) {
    this.talents = []
    for (let t of newTalents) {
      if (t) {
        this.talents.push(t)
      }
    }
  }
  openAddUpdateDialog(id: string) {
    const dialogRef = this.dialog.open(AddUpdateAdminJobMatchingComponent, {
      data: {
        id: id
      }
    })
    dialogRef.afterClosed().subscribe(() => {
      // do some magic after dialog closed.
    })
  }

  async onRemove(id: string) {
    const confirmRemove = confirm(`Are you sure to remove ${id} ?`)
    if (confirmRemove) {
      try {
        await this.firestore.collection(dbKeys.job_matching_items).doc(id).delete()

        openSimpleMessageDialog(this.dialog, 'action success!')
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `ERROR: ${e.message}`)
      }
    }
  }

  textToArray(t: string) {
    if (!t) return []
    return textToArray(t)
  }
}
