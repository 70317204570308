<div class="thisBody">
    <div class="title">
        <b class="titleText">{{i18n.get('menu_job_industry_cats')}}</b>
    </div>
    <br>
    <div class="outerBody">
        <div class="innerBody">
            <div class="thisHeadSelect">
                <mat-form-field>
                    <mat-label>{{i18n.get('parent_industry')}}</mat-label>
                    <mat-select [(ngModel)]="_parentId" (selectionChange)="onSelectChange()">
                        <mat-option value="top">
                            Top Lv. / 最高層
                        </mat-option>
                      <mat-option *ngFor="let o of selectCats" [value]="o.id">
                        {{_getIndustryNameByCurrentLocate(o, null)}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <table class="thisTable" border=1>
                <tr>
                    <td>中文名稱</td>
                    <td>En Name</td>
                    <td>Action/操作</td>
                </tr>
                <ng-container *ngIf="currentCats === null ">
                    <tr>
                        <td colspan="3">
                            <p class="singleP">{{i18n.get('loading')}}</p>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="currentCats &&  currentCats.length <= 0">
                    <tr>
                        <td colspan="3">
                            <p class="singleP">{{i18n.get('nothing_to_show')}}</p>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="currentCats && currentCats.length > 0">
                    <tr *ngFor="let o of currentCats">
                        <td>
                            <mat-form-field class="example-full-width">
                                <mat-label>中文名稱</mat-label>
                                <input matInput placeholder="中文名稱" [(ngModel)]="o.hant_name">
                            </mat-form-field>
                        </td>

                        <td>
                            <mat-form-field class="example-full-width">
                                <mat-label>Eng Name</mat-label>
                                <input matInput placeholder="Eng Name" [(ngModel)]="o.en_name">
                            </mat-form-field>
                        </td>
                        <td>
                            <div class="buttonList">
                                <button class="btn btn-primary" (click)="onUpdateIndustry(o)">{{i18n.get('edit')}}</button>
                                <button class="btn btn-info" (click)="onRemoveIndustry(o)">{{i18n.get('remove')}}</button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="currentCats">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width">
                                <mat-label>中文名稱</mat-label>
                                <input matInput placeholder="中文名稱" [(ngModel)]="newIndustry.hant_name">
                            </mat-form-field>
                        </td>

                        <td>
                            <mat-form-field class="example-full-width">
                                <mat-label>Eng Name</mat-label>
                                <input matInput placeholder="Eng Name" [(ngModel)]="newIndustry.en_name">
                            </mat-form-field>
                        </td>
                        <td>
                            <div class="buttonList">
                                <button class="btn btn-primary" (click)="onAddIndustry()">{{i18n.get('add')}}</button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</div>
