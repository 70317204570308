import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { makeid, cloudFunctionsKeys, SnsUrl, openSimpleMessageDialog, checkIsInCareerAdvisor } from 'src/app/class/common-objects.enum';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material';
import moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  i18n = new I18n()

  sns = SnsUrl
  constructor(
    public afFunctions :AngularFireFunctions,
    public dialog : MatDialog
  ) { }
  get thisYear(){
    let now = new Date()
    return now.getFullYear()
  }
  ngOnInit() {
  }
  get lisenseNumber(){
    return this.i18n.translate.job_advise_license_number + `：${70429}`
  }
  get lastBuildTime(){
    const t = environment.lastBuildTime
    //return `${t.getFullYear()}-${fillOnLeft(t.getMonth(), "0",2)}-${fillOnLeft(t.getDate(), "0", 2)} ${fillOnLeft(t.getHours(), "0", 2)}:${fillOnLeft(t.getMinutes(), "0", 2)}:${fillOnLeft(t.getSeconds(), "0", 2)}`
    return moment(t).format('DD MMM YYYY, HH:mm:ss')
  }
  email = ''
  newslatterFormId = `footer_newslatterFormId_${makeid(10)}`
  isLoading =false
  get getFormDisplay(){
    if(this.isLoading){
      return 'none'
    }else{
      return ''
    }
  }
  async onSubmit(){
    this.isLoading = true
    const form = <HTMLFormElement> document.getElementById(this.newslatterFormId)
    if(form.reportValidity()){
      const result = await this.afFunctions.httpsCallable(cloudFunctionsKeys.sendNewslatterMail)({
        email: this.email
      }).toPromise()
      console.log(result)
      if(result === true){
        openSimpleMessageDialog(this.dialog, this.i18n.get('notify_msg_action_success'))

      }else{
        openSimpleMessageDialog(this.dialog, this.i18n.get('error_msg_action_failed'))
      }
    }
    this.isLoading = false
  }

  get isInCareerAdvisor(){
    return checkIsInCareerAdvisor()
  }
}
