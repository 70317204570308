<app-main-page-slider [pageType]="silderPageType"></app-main-page-slider>
<div class="background">
  <div class="container-wrap">
    <div class="main container">
      <div class="row">
        <div class="col-12">
          <app-main-title [title]="i18n.getNoSpace('main_about_us')"></app-main-title>
          <div [innerHTML]="i18n.getNoSpace('main_about_us_content')"></div>
          <app-main-page-we-provide></app-main-page-we-provide>
          <hr />
          <ng-container *ngIf="showProjectDescription">
            <div flex="justify-evenly" class="upper">
              <div flex="align-center justify-center" flex-item="grow-3">
                <!-- <div>
                  <img class="bottomLogo" src="/assets/HRCA_project-06-06.png" />
                </div> -->
                  <!-- <h1>賽馬會樂動一族計劃</h1>
                  <h2>Jockey Club Happy Golden Sports Project</h2> -->

                  <div flex="align-center justify-center">
                    <div class="text">主辦機構<br>Organized By</div>
                    <div style="display: flex;flex-direction: row;margin: auto;align-items: center;padding-top: 15px;">
                      <img class="logo" src="/assets/HRCA_Logo-01.png" />
                      <span>樂活新中年慈善動力</span>
                    </div>
                  </div>
                </div>
              <div flex="align-center justify-center" flex-item="grow-3">
                <div class="text">捐助機構<br>Funded By</div>
                <div>
                  <a href="https://charities.hkjc.com/charities/chinese/charities-trust/index.aspx" target="_blank">
                    <img class="logo" src="/assets/Trust_Print_FullColor_coated_B_H.JPG" />
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="content" [innerHTML]="i18n.getNoSpace('main_project_description_content')"></div> -->
            <!-- <div class="c" [innerHTML]="i18n.translate.mainpage_sport_desc"> -->
            <div class="cccc">
              <ol>
                <li>香港賽馬會社區資助計劃:樂進一族 360
                  <br>
                  The Hong Kong Jockey Club Community Project Grant: Elderly and Middle-Aged Empowerment Project
                </li>
                <li>
                  賽馬會樂動一族計劃
                  <br>
                  Jockey Club Happy Golden Sports Project
                </li>
              </ol>
            </div>

        <hr />
        </ng-container>
        <app-main-title [title]="i18n.getNoSpace('main_contact_us')"></app-main-title>
        <div class="content" [innerHTML]="i18n.getNoSpace('main_contact_us_content')"></div>
        <div [innerHTML]="i18n.getNoSpace('contact_us_address')"></div>
        <hr />
        <div>
          <div flex="justify-evenly" class="bottom">
            <!-- <div flex="align-center justify-center">
                <div class="text">捐助機構<br>Funded By</div>
                <div>
                  <img class="logo" src="/assets/mainpage_sai_icon.png" />
                </div>
              </div> -->
            <!-- <div flex="align-center justify-center">
                <div class="text">主辦機構<br>Organized By</div>
                <div>
                  <img class="logo" src="/assets/HRCA_Logo-01.png" />
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
