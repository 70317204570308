<div class="container">
  <div calss="row">
    <div class="col-12 col-lg-8 offset-lg-2 formButtonContainer">
      <a
        class="btn btn-primary btn-block btn-lg formButton"
        [innerHtml]="buttonInnerHtml"
      >
      </a>
    </div>
  </div>
</div>
