import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { dbKeys, unsubscribeAll } from 'src/app/class/common-objects.enum';
import { ISportCourseApplication } from 'src/app/class/ISportCourseApplication';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-sport-course-application',
  templateUrl: './sport-course-application.component.html',
  styleUrls: ['./sport-course-application.component.less']
})
export class SportCourseApplicationComponent implements OnInit {
  inId = ''
  course:ISportCourseApplication = null
  i18n = new I18n()
  constructor(
    private activatedRoute: ActivatedRoute,
    private afFirestore: AngularFirestore,
    private afAuth:AngularFireAuth,
    private router:Router,
    private snackbar :MatSnackBar,
  ) {
    activatedRoute.params.subscribe(params=>{
      this.inId = params?.id || ''
    })
   }

  ngOnInit() {
    this.reloadApplicationInfo()
  }

  sub0Application:Subscription
  reloadApplicationInfo(){
    unsubscribeAll([this.sub0Application])
    this.sub0Application = this.afFirestore.collection(dbKeys.sport_course_applications)
    .doc<ISportCourseApplication>(this.inId)
    .valueChanges()
    .subscribe(v=>{
      this.course = v||null;
    })
  }

}
