import { AvaliableLocateInterface } from './language-interface';

export enum i18n_locate {
    en = 'en',
    hant = 'hant'
}
export const avaliable_locate : Array<AvaliableLocateInterface>= [
    {
        key: i18n_locate.en,
        shortName: 'En',
        longName: 'English'
    },
    {
        key: i18n_locate.hant,
        shortName: '中',
        longName: '正體中文'
    }
]