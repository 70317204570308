<hr />
<p class="thisTitle">
  {{ i18n.get("apply_job") }}
</p>
<div *ngIf="!mainActivity">
  {{ i18n.get("nothing_to_show") }}
</div>
<div *ngIf="mainActivity && mainActivity != null && !displayForm">
  <button class="btn btn-primary" (click)="displayFormFunction()">
    {{ i18n.get("apply_now") }}
  </button>
</div>
<div *ngIf="isApplySuccess">
  {{ i18n.get("job_application_success") }}
</div>
<div
  *ngIf="mainActivity && mainActivity != null && displayForm && !isApplySuccess"
>
  <form [id]="thisFormId" class="apply-form" (submit)="onSubmit()">
    <div class="input-group mb-3">
      <label>{{ i18n.get("name") }}</label>
      <input
        type="text"
        class="form-control"
        [placeholder]="i18n.get('placeholder_name')"
        [(ngModel)]="formData.name"
        [ngModelOptions]="{ standalone: true }"
        aria-label="Username"
        aria-describedby="basic-addon1"
        required
      />
    </div>
    <div class="input-group mb-3">
      <label>{{ i18n.get("placeholder_mobile_phone") }}</label>
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">+852</span>
      </div>
      <input
        type="number"
        class="form-control"
        [placeholder]="i18n.get('placeholder_phone_number')"
        [(ngModel)]="formData.phoneNumber"
        [ngModelOptions]="{ standalone: true }"
        aria-describedby="basic-addon1"
        min="10000000"
        max="99999999"
        required
      />
    </div>
    <div class="input-group mb-3">
      <label>{{ i18n.get("placeholder_email_address") }}</label>
      <input
        type="email"
        class="form-control"
        [placeholder]="i18n.get('placeholder_email')"
        aria-label="Username"
        [(ngModel)]="formData.email"
        [ngModelOptions]="{ standalone: true }"
        aria-describedby="basic-addon1"
        required
      />
    </div>
    <br />
    <div>
      <div flex>
        <div class="checkbox-wrap">
          <mat-checkbox
            color="primary"
            [(ngModel)]="formData.applyFormChecked3"
            [ngModelOptions]="{ standalone: true }"
            required
          ></mat-checkbox>
        </div>
        <div innerHTML="{{ i18n.get('apply_activity_term_3') }}"></div>
      </div>
    </div>
    <input type="submit" class="btn btn-primary" [value]="i18n.get('submit')" />
  </form>
</div>
