<div class="thisBody card">
  <h5>
    <span>{{ i18n.get("my_job_ads_action") }}</span>
  </h5>
  <div>
    <div
      class="alert alert-danger errorMessage"
      role="alert"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <div
      class="alert alert-info errorMessage"
      role="alert"
      *ngIf="notifyMessage"
    >
      {{ notifyMessage }}
    </div>
  </div>
  <div *ngIf="jobs === null">
    {{ i18n.get("loading") }}
  </div>
  <div *ngIf="jobs !== null && jobs.size <= 0">
    {{ i18n.get("nothing_to_show") }}
  </div>
  <div *ngIf="jobs && jobs.size > 0">
    <div flex>
      <div flex-item="grow-1">
        <mat-form-field>
          <b>Status filter</b>
          <mat-select
            #this_list_job_zxc102
            required
            value="all"
           (selectionChange)="onStatusFilterChange(this_list_job_zxc102.value)"
          >
            <mat-option value="all">All</mat-option>
            <mat-option value="pending">Pending</mat-option>
            <mat-option value="active">Active</mat-option>
            <mat-option value="deactive">Deactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <button
        mat-raised-button
        [disabled]="isRefreshing"
        flex-item="align-center"
        class="btn btn-light"
        (click)="reloadJobApplicationCount()"
      >
        {{ isRefreshing ? "Loading..." : "Refresh" }}
      </button> -->
    </div>
    <table border="1">
      <tr>
        <td></td>
        <td>{{ i18n.get("job_table_creator_uid") }}</td>
        <td>{{ i18n.get("job_table_creator_name") }}</td>
        <td>{{ i18n.get("job_table_job_id") }}</td>
        <td>{{ i18n.get("job_table_job_title") }}</td>
        <td>{{ i18n.get("expiryDate") }}</td>
        <td>{{ i18n.get("placeholder_job_ads_is_application_hidden") }}</td>
        <td>{{ i18n.get("job_table_job_status") }}</td>
        <td>{{ i18n.get("edit") }}</td>
        <td>{{ i18n.get("job_table_job_preview") }}</td>
        <td>{{ i18n.get("job_table_job_approve") }}</td>
        <td>{{ i18n.get("admin_job_application_count") }}</td>
        <td>{{ i18n.get("admin_job_application_list") }}</td>
      </tr>
      <tr *ngIf="jobs && jobs.size && resultCount <= 0">
        <td colspan="8">{{ i18n.get("nothing_to_show") }}</td>
      </tr>
      <ng-container *ngFor="let item of orderedJobAds;index as i">
        <tr *ngIf="item ">
          <td>{{i+1}}</td>
          <td>
            {{item.job.creator_uid}}
            </td>
          <td>
            {{ getCompanyInfo(item.job.creator_uid)}}
            1
            <span *ngIf="item.job.creator_uid && getCompanyInfo(item.job.creator_uid)">
              2
              {{  getCompanyInfo(item.job.creator_uid).name}}
            </span>
          </td>
          <td>{{ item.job.id }}</td>
          <td>{{ item.job.title }}</td>
          <td>
            <span *ngIf="item.job.deadline ">{{ item.job.deadline | expiryDate }}</span>
          </td>
          <td>{{ !!item.job.isApplicationHidden }}</td>
          <td>{{ item.job.status }}</td>
          <td>
            <button class="btn btn-light" (click)="openUpdateDialog(item.job)">
              {{ i18n.get("edit") }}
            </button>
          </td>
          <td>
            <a
              class="btn btn-light"
              target="_blank"
              [href]="'/jobs/' + item.job.id"
            >
              {{ i18n.get("job_table_job_preview") }}
            </a>
          </td>
          <td>
            <span *ngIf="item.job.status == 'pending'">
              <a class="btn btn-light" (click)="approveJob(item.job.id)">{{
                i18n.get("action_approve")
              }}</a>
            </span>
            <span *ngIf="item.job.status == 'active'">
              <a class="btn btn-light" (click)="setDeactiveJob(item.job.id)">{{
                i18n.get("set_to_deactive")
              }}</a>
            </span>
            <span *ngIf="item.job.status == 'deactive'">
              <a class="btn btn-light" (click)="setActiveJob(item.job.id)">{{
                i18n.get("set_to_active")
              }}</a>
            </span>
          </td>
          <td>{{ item.application_count }}</td>
          <td>
            <a (click)="goToThisJobApplication(item.job.id)">{{
              i18n.get("view")
            }}</a>
          </td>
        </tr>
      </ng-container>
    </table>
    <button
    mat-raised-button
    [disabled]="isRefreshing"
    style="width:100%;"
    class="btn btn-light"
    *ngIf="!isLoadMore"
    (click)="isLoadMore = true; updateAllJobAds();"
  >
    LOAD ALL | 載入所有
  </button>
  </div>
</div>
