import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { I18n } from 'src/app/i18n/i18n';

import { Sort } from '@angular/material/sort';
import { formatDate } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { AddAdminDialogComponent } from '../dialog/add-admin-dialog/add-admin-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, openSimpleMessageDialog, cloudFunctionsKeys } from '../../../class/common-objects.enum'
import { ChangeAdminPermissionDialogComponent } from './change-admin-permission-dialog/change-admin-permission-dialog.component';

@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.less']
})
export class ListAdminComponent implements OnInit {
  arrAdminUsers = null
  i18n = new I18n()
  notifyMessage = ''
  errorMessage = ''
  sortedData: any[] = null
  loadingCurrent = 0
  loadingTotal = 0
  isBtnLoading = false
  constructor(
    public afFunctions: AngularFireFunctions,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore
  ) { }

  ngOnInit() {
    this.updateAdminUsers()
  }

  async toBan(email: string, ban: boolean) {
    const currentUser = this.afAuth.auth.currentUser
    if (email && currentUser && currentUser.uid) {
      this.isBtnLoading = true
      const token = await currentUser.getIdToken()
      try {
        const result = await this.afFunctions.httpsCallable(cloudFunctionsKeys.banAdminAccount)({
          token: token,
          email: email,
          ban: ban
        }).toPromise()
        if (result && result === 'success') {
          // success
          openSimpleMessageDialog(this.dialog, `操作成功！\nAction Success!`)
        } else {
          openSimpleMessageDialog(this.dialog, `Action Failed\n操作失敗\nCODE: ${result}`)
        }
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `Failed!\n操作失敗！\n${e.message}`)
      }
      this.isBtnLoading = false
    }
  }
  updateAdminUsers() {

    let sub = this.afAuth.authState.subscribe(async auth => {
      if (auth && auth.uid) {
        let token = await auth.getIdToken(true)
        //this.afFunctions.httpsCallable('getAllAdmin')({ token: token }).subscribe()
        this.firestore.collection(dbKeys.cache_list_admins).valueChanges().subscribe(result => {
          console.log(result)
          this.arrAdminUsers = []
          this.sortedData = []
          if (result && result.length > 0) {
            this.loadingTotal = result.length
            this.loadingCurrent = 0
            for (let o of result) {
              this.loadingCurrent++
              if (o) {
                this.arrAdminUsers.push(o)
                this.sortedData.push(o)
              }
            }
          }
          console.log(this.sortedData)
        })
      }
    })
  }
  formatGmtDate(gmtTime: string) {
    let d = new Date(gmtTime)
    return d.toISOString().replace(/T/, ' ').replace('.000Z', '')
  }
  sortData(sort: Sort) {

    const data = this.arrAdminUsers.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'calories': return compare(a.calories, b.calories, isAsc);
        case 'fat': return compare(a.fat, b.fat, isAsc);
        case 'carbs': return compare(a.carbs, b.carbs, isAsc);
        case 'protein': return compare(a.protein, b.protein, isAsc);
        default: return 0;
      }
    });
  }
  addAdminDialog() {
    let dialogRef = this.dialog.open(AddAdminDialogComponent, {})
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.updateAdminUsers()
        this.notifyMessage = this.i18n.get('notify_msg_admin_create_reset_mail_sent')

      }
    })
  }

  openPermissionDialog(uid: string) {
    this.dialog.open(ChangeAdminPermissionDialogComponent, {
      data: {
        uid
      }
    })
  }

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
