import { Pipe, PipeTransform } from '@angular/core';
import { fillOnLeft } from 'src/app/class/common-objects.enum';

@Pipe({
  name: 'articleDate'
})
export class ArticleDatePipe implements PipeTransform {

  transform(date: Date): string {
    return `${date.getFullYear()}-${fillOnLeft(date.getMonth() + 1, '0', 2)}-${fillOnLeft(date.getDate(), '0', 2)} ${fillOnLeft(date.getHours(), '0', 2)}:${fillOnLeft(date.getMinutes(), '0', 2)}:${fillOnLeft(date.getSeconds(), '0', 2)}`
  }

}
