import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, UserRecordInMap } from 'src/app/class/common-objects.enum';
import { JobAds } from 'src/app/class/job-ads';
import { IDownloadCsvMask } from '../raw-data-download.component';

@Component({
  selector: 'app-dl-raw-job-info',
  templateUrl: './dl-raw-job-info.component.html',
  styleUrls: ['./dl-raw-job-info.component.less']
})
export class DlRawJobInfoComponent implements OnInit {

  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_job_ads_info'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.job_ads)
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
              "job_ads_id",
              "title",
              "status",
              "top_description",
              "bottom_description",
              "description",
              "applications_count",
              "creator_uid",
              "duties",
              "education",
              "employment_forms",
              "industry",
              "location",
              "position",
              "requirements",
              "salary",
              "welfare",
              "workday",
            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <JobAds>docs.data()
            if (record && record.id) {
              arrOutput.push([
                record?.id||'',
                record?.title||'',
                record?.status||'',
                record?.topDescription||'',
                record?.bottomDescription||'',
                record?.description||'',
                record?.application_count.toString()||'',
                record?.creator_uid||'',
                record?.duties.filter(v=>v).join('\n') ||'',
                record?.education||'',
                record?.employmentForms||'',
                record?.industry||'',
                record?.location||'',
                record?.position||'',
                record?.requirements.filter(v=>v).join('\n')||'',
                record?.salary||'',
                record?.welfare||'',
                record?.workday||'',


              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }


}
