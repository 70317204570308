<div class="banner">
  <img src="/assets/join our career advisor team (3251 × 1010).png" />
  <div class="selectContainer" flex="column" *ngIf="false" reason="good old thing">
    <div flex-item="grow-4">
      <h2>加入樂職顧問團隊｜Join our Career Advisor Team</h2>
    </div>
    <div flex-item="grow-1">
    </div>
    <div flex-item="grow-1">
    </div>
  </div>
</div>
<div class="thisBody">
  <div class="contentOuter" flex="justify-center">
    <div class="contentBody">
      <div class="joinCaContent">
        歡迎加入樂職顧問團隊(HRCA Career Advisor Team), 傳承經驗，幫助年青人我們團隊來自各行各業，現階段大多以義務性質為年青人提供職涯諮詢服務。
      </div>
      <div class="joinCaContent">
        我們會安排適合的樂職顧問在學校、大專院校及志願機構介紹行業及分享職場經驗。此外，亦會舉辦分享小組，以及一對一諮詢面談。我們希望透過不同方式，接觸到身處不同人生階段的年靑一代。
      </div>
      <div class="listTitle">
        什麼是樂職顧問?
      </div>
      <ul>
        <li>累積多年職場經驗，你有否想過扶助一班職場迷惘的年青一代，將寶貴經驗傳承下去？無論在職或退休，若你願意為年青人提供職場意見，令他們在職場路上不再徬徨無助，或許你也適合成為樂活慈善動力的樂職顧問（Career Advisor)。</li>
      </ul>
      <div class="listTitle">
        樂職顧問有什麼要做呢？
      </div>
      <ul>
        <li>我們會安排適合的樂職顧問在學校、大專院校、志願機構或其他團體介紹行業及分享經驗。此外亦會透過分享小組，以及一對一面談。為身處不同階段的年靑一代提供諮詢。</li>
      </ul>
      <div class="listTitle">
        我可以做樂職顧問嗎？
      </div>
      <ul>
        <li>你在同一行業或專業有逾20年經驗，有耐心、溝通技巧良好的話，都可以考慮填妥網上表格，申請成為樂職顧問，以便我們再安排進一步認證！</li>
      </ul>
      <div class="listTitle">
        申請樂職顧問流程
      </div>
      <ol>
        <li>提交網上申請表格 : 填寫簡單個人資料及背景</li>
        <li>提交個人履歷到 <a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
        <li>完成以上兩個步驟後，我們將以WhatsApp安排與團隊會談及認證</li>
        <li>會談/認證後，如團隊認為你適合成為樂職顧問，我們將以電郵及WhatsApp 確認。</li>
      </ol>
      <div class="joinCaContent">
        正式成為樂職顧問後，如我們收到職涯諮詢服務申請，將首先在樂職顧問庫中做行業配對，再揀選及委派合適的顧問提供諮詢服務。而在正式提供諮詢服務前，團隊會為委派的樂職顧問講解服務和諮詢服務對象的資料。
      </div>
      <div class="listTitle">
        備註（遞交網上表格申請前，請細閱）：
      </div>
      <ul>
        <li>此網上表格旨在收集申請人的個人資料,以便進行甄選之用,內容絕對保密。</li>
        <li>申請人需保證所填報及遞交的資料正確無誤，且明白若有虛報或蓄意隱瞞事實,本機構有權追究及要求作出賠償。</li>
      </ul>
    </div>
  </div>
</div>
<div class="container">
  <div calss="row">
    <div class="col-12 col-lg-8 offset-lg-2 formButtonContainer">
      <a
        class="btn btn-primary btn-block btn-lg formButton"
        href="https://forms.gle/29mLJpANPkHCQypZ7"
        target="_blank"
      >
      立即申請成為樂職顧問
      </a>
    </div>
  </div>
</div>