import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18n } from 'src/app/i18n/i18n';
import { SnsUrl, checkIsInCareerAdvisor } from 'src/app/class/common-objects.enum';

@Component({
  selector: 'app-top-logo-bar',
  templateUrl: './top-logo-bar.component.html',
  styleUrls: ['./top-logo-bar.component.less']
})
export class TopLogoBarComponent implements OnInit {
  i18n = new I18n()
  sns = SnsUrl

  openIt(v: string) {
    open(v)
  }
  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }
  toHomePage() {
    if (this.isInCareerAdvisor) {
      this.router.navigate(['/ca'])
    } else {
      this.router.navigate([''])
    }
  }
  get isInCareerAdvisor() {
    return checkIsInCareerAdvisor()
  }
}
