<div class="card">
        <h5>
            <span>{{i18n.get('ads_banner_config')}}</span>
            <a class="btn btn-light" (click)="addRecordDialog()">{{i18n.get('add')}}</a>
            <a class="btn btn-light" (click)="reloadTable()">{{i18n.get('reload')}}</a>
        </h5>
        <div>
            <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
                {{notifyMessage}}
            </div>
        </div>
        <div *ngIf="arrImageSlides == null">
            {{i18n.get('loading')}}
        </div>
        <div *ngIf="arrImageSlides!= null && arrImageSlides.length <= 0">
            {{i18n.get('nothing_to_show')}}
        </div>
        <div class="scrolling-wrapper">
            <div *ngIf="arrImageSlides">
                <table border="1" (matSortChange)="sortData($event)">
                    <tr>
                        <th >{{i18n.get('type')}}</th>
                        <th >{{i18n.get('click_url')}}</th>
                        <th >{{i18n.get('view')}}</th>
                        <th>{{i18n.get('edit')}}</th>
                        <th>{{i18n.get('remove')}}</th>
                    </tr>
    
                    <tr *ngFor="let o of arrImageSlides">
                        <td>{{o.type}}</td>
                        <td><a [href]="o.clickURL">{{o.clickURL}}</a></td>
                        
                        <td>
                            <a  [href]="o.imageURL" target="_blank">{{i18n.get('view')}}</a>
                        </td>
                        <td>
                            <a class="btn btn-light" (click)="editRecordDialog(o.id)">{{i18n.get('edit')}}</a>
                        </td>
                        <td><a class="btn btn-light" (click)="removeSlide(o.id)">{{i18n.get('remove')}}</a></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>