<div class="card">
    <h5>
        <span>{{pageType}} silders</span>
        <a class="btn btn-light" (click)="addRecordDialog()">{{i18n.get('add')}}</a>
        <a class="btn btn-light" (click)="reloadTable()">{{i18n.get('reload')}}</a>
    </h5>
    <div class="selector">
        <mat-form-field appearance="fill">
            <mat-label>Silder Position</mat-label>
            <mat-select [(ngModel)]="pageType" (selectionChange)="onSelectChange($event.value)">
                <mat-option *ngFor="let t of enumTypeArray" [value]="t">
                    {{t}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
            {{notifyMessage}}
        </div>
    </div>
    <div *ngIf="arrImageSlides == null">
        {{i18n.get('loading')}}
    </div>
    <div *ngIf="arrImageSlides!= null && arrImageSlides.length <= 0">
        {{i18n.get('nothing_to_show')}}
    </div>
    <div class="scrolling-wrapper">
        <div *ngIf="arrImageSlides">
            <table border="1" matSort (matSortChange)="sortData($event)">
                <tr>
                    <th>{{i18n.get('placeholder_job_ads_title')}}</th>
                    <th>{{i18n.get('contents')}}</th>
                    <th>{{i18n.translate.visible}}</th>
                    <th>{{i18n.get('button_text')}}</th>
                    <th>{{i18n.get('button_url')}}</th>
                    <th>{{i18n.get('image')}}</th>
                    <th>{{i18n.get('edit')}}</th>
                    <th>{{i18n.get('remove')}}</th>
                </tr>

                <tr *ngFor="let o of arrImageSlides">
                    <td>{{o.title}}</td>
                    <td>{{o.content}}</td>
                    <td>
                        <mat-slide-toggle
                        [(ngModel)]="o.visible"
                        (toggleChange)="onVisibleChange(o)"
                        >
                            {{i18n.translate.visible}}？
                        </mat-slide-toggle>
                    </td>
                    <td>{{o.buttonText}}</td>
                    <td>{{o.buttonURL}}</td>
                    <td>
                        <a [href]="o.imageURL" target="_blank">{{i18n.get('view')}}</a>
                    </td>
                    <td><a class="btn btn-light" (click)="updateRecordDialog(o.id)">{{i18n.get('edit')}}</a></td>
                    <td><a class="btn btn-light" (click)="removeSlide(o.id)">{{i18n.get('remove')}}</a></td>
                </tr>
            </table>
        </div>
    </div>
</div>