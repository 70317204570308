import { makeid } from 'src/app/class/common-objects.enum';
import { TheAdvisor } from 'src/app/class/the-advisor';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TheAdvisorBookingRecord } from '../../../../class/theAdvisorBooking';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, openSimpleMessageDialog, cloudFunctionsKeys } from '../../../../class/common-objects.enum';
import { firestore } from 'firebase/app';
import { I18n } from '../../../../i18n/i18n';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ca-appointment-order-details',
  templateUrl: './ca-appointment-order-details.component.html',
  styleUrls: ['./ca-appointment-order-details.component.less']
})
export class CaAppointmentOrderDetailsComponent implements OnInit {
  i18n = new I18n()
  initId = ''
  advisor: TheAdvisor = null
  record: TheAdvisorBookingRecord = null
  payFormId = `sjdiao_payment_form_id__${makeid(13)}`
  handingPayments = false
  constructor(
    public activatedRoute: ActivatedRoute,
    public afFirestore: AngularFirestore,
    public afFunctions: AngularFireFunctions,
    public dialog: MatDialog
  ) {

    (<any>window).Stripe.setPublishableKey(environment.stripePublicKey);
    const id = activatedRoute.snapshot.paramMap.get('id')
    if (id) this.initId = id
    this.refreshItems()
  }

  ngOnInit() {}

  formData = {
    cardNumber: '',
    expDate: '',
    cvc: '',
    phoneNumber: '',
    name: '',
    email: '',
    recordId: ''
  }
  sub01: Subscription = null
  async tryPay() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe()
      } catch (e) { }
    }
    const form = <HTMLFormElement>document.getElementById(this.payFormId)
    if (form && form.reportValidity()) {
      const formData = this.formData
      if (formData.cardNumber && formData.cvc && formData.email && formData.expDate && formData.name && formData.phoneNumber) {
        formData.cardNumber = formData.cardNumber.split(' ').join('')
        let expMonth = ''
        let expYear = ''
        const e = formData.expDate.split('/')
        if (e.length == 2) {
          expMonth = e[0].trim()
          expYear = e[1].trim()
        }
        const now = new Date()

        console.log(formData)
        if (formData.cardNumber.length != 16) {
          openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_credit_debit_card_information'))
        } else if (!formData.email.includes('.') || !formData.email.includes('@')) {
          openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_email_address'))
        } else if (formData.name.length <= 2) {
          openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_name'))
        } else if (parseInt(expYear) < now.getFullYear() || (parseInt(expYear) == now.getFullYear() && parseInt(expMonth) < now.getMonth() + 1)) {
          openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_exp_date'))
        } else {
          this.handingPayments = true
          const self = this;
          (<any>window).Stripe.card.createToken({
            number: formData.cardNumber,
            cvc: formData.cvc,
            exp_month: expMonth,
            exp_year: expYear
          }, (status, response) => {

            if (response.error) {
              openSimpleMessageDialog(this.dialog, '錯誤！\nERROR!\nCODE: ' + response.error.message)

            } else {
              console.log(response)
              let token = response.id;

              this.afFunctions.httpsCallable(cloudFunctionsKeys.handleAdvisorBookingPayment)({
                chargeToken: token,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                name: formData.name,
                amount: this.advisor.fee * 100,
                recordId: this.record.id
              }).subscribe(async result => {
                console.log(result)
                if (result == 'success') {
                  openSimpleMessageDialog(this.dialog, '付款成功！我們的職員會儘快聯絡你確認時間。\nPayment success! Our staff will contact you soon.')
                  this.refreshItems()
                } else {
                  openSimpleMessageDialog(this.dialog, `支付失敗！  Payment failed!\n 錯誤代碼 | ERROR_CODE: ${result}`)
                  this.handingPayments = false
                }
                this.handingPayments = false
              })
            }
          })
        }
      }
    }
  }
  async refreshItems() {
    try {
      const r = await this.afFirestore.collection(dbKeys.advisor_appointments).doc(this.initId).get().toPromise()
      if (r && r.exists) {
        const a = <any>r.data()
        const n = <TheAdvisorBookingRecord>a
        n.createAt = (<firestore.Timestamp>a.createAt).toDate()
        n.expect_datetime = (<firestore.Timestamp>a.expect_datetime).toDate()
        this.record = n
        this.formData.phoneNumber = this.record.phone
        this.formData.email = this.record.email
        this.formData.name = this.record.name
        this.formData.recordId = this.record.id
        if (this.record.advisor_id) {
          const x = await this.afFirestore.collection(dbKeys.the_advisors).doc(this.record.advisor_id).get().toPromise()
          if (x && x.exists) {
            const b = <any>x.data()
            const z = <TheAdvisor>b
            z.createAt = (<firestore.Timestamp>b.createAt).toDate()
            z.updateAt = (<firestore.Timestamp>b.updateAt).toDate()
            this.advisor = z

          }
        }
      }
    } catch (e) {
      console.error(e)
    }
    console.log(this.record)
    console.log(this.advisor)
  }
}
