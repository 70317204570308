import { Component, OnInit } from '@angular/core';
import { enumImagesSildeType } from 'src/app/class/common-objects.enum';

@Component({
  selector: 'app-admin-homepage',
  templateUrl: './admin-homepage.component.html',
  styleUrls: ['./admin-homepage.component.less']
})
export class AdminHomepageComponent implements OnInit {



  constructor() { }

  ngOnInit() {
  }

}
