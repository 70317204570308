import * as firebase from 'firebase/app';
export interface TheActivity {
    id: string,
    creator_uid: string,
    title: string,
    status: 'pending' | 'active' | 'deactive' | 'deteled',
    visible: boolean,
    description: string,
    category: string,
    location: string,
    address: string,
    price: string,
    startAt: Date | '',
    endAt: Date | '',
    deadline: Date | '',
    createdAt: Date | '',
    updatedAt: Date | '',
    quota: number,
    coverURL: string,
    isFeatured: boolean,
    speakers: string,
    priority: number,
    createDateTime?:firebase.firestore.Timestamp,
    updateDateTime?:firebase.firestore.Timestamp,
}
export function convertActivityDocumentDataToLocalVarible(doc: any): TheActivity {
    const activity = <TheActivity>doc;
    try {
        activity.createdAt = (doc.createdAt as firebase.firestore.Timestamp).toDate();
        activity.updatedAt = (doc.updatedAt as firebase.firestore.Timestamp).toDate();
        activity.startAt = (doc.startAt as firebase.firestore.Timestamp).toDate();
        activity.endAt = (doc.endAt as firebase.firestore.Timestamp).toDate();
        activity.deadline = (doc.deadline as firebase.firestore.Timestamp).toDate();
    } catch (e) {
        console.error(e);
    }
    return activity;
}

export interface ActivityApplication {
    id: string,
    user_id: string,
    activity_id: string,
    status: 'pending' | 'done';
    name: string,
    phoneNumber: string,
    email: string,
    createDateTime?: Date | '',
}