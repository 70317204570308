import { LanguageInterface } from "../language-interface";
import {
  TheAdvisorBookingRecord,
  TheAdvisorBookingRecordStatus,
} from "../../class/theAdvisorBooking";
import { CompanyUserPermissionEnumItem } from "../../class/CompanyUserPermission";
import { AdminPermissionEnumItem } from "src/app/class/AdminPermission";
import { enumWhatsappTemplates } from "src/app/class/WhatsappTemplatesEnumKeys";

export const Hant: LanguageInterface = {
  appname: "HRCA",
  login: "登入",
  captial_or: "或",
  firebaseError: new Map([
    ["auth/user-disabled", "賬戶已停用，可能正等待審覈或被管理員停用。"],
  ]),
  err_msg_enter_your_data: "輸入你的個人資料",
  err_msg_enter_your_fullname: "輸入你的全名",
  err_msg_invalid_fullname: "輸入的姓名無效",
  err_msg_select_your_worked_years: "請選擇你的工作年期",
  err_msg_select_job_type: "請選擇行業",
  err_msg_selct_job_title: "請輸入工作標題",
  err_msg_invalid_job_title: "無效的工作標題",
  err_msg_enter_your_work_date_range: "請輸入你的工作區間",
  err_msg_select_edu_level: "請選擇你的教育水平",
  err_msg_invalid_expect_salary: "請輸入你的期望薪水",
  err_msg_choose_privacy_setting: "請選擇你的私隱設定",
  err_msg_file_too_large: "文件過大！",
  err_msg_disallow_file_type: "不支援的文件類型！",
  input_user_info_title: "個人檔案",
  input_user_info_subtitle: "請填寫你的個人資料獲得更好的瀏覽體驗",
  input_select_worked_years: "請選擇工作年期",
  input_worked_year_prefix: "工作了",
  input_worked_year_over_prefix: "工作超過",
  input_worked_year_postfix: "年",
  select_edu_level: "請選擇你的教育程度",
  map_edu_level: new Map([
    ["0below_f5", "中五以下"],
    ["1f5_hkcee", "中五/會考程度"],
    ["2f6_dsc", "中六/中學文憑程度"],
    ["3f7_hkal", "中七/高考程度"],
    ["4certificate", "持有證書"],
    ["5diploma", "文憑學位"],
    ["6adgree_hd", "副學士/高級文憑學位"],
    ["7bsc", "學士學位"],
    ["8over_bsc", "碩士以上"],
  ]),
  select_job_type: "請選擇工作類型",
  map_job_type: new Map([
    ["accounting", "會計/經濟"],
    ["Adminstraion", "管理"],
    ["banking", "銀行/金融業"],
    ["catering_hospitality", "飲食/服務業"],
    ["construction_property", "物業/建築業"],
    ["design", "設計"],
    ["digital_web_mobile", "網頁/應用開發"],
    ["education_training", "訓練/教育業"],
    ["engineering", "工程"],
    ["government_social_services", "管理/社會服務"],
    ["health_fitness_beauty_care", "保健/美容"],
    ["human_resources", "人力資源"],
    ["it", "資訊科技"],
    ["insurance", "保險業"],
    ["legal_professional_services", "法律/專業服務"],
    ["logistices_transportation", "後勤/運輸業"],
    ["management", "管理"],
    ["manufacturing", "製造業"],
    ["pr", "市場推廣/公共關係"],
    ["media_advertising", "多媒體廣告"],
    ["medical_services", "醫療服務"],
    ["merchandising_purchasing", "商品採購"],
    ["qc_qa", "品質管理"],
    ["sales_cs", "銷售/客戶服務"],
    ["science_lab_research", "科技研發"],
    ["security_property_management_safety_control", "安全/物業管理"],
    ["travel_tourism", "旅遊業"],
    ["secretarial_or_administration", "祕書/行政"],
    ["zother", "其他"],
  ]),
  show_your_profile_question: "是否同意匿名地提供你的資料給予僱主查閱？",
  map_profile_privacy: new Map([
    ["public", "是，保持公開。"],
    ["private", "否，請讓其私密。"],
  ]),
  your_cv_optional_title: "你的履歷（可選）",
  download_updated_cv: "下載已上傳的履歷",
  btn_update: "確定更新",
  update_cv_limit_description:
    "*接受格式：PDF/DOC/DOCX/JPG/PNG; 文件大小限制：10MB",
  work_from: "由",
  work_to: "工作至",
  last_job_title: "最近期的工作經驗（必須）",
  menu_profiles: "賬戶資料",
  menu_logout: "登出",
  menu_about: "關於我們",
  menu_our_visions: "我們的使命",
  menu_our_services: "我們的服務",
  menu_job_matching: "人才庫",
  menu_carrer_advisor: "職涯諮詢",
  menu_events: "活動",
  map_event_types: new Map([
    ["recruitment_talk", "樂職工作坊"],
    ["career_talk", "招聘講座"],
    ["volunteer_activities", "義工活動"],
  ]),
  menu_blog: "博客文章",
  sign_in: "登入",
  forget_password: "忘記密碼",
  register: "註冊",
  placeholder_mobile_phone: "電話號碼",
  placeholder_one_time_password: "單次密碼",
  get_code: "獲取密碼",
  btn_phone_login: "電話登入",
  login_with_email: "電郵登入",
  register_with_email: "以電郵註冊",
  use_phone_number_login: "以手機號碼登入",
  placeholder_email_address: "電子郵箱",
  placeholder_password: "輸入密碼",
  placeholder_repeat_password: "再次輸入密碼",
  forget_password_question: "忘記密碼？",
  reset_password: "重設密碼",
  back: " 返回",
  admin_management: "管理操作",
  list_all_admins_action: "管理員一覽",
  nothing_to_show: "沒有資料",
  loading: "載入中……",
  list_admin_title: "管理員一覽",
  captial_UID: "UID",
  email: "電郵地址",
  email_verified: "電郵已驗證",
  create_time: "創建時間",
  last_sign_in: "最後一次登入",
  remove_this_account: "移除此帳號",
  add: "新增",
  add_admin_title: "新增管理員",
  error_msg_invalid_email_address: "無效電郵地址",
  error_msg_please_enter_password: "請輸入密碼",
  error_msg_please_enter_confirm_password: "請再次輸入密碼",
  error_msg_password_mismatch: "密碼並不相同",
  error_msg_invalid_company_name: "無效的公司名稱",
  error_msg_invalid_company_desc: "無效的公司簡介",
  error_msg_comapny_desc_too_short: "公司簡介過短（至少10個字）",
  notify_msg_verify_mail_sent: "確定電郵已傳送至該電郵。",
  notify_msg_admin_create_reset_mail_sent:
    "新增成功，重設密碼郵件已寄往此電郵。",
  reload: "重整",
  business_user_user: "僱主用戶註冊",
  placeholder_company_name: "僱主名稱",
  placeholder_company_desc: "僱主簡介",
  your_company_logo_title: "僱主Logo（可選）",
  company_info_submitted_wait_approved: "註冊成功！請耐心等待管理員審覈。",
  list_all_companies_action: "公司賬戶一覽",
  is_approved_question: "審批?",
  action_approve: "批准",
  name: "名稱",
  business_corner: "僱主角",
  my_job_ads_action: "工作一覽",
  list_company_title: "公司一覽",
  placeholder_job_ads_title: "標題",
  placeholder_job_ads_duties: "職責",
  placeholder_job_ads_requirements: "要求",
  placeholder_job_ads_description: "詳情",
  placeholder_job_ads_short_description: "概要",
  placeholder_job_ads_position: "職位",
  placeholder_job_ads_location: "地點",
  placeholder_job_ads_education: "教育程度",
  placeholder_job_ads_salary: "工資",
  placeholder_job_ads_welfare: "福利",
  placeholder_job_ads_workday: "工作日數/時間",
  placeholder_job_ads_deadline: "刊登至",
  placeholder_job_ads_metadata: "其他資訊",
  placeholder_job_ads_is_application_hidden: "隱藏此工作的網上報名？",
  notify_msg_created_pending_approval:
    "操作成功！有關內容已創建/更新，等待管理員審覈。",
  job_table_title: "工作標題",
  job_table_status: "狀態",
  job_table_position: "職位",
  job_table_deadline: "顯示至",
  menu_job_ads: "工作配對服務",
  menu_list_all_job_ads: "工作一覽",
  list_pending_jobs_action: "工作一覽",
  job_table_creator_uid: "公司UID",
  job_table_creator_name: "公司名稱",
  job_table_job_id: "工作ID",
  job_table_job_title: "工作標題",
  job_table_job_position: "工作職位",
  job_table_job_approve: "批准？",
  error_msg_action_failed: "操作失敗！",
  notify_msg_action_success: "操作成功！",
  job_table_job_status: "工作狀態",
  menu_employee: "求職者",
  menu_member_register: "會員登記",
  menu_my_account: "我的賬戶",
  menu_cooperation: "合作",
  menu_employments: "招聘人才",
  menu_ads_question: "廣告查詢",
  menu_contact_us: "聯絡我們",
  menu_resoucrces: "資源",
  menu_latest_news: "最新消息",
  menu_hot_articles: "熱門文章",
  menu_latest_event: "最新活動",
  topbar_subtitle_hkjc360: "賽馬會樂職一族360°計劃",
  submit: "提交",
  job_table_job_preview: "預覽",
  apply_job: "報名",
  map_job_location: new Map([
    ["central_and_western_district", "港島中西區"],
    ["eastern_district", "港島東區"],
    ["southern_district", "港島南區"],
    ["wan_chai", "灣仔區"],
    ["kowloon_city", "九龍城區"],
    ["kwun_tong", "觀塘區"],
    ["ssp", "深水埗區"],
    ["wong_tai_sin", "黃大仙區"],
    ["yau_tsim_mong", "油尖旺區"],
    ["island", "離島"],
    ["kwai_tsing", "葵青區"],
    ["north_district", "新界北區"],
    ["sai_kung", "西貢區"],
    ["sha_tin", "沙田區"],
    ["tai_po", "大埔區"],
    ["tsuen_wan", "荃灣區"],
    ["tuen_mun", "屯門區"],
    ["yuen_long", "元朗區"],
    ["other", "其他"],
  ]),
  placeholder_job_types: "工作類別",
  map_job_employment_forms: new Map([
    ["fulltime", "全職"],
    ["parttime", "兼職"],
    ["freelance", "Freelance"],
    ["Project-based", "Project-based"],
    ["other", "其他"],
  ]),
  placeholder_job_employmentForm: "受僱型式",
  member_register: "會員註冊",
  member_login: "會員登入",
  business_register: "僱主註冊",
  business_login: "僱主登入",
  admin_mainapge_slider: "首頁設定",
  job_application_success: "報名已成功提交。",
  job_application_failed: "提交失敗",
  admin_job_application_list: "工作報名一覽",
  admin_job_application_count: "工作報名數目",
  job_matching_title: "人才庫 | Talent Pool",
  placeholder_last_job_title: "最近期工作",
  placeholder_job_type: "工作類別",
  placeholder_job_worked_year: "工作年期",
  placeholder_job_edu_level: "學歷",
  placeholder_job_expect_salary: "期望薪酬",
  member: "會員",
  need_employee_question: "需要聘請人才？",
  contact_us: "聯絡我們",
  admin_ads_banner_list: "廣告Banner",
  active: "有效",
  deactive: "無效",
  placeholder_contact_name: "聯絡人姓名",
  placeholder_contact_email: "聯絡人電郵",
  placeholder_contact_message: "信息內容",
  edit: "修改",
  menu_mainpage_bottom_items: "首頁底部Items",
  list_all_normal_user_action: "普通用戶一覽",
  alreay_account: "已有賬戶？",
  placeholder_expect_salary: "期望薪酬（每月，HKD）",
  placeholder_fullname: "姓名",
  apply_now: "立即申請",
  placeholder_name: "姓名",
  placeholder_phone_number: "電話號碼",
  placeholder_email: "電郵地址",
  message_was_uploaded: "已上傳",
  button_view_file: "查看文件",
  placeholder_keywords: "關鍵字",
  search: "搜尋",
  search_history: "搜尋歷史",
  update: "更新",
  option_location: "地區",
  option_indutry: "行業",
  option_employment_way: "聘用方式",
  have_account_login_now: "已有賬戶？立即登入。",
  menu_admin_job_matching: "管理人才庫",
  membership_number: "會員編號",
  industry: "行業",
  personal_info: "個人簡介",
  you_have_not_login: "請先登入閣下會員帳戶。如未成為會員，請先註冊登記。",
  you_had_loggged_on: "你已經登入！請先登出！",
  view_all: "查看所有",
  view_more: "查看更多",
  no_more_results: "這已是所有內容。",
  menu_donation_to_us: "支持我們",
  my_activities_action: "我的活動",
  map_activity_categories: new Map([
    ["recruitment_talk", "面試技巧"],
    ["career_talk_workshop", "職場資訊"],
    ["others", "活動回顧"],
  ]),
  add_activity_title: "新增活動",
  add_activity_placeholder_title: "標題",
  add_activity_placeholder_categories: "分類",
  add_activity_placeholder_location: "地區",
  add_activity_placeholder_address: "地址",
  add_activity_placeholder_description: "詳情",
  add_activity_placeholder_price_per_person: "收費/人",
  add_activity_placeholder_start_at: "活動開始時間",
  add_activity_placeholder_end_at: "活動結束時間",
  add_activity_placeholder_application_deadling: "報名截止日期",
  msg_invalid_start_date_and_or_end_date: "開始及/或結束日期無效。",
  msg_deadline_is_later_than_start_date: "報名截止日期比活動結束日期早。",
  msg_start_date_or_end_date_on_pass: "開始日期或結束日期是過去日期",
  invalid_start_end_or_deadline: "無效的開始日期/結束日期/報名截止日期",
  remove: "移除",
  menu_display_pending_activities: "活動一覽",
  activity_holder: "舉辦者",
  change_status: "更改狀態",
  activity_datetime: "活動時間",
  to: "至",
  from: "由",
  application_deadline_to: "報名截止日期",
  activity: "活動",
  map_list_activities_status: new Map([
    ["can_apply", "可報名"],
    ["helding", "進行中"],
    ["pass", "已結束"],
  ]),
  apply_status: "報名情況",
  set_to_active: "設爲 Active",
  set_to_deactive: "設爲 Deactive",
  set_to_pending: "設爲Pending",
  set_to_done: "設爲 Done",
  view: "查看",
  unique_id: "獨特ID",
  leave_unique_id_blank_to_random_id:
    "保持讀得ID空白會自動生成隨機ID（但並不建議）",
  status_filtering: "按狀態篩選",
  type: "類型",
  click_url: "點擊的URL",
  company_filtering: "按公司過濾",
  user_id: "用戶ID",
  apply: "應用",
  homepage_silders: "首頁Silders",
  contents: "內容",
  button_text: "按鈕文字",
  button_url: "按鈕鏈結",
  image: "圖片",
  donation_amount_with_dest: "捐款金額/港元（最少10元港幣）",
  pay: "付款",
  powered_by_stripe: "經由HTTPS透過Stripe進行安全支付",
  know_more: "瞭解更多",
  upload_image: "上傳圖片",
  ads_banner_config: "廣告Banner設定",
  ban: "封鎖",
  unban: "解鎖",
  hongkong_mobile_number: "香港手機號碼",
  credit_debit_card_number: "信用卡/預付卡號碼",
  card_holder_fullname: "姓名",
  expiryDate: "逾期日",
  continute: "繼續",
  account_action: "賬戶操作",
  account_banned: "賬戶已停用",
  plese_enter_all_information_to_donate: "請輸入所有必須的資料。",
  please_enter_valid_hk_mobile_phone_number: "請輸入有效的香港手機號碼",
  please_enter_valid_email_address: "請輸入有效的電郵地址",
  please_enter_valid_credit_debit_card_information:
    "請輸入有效的信用卡/扣款卡的資料",
  please_enter_valid_name: "請輸入 閣下的名字",
  please_enter_valid_exp_date: "請輸入有效的咭片逾期日期",
  donation_amount_too_low: "捐款金額過低",
  donation_success: "捐款成功！",
  title_contact_number: "聯絡電話",
  title_address: "聯絡地址",
  menu_list_articles: "文章一覽",
  add_article: "新增文章",
  title: "標題",
  job_position_apply: "職位申請",
  add_activity_placeholder_speakers: "講者",
  add_activity_placeholder_quota: "名額(位)",
  person: "位",
  cover_image: "封面圖片",
  created_at: "創建於",
  updated_at: "更新於",
  post_at: "張貼於",
  blog_article_title: "博客文章",
  update_article: "修改文章",
  volunteer_recruitment: "義工招募",
  map_blog_categories: new Map([
    ["update_news", "職場故事"],
    ["event_news", "活動資訊"],
    ["career_advisor", "職場資訊"],

    ["job_matching_articles", "工作配對文章"],
    ["happy_sporter_articles", "樂動文章"],
    ["revivion_sport_course", "樂動訓練課程重温"],
    ["rewind_sporter_event", "樂動活動回顧"],
    ["ca_ca_articles", "職涯文章"],
  ]),
  article_categories: "文章分類",
  professional_qualification: "專業資格",
  expertise: "專業",
  work_experience: "工作經驗",
  featured_activity: "精選活動",
  introduction: "簡介",
  find_ca: "預約樂職顧問",
  ca_blog: "網上課室",
  map_qual_languages: new Map([
    ["chinese", "廣東話"],
    ["english", "英文"],
    ["putonghua", "普通話"],
  ]),
  map_it_skills: new Map([
    ["msoffice", "MS Office"],
    ["video_editing", "影片剪輯"],
    ["chinese_typing", "中文打字"],
  ]),
  languages: "語言",
  it_skills: "電腦技能",
  other: "其他",
  work_availability: "可上班日期",
  list_advisors: "Advisors一覽",
  add_advisor: "新增Advisor",
  advisor_name: "Advisor 姓名",
  placeholder_expertise: "專業分野",
  placeholder_duration: "每課時長",
  placeholder_consultion_fee: "每次費用",
  placeholder_working_experience: "工作經驗",
  placeholder_major_achievements: "主要成就",
  placeholder_focus_advice: "重點建議",
  minutes: "分鐘",
  locate_language: "系統語言分類",
  hour: "小時",
  make_appointment: "預約一對一職涯查詢服務",
  update_at: "更新於 ",
  book: "預約",
  mapAdvisorBooking: new Map([
    [TheAdvisorBookingRecordStatus.not_paid, "等待付款"],
    [TheAdvisorBookingRecordStatus.wait_confirm, "等待HRCA確認"],
    [TheAdvisorBookingRecordStatus.confirmed, "已確定預約"],
    [TheAdvisorBookingRecordStatus.refunded, "已退款"],
    ["pending_refunded", "處理退款中"],
  ]),
  expect_book_datetime: "期望預約時間",
  remarks: "備註/特殊要求",
  booking_record: "預約記錄",
  pay_by_credit_card_for_appointment_fee: "以信用卡繳付費用",
  menu_ca_list_appoint_advisor: "CA導師預約一覽",
  mark_paid: "標記爲已付款",
  mark_confirmed: "確定預約",
  mark_refund: "退款",
  map_register_for: new Map([
    ["find_job", "尋找工作"],
    ["become_advisor", "申請成為樂職顧問"],
    ["career_advisor_service", "預約職涯諮詢服務"],
  ]),
  user_register_for: "註冊目的",
  choose_your_industry: "選擇你的專業",
  all: "所有",
  menu_job_industry_cats: "編輯Industry分類",
  yes: "是",
  no: "否",
  parent_industry: "上級分類",
  sub_industry: "次級分類",
  suitable_position: "適合職位",
  interest_to_be_ca: "是否有興趣成為HRCA的 職涯顧問（Career advisor ）？",
  map_interest_tobe_ca: new Map<string, string>([
    [
      "interest",
      "有興趣 (HRCA會再進一步聯絡閣下，辦理職涯顧問申請及認證手續）",
    ],
    ["not_interest", "沒有興趣"],
  ]),
  err_msg_choose_interest_ca: "請告訴我們你對職涯顧問的意向。",
  privacy_dclaimer:
    "收集個人資料聲明：閣下所提供的資料將用作此招聘或職涯諮詢服務(Career advisory service)之用途，所得資料將會絕對保密。",
  map_work_years: new Map([
    ["1", "0-2年"],
    ["2", "3-7年"],
    ["3", "8-10年"],
    ["4", "11-15年"],
    ["5", "16-20年"],
    ["6", "21年以上"],
  ]),
  worked_years: "工作年期",
  worked_type: "工作類型",
  edu_level: "教育程度",
  details: "詳情",
  candidate_summary: "會員摘要：",
  n: "n",
  want_employ_this_candidate: "有興趣聘請求職者",
  this_month_activities: "本月活動",
  map_company_permission: new Map([
    [CompanyUserPermissionEnumItem.company_edit_activity, "新增/修改普通活動"],
    [CompanyUserPermissionEnumItem.company_edit_ca_activity, "新增/修改CA活動"],
    [CompanyUserPermissionEnumItem.company_edit_job, "新增/修改工作"],
    [
      CompanyUserPermissionEnumItem.company_edit_vol_eve_activity,
      "新增/修改義工活動",
    ],
  ]),
  company_zero_permission: "你沒有任何權限，請聯絡管理員跟進。",

  permissions: "權限",
  choose_indutry: "選擇行業",
  select_in_talentpool: "在人才庫直接挑選優秀求職者",
  though_hrca_touch_employee: "透過HRCA平台與求職者接觸",
  view_details: "查看詳情",
  career_info: "新中年職場資訊",
  youth_old_interview: "新中年專訪",
  hrca_update_news: "HRCA最新走向",
  know_update_requitment_now: "立即瞭解最新招聘",
  more: "更多",

  author: "作者",

  main_about_us: "關於我們",

  jobs_latest_title: "HRCA 最新資訊",
  jobs_latest_subtitle: "我們特別爲你揀選最新文章及活動資訊",

  comma: "，",

  map_admin_permission: new Map([
    [AdminPermissionEnumItem.admin_edit_user, "管理普通用戶"],
    [AdminPermissionEnumItem.admin_edit_admin, "管理管理員"],
    [AdminPermissionEnumItem.admin_edit_company, "管理公司帳戶"],
    [AdminPermissionEnumItem.admin_edit_ads_banner, "設定廣告Banner"],
    [AdminPermissionEnumItem.admin_edit_mainpage, "設定首頁"],
    [AdminPermissionEnumItem.admin_edit_job, "管理工作"],
    [AdminPermissionEnumItem.admin_edit_industry, "管理Industry分類"],
    [AdminPermissionEnumItem.admin_edit_job_matching, "管理人才庫"],
    [AdminPermissionEnumItem.admin_edit_activity, "管理活動"],
    [AdminPermissionEnumItem.admin_edit_article, "管理博客文章"],
    [AdminPermissionEnumItem.admin_edit_advisor, "管理Advisor"],
    [AdminPermissionEnumItem.admin_edit_ca_article, "管理CA博客文章"],
    [AdminPermissionEnumItem.admin_edit_ca_activity, "管理CA活動"],
    [AdminPermissionEnumItem.admin_download_raw_data, "下載原始資料"],
    [AdminPermissionEnumItem.admin_manage_whatsapp_templates, "WhatsApp 模板"],
    [
      AdminPermissionEnumItem.admin_manage_whatsapp_auto_response,
      "WhatsApp 自動回應",
    ],
    [AdminPermissionEnumItem.admin_sport_articles, "樂動文章"],
    [AdminPermissionEnumItem.admin_sport_rewines, "樂動回顧"],
    [AdminPermissionEnumItem.admin_sport_revisions, "樂動課程重温"],
    [AdminPermissionEnumItem.admin_courses_edit, "樂動課程編輯"],
  ]),
  admin_zero_permission: "你沒有任何權限，請聯絡系統管理員。",

  map_ca_blog_categories: new Map([
    ["career_info", "面試技巧"],
    ["event_news", "活動回顧"],
    ["career_advisor", "職場資訊"],
  ]),
  advisor: "顧問",
  related_articles: "相關文章",

  job_result_prefix: "我們有",
  job_result_suffix: "工作配對。<br>你可以選取一個瀏覽細節。",

  job_select_description:
    "揀選指定行業，再揀選合適的人才，<br>再透過 HRCA 聯絡他們。",

  apply_job_term_1:
    "所有個人履歷/資料收集後，將嚴格保密及只會收集作進行職位甄選之用",
  apply_job_term_2:
    "如申請工作逾4星期及沒有收到機構聯絡閣下，閣下的個人履歷/資料將會保留；得到閣下同意下，予有合適空缺的公司作甄選考慮（職位甄選之用）",

  footer_contact_us: "聯絡我們",
  footer_know_more: "知道更多",
  footer_about_us: "關於我們",
  footer_terms_of_use: "使用條款",
  footer_privacy_policy: "私穩條例",
  footer_follow_us: "關注我們",

  footer_tel: "電話：",
  footer_whatsapp: "WhatsApp熱線：",
  footer_email: "電郵：",
  footer_address: "香港九龍長沙灣道760號香港紗廠第5期4樓C室",

  contact_us_title: "聯絡我們 | Contact Us",
  contact_us_header: "聯絡我們：",
  contact_us_content: `
    <ul>
      <li class="contact-li">【聘用新中年服務】：新中年人才配對 ｜ 刊登招聘廣告 ｜ 舉辦招聘講座
      <br><a href="mailto:job@happy-retired.org">job@happy-retired.org</a></li>
      <li class="contact-li">【新中年發展相關】：舉辦職場工作坊 ｜ 義工招募
      <br><a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li class="contact-li">【年青人職涯發展相關】：職涯諮詢服務 ｜ 成為樂職顧問 ｜ 舉辦職涯/行業講座
      <br><a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li class="contact-li">【新中年運動相關】：舉辦體能活動 ｜ 成為樂動區長
      <br><a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li class="contact-li">一般查詢：<a href="mailto:info@happy-retired.org">info@happy-retired.org</a> </li>
      <li class="contact-li">媒體及任何廣告聯絡：<a href="mailto:info@happy-retired.com">info@happy-retired.com</a> </li>
      <li class="contact-li">需要技術支援的會員/用戶：<a href="https://api.whatsapp.com/send?phone=85266865812" rel="nofollow noreferrer noopener" target="_blank">WhatsApp 6686 5812</a> </li>
    </ul>
    <br>歡迎聯絡我們！
    `,
  contact_us_address: "地址：香港九龍長沙灣道760號香港紗廠第5期4樓C室",

  main_our_service: "樂齡工作",
  main_we_provide_1: "樂進工作坊/課程",
  main_we_provide_2: "樂進工作坊/課程",
  main_we_provide_3: "僱主案例分享",
  main_we_provide_4: "職涯諮詢服務",
  main_we_provide_5: "職•興自測",

  main_contact_us: "聯絡我們",
  main_contact_us_content: `
    <ul>
      <li>有關人才配對或刊登招聘廣告 / 招聘講座 / 義工招募：<a href="mailto:job@happy-retired.org">job@happy-retired.org</a></li>
      <li>新中年職場工作坊 / 年青人職涯諮詢服務：<a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li>賽馬會樂動一族各項課程：<a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
      <li>香港賽馬會社區資助計劃 樂優一族 360：<a href="mailto:info@happy-retired.org">info@happy-retired.org</a></li>
     </ul>
    <br>歡迎聯絡我們！
    `,
  main_about_us_content: `
    <p><b>樂活新中年慈善動力(Happy-Retired Charity Action) 成立於2015年，
    為香港稅局認可慈善機構，主要為本地 50歲或以上的新中年，包括退休及計劃退休人士，
    提供工作配對、職涯諮詢顧問、及體能訓練的多元化服務。</b></p>
    <ol>
    <li><b>香港賽馬會社區資助計劃:樂優一族 360</b>
    計劃由香港賽馬會慈善信託基金捐助，旨在鼓勵 50+新中年或退休人士，制定自己的退休計劃及方向，透過自我評估，進一步了解自 己的興趣、才能、性格和潛能，選擇重投職場、參予義務工作、開創業務、或學習新事物等，為自己的退休之路開拓邊界，燃亮第二人 生，從而建構全方位優質及有意義的退休生活。
    我們會為 50+新中年提供工作及義工配對，並舉辦不同工作坊及培訓，讓新中年學習所需技能及知識。
    <b>僱主</b>:我們鼓勵僱主積極聘用50+新中年，在職場加入富經驗、人脈及高質素的 50+人才，提升生產力。無論公營機構或私人企業，若 透過我們成功聘請新中年，我們更會為僱主認證，成為「 樂齡僱主」，有機會獲得我們就僱主「多元共融」作為 ESG政策提供諮詢。</li>
    <li>
    <b>職涯諮詢</b>:我們提供職涯諮詢服務，鼓勵 50+新中年加入成為「樂職顧問」傳承、分享自己的社會經驗，為有需要人士提供事業路向、 行業知識、求識技巧及專業發展的職場諮詢。
    </li>
    <li>
    <b>樂動一族</b> :我們鼓勵 50+新中年齊運動、強體魄。透過參加由香港賽馬會慈善信託基金捐助的「賽馬會樂動一族計劃」，新中年有機會 成為樂動區長，帶領中年人及長者做運動; 此外，計劃亦在各區提供體能課程，鼓勵平時少做運動的中年人及長者，由零開始，一步 步培養恆常運動的習慣。
    </li>
    </ol>
    <p>新中年可透過平台服務，確立退休及生活目標，一方面善用個人寶貴的經驗和專長，
    貢獻社會;另一方面鍛練體能，保持身心健康，強健體魄裝備自己，活出更精彩的第二人生，提高社會對新中年群組的認同。</p>
    `,

  main_project_description: "賽馬會躍動啓航計劃 - 樂職一族 360",
  main_project_description_content: `
    <p>「樂活新中年慈善動力」於 2020年,獲得「香港賽馬會慈善信託基金」的捐助,
    開展一項為期三年的項目,名為「賽馬會躍動啓航計劃-樂職一族 360」,
    項目分為兩大部份，第一部份為新中年建立工作配對平台，為有意重投職場的新中年，
    提供工作履歷認證和工作配對服務，以及全面的資訊、訓練，
    例如退休後就業心態培訓、重投職場準備，開展事業的新一頁。</p>

<p>另一方面,我們亦擴大「樂職顧問」服務，招募新中年成為「樂職顧問」，
為年青人提供事業路向、行業及專業發展的職場諮詢。新中年和年青人可一同透過計劃，
確立自己的生涯及職涯目標，豐富個人經驗，建構豐盛人生。</p>`,

  common_close: "關閉",

  jobs_register_member: "登記成為會員",
  jobs_submit_cv: "提交個人履歷表",
  jobs_browse_jobs: "查看職位空缺",

  experience_new_company: "新僱主",
  experience_company: "僱主",
  experience_details: "經驗細節",

  register_term_1:
    "我同意樂活慈善動力網（HRCA）使用個人資料，以提供職位資訊、義工資訊、活動資訊和職涯諮詢服務資訊。",
  register_term_2:
    '本人確認已閱讀及同意樂活慈善動力網（HRCA）的 <a href="/terms" target="_blank">《使用條款》</a>及 <a href="/privacy" target="_blank">《私隱政策聲明》</a>。',
  err_reg_form_checkboxes: "請選取最後兩個核取方塊以同意條款並繼續。",
  err_reg_form_register_for: '請選取最少一項"註冊目的"',

  menu_join_ca: "加入樂職顧問團隊",

  all_activities: "所有活動",
  apply_activity_term_1: "活動只限本網免會員參加",
  apply_activity_term_2:
    "如閣下未成爲會員，我們會根據你上述提供的資料幫你登記爲會員。",
  apply_activity_term_3:
    '按此可瀏覽<a href="/terms" target="_blank">《使用條款》</a>及<a href="/privacy" target="_blank">《私隱政策聲明》</a>。',
  err_apply_activity_form_checkboxes:
    "請選取最後三個核取方塊以同意條款並繼續。",

  supported_appointment_format: "預約形式",
  phone_call: "電話",
  face_to_face: "面對面",

  appointment_count: "預約數目",

  menu_talent_search: "專才搜尋服務",

  your_cv_title: "你的履歷",
  job_application_failed_no_cv: "請附上履歷",

  manage_qna_list: "管理問答",

  qna: "問與答",

  clear_tag: "清除標籤搜尋",
  tag: "標籤",
  visible: "可見",
  content: "內容",
  tags: "標籤",
  coverImage: "封面",
  short_question: "問",
  short_answer: "答",
  left: "左",
  right: "右",
  question: "問題",
  answer: "回答",
  add_line: "新增一行",
  new_tag: "新標籤",
  upload_cover: "上載封面",
  cancel: "取消",
  action_success: "操作成功",
  action_failed: "操作失敗",
  please_upload_cover_image: "請上載封面圖片",
  hidden: "隱藏",

  map_whatsapp_templates_key_translates: new Map([
    [
      enumWhatsappTemplates.confirmation_of_ca_advising_to_advisor,
      "報名確認信息，給指導方。",
    ],
    [
      enumWhatsappTemplates.confirmation_of_ca_advising_to_young,
      "報名確認信息，給報名方。",
    ],
    [
      enumWhatsappTemplates.consultation_done_or_not_to_advisor,
      "會見後詢問是否完成，給指導方。",
    ],
    [
      enumWhatsappTemplates.consultation_done_or_not_to_young,
      "會見後詢問是否完成，給報名方。",
    ],
    [
      enumWhatsappTemplates.consultation_is_done_message_to_advisor,
      "會見後，指導方回應 全數完成後信息。",
    ],
    [
      enumWhatsappTemplates.consultation_not_done_message_to_advisor,
      "會見後，指導方回應 沒有完成任意會見。",
    ],
    [
      enumWhatsappTemplates.done_and_thanks_to_young,
      "報名方回報會見完成後感謝信息。",
    ],
    [enumWhatsappTemplates.evaluation_form_to_young, "問卷信息，給報名方。"],
    [
      enumWhatsappTemplates.reminder_before_few_hour_to_advisor,
      "會見前數刻的提醒信息，給指導方。",
    ],
    [
      enumWhatsappTemplates.reminder_before_few_hour_to_young,
      "會見前數刻的提醒信息，給報名方。",
    ],
    [
      enumWhatsappTemplates.will_reschedule_question_to_young,
      "詢問會否重新安排約見，給報名方。",
    ],
  ]),
  real_job_matching: "工作配對",
  book_career_advisor: "預約樂職",
  event_rewind: "活動回顧",
  trainning_revision: "訓練課程重温",
  fitness_course: "體能課程",
  happy_local_sporter: "樂動區長",
  intro: "簡介",
  happy_sporter: "樂動課程",
  ca_dot_20: "行業講座 • 職涯規劃 • 其他",
  ca_online_cla_02: "面試技巧 • 職場資訊 • 活動回顧",
  about_us: "關於我們",
  jobMatchingIntro_content1:
    "作為僱主和新中年之間橋樑HRCA致力為雙方提供專業配對服務",
  mission: "使命",
  jobMatchingIntro_content2:
    "我們的使命是釋放新中年的價值，讓他們盡己所能，繼續貢獻社會。我們同時致力推動僱主對新中年的認可。我們深信新中年豐富的職場經驗、專業知識和人脈網絡, 可讓僱主受益匪淺。",
  job_matching: "工作配對服務",
  talent_search: "專才搜尋服務",
  profile_accreditation: "工作履歷認證",

  map_sport_rewine_categoies: new Map([
    ["event_sharing", "活動分享"],
    ["personal_sharing", "個人分享"],
  ]),
  map_sport_revision_categories: new Map([
    ["group_revision", "小組活動重温"],
    ["area_leader_revision", "區長訓練重温"],
    ["fitness_training_revision", "體能訓練重温"],
  ]),
  map_sport_article_categories: new Map([
    ["health_express", "健康快訊"],
    ["sport_tips", "運動小貼士"],
  ]),

  now_event: "最新活動",
  ended_event: "過往活動",

  map_health_questionire_age: new Map([
    ["below-59", "59歲以下"],
    ["60-65", "60至65歲"],
    ["66-70", "66至70歲"],
    ["71-75", "75至75歲"],
    ["76-80", "76至80歲"],
    ["81-90", "81至90歲"],
    ["90upper", "90歲以上"],
  ]),
  map_health_questionire_sport_time_per_week: new Map([
    ["0-1_times", "0到1次"],
    ["2-4", "2到4次"],
    ["5-6", "5到6次"],
    ["7", "每天都運動"],
  ]),
  map_health_questionire_sport_minute_per_time: new Map([
    ["below_15", "低於15分鐘"],
    ["16-30", "16到30分鐘"],
    ["31-60", "31到60分鐘"],
    ["60up", "60分鐘以上"],
  ]),

  pending: "等待處理",
  accepted: "已接受",
  canceled: "已取消",

  sport_articles: "樂動文章",
  sport_revistions: "樂動課程重温",
  sport_rewines: "樂動活動回顧",
  sport_courses_edit: "樂動課程管理",

  select_good_job_to_get_ca_appoinment_0: "預約一對一職涯查詢服務",
  select_good_job_to_get_ca_appoinment_1: "按此 立即預約HRCA職涯諮詢服務！",
  zoom_call: "Zoom 通話",
  view_count: "瀏覽次數",

  employer: "僱主",
  young_olds: "新中年",
  job_matching_about_us_emp_point_0: "有效利用騰出的時間和資源，專注於核心事務",
  job_matching_about_us_emp_point_1: "將工作配對交托給值得信賴的工作伙伴",
  job_matching_about_us_emp_point_2: "提供專屬和多元化的一站式服務",
  job_matching_about_us_young_point_0: "人職配對，助你釋放價值",
  job_matching_about_us_young_point_1: "履歷認證，多重曝光，增加受聘機會",
  job_matching_about_us_young_point_2: "跨代工作技能培訓，助您融入年輕職場",
  service_scope: "服務範圍",
  job_matching_service: "工作配對服務",
  talent_search_service: "專才搜尋服務",
  profile_accredition: "工作履歷認證",
  events: "活動",
  service_scope_desc_0:
    "根據僱主的招聘要求進行基本的新中年 vs 職位配對 （全職/兼職/自由工作等）",
  service_scope_desc_1:
    "為僱主提供全方位人才解決方案尋找最切合企業所需的新中年專才",
  service_scope_desc_2:
    "獲取履歷認證取得更多僱主認可並可加入HRCA人才庫 增加受聘機會",
  service_scope_desc_3:
    "提供各類活動幫助新中年充分裝備自己重投職場 （培訓/講座/工作坊等）",

  target_candidates_title: "目標人選",
  target_candidates_point_0: "正值金齡的新中年",
  target_candidates_point_1: "仍然活躍於職場的專業人士",
  target_candidates_point_2:
    "擁有豐富職場經驗、專業技能、深博知識，以及寬廣人脈網絡的人才 ",
  tss_why_title: "為什麼",
  tss_why_desc_0: "“Old but Gold”!",
  tss_why_desc_1: "經驗和視野非朝夕得以複制或傳授",
  tss_why_desc_2: "建立團隊的專業知識和業務網絡亦非易事",
  tss_why_desc_3: "配對得宜的金齡人才可以成為現有團隊的良師益友",
  hrca_professional_talent_search_team_title: "HRCA 的專業人才搜尋團隊",
  hrca_professional_talent_search_team_point_0: "“Old but Gold!",
  hrca_professional_talent_search_team_point_1:
    "我們擁有功底紮實、經驗豐富的金齡獵頭專家 ",
  hrca_professional_talent_search_team_point_2:
    "在各行業和職位類別都有成功配對的良好往績 ",
  hrca_professional_talent_search_team_point_3:
    "專心一致助您應對人才挑戰，達成目標 ",
  hrca_professional_talent_search_team_point_4:
    "明白成功的匹配包括關鍵技能、相關經驗、具體知識、文化合拍度、發展階段、心智、態度、化學作用……等等...等等...",
  our_expertise_title: "我們的專長",
  our_expertise_desc_0:
    "行業：零售、快消、餐飲、酒店、銀行和金融、電信、資訊技術、房地產、建築和工程、製造業 ",
  our_expertise_desc_1:
    "工作職能：綜合管理、營運管理、財務和會計、人力資源、銷售、市場推廣 、質量管理、專業服務等",
  our_strengths_title: "我們的優勢",
  our_strentths_desc_0: "“Old but Gold”！",
  our_strentths_desc_1:
    "為您而設的專項團隊由顧問和調研專員組成，負責管理和推進每個人才搜尋項目，直至成功完成任務",
  our_strentths_desc_2:
    "我們是您的業務合作夥伴，樂意為您出謀劃策， 制定招聘策略",
  our_strentths_desc_3: "我們深切明白企業家的期望和客戶所需",
  our_strentths_desc_4: "擁有強大的人脈網絡和多樣化的人才搜索渠道",
  contact_us_and_talk_to_our_experts_today: "立即聯絡我們，約談HRCA顧問！",
  ca_blog_subtitti: "行業講座 • 職涯規劃 • 其他",
  service_scopes: "服務範圍",
  main_we_provide_sport: "體能訓練",
  mainpage_sport_desc: `<p>我們<b>為新中年開展全新運動項目，於香港島、九龍、新界東及新界西推出運動領袖訓練、體能課程及小組活動等，敬請留意。</b></p>`,

  ole_navbar_text: "職涯規劃教育(OLE)",
  job_advise_license_number: "職業介紹所牌照號碼",
  map_vol_eve_activity_categories: new Map([
    // ['0Causes', '範疇'],
    ["1AnimalBenefits", "動物福利"],
    ["2ElderlyService", "長者服務"],
    ["3Child_Youth", "兒童/青年"],
    ["4EnvironmentalProtection", "環境保護"],
    ["5SocialIssue", "社會議題"],
  ]),
  my_volunteer_activities: "我的義工活動",
  menu_display_pending_vol_activities: "義工活動一覽",
  volunteer_activities: "義工活動",
  happy_miles: "樂進里程",
  happy_mile_event_and_training: "樂進活動及培訓",
  happy_mile_age_work: "樂齡工作",
  happy_mile_friendly_employer: "樂齡友善僱主",
  job_self_test: "職•興自測",
  ended_rewine: "過往項目",

  happy_mile_event_and_course_intro: "活動及課程概覽",
  happy_mile_employment_event: "招聘會",
  happy_mile_result_sharing: "成果分享",
  happy_mile_volunteer_pairing: "義工推介服務",
  happy_mile_job_pairing: "工作推介服務",
  happy_mile_elite_pairing: "專才配對服務",
  happy_mile_happy_age_elite_db: "樂齡人才庫",
  happy_mile_employer_case_sharing: "僱主案例分享",
  happy_mile_event_for_employer: "樂齡僱主活動",
  happy_mile_360_premium_businesss: "樂優360企業會員",
};
