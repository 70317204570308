import { Pipe, PipeTransform } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';

@Pipe({
  name: 'advisorAppointmentStatus'
})
export class AdvisorAppointmentStatusPipe implements PipeTransform {
  i18n = new I18n()

  transform(value: string): string {
    return this.i18n.getStringMap('mapAdvisorBooking').get(value) || value
  }

}
