import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { dbKeys, UserRecordInMap } from 'src/app/class/common-objects.enum';
import { TheActivity } from 'src/app/class/the-activity';
import { ILogging, LoggingKey } from 'src/app/utils/logger-utils.service';
import { IDownloadCsvMask } from '../raw-data-download.component';

@Component({
  selector: 'app-dl-raw-events-info',
  templateUrl: './dl-raw-events-info.component.html',
  styleUrls: ['./dl-raw-events-info.component.less']
})
export class DlRawEventsInfoComponent implements OnInit {
  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_EVENT_INFO'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.the_activities)
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
              "event_id",
              "category",
              "price",
              "speakers",
              "status",
              "title",
              "description",
              "cover_image",
              "location",
              "featured_event",
              "start_from",
              "end_at",
              "reserve_deadline",
              "createDateTune",
              "lastUpdateDateTime",


            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <TheActivity>docs.data()
            if (record && record.id) {
              arrOutput.push([
                record?.id||"",
                record?.category||'',
                record?.price||'',
                record?.speakers||'',
                record?.status||'',
                record?.title||'',
                record?.description||'',
                record?.coverURL||'',
                record?.location||'',
                record?.isFeatured? 'yes':'no',
                (<firestore.Timestamp> (<any>record?.startAt))?.toDate()?.toLocaleString() || '',
                (<firestore.Timestamp> (<any>record?.endAt))?.toDate()?.toLocaleString() || '',
                (<firestore.Timestamp> (<any>record?.deadline))?.toDate()?.toLocaleString() || '',
                (<firestore.Timestamp> (<any>record?.createdAt))?.toDate()?.toLocaleString() || '',
                (<firestore.Timestamp> (<any>record?.updateDateTime))?.toDate()?.toLocaleString() ||  (<firestore.Timestamp> (<any>record?.createdAt))?.toDate()?.toLocaleString() || '',
                

              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }

}
