import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { I18n } from '../../../i18n/i18n';
import { MatDialog } from '@angular/material/dialog';
import { AddAdminListAdvisorDialogComponent } from './add-admin-list-advisor-dialog/add-admin-list-advisor-dialog.component';
import { TheAdvisor } from '../../../class/the-advisor';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, openSimpleMessageDialog, cloudFunctionsKeys } from '../../../class/common-objects.enum';
import { firestore } from 'firebase/app';
import { IndustryCatItem } from '../../../class/IndustryCatItem';
import { i18n_locate } from 'src/app/i18n/i18n_const';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-ca-admin-list-advisor',
  templateUrl: './ca-admin-list-advisor.component.html',
  styleUrls: ['./ca-admin-list-advisor.component.less']
})
export class CaAdminListAdvisorComponent implements OnInit {

  topIndustries: Map<string, IndustryCatItem> = new Map()
  subIndustries: Map<string, IndustryCatItem> = new Map()
  i18n = new I18n()
  isRefreshing: boolean = false

  constructor(
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    public afFunction: AngularFireFunctions,
  ) { }

  ngOnInit() {

    this.firestore.collection(dbKeys.the_advisors, (ref => ref.orderBy('name'))).valueChanges().subscribe(d => {
      if (d && d.length > 0) {
        this.advisors = []
        for (const o of d) {
          const r: any = o
          const result = <TheAdvisor>r
          result.createAt = (<firestore.Timestamp>r.createAt).toDate()
          result.updateAt = (<firestore.Timestamp>r.updateAt).toDate()
          this.advisors.push(result)
        }
      } else {
        this.advisors = []
      }
    })
    this.subIndustriesUpdate()
  }
  advisors: Array<TheAdvisor> = null

  get mapIndustry() {
    return this.i18n.getStringMap('map_job_type')
  }
  async setStatus(id: string, status: 'active' | 'deactive') {
    if (id && status) {
      try {
        await this.firestore.collection(dbKeys.the_advisors).doc(id).update({
          status: status
        })
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `錯誤！ ERROR！\nCODE: ${e.message}`)
      }
    }
  }
  toAppointmentList(id: string) {
    if (!id) return
    const s = ['admin', 'listAppointAdvisor', id].join('/')
    open(s)
  }
  openAddDialog(id?: string) {
    this.dialog.open(AddAdminListAdvisorDialogComponent, {
      data: {
        id: id
      }
    }).afterClosed().subscribe(() => {

    })
  }

  sub01: Subscription = null
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe()
      } catch (e) { }
    }
    this.firestore.collection(dbKeys.map_industry_cats)
      .valueChanges().subscribe(d => {
        if (d && d.length > 0) {
          for (let o of d) {
            const any0: any = <any>o
            const industry: IndustryCatItem = <IndustryCatItem>any0
            if (industry && industry.id) {
              if (industry.parent_id) {
                this.subIndustries.set(industry.id, industry)
              } else {
                this.topIndustries.set(industry.id, industry)
              }
            }
          }
        }
      })
  }
  getIndustryNameById(id: string) {
    // console.log(id)
    const s = this.topIndustries.get(id)
    // console.log(s)
    if (s && s.id) {
      const industry = s
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name
      } else {
        return industry.en_name
      }
    }
    return '其他/Other'
  }


  async reloadAdvisorApplicationCount() {
    if (this.isRefreshing) return
    this.isRefreshing = true
    const calltable = this.afFunction.httpsCallable(cloudFunctionsKeys.updateAllAdvisorAppointmentCount)
    calltable({}).subscribe(
      async (next) => {
        this.isRefreshing = false
        return
      },
      async (error) => {
        this.isRefreshing = false
        console.log(error)
      }
    )
  }
}
