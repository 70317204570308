<div class="thisBody">
  <div class="navbar-collapse collapse w-100 order-3 dual-collapse2" id="navbarSupportedContent2">
    <div class="dropdown-divider" id="dashTHISokp"></div>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item" *ngIf="isLogin == null">
        <a class="nav-link w">?!?!</a>
      </li>
      <li class="nav-item" *ngIf="isLogin != null && !isLogin">
        <a class="nav-link w" [id]="registerButtonGlobalId" (click)="onLoginClick(1);closeNavIfNotcollapse();">{{
          i18n.get("member_register") }}</a>
      </li>

      <li class="nav-item" *ngIf="isLogin != null && !isLogin">
        <a class="nav-link w" [id]="loginButtonGlobalId" (click)="onLoginClick(0)">{{ i18n.get("member_login") }}</a>
      </li>
      <li class="nav-item" *ngIf="isLogin != null && !isLogin">
        <a class="nav-link w" (click)="onLoginClick(2);closeNavIfNotcollapse();">{{
          i18n.get("business_register")
          }}</a>
      </li>

      <li class="nav-item" *ngIf="isLogin != null && !isLogin">
        <a class="nav-link w" (click)="onLoginClick(0);closeNavIfNotcollapse();">{{
          i18n.get("business_login")
          }}</a>
      </li>
      <li class="nav-item dropdown" *ngIf="isLogin != null && !!isLogin && currentUser && isAdmin">
        <a class="nav-link w dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ i18n.get("admin_management") }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item w" style="cursor: pointer;" *ngIf="
          !checkAdminPermissionIfAny()
          ">{{ i18n.get("admin_zero_permission")
            }}</a>
          <a class="dropdown-item" style="cursor: pointer;"
            *ngIf="checkAdminPermission(adminPermissionItems.admin_edit_user)"
            (click)="toListNormalUsers();closeNavIfNotcollapse();">{{ i18n.get("list_all_normal_user_action") }}</a>
          <a class="dropdown-item" style="cursor: pointer;"
            *ngIf="checkAdminPermission(adminPermissionItems.admin_edit_admin)"
            (click)="toListAdmin();closeNavIfNotcollapse();">{{ i18n.get("list_all_admins_action") }}</a>
          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_company)
            " (click)="toListCompany();closeNavIfNotcollapse();">{{ i18n.get("list_all_companies_action") }}</a>
          <hr *ngIf="
              checkAdminPermission(
                adminPermissionItems.admin_edit_ads_banner
              ) ||
              checkAdminPermission(adminPermissionItems.admin_edit_mainpage)
            " />
          <a class="dropdown-item" (click)="toAdminAdsBannerList();closeNavIfNotcollapse();" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_ads_banner)
            ">{{ i18n.get("admin_ads_banner_list") }}</a>
          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_mainpage)
            " (click)="toAdminEditSlider();closeNavIfNotcollapse();">{{ i18n.get("admin_mainapge_slider") }}</a>
          <hr *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_job) ||
              checkAdminPermission(adminPermissionItems.admin_edit_industry) ||
              checkAdminPermission(
                adminPermissionItems.admin_edit_job_matching
              ) ||
              checkAdminPermission(adminPermissionItems.admin_edit_activity) ||
              checkAdminPermission(adminPermissionItems.admin_edit_article)
            " />
          <a class="dropdown-item" style="cursor: pointer;"
            *ngIf="checkAdminPermission(adminPermissionItems.admin_edit_job)"
            (click)="toPendingJobs();closeNavIfNotcollapse();">{{ i18n.get("list_pending_jobs_action") }}</a>
          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_industry)
            " routerLink="/admin/manageIndustryCats" (click)="closeNavIfNotcollapse();">{{
            i18n.get("menu_job_industry_cats") }}</a>
          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_job_matching)
            " (click)="toAdminTalent();closeNavIfNotcollapse();">{{ i18n.get("menu_admin_job_matching") }}</a>
          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_activity)
            " (click)="toPendingActivities();closeNavIfNotcollapse();">{{ i18n.get("menu_display_pending_activities")
            }}</a>
               <a class="dropdown-item" style="cursor: pointer;" *ngIf="
               checkAdminPermission(adminPermissionItems.admin_edit_activity)
             " (click)="toPendingVolActivities();closeNavIfNotcollapse();">{{ i18n.get("menu_display_pending_vol_activities")
             }}</a>
          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_article)
            " (click)="toAdminListArticles();closeNavIfNotcollapse();">{{ i18n.get("menu_list_articles") }}</a>
          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_qna_management)
            " routerLink="/admin/manage_qna" (click)="closeNavIfNotcollapse();">{{ i18n.get("manage_qna_list") }}</a>

          <hr *ngIf="
              checkAdminPermission(adminPermissionItems.admin_sport_revisions) ||
              checkAdminPermission(
                adminPermissionItems.admin_sport_articles
              ) ||
              checkAdminPermission(adminPermissionItems.admin_sport_rewines) ||
              checkAdminPermission(adminPermissionItems.admin_courses_edit)
            " />

          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_sport_articles)
            " routerLink="/admin/sportArticles" (click)="closeNavIfNotcollapse();">{{ i18n.get("sport_articles") }}</a>

          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_sport_revisions)
            " routerLink="/admin/sportRevisions" (click)="closeNavIfNotcollapse();">{{ i18n.get("sport_revistions")
            }}</a>

          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_sport_rewines)
            " routerLink="/admin/sportRewines" (click)="closeNavIfNotcollapse();">{{ i18n.get("sport_rewines") }}</a>

          <a class="dropdown-item" style="cursor: pointer;" *ngIf="
            checkAdminPermission(adminPermissionItems.admin_courses_edit)
          " routerLink="/admin/sport-courses" (click)="closeNavIfNotcollapse();">{{ i18n.get("sport_courses_edit")
            }}</a>

          <hr *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_advisor) ||
              checkAdminPermission(
                adminPermissionItems.admin_edit_ca_article
              ) ||
              checkAdminPermission(adminPermissionItems.admin_edit_ca_activity)
            " />
          <a class="dropdown-item" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_advisor)
            " (click)="toAdminListAdvisors();closeNavIfNotcollapse();">{{ i18n.get("list_advisors") }}</a>
          <a class="dropdown-item" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_ca_article)
            " routerLink="/caAdmin/listArticles" (click)="closeNavIfNotcollapse();">CA {{
            i18n.get("menu_list_articles") }}</a>
          <a class="dropdown-item" *ngIf="
              checkAdminPermission(adminPermissionItems.admin_edit_ca_activity)
            " routerLink="/caAdmin/adminListPendingActivities" (click)="closeNavIfNotcollapse();">CA {{
            i18n.get("menu_display_pending_activities") }}</a>
          <hr *ngIf="
          checkAdminPermission(adminPermissionItems.admin_download_raw_data)
          || 
          checkAdminPermission(adminPermissionItems.admin_manage_whatsapp_templates)
          ||
          checkAdminPermission(adminPermissionItems.admin_manage_whatsapp_auto_response)
          " />
          <a class="dropdown-item" *ngIf="
            checkAdminPermission(adminPermissionItems.admin_manage_whatsapp_templates)
          " routerLink="/admin/manage-whatsapp-templates" (click)="closeNavIfNotcollapse();">{{ i18n.get("WhatsApp
            Templates") }}</a>
          <a class="dropdown-item" *ngIf="
          checkAdminPermission(adminPermissionItems.admin_manage_whatsapp_auto_response)
        " routerLink="/admin/manage-whatsapp-auto-response" (click)="closeNavIfNotcollapse();">{{ i18n.get("WhatsApp
            Auto-Response") }}</a>
          <a class="dropdown-item" *ngIf="
          checkAdminPermission(adminPermissionItems.admin_download_raw_data)
        " routerLink="/admin/raw-data-dl" (click)="closeNavIfNotcollapse();">{{ i18n.get("Download_raw_data") }}</a>
        </div>
      </li>

      <li class="nav-item dropdown" *ngIf="isLogin != null && !!isLogin && currentUser && isCompany">
        <a class="nav-link w dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ i18n.get("business_corner") }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" *ngIf="
         !checkCompanyPermissionIfAny()
          ">{{i18n.get('company_zero_permission')}}</a>
          <a class="dropdown-item" *ngIf="checkCompanyPermission(companyPermissionItems.company_edit_job)"
            (click)="toCompanySelfJobAds();closeNavIfNotcollapse();">{{i18n.get('my_job_ads_action')}}</a>
          <a class="dropdown-item" (click)="toCompanySelfActivities();closeNavIfNotcollapse();"
            *ngIf=" checkCompanyPermission(companyPermissionItems.company_edit_activity)">{{i18n.get('my_activities_action')}}</a>
            <a class="dropdown-item" routerLink="/caCompany/myActivities" (click)="closeNavIfNotcollapse();"
            *ngIf="checkCompanyPermission(companyPermissionItems.company_edit_ca_activity)">CA
            {{i18n.get('my_activities_action')}}</a>
            <a class="dropdown-item" routerLink="/company/myVoluEveActivities" (click)="closeNavIfNotcollapse();"
            *ngIf="checkCompanyPermission(companyPermissionItems.company_edit_vol_eve_activity)">
            {{i18n.get('my_volunteer_activities')}}</a>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf="isLogin != null && !!isLogin && currentUser">
        <a class="nav-link w dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ (observableCurrentUser | async)?.displayName }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" *ngIf="!isAdmin" (click)="openInputInfoDialog(true);closeNavIfNotcollapse();"
            [id]="submitCvGlobalId">{{ i18n.get("menu_profiles") }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()">{{
            i18n.get("menu_logout")
            }}</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link w dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ i18n.locateDetails.shortName }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" *ngFor="let language of i18n.avaliable_locate"
            (click)="i18n.setCurrentLocateFromString(language.key);closeNavIfNotcollapse();">
            {{ language.longName }}
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>