<h3 class="thisTitle">
  <a class="l" style="cursor: pointer" (click)="closeThisDialog()">X</a>
  <div class="r">{{ i18n.get(topbar_title) }}</div>
</h3>

<mat-dialog-content id="logreg-forms">
  <div>
    <div
      class="alert alert-danger errorMessage"
      role="alert"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <div
      class="alert alert-info errorMessage"
      role="alert"
      *ngIf="notifyMessage"
    >
      {{ notifyMessage }}
    </div>
  </div>
  <br />

  <div class="form-phoneLogin" [style.display]="displayPhoneForm">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon3">+852</span>
      </div>
      <input
        type="number"
        [(ngModel)]="inputPhoneNumber"
        min="10000000"
        max="99999999"
        class="form-control"
        [placeholder]="i18n.get('placeholder_mobile_phone')"
        aria-describedby="basic-addon3"
      />
    </div>

    <div class="input-group mb-3">
      <input
        type="text"
        name="inputPhoneCode"
        [(ngModel)]="inputPhoneCode"
        class="form-control"
        [placeholder]="i18n.get('placeholder_one_time_password')"
      />
      <div class="input-group-append">
        <button
          class="input-group-text"
          id="get-phone-login-code-button"
          (click)="sendPhoneCode()"
        >
          {{ i18n.get("get_code") }}
        </button>
      </div>
    </div>

    <div id="recaptcha-container"></div>
    <button class="btn btn-success btn-block" (click)="goPhoneLogin()">
      <i class="fas fa-sign-in-alt"></i>{{ i18n.get("login") }}
    </button>
    <br />
    <a class="btn btn-link btn-block btn-a" (click)="showLoginForm()"
      ><i class="fas fa-sign-in-alt"></i>{{ i18n.get("login_with_email") }}</a
    >
    <a class="btn btn-link btn-block btn-a" (click)="showSignUpForm()"
      ><i class="fas fa-sign-in-alt"></i
      >{{ i18n.get("register_with_email") }}</a
    >
  </div>
  <div class="form-signin" [style.display]="displayLoginForm">
    <input
      type="email"
      [(ngModel)]="inputLoginEmail"
      id="inputEmail"
      class="form-control"
      [placeholder]="i18n.get('placeholder_email_address')"
      required=""
      autofocus=""
    />
    <input
      type="password"
      [(ngModel)]="inputLoginPassword"
      id="inputPassword"
      class="form-control"
      [placeholder]="i18n.get('placeholder_password')"
      required=""
    />

    <button
      class="btn btn-success btn-block"
      (click)="emailLogin()"
      type="submit"
    >
      <i class="fas fa-sign-in-alt"></i>{{ i18n.get("sign_in") }}
    </button>
    <a class="btn btn-link" (click)="showForgetForm()" id="forgot_pswd">{{
      i18n.get("forget_password_question")
    }}</a>
    <hr />
    <!-- <p>Don't have an account!</p>  -->
    <button
      class="btn btn-link btn-block"
      (click)="showSignUpForm()"
      type="button"
      id="btn-signup"
    >
      <i class="fas fa-user-plus"></i>{{ i18n.get("register_with_email") }}
    </button>

    <!--<p style="text-align:center"> {{i18n.get('captial_or')}} </p>-->
    <a class="btn btn-link" (click)="showPhoneForm()"
      ><i class="fas fa-sign-in-alt"></i
      >{{ i18n.get("use_phone_number_login") }}</a
    >
  </div>

  <div class="form-reset" [style.display]="displayForgetForm">
    <input
      type="email"
      [(ngModel)]="inputForgetPasswordEmail"
      class="form-control"
      [placeholder]="i18n.get('placeholder_email_address')"
      required=""
      autofocus=""
    />
    <button class="btn btn-light btn-block" (click)="forgetPassword()">
      {{ i18n.get("reset_password") }}
    </button>
    <a (click)="showLoginForm()" id="cancel_reset"
      ><i class="fas fa-angle-left"></i> {{ i18n.get("back") }}</a
    >
  </div>

  <div class="form-signup" [style.display]="displaySignUpForm">
    <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fa fa-user"></i> </span>
      </div>
      <input
        name=""
        class="form-control"
        [placeholder]="i18n.get('placeholder_fullname')"
        type="text"
        [(ngModel)]="userInfo.fullname"
        required
      />
    </div>
    <!-- form-group// -->
    <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fas fa-envelope"></i> </span>
      </div>
      <input
        type="email"
        id="user-email"
        [(ngModel)]="inputRegEmail"
        class="form-control"
        [placeholder]="i18n.get('placeholder_email_address')"
        required
        autofocus=""
      />
    </div>
    <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fas fa-unlock-alt"></i>
        </span>
      </div>
      <input
        type="password"
        id="user-pass"
        [(ngModel)]="inputRegPassword"
        class="form-control"
        [placeholder]="i18n.get('placeholder_password')"
        required
        autofocus=""
      />
    </div>

    <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-unlock"></i></span>
      </div>
      <input
        type="password"
        id="user-repeatpass"
        [(ngModel)]="inputRegConfirmPassword"
        class="form-control"
        [placeholder]="i18n.get('placeholder_repeat_password')"
        required
        autofocus=""
      />
    </div>
    <br />
    <!-- <h5>{{ i18n.get("last_job_title") }}</h5> -->
    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fas fa-briefcase"></i> </span>
      </div>

      <select class="form-control" [(ngModel)]="userInfo.workedYears" required>
        <option value="">{{ i18n.get("input_select_worked_years") }}</option>
        <option
          [value]="o.key"
          *ngFor="let o of i18n.getStringMap('map_work_years') | keyvalue"
        >
          {{ o.value }}
        </option>
      </select>
    </div> -->
    <!-- form-group// -->
    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fa fa-building"></i> </span>
      </div>
      <select class="form-control" [(ngModel)]="userInfo.jobType" required>
        <option value="">{{ i18n.get("select_job_type") }}</option>
        <option
          *ngFor="let o of i18n.getStringMap('map_job_type') | keyvalue"
          [value]="o.key"
        >
          {{ o.value }}
        </option>
      </select>
    </div> -->
    <!-- form-group end.// -->
    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fas fa-user-md"></i> </span>
      </div>
      <input
        class="form-control"
        [placeholder]="i18n.get('placeholder_last_job_title')"
        type="text"
        [(ngModel)]="userInfo.jobTitle"
        required
      />
    </div> -->
    <!-- form-group// -->
<!-- 
    <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="far fa-building"></i> </span>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text"> {{ i18n.get("work_from") }} </span>
      </div>
      <input
        class="form-control"
        placeholder="Company Info"
        type="date"
        [value]="parseTimestamp(userInfo.workedFrom)"
        (input)="userInfo.workedFrom = parseDate($event.target.value)"
        required
      />
      <div class="work_from_to_spacer"></div>
      <div class="input-group-prepend">
        <span class="input-group-text"> {{ i18n.get("work_to") }} </span>
      </div>

      <input
        class="form-control"
        placeholder="Company Info"
        type="date"
        [value]="parseTimestamp(userInfo.workedTo)"
        (input)="userInfo.workedTo = parseDate($event.target.value)"
        required
      />
    </div> -->
    <!-- form-group// -->

    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fas fa-school"></i> </span>
      </div>
      <select
        class="form-control"
        [(ngModel)]="userInfo.educationLevel"
        required
      >
        <option value="">{{ i18n.get("select_edu_level") }}</option>
        <option
          *ngFor="let o of this.i18n.getStringMap('map_edu_level') | keyvalue"
          [value]="o.key"
        >
          {{ o.value }}
        </option>
      </select>
    </div> -->
    <!-- form-group end.// -->

    <div>
      <h5>{{ i18n.get("user_register_for") }}</h5>
    </div>
    <div>
      <ng-container *ngFor="let k of userRegisterForKeys">
        <div flex>
          <mat-checkbox
            color="primary"
            class="example-margin"
            [(ngModel)]="userRegisterFor[k]"
            (ngModelChange)="userRegisterForChange($event, k)"
            >{{ i18n.getStringMap("map_register_for").get(k) }}</mat-checkbox
          >
        </div>
      </ng-container>
    </div>

    <div [style.display]="findJobBlockDisplay">
      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_expect_salary") }}</mat-label>
        <input
          matInput
          type="number"
          [placeholder]="i18n.get('placeholder_expect_salary')"
          [(ngModel)]="userInfo.expectedSalary"
        />
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-select
          [placeholder]="i18n.get('show_your_profile_question')"
          [(ngModel)]="userInfo.privacySetting"
        >
          <mat-option
            *ngFor="
              let o of i18n.getStringMap('map_profile_privacy') | keyvalue
            "
            [value]="o.key"
          >
            {{ o.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-select
          [placeholder]="i18n.get('interest_to_be_ca')"
          [(ngModel)]="userInfo.interest_to_ca"
        >
          <mat-option
            *ngFor="
              let o of i18n.getStringMap('map_interest_tobe_ca') | keyvalue
            "
            [value]="o.key"
          >
            {{ o.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <br />
    <!-- <div class="dropzone" dropZone (dropped)="uploadFile($event)">
      <h5>{{ i18n.get("your_cv_optional_title") }}</h5>

      <div class="file">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            (change)="uploadFile($event)"
            accept=".doc,.docx,.odf,.pdf,.jpg,.png"
            style="width: 100%"
          />
        </label>
        <span class="file-cta">
          <span class="file-icon">
            <i class="fa fa-upload"></i>
          </span>
          <span class="file-label">
            <span>
              <br />
              <div *ngIf="percentage | async as pct">
                <span *ngIf="pct <= 99">
                  <progress
                    class="progress is-info"
                    [value]="pct"
                    max="100"
                  ></progress>

                  {{ pct | number }}%
                </span>
                <span *ngIf="pct == 100">
                  {{ i18n.get("message_was_uploaded") }}
                </span>
              </div>
            </span>
          </span>
        </span>
      </div>
    </div> -->

    <!-- <div>
      <b>{{ i18n.get("update_cv_limit_description") }}</b>
      <p>{{ i18n.get("privacy_dclaimer") }}</p>
    </div> -->
    <div>
      <div flex>
        <div class="checkbox-wrap">
          <mat-checkbox
            color="primary"
            class="example-margin"
            [(ngModel)]="regFormChecked1"
          ></mat-checkbox>
        </div>
        <div innerHTML="{{ i18n.get('register_term_1') }}"></div>
      </div>
      <div flex>
        <div class="checkbox-wrap">
          <mat-checkbox
            color="primary"
            class="example-margin"
            [(ngModel)]="regFormChecked2"
          ></mat-checkbox>
        </div>
        <div innerHTML="{{ i18n.get('register_term_2') }}"></div>
      </div>
    </div>
    <div class="form-group" *ngIf="downloadURL">
      <br />
      <a [href]="downloadURL" download target="_blank"
        >{{ i18n.get("download_updated_cv") }} ({{ downloadFilename }})</a
      >
    </div>
    <br />
    <button class="btn btn-light btn-block" (click)="emailSignUp()">
      <i class="fas fa-user-plus"></i>
      {{ i18n.get("register") }}
    </button>
    <br />
    <a id="cancel_signup" (click)="showLoginForm()"
      ><i class="fas fa-angle-left"></i> {{ i18n.get("alreay_account") }}</a
    >
  </div>
  <br />
  <div>
    <p style="text-align: center">{{ i18n.get("captial_or") }}</p>
    <a class="btn btn-light" (click)="closeAndOpenBusiness()">{{
      i18n.get("business_user_user")
    }}</a>
  </div>
</mat-dialog-content>
