import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { cloudFunctionsKeys } from 'src/app/class/common-objects.enum';

@Component({
  selector: 'app-add-admin-dialog',
  templateUrl: './add-admin-dialog.component.html',
  styleUrls: ['./add-admin-dialog.component.less']
})
export class AddAdminDialogComponent implements OnInit {
  i18n = new I18n()
  inputRegEmail = ''
  notifyMessage = ''
  errorMessage = ''
  isBtnLoading = false
  constructor(
    public dialog : MatDialogRef<AddAdminDialogComponent>,
    public afFunction : AngularFireFunctions,
    public afAuth : AngularFireAuth
  ) { }

  ngOnInit() {
  }
  closeThisDialog(){
    this.dialog.close()
  }
  async onAddAdminClick(){
    this.errorMessage = ''
    this.notifyMessage = ''
    if (!this.inputRegEmail.includes('@') || !this.inputRegEmail.includes('.')) {
      // not valid email
      this.errorMessage = this.i18n.get('error_msg_invalid_email_address')
    } else {
      this.notifyMessage = this.i18n.get('loading')
      this.isBtnLoading = true
      try{
        let token = await this.afAuth.auth.currentUser.getIdToken(true)
        let email = this.inputRegEmail
      let callable = this.afFunction.httpsCallable(cloudFunctionsKeys.addNewAdmin)({
        token: token,
        email: email
      }).subscribe(result=>{
        if(result === true){
          this.afAuth.auth.sendPasswordResetEmail(email)
          this.inputRegEmail = ''
          this.dialog.close(true)
        }else{
          this.notifyMessage = ''
          this.errorMessage = result
        }

      this.isBtnLoading = false
      })
      }catch(e){
        this.errorMessage = e.message
        this.isBtnLoading = false
      }
    }
  }
}
