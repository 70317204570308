import { Component, OnInit, Input, Inject } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { NgForm } from '@angular/forms';
import { makeid, dbKeys, jwtHelper } from 'src/app/class/common-objects.enum';
import { JobAds } from 'src/app/class/job-ads';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-update-job-ads',
  templateUrl: './add-update-job-ads.component.html',
  styleUrls: ['./add-update-job-ads.component.less']
})
export class AddUpdateJobAdsComponent implements OnInit {
  notifyMessage = '';
  errorMessage = '';
  jobTypes: Array<string> = [];
  employmentForms: Array<string> = [];
  locations: Array<string> = [];
  // form input
  formId = `add_update_job_ads_form__${makeid(20)}`;
  @Input() initJob: JobAds = null;
  form: any;
  get isUpdate(): boolean { return !!this.initJob; }
  defaultDuties = Array<string>(30).fill('');
  defaultRequirements = Array<string>(30).fill('');

  job: JobAds = {
    id: '',
    creator_uid: this.afAuth.auth.currentUser.uid,
    title: '',
    status: 'pending',
    duties: Array<string>(30).fill(''),
    requirements: Array<string>(30).fill(''),
    topDescription: '',
    bottomDescription: '',
    position: '',
    location: '',
    education: '',
    salary: '',
    employmentForms: '',
    welfare: '',
    industry: '',
    workday: '',
    deadline: '',
    application_count: 0,
    isApplicationHidden: false,
  };
  // end of form input
  i18n = new I18n();
  constructor(
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<AddUpdateJobAdsComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.initJob) {
      const o = data.initJob;
      console.log(o.deadline);
      this.initJob = {
        id: o.id,
        creator_uid: o.creator_uid,
        title: o.title || "",
        status: o.status,
        duties: this.arrayToArrayWithFill(o.duties),
        requirements: this.arrayToArrayWithFill(o.requirements),
        topDescription: o.topDescription || "",
        bottomDescription: o.bottomDescription || "",
        position: o.position || "",
        location: o.location || "",
        education: o.education || "",
        salary: o.salary || "",
        employmentForms: o.employmentForms || "",
        welfare: o.welfare || "",
        industry: o.industry || "",
        workday: o.workday || "",
        deadline: o.deadline,
        application_count: o.application_count || 0,
        isApplicationHidden: !!o.isApplicationHidden,
      };
      this.defaultDuties = this.arrayToArrayWithFill(o.duties);
      this.defaultRequirements = this.arrayToArrayWithFill(o.requirements);
      console.log(this.defaultDuties);
      this.job.employmentForms = o.employmentForms;
      this.job = this.initJob;
      console.log(this.job);
    }
  }
  arrayToArrayWithFill(inArr: string[]) {
    if (inArr.length <= 0) {
      return Array<string>(30).fill('');
    } else {
      const arr: string[] = [];
      for (const o of inArr) {
        arr.push(o);
      }
      const lengthDe = 30 - arr.length;
      for (let i = 0; i < lengthDe; i++) {
        arr.push('');
      }
      console.log(arr);
      return arr;

    }
  }
  ngOnInit() {
    this.updateJobTypes();

  }
  jobTypeMaps(jobType: string) {

    let map = this.i18n.getStringMap('map_job_type');
    return map.get(jobType);
  }
  jobEmpFormMaps(t: string) {

    let map = this.i18n.getStringMap('map_job_employment_forms');
    return map.get(t);
  }
  jobLocationMaps(t: string) {

    let map = this.i18n.getStringMap('map_job_location');
    return map.get(t);
  }
  updateJobTypes() {
    this.jobTypes = [];
    this.employmentForms = [];
    this.locations = [];
    let map1 = this.i18n.getStringMap('map_job_type');
    map1.forEach((v, key) => {
      this.jobTypes.push(key);
    });
    let map2 = this.i18n.getStringMap('map_job_employment_forms');
    map2.forEach((v, key) => {
      this.employmentForms.push(key);
    });
    let map3 = this.i18n.getStringMap('map_job_location');
    map3.forEach((v, k) => {
      this.locations.push(k);
    });
  }

  get pickerMode() {
    const width = outerWidth | window.outerWidth | 0;
    if (width >= 900) {
      return 'popup';
    } else {
      return 'dialog';
    }
  }
  updateDuties(i, data) {
    console.log(i);
    console.log(data);
    this.job.duties[i] = data;
  }
  updateRequirements(i, data) {
    this.job.requirements[i] = data;
  }
  trackByIndex(index, item) {
    this.job[index] = item;
    return index;
  }
  stringToDate(newDate: string): Date {
    return new Date(newDate);
  }
  async onFormSubmit() {
    let form = <HTMLFormElement>document.getElementById(this.formId);
    console.log(form);
    console.log(this.job);
    if (form.reportValidity()) {
      if (this.job.creator_uid) {
        try {
          if (this.isUpdate) {
            console.log(this.job);
            let token = await this.afAuth.auth.currentUser.getIdToken(true);
            let dToken = jwtHelper.decodeToken(token);
            console.log(dToken);
            if (!dToken.isAdmin) this.job.status = 'pending';
            await this.firestore.collection(dbKeys.job_ads).doc(this.job.id).update(this.job);

          } else {
            let result = await this.firestore.collection(dbKeys.job_ads).add(this.job);
            await result.update({
              creator_uid: this.job.creator_uid,
              status: 'pending',
              id: result.id
            });
          }

          this.dialogRef.close(true);
        } catch (e) {
          console.error(e);
          this.errorMessage = e.message;

        }
      } else {
        alert("ILLEGAL ACCESS!\n ERROR CODE: WAS_SIGN_OUT0!!!");
      }
    }
  }

}
