import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'firebase';
import { Subscription } from 'rxjs';
import { dbKeys, unsubscribeAll } from 'src/app/class/common-objects.enum';
import { ISportCourse } from 'src/app/class/ISportCourse';
import { ISportCourseApplication, ISportCourseApplicationStatus } from 'src/app/class/ISportCourseApplication';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-apply-sport-course',
  templateUrl: './apply-sport-course.component.html',
  styleUrls: ['./apply-sport-course.component.less']
})
export class ApplySportCourseComponent implements OnInit {

  @ViewChild('form') form : ElementRef<HTMLFormElement>
  inId = ''
  i18n = new I18n()
  course: ISportCourse = null;
  currentUser :User = null
  application : ISportCourseApplication ={
    status:ISportCourseApplicationStatus.pending,
    uid: '',
    courseId: '',
    name: '',
    phoneNumber: '',
    email: '',
    address: '',
    remarks: '',
    age: '',
    sport_time_per_week: '',
    sport_minute_per_time: '',
    what_sport_do: ''
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private afFirestore: AngularFirestore,
    private afAuth:AngularFireAuth,
    private router:Router,
    private snackbar :MatSnackBar,
  ) {
    activatedRoute.params.subscribe(params => {
      console.log(params)
      if (params?.id) {
        this.inId = params.id
        this.application.courseId = this.inId
        this.loadCurrentCourse()
      }
    })
    afAuth.authState.subscribe(user=>{
      this.currentUser = user || null
      if(user && !this.prefilled){
        this.application.name = this.currentUser.displayName || ''
        this.application.email = this.currentUser.email || ''
        this.application.phoneNumber = this.currentUser.phoneNumber || ''
        this.prefilled = true;
      }
    })
  }

  prefilled=false
  ngOnInit() {
  }

  sub0CurrentCourse: Subscription
  loadCurrentCourse() {
    unsubscribeAll([this.sub0CurrentCourse])
    this.sub0CurrentCourse = this.afFirestore.collection(dbKeys.sport_courses)
      .doc<ISportCourse>(this.inId)
      .valueChanges()
      .subscribe(v => {
        this.course = v;
      })
  }
  get courseDetails(){
    return [
      `課程：${this.course.countName}`,
      `日期：${this.course.holdDateText}`,
      `時間：${this.course.holdTimeText}`,
      `地點：${this.course.location}`,
      
    ]
  }
  onSubmit(){
    if(this.form?.nativeElement?.reportValidity()){
      this.application.uid = this.currentUser?.uid || ''
      console.log('current apliuicaion', this.application)
      this.afFirestore.collection(dbKeys.sport_course_applications)
      .add(this.application)
      .then(v=>{
        this.snackbar.open(this.i18n.translate.action_success+ `(${v.id})`)
        this.router.navigate(['sport-course-application', v.id])
      }).catch(e=>{
        this.snackbar.open(`${this.i18n.translate.action_failed}. ${e.toString()}`)
      })
    }
  }
}
