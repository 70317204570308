import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { TestComponent } from './test/test.component';
import { MainpageComponent } from './mainpage/mainpage.component';

import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, customClaims, AuthPipe, AuthPipeGenerator } from '@angular/fire/auth-guard';
import { ListAdminComponent } from './pages/admin/list-admin/list-admin.component';
import { ListCompanyComponent } from './pages/admin/list-company/list-company.component';
import { MyJobAdsComponent } from './pages/company/my-job-ads/my-job-ads.component';
import { ListPendingJobsComponent } from './pages/admin/list-pending-jobs/list-pending-jobs.component';
import { OurServicesComponent } from './pages/about/our-services/our-services.component';
import { ListJobsComponent } from './pages/jobs/list-jobs/list-jobs.component';
import { AdminMainpageSliderEditComponent } from './pages/admin/admin-mainpage-slider-edit/admin-mainpage-slider-edit.component';
import { AdminListJobApplicationsComponent } from './pages/admin/admin-list-job-applications/admin-list-job-applications.component';
import { ListJobMatchingComponent } from './pages/list-job-matching/list-job-matching.component';
import { TalentSearchComponent } from './pages/talent-search/talent-search.component';
import { TalentSearchServiceComponent } from './pages/talent-search/talent-search-service.component';
import { TalentSearchModelComponent } from './pages/talent-search/talent-search-model.component';
import { AdminAdsListComponent } from './pages/admin/admin-ads-list/admin-ads-list.component';
import { AdminShowUserProfileComponent } from './pages/admin/admin-show-user-profile/admin-show-user-profile.component';
import { ConfigMainpageBottomItemsComponent } from './pages/admin/config-mainpage-bottom-items/config-mainpage-bottom-items.component';
import { ListNormalUserAdminComponent } from './pages/admin/list-normal-user-admin/list-normal-user-admin.component';
import { AdminHomepageComponent } from './pages/admin/admin-homepage/admin-homepage.component';
import { AdminListJobMatchingComponent } from './pages/admin/admin-list-job-matching/admin-list-job-matching.component';
import { DonationToUsComponent } from './pages/donation-to-us/donation-to-us.component';
import { MyActivitiesComponent } from './pages/company/my-activities/my-activities.component';
import { AdminListPendingActivitiesComponent } from './pages/admin/admin-list-pending-activities/admin-list-pending-activities.component';
import { ListActivitiesComponent } from './pages/list-activities/list-activities.component';
import { ListActivitiesApplicationByActivityComponent } from './pages/admin/list-activities-application-by-activity/list-activities-application-by-activity.component';
import { ListArticlesComponent } from './pages/admin/list-articles/list-articles.component';
import { AddArticlesComponent } from './pages/admin/list-articles/add-articles/add-articles.component';
import { ViewSingleArticleComponent } from './pages/articles/view-single-article/view-single-article.component';
import { ArticlesComponent } from './pages/articles/articles.component';
import { CaHomepageComponent } from './pages/ca/ca-homepage/ca-homepage.component';
import { CaGetAppointmentComponent } from './pages/ca/ca-get-appointment/ca-get-appointment.component';
import { CaBlogComponent } from './pages/ca/ca-blog/ca-blog.component';
import { CaAdminListAdvisorComponent } from './pages/admin/ca-admin-list-advisor/ca-admin-list-advisor.component';
import { ViewSingleAdvisorComponent } from './pages/ca/ca-get-appointment/view-single-advisor/view-single-advisor.component';
import { CaAppointmentOrderDetailsComponent } from './pages/ca/ca-get-appointment/ca-appointment-order-details/ca-appointment-order-details.component';
import { ViewSingleAppointmentComponent } from './pages/ca/ca-get-appointment/view-single-appointment.component';
import { CaListAppointmentAdvisorComponent } from './pages/admin/ca-list-appointment-advisor/ca-list-appointment-advisor.component';
import { ListAppointmentCategoriesComponent } from './pages/ca/ca-get-appointment/list-appointment-categories/list-appointment-categories.component';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddEditIndustryCategoriesComponent } from './pages/admin/add-edit-industry-categories/add-edit-industry-categories.component';
import { environment } from 'src/environments/environment';
import { ViewSingleTalentpoolComponent } from './pages/list-job-matching/view-single-talentpool/view-single-talentpool.component';

import { CaBlogsComponent } from './pages/ca/ca-blogs/ca-blogs.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AdminContentComponent } from './pages/admin/admin-content/admin-content.component';
import { ContentComponent } from './pages/content/content.component';
import { JoinCaTeamComponent } from './pages/ca/join-ca-team/join-ca-team.component';
import { ManageQnaComponent } from './admin/manage-qna/manage-qna.component';
import { ListQnaComponent } from './list-qna/list-qna.component';
import { SingleQnaComponent } from './list-qna/single-qna/single-qna.component';
import { RawDataDownloadComponent } from './raw-data-download/raw-data-download.component';
import { AdminManageWhatsappTemplatesComponent } from './admin-manage-whatsapp-templates/admin-manage-whatsapp-templates.component';
import { ManageWhatsappAutoResponseComponent } from './admin/manage-whatsapp-auto-response/manage-whatsapp-auto-response.component';
import { JobMachingIntroComponent } from './job-maching-intro/job-maching-intro.component';
import { HappySportIntroComponent } from './sport/happy-sport-intro/happy-sport-intro.component';
import { SportLeaderApplyComponent } from './sport-leader-apply/sport-leader-apply.component';
import { AdminSportCourseEditComponent } from './admin/admin-sport-course-edit/admin-sport-course-edit.component';
import { SportCoursesComponent } from './sport-courses/sport-courses.component';
import { ApplySportCourseComponent } from './sport-courses/apply-sport-course/apply-sport-course.component';
import { SportCourseApplicationComponent } from './sport-courses/sport-course-application/sport-course-application.component';
import { SportCourseApplicationsListingComponent } from './admin/sport-course-applications-listing/sport-course-applications-listing.component';
import { CaOleIntroComponent } from './pages/ca/ca-ole-intro/ca-ole-intro.component';
import { volunteerKeys } from './class/common-objects.enum';


const canActivate0 = (pipe: AuthPipe | AuthPipeGenerator) => ({
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: pipe.name !== '' ? pipe : () => pipe },
});
let isAdmin: any = hasCustomClaim('isAdmin');
let isCompany: any = hasCustomClaim('isCompany');
isAdmin = () => hasCustomClaim('isAdmin');
isCompany = () => hasCustomClaim('isCompany');


const routes: Routes = [
  {
    path: '',
    component: MainpageComponent
  },
  {
    path: 'sport-leader-apply',
    component: SportLeaderApplyComponent,
  },
  {
    path: 'job-matching-intro',
    component: JobMachingIntroComponent,
  },
  {
    path: 'test',
    component: TestComponent
  },
  {
    path: 'talentpool',
    component: ListJobMatchingComponent
  },
  {
    path: 'talentsearch',
    component: TalentSearchComponent
  },
  {
    path: 'talentsearch/service',
    component: TalentSearchServiceComponent
  },
  {
    path: 'talentsearch/model',
    component: TalentSearchModelComponent
  },
  {
    path: 'talentpool/:id',
    component: ListJobMatchingComponent
  },
  {
    path: 'talentpoolDetails/:id',
    component: ViewSingleTalentpoolComponent
  },
  {
    path: 'jobs',
    component: ListJobsComponent
  },
  {
    path: 'jobs/:id',
    component: ListJobsComponent
  },
  {
    path: 'ourServices',
    component: OurServicesComponent
  },
  {
    path: 'articles',
    component: CaBlogsComponent
  },
  {
    path: 'articles/category/:category',
    component: CaBlogsComponent
  },
  {
    path: 'article/:id',
    component: ViewSingleArticleComponent
  },


  {
    path: `${volunteerKeys}/listActivities`,
    component: ListActivitiesComponent
  },


  {
    path: `${volunteerKeys}/featureActivities`,
    component: ListActivitiesComponent
  },
  {
    path: `${volunteerKeys}/listActivities/:id`,
    component: ListActivitiesComponent
  },

  {
    path: 'sportArticles',
    component: CaBlogsComponent
  },
  {
    path: 'sportArticles/category/:category',
    component: CaBlogsComponent
  },
  {
    path: 'sportArticle/:id',
    component: ViewSingleArticleComponent
  },
  {
    path: 'sport-courses',
    component: SportCoursesComponent,
  },

  {
    path: 'sport-course-apply/:id',
    component: ApplySportCourseComponent
  },

  {
    path: 'sport-course-application/:id',
    component: SportCourseApplicationComponent
  },
  {
    path: 'sportRewines',
    component: CaBlogsComponent
  },
  {
    path: 'sportRewines/category/:category',
    component: CaBlogsComponent
  },
  {
    path: 'sportRewine/:id',
    component: ViewSingleArticleComponent
  },

  {
    path: 'sportRevisions',
    component: CaBlogsComponent
  },
  {
    path: 'sportRevisions/category/:category',
    component: CaBlogsComponent
  },
  {
    path: 'sportRevision/:id',
    component: ViewSingleArticleComponent
  },
  {
    path: 'privacy',
    component: ContentComponent
  },
  {
    path: 'terms',
    component: ContentComponent
  },
  {
    path: 'contact',
    component: ContactUsComponent
  },
  {
    path: 'sport-intro',
    component: HappySportIntroComponent,
  },
  {
    path: 'qna',
    children: [
      {
        path: '',
        component: ListQnaComponent
      },
      {
        path: 's/:id',
        component: SingleQnaComponent,
      },
      {
        path: 'tag/:tag_id',
        component: ListQnaComponent,
      }
    ]
  },
  {
    path: 'admin',
    children: [
      {
        path: 'sport-courses',
        component: AdminSportCourseEditComponent,
      },
      {
        path: 'sport-course-applications/:id',
        component: SportCourseApplicationsListingComponent,
      },
      {
        path: 'listAdmin',
        component: ListAdminComponent,

      },
      {
        path: 'listNormalUser',
        component: ListNormalUserAdminComponent
      },
      {
        path: 'listCompany',
        component: ListCompanyComponent
      },
      {
        path: 'pendingJobs',
        component: ListPendingJobsComponent
      },
      {
        path: 'mainpageSlider',
        component: AdminMainpageSliderEditComponent
      }, {
        path: 'jobApplications',
        component: AdminListJobApplicationsComponent
      }, {
        path: 'jobApplications/:id',
        component: AdminListJobApplicationsComponent
      }, {
        path: 'adsBannerList',
        component: AdminAdsListComponent
      }, {
        path: 'showUserProfile/:uid',
        component: AdminShowUserProfileComponent
      },
      {
        path: 'mainpageBottomItems',
        component: ConfigMainpageBottomItemsComponent
      },
      {
        path: 'adminHomepageConfig',
        component: AdminHomepageComponent
      },
      {
        path: 'adminJobMatching',
        component: AdminListJobMatchingComponent
      }, {
        path: 'adminListPendingActivities',
        component: AdminListPendingActivitiesComponent
      }, 
      {
        path: 'adminListPendingVolActivities_'+volunteerKeys,
        component: AdminListPendingActivitiesComponent
      }, 
      {
        path: 'listActivityApplication/:id',
        component: ListActivitiesApplicationByActivityComponent
      },
      
      {
        path: `list${volunteerKeys}ActivityApplication/:id`,
        component: ListActivitiesApplicationByActivityComponent
      }, {
        path: 'listArticles',
        children: [
          {
            path: '',
            component: ListArticlesComponent
          },
          {
            path: 'add',
            component: AddArticlesComponent
          },
          {
            path: 'update/:id',
            component: AddArticlesComponent
          }
        ]
      },

      {
        path: 'sportArticles',
        children: [
          {
            path: '',
            component: ListArticlesComponent
          },
          {
            path: 'add',
            component: AddArticlesComponent
          },
          {
            path: 'update/:id',
            component: AddArticlesComponent
          }
        ]
      },
      {
        path: 'sportRevisions',
        children: [
          {
            path: '',
            component: ListArticlesComponent
          },
          {
            path: 'add',
            component: AddArticlesComponent
          },
          {
            path: 'update/:id',
            component: AddArticlesComponent
          }
        ]
      },
      {
        path: 'sportRewines',
        children: [
          {
            path: '',
            component: ListArticlesComponent
          },
          {
            path: 'add',
            component: AddArticlesComponent
          },
          {
            path: 'update/:id',
            component: AddArticlesComponent
          }
        ]
      },
      {
        path: 'listAdvisors',
        children: [
          {
            path: '',
            component: CaAdminListAdvisorComponent
          }
        ]
      },
      {
        path: 'listAppointAdvisor/:id',
        component: CaListAppointmentAdvisorComponent
      },
      {
        path: 'manageIndustryCats',
        component: AddEditIndustryCategoriesComponent
      },
      {
        path: 'privacy',
        component: AdminContentComponent
      },
      {
        path: 'terms',
        component: AdminContentComponent
      },
      {
        path: 'manage_qna',
        component: ManageQnaComponent
      },
      {
        path: 'raw-data-dl',
        component: RawDataDownloadComponent,
      },
      {
        path: 'manage-whatsapp-templates',
        component: AdminManageWhatsappTemplatesComponent,
      },
      {
        path: 'manage-whatsapp-auto-response',
        component: ManageWhatsappAutoResponseComponent,
      }
    ],

    ...canActivate0(isAdmin),
    ...redirectUnauthorizedTo(['/'])


  },
  {
    path: 'caAdmin',
    children: [

      {
        path: 'listActivityApplication/:id',
        component: ListActivitiesApplicationByActivityComponent
      },
      {
        path: 'listArticles',
        children: [
          {
            path: '',
            component: ListArticlesComponent
          },
          {
            path: 'add',
            component: AddArticlesComponent
          },
          {
            path: 'update/:id',
            component: AddArticlesComponent
          },
        ]
      },
      {
        path: 'adminListPendingActivities',
        component: AdminListPendingActivitiesComponent
      },
    ]
  },
  {
    path: 'company',
    children: [
      {
        path: 'myJobAds',
        component: MyJobAdsComponent
      }, {
        path: 'myActivities',
        component: MyActivitiesComponent
      }, {
        path: 'listActivityApplication/:id',
        component: ListActivitiesApplicationByActivityComponent
      },
      {
        path: 'myVoluEveActivities',
        component: MyActivitiesComponent
      },
      {
        path: `list${volunteerKeys}ActivityApplication/:id`,
        component: ListActivitiesApplicationByActivityComponent
      },
      
    ],
    ...canActivate0(isCompany),
    ...redirectUnauthorizedTo(['/'])
  },
  {
    path: 'caCompany',
    children: [
      {
        path: 'myActivities',
        component: MyActivitiesComponent
      },
      {
        path: 'listActivityApplication/:id',
        component: ListActivitiesApplicationByActivityComponent
      },
    ]
  },
  {
    path: 'donation',
    component: DonationToUsComponent
  }, {
    path: 'listActivities',
    component: ListActivitiesComponent
  },
  {
    path: 'featureActivities',
    component: ListActivitiesComponent
  },
  {
    path: 'listActivities/:id',
    component: ListActivitiesComponent
  }, {
    path: 'ca',
    children: [
      {
        path: '',
        component: CaHomepageComponent,
      },
      {
        path: 'find',
        children: [
          {
            path: '',
            component: CaGetAppointmentComponent
          },
          {
            path: ':id',
            component: CaGetAppointmentComponent
          },
        ],
      },
      {
        path: 'findsCats',
        component: ListAppointmentCategoriesComponent
      },
      {
        path: 'articles',
        component: CaBlogsComponent
      },
      {
        path: 'articles/category/:category',
        component: CaBlogsComponent
      },
      {
        path: 'article/:id',
        component: ViewSingleArticleComponent
      },

      {
        path: 'advisor/:id',
        component: ViewSingleAdvisorComponent
      },
      {
        path: 'advisorOrder/:id',
        component: CaAppointmentOrderDetailsComponent
      },
      {
        path: 'appointment/:id',
        component: ViewSingleAppointmentComponent
      },
      {
        path: 'listActivities',
        component: ListActivitiesComponent
      },
      {
        path: 'featureActivities',
        component: ListActivitiesComponent
      },
      {
        path: 'listActivities/:id',
        component: ListActivitiesComponent
      },
      {
        path: 'joinCaTeam',
        component: JoinCaTeamComponent
      },


      {
        path: 'qna',
        children: [
          {
            path: '',
            component: ListQnaComponent
          },
          {
            path: 's/:id',
            component: SingleQnaComponent,
          },
          {
            path: 'tag/:tag_id',
            component: ListQnaComponent,
          }
        ]
      },
      {
        path: 'ole',
        component: CaOleIntroComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
