<div class="all">
    <!-- <h2>{{i18n.translate.add}} </h2> -->
    <mat-dialog-content>
        <form #form>

            <mat-form-field class="example-full-width">
                <mat-label>{{i18n.translate.title}}</mat-label>
                <input matInput placeholder="Ex. Pizza" [(ngModel)]="faqItem.title" required name="title">
            </mat-form-field>


            <div class="list">
                <h4>{{i18n.translate.content}}</h4>
                <ng-container *ngFor="let item of faqItem.contents;index as i">
                    <div class="item">
                        <span class="orderNumber">{{i+1}}</span>
                        <div class="checkBlock">
                            <span>{{i18n.translate.short_question}}({{i18n.translate.left}}) <=> {{i18n.translate.short_answer}}({{i18n.translate.right}})</span>
                            <mat-slide-toggle (change)="faqItem.contents[i].isSelfSide = $event.checked"
                                [checked]="item.isSelfSide"></mat-slide-toggle>
                        </div>

                        <mat-form-field class="example-full-width">
                            <mat-label>{{item.isSelfSide?i18n.translate.question:i18n.translate.answer}}....</mat-label>
                            <textarea matInput [placeholder]="item.isSelfSide?i18n.translate.question:i18n.translate.answer" rows="3"
                                (change)="updateContentText(i, $event.target.value)" required name="content"
                                [value]="getContentText(i)"></textarea>
                        </mat-form-field>


                        <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
                            (click)="removeContentItem(item)">
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <div class="line"></div>
                    </div>
                </ng-container>

                <mat-form-field class="example-full-width" *ngFor="let item of arrContent;index as i"
                    style="display: none;">
                    <mat-label>Leave a comment</mat-label>
                    <textarea matInput placeholder="Ex. It makes me feel..." rows="3"
                        (change)="updateContentText(i, $event.target.value)" name="content"
                        [ngModel]="getContentText(i)">{{getContentText(i)}}</textarea>
                </mat-form-field>

            </div>


            <button mat-button color="primary" style="width: 100%;" class="add_button" (click)="addContentLine()">
                {{i18n.translate.add_line}}
            </button>

            <mat-form-field class="example-chip-list" style="width: 100%">
                <mat-label>{{i18n.translate.tags}}</mat-label>
                <mat-chip-list #chipList aria-label="tags selection">
                    <mat-chip
                    *ngFor="let tag of faqItem.tags || []"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeTag(tag)">
                    {{tag}}
                    <button matChipRemove *ngIf="true">
                      <mat-icon style="margin-left: -10px; margin-top: -5px;">cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    [placeholder]="i18n.translate.new_tag + '...'"
                    #tagInput
                    [formControl]="newTagControl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addTag($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOptionalTag($event)">
                  <mat-option *ngFor="let fruit of filterdTags | async" [value]="fruit">
                    {{fruit}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

            <div class="uploadBlock">
                <h4>{{i18n.translate.coverImage}}</h4>


                <img [src]="faqItem.coverImage" *ngIf="faqItem.coverImage " class="coverImage">


                <mat-progress-bar color="primary" mode="mode" [value]="percentage | async" *ngIf="isCoverUploading">
                </mat-progress-bar>

                <div>


                    <button mat-button color="primary" class="n_button" (click)="clickCoverUpload();"
                        [disabled]="isCoverUploading">
                        {{i18n.translate.upload_cover}}
                    </button>

                    <input type='file' name='coverImage' (change)="uploadFile($event)" #coverImageUploadButton
                        style="display: none;" />
                </div>
                <br />
                <br />
                <br />

            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>

        <button mat-button mat-dialog-close>{{i18n.translate.cancel}}</button>

        <button mat-button color="primary" (click)="onSubmit();">{{i18n.translate.submit}}</button>
    </mat-dialog-actions>
</div>