import { textToArray } from 'src/app/class/common-objects.enum';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TheAdvisor } from '../../../../class/the-advisor';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, analyticsEventKeys, registerButtonGlobalId, loginButtonGlobalId, openSimpleMessageDialog } from '../../../../class/common-objects.enum';
import { Subscription } from 'rxjs';
import { firestore } from 'firebase/app';
import { I18n } from '../../../../i18n/i18n';
import { MatDialog } from '@angular/material/dialog';
import { MakeAppointmentDialogComponent } from './make-appointment-dialog/make-appointment-dialog.component';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';
import { SimpleMessageDialogComponent } from '../../../../universal-dialog/simple-message-dialog/simple-message-dialog.component';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';

import  showdown from 'showdown'
@Component({
  selector: 'app-view-single-advisor',
  templateUrl: './view-single-advisor.component.html',
  styleUrls: ['./view-single-advisor.component.less']
})
export class ViewSingleAdvisorComponent implements OnInit {
  i18n = new I18n()
  initId = ''
  advisor: TheAdvisor = null
  currentUser: firebase.User = null
  logged = false;
  constructor(
    public activatedRoute: ActivatedRoute,
    public afFirestore: AngularFirestore,
    public dialog: MatDialog,
    public analytics: AngularFireAnalytics,
    public afAuth: AngularFireAuth,
    private logger: LoggingUtilsService,
  ) {
    afAuth.authState.subscribe(user => {
      if (user && user.uid) {
        this.currentUser = user
      } else {
        this.currentUser = null
      }
    })
    activatedRoute.paramMap.subscribe(v => {
      const id = v.get('id')
      if (id) {
        this.initId = id
        this.refreshItem()
        if (!this.logged) {
          logger.log(LoggingKey.career_advisor_detail_click, id)
        }
      } else {
        history.back()
      }
    })
  }

  ngOnInit() {
    //this.advisor.expertise
  }
  markdownToHtml(md: string){
    console.log('md',{a:md})
    let converter = new showdown.Converter();
    showdown.setFlavor('github');
    converter.setOption('simpleLineBreaks', true);
   let  html      = converter.makeHtml(md.replace(/[ ]*\n/g, "    \n"));
    console.log('html',html)
    if(html){
      return html;
    }
    return md

  }
  sub0: Subscription = null
  refreshItem() {
    if (this.sub0) {
      try {
        this.sub0.unsubscribe()
      } catch (e) { }
    }
    if (this.initId) {
      this.afFirestore.collection(dbKeys.the_advisors).doc(this.initId).valueChanges()
        .subscribe(d => {
          const a = <any>d
          const res = <TheAdvisor>a
          res.createAt = (<firestore.Timestamp>a?.createAt)?.toDate()
          res.updateAt = (<firestore.Timestamp>a?.updateAt)?.toDate()
          this.advisor = res
          this.analytics.logEvent(analyticsEventKeys.advisors_view, {
            advisor_id: this.advisor.id,
            advisor_name: this.advisor.name
          })
        })
    }
  }
  
  openMakeAppointmentDialog() {
    open('https://forms.gle/Q8hHEF8RjTE8UVT77')
    // if (this.currentUser && this.currentUser.uid) {
      // this.dialog.open(MakeAppointmentDialogComponent, {
      //   data: {
      //     id: this.initId
      //   }
      // }).afterClosed().subscribe(v=>{

      // })
    // } else {
    //   const ref = this.dialog.open(SimpleMessageDialogComponent, {
    //     data: {
    //       message: `報名需要先登入成爲會員\nPlease register first.`
    //     }
    //   })
    //   ref.afterClosed().subscribe(() => {
    //     const d = document.getElementById(loginButtonGlobalId)
    //     if (d) {
    //       d.click()
    //     }
    //   })
    // }
  }
  careerHistoryTextToArray(t: string) {
    let array = textToArray(t).filter(x => x.trim().length > 0).map(x => {
      if (x.startsWith('・')) {
        let transformed = x.replace('・', '');
        transformed = `<ul><li>${transformed.trim()}</li></ul>`
        return transformed
      }
      return x
    })

    return array
  }
  textToArray0(t: string) {
    return textToArray(t)
  }
  minsToMinsWithHour(m: number) {
    let hour = 0
    let minute = m
    while (minute >= 60) {
      minute = minute - 60
      hour++
    }
    if (hour <= 0 && minute > 0) {
      return `${minute} ${this.i18n.get('minutes')}`
    } else if (hour > 0 && minute <= 0) {
      return `${hour} ${this.i18n.get('hour')}`
    } else {
      return `${hour} ${this.i18n.get('hour')} ${minute} ${this.i18n.get('minutes')}}`
    }
  }
  dateToText(date: Date) {
    let str = date.toISOString()
    str = str.replace('T', ' ')
    str = str.substring(0, str.lastIndexOf('.'))
    return str
  }
}
