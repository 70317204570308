import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { dbKeys, unsubscribeAll } from '../class/common-objects.enum';
import { ISportCourse } from '../class/ISportCourse';
import { I18n } from '../i18n/i18n';

@Component({
  selector: 'app-sport-courses',
  templateUrl: './sport-courses.component.html',
  styleUrls: ['./sport-courses.component.less']
})
export class SportCoursesComponent implements OnInit {
  i18n = new I18n()
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
    this.loadAllActiveCourses()
  }
  arrClassA: ISportCourse[] = null;
  arrClassB: ISportCourse[] = null;

  sub0AllActiveCourses: Subscription
  loadAllActiveCourses() {
    unsubscribeAll([this.sub0AllActiveCourses])
    const filterDateTime = new Date((new Date().getTime() + (1000 * 60 * 60 * 24)))
    this.sub0AllActiveCourses = this.afFirestore.collection<ISportCourse>(dbKeys.sport_courses,
      ref => ref
        .where('holdDateTime', '>=', filterDateTime)
        .where('status', '==', 'active')
        .orderBy('holdDateTime', 'asc')
        .limit(8)
    ).valueChanges()
      .subscribe(v => {
        console.log(v)
        this.arrClassA = v.filter(o => o.type == 'A')
        this.arrClassB = v.filter(o => o.type == 'B')
        console.log('arrClassA', this.arrClassA)
        console.log('arrClassB', this.arrClassB)
      })
  }
}
