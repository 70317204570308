<div class="thisBody">
  <div class="headerBanner">
<app-main-page-slider
[pageType]="pageType"
></app-main-page-slider>
  </div>
  <div class="contentOuter" flex="justify-center" *ngIf="!isHant">
    <div class="contentBody">
      <div class="header">About US >></div>
      <div>
        “Happy Career Advisory Service” is a service that helps connect our
        retirees or young-old members possessing a wealth of experience and
        extensive knowledge in certain industries with those younger people
        hoping to get third-party advice and guidance on their career path.
      </div>
      <div class="header">Mission >></div>
      <div>
        Our mission is to encourage retirees or young-old professionals, whether
        local or overseas, to share and pass on their valuable experience and
        network accumulated throughout their career to young people, giving them
        insights including but not limited to best practices and developments in
        particular industries, leadership and management skills, problem
        solving, technical skills in certain areas, or general advice on setting
        career goals and development plan. <br />We hope the young people after
        meeting our retiree advisors can create their own ideas and plans
        leading to a brighter future.
      </div>
      <div class="header">PARTICIPANT'S REVIEW</div>
      <div class="quotationContainer">
        <div class="quotation">
          “ Rewarding and impressive conversation with lots of great genuine
          advice and insight from a very experienced and successful leader in
          luxury fashion. Open and interactive sharing about management skills,
          team building and business development.”
        </div>
        <div class="author">Ms Chan <br />Buying Manager, Ralph Lauren APAC</div>
      </div>
      <div class="header">2 ways to make appointment >></div>
      <div class="subHeader">Method 1 :</div>
      <div>
        <div flex>
          <div class="stepIndicator">Step 1 :</div>
          <div>Register as HRCA member</div>
        </div>
        <div flex>
          <div class="stepIndicator">Step 2 :</div>
          <div>
            Visit our <a [routerLink]="['/ca/find']">Advisor Team page</a> and select the right one you want to
            talk to.
          </div>
        </div>
        <div flex>
          <div class="stepIndicator">Step 3 :</div>
          <div>
            After making an appointment, our career advisory team will contact you
            by our WhatsApp hotline / e-mail within 1-2 working day(s), so as to
            confirm / schedule the career advisory session.
          </div>
        </div>
        <br />or
      </div>
      <div class="subHeader">Method 2 :</div>
      <div>
        You might submit your questions about your career with simple personal
        contact information <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSerI8JEt9M_pgmZbhF7tW3IKVYJSZ6XDNRer8PAT-9UZNvvLA/viewform">here</a>, our career advisory
        team will contact you
        within 1-2 working days and arrange an one on one career advisory
        session to you.
      </div>
      <div>
        --------------
        <br />
        <br />If you have needs on having career advices, but have any problems
        on selecting advisors, please contact us by Whatsapp hotline:
        <a href="https://api.whatsapp.com/send/?phone=85266865812&text&app_absent=0">6686 5812</a>
        or <a href="mailto:info@happy-retired.org">info@happy-retired.org</a>
      </div>
      <hr>
      <app-bottom></app-bottom>
    </div>
  </div>


  <div class="contentOuter" flex="justify-center" *ngIf="isHant">
    <div class="contentBody">
      <div class="header">關於我們 >></div>
      <div>
        「樂職顧問服務」——讓擁有行業豐富經驗和知識的退休人士或新中年，為年青人提供有關職涯路上的第三方建議和指導。
      </div>
      <div class="header">使命 >></div>
      <div>
        我們的使命是鼓勵退休人士或新中年專業人士，無論本地還是海外，將他們在職業生涯中積累的寶貴經驗和人脈，透過一對一諮詢，分享和傳承予年靑人，為他們提供不限於特定行業的最佳實務方法和發展領導和管理技能、解決問題的能力、某些領域技術的技能，或關於設定事業目標和發展計劃的建議。
        <br>
        我們希望年青人與「樂職顧問」一對一會談後，能夠建立自己的意見和計劃，邁向更美好的未來。
      </div>
      <div class="header">PARTICIPANT'S REVIEW</div>
      <div class="quotationContainer">
        <div class="quotation">
          “ Rewarding and impressive conversation with lots of great genuine
          advice and insight from a very experienced and successful leader in
          luxury fashion. Open and interactive sharing about management skills,
          team building and business development.”
        </div>
        <div class="author">Ms Chan <br />Buying Manager, Ralph Lauren APAC</div>
      </div>
      <div class="header">兩種預約方式 >></div>
      <div class="subHeader">方法一:</div>
      <div>
        <div flex>
          <div class="stepIndicator">第一步:</div>
          <div>註冊成為 HRCA 會員</div>
        </div>
        <div flex>
          <div class="stepIndicator">第二步:</div>
          <div>
            瀏覽我們的 「<a [routerLink]="['/ca/find']">樂職顧問團隊頁面</a>」 選擇並預約您想與之會談的樂職顧問。
          </div>
        </div>
        <div flex>
          <div class="stepIndicator">第三步:</div>
          <div>
            預約後，我們的團隊將在1-2個工作日內，通過我們的WhatsApp熱線/電子郵件與您聯繫，以安排一對一諮詢會談。
          </div>
        </div>
        <br />或
        <br/>
      </div>
      <div class="subHeader">方法二:</div>
      <div>
        您可用簡單的個人聯繫方式 （<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSerI8JEt9M_pgmZbhF7tW3IKVYJSZ6XDNRer8PAT-9UZNvvLA/viewform">這裏</a>）,
        我們的樂職顧問團隊將在1-2個工作日內與聯繫您，及為您安排一對一諮詢。
      </div>
      <div>
        --------------
        <br />
        <br />如果您需要職涯規劃諮詢，但在選擇樂職顧問方面有任何問題，請透過Whatsapp熱線聯繫我們：
        <a href="https://api.whatsapp.com/send/?phone=85266865812&text&app_absent=0">6686 5812</a>
        或 <a href="mailto:info@happy-retired.org">info@happy-retired.org</a>
      </div>
      <hr>
      <app-bottom></app-bottom>
    </div>
  </div>
</div>