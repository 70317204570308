<div class="all">
    <img class="headerCover" src="/assets/sport_intro/leader_cover.png" />

    <div class="bodyOuter">
        <div class="bodyInner">
            <div class="hTitle">
                免費專業訓練
            </div>
            <div class="p">

            <p>
                如果你是定期做運動的新中年(50 歲或以上)，想推動更多朋友參與，又希望接受免費訓練，機會嚟啦！
                <br>
                HRCA現正招募新中年出任「樂動區長」(區長)，區長將於所屬區內，帶領及推動少做運動的同齡人士或長者，定期運動的習慣。
            </p>
                HRCA將初步篩選合適申請者出席面試及體能測試， 合格者須參加以下的訓練及課程：

                <ul>
                    <li>10堂「區長訓練課程」** ， 及在完成訓練後</li>
                    <li>與約20名新中年參加4堂的「特定體能課程」，為期一個月</li>
                </ul>

                「區長訓練課程」及「特定體能課程」均由「中國香港體適能總會」設計及提供，由專業教練負責訓練。
            </div>
            <div class="hTitle">
                帶領小組
            </div>
            <p>
                完成上述訓練及課程後，每位區長會帶領15人小組齊做運動，進行每週一次，每次約45分鐘至1小時的室內、或室外簡單運動，為期5個月*。HRCA 會提供室內場地，並
                提議小組運動種類，區長亦可根據自己運動的專長、知識作出合適建議。
                </p>
                <p>
                期間區長可透過WhatsApp 與組員互動、分享資訊、指導自我健康監察(由本機構提供血壓App)、推動組員出席活動等。
                *區長在帶領小組的5個月期間，每月將獲$4000元津貼。
            </p>
            <p>
                ** HRCA將會負責篩選申請者出席面試及體能測試，如有任何爭議，HRCA將保留最終決定權。
                通過面試及體能測試者方可參加「區長訓練課程」，而獲選參加「區長訓練課程」的人士，須繳交＄300 按金，完成10堂訓練後將獲全數退還。
                
               
            </p>
            <div class="flex">
                <span class="left c">
                    <div class="hTitle">
                        區長職責
                    </div>
                    <div class="p">
                        <ul>
                            <li>帶領小組最少每週一次，每次約1小時運動，為期5個月</li>
                            <li>協助招募小組成員，與組員互動，分享健康運動資訊</li>
                            <li> 推動、鼓勵組員出席每週活動、點名及保留出席及活動紀錄</li>
                            <li>協助組員下載舉辦機構(HRCA)所提供的紀錄血壓App，及提醒組員定期監察及上載紀錄</li>
                            <li>與HRCA聯繫，確保機構知悉小組進度，及互相分享活動相關資訊</li>
                        </ul>
                    </div>
                </span>
                <span class="right c">
                    <div class="hTitle">
                        要求
                    </div>
                    <div class="p">
                        <li>熱愛及有定期做運動的新中年</li>
                        <li>有熱誠推動新中年及長者培養定期運動習慣</li>
                        <li>健康、體格良好</li>
                        <li>有經驗帶領小組活動優先考慮</li>
                        <li>性格開朗、樂觀、主動、有良好溝通及社交能力</li>
                        <li>對使用智能電話或裝置有基本認識</li>
                    </div>
                </span>

            </div>


            <div class="buttonOuter">
                <div class="buttonInner">
                    <div class="cOuter ">
                        <button class="cButton l" (click)="openForm()" [disabled]="false">
                            申請加入領袖區長
                            <!-- 今期已額滿 -->

                        </button>
                    </div>
                    <div class="cOuter ">
                        <button class="cButton r" (click)="openWhatsapp()">
                            WhatsApp我們
                        </button>
                    </div>
                </div>
            </div>

    <br>
    <br>
        </div>
    </div>
</div>