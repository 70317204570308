import { Subscription } from 'rxjs';
import { textToArray } from 'src/app/class/common-objects.enum';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { MemberTalentItem, dbKeys, analyticsEventKeys } from '../../../class/common-objects.enum';
import { I18n } from '../../../i18n/i18n';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { i18n_locate } from 'src/app/i18n/i18n_const';
import { IndustryCatItem } from '../../../class/IndustryCatItem';

@Component({
  selector: 'app-view-single-job-matching-record',
  templateUrl: './view-single-job-matching-record.component.html',
  styleUrls: ['./view-single-job-matching-record.component.less']
})
export class ViewSingleJobMatchingRecordComponent implements OnInit {
  initId = ''
  i18n = new I18n()
  talnet : MemberTalentItem = null

  topIndustries: Map<string, IndustryCatItem> = new Map()
  subIndustries: Map<string, IndustryCatItem> = new Map()

  arrTalentItems: Array<MemberTalentItem> = null
  lastDoc: firebase.firestore.QueryDocumentSnapshot = null
  constructor(
    @Inject(MAT_DIALOG_DATA) data : any,
    public firestore : AngularFirestore,
    public dialogRef : MatDialogRef<ViewSingleJobMatchingRecordComponent>,
    public analytics :AngularFireAnalytics
    ) {
    if(data && data.initId){
      this.initId = data.initId
    this.firestore.collection(dbKeys.job_matching_items).doc<MemberTalentItem>(this.initId).valueChanges()
    .subscribe(data=>{
      if(data){
        console.log(data)
        this.talnet = data
      }
      analytics.logEvent(analyticsEventKeys.job_matching_view, data)
    })
    }
   }

   getTopIndustryNameById(id: string) {
    const s= this.topIndustries.get(id)
    if (s && s.id) {
      const industry = s
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name
      } else {
        return industry.en_name
      }
    }
  return '其他/Other'

}
  
   getSubIndustryNameById(id: string) {
    const s= this.subIndustries.get(id)
    if (s && s.id) {
      const industry = s
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name
      } else {
        return industry.en_name
      }
    }
  return '其他/Other'

}
  sub01: Subscription = null
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe()
      } catch (e) { }
    }
    this.firestore.collection(dbKeys.map_industry_cats)
      .valueChanges().subscribe(d => {
        if (d && d.length > 0) {
          for (let o of d) {
            const any0: any = <any>o
            const industry: IndustryCatItem = <IndustryCatItem>any0
            if (industry && industry.id) {
              if (industry.parent_id) {
                this.subIndustries.set(industry.id, industry)
              } else {
                this.topIndustries.set(industry.id, industry)
              }
            }
          }
        }
      })
  }
  ngOnInit() {
    this.subIndustriesUpdate()
  }

  textToArray(t: string) {
    return textToArray(t)
  }
  closeThis(){
    this.dialogRef.close()
  }

}
