import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { WindowService } from 'src/app/window.service';

import * as firebase from 'firebase/app';
import { I18n } from 'src/app/i18n/i18n';
import { AngularFireFunctions } from '@angular/fire/functions';
import { catchError, finalize } from 'rxjs/operators';
import { UserInfo } from 'src/app/class/user-info';
import { AngularFireUploadTask, AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { makeid, dbKeys, cloudFunctionsKeys, isCvFileTypeAllowed } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';
@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.less']
})
export class LoginDialogComponent implements OnInit {
  private canShowUpdateInfo = true
  displayPhoneForm = ''
  private key_storage_user_uploaded_cv = 'user_uploaded_cv'

  userInfo: UserInfo = {
    fullname: '',
    workedYears: '',
    jobType: '',
    jobTitle: '',
    workedFrom: firebase.firestore.Timestamp.now(),
    workedTo: firebase.firestore.Timestamp.now(),
    educationLevel: '',
    expectedSalary: '',
    privacySetting: '',
    registerFor: '',
    interest_to_ca: '',
    cvURL: '',
    register_for_find_jobs: false,
    register_for_become_advisor: false,
    register_for_career_advisor_service: false,
  }
  get isRegisterForFindJob() {
    return this.userRegisterFor.find_job;
  }

  get findJobBlockDisplay() {
    if (this.isRegisterForFindJob) {
      return ''
    } else {
      this.userInfo.privacySetting = ''
      this.userInfo.expectedSalary = ''
      this.userInfo.interest_to_ca = ''
      return 'none'
    }
  }
  displayLoginForm = 'none'
  displaySignUpForm = 'none'
  displayForgetForm = 'none'
  topbar_title = 'sign_in'
  inputPhoneNumber: number
  inputPhoneCode = ''
  inputLoginEmail = ''
  inputLoginPassword = ''
  private IerrorMessage = ''
  private InormalMessage = ''
  verificationId = ''
  recaptcha: any
  inputRegEmail = ''
  inputRegPassword = ''
  inputRegConfirmPassword = ''
  inputForgetPasswordEmail = ''
  i18n = new I18n()
  windowRef: any;
  isRecapchaSolved = false
  allowFileSize = 10 * 1024 * 1024

  regFormChecked1 = false
  regFormChecked2 = false

  userRegisterForKeys = ['find_job', 'become_advisor', 'career_advisor_service']
  userRegisterFor = {
    find_job: false,
    become_advisor: false,
    career_advisor_service: false,
  }

  userRegisterForChange($event, key) {
    if ($event === true && key === 'career_advisor_service') {
      this.userRegisterFor.find_job = false;
      this.userRegisterFor.become_advisor = false;
    } else {
      if (this.userRegisterFor.career_advisor_service && key !== 'career_advisor_service') {
        let that = this;
        setTimeout(() => {
          that.userRegisterFor.find_job = false;
          that.userRegisterFor.become_advisor = false;
        });
      }
    }
  }

  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;
  downloadFilename: string

  get errorMessage() { return this.IerrorMessage }
  set errorMessage(v: string) { this.IerrorMessage = v; this.InormalMessage = '' }
  get notifyMessage() { return this.InormalMessage }
  set notifyMessage(v: string) {
    this.InormalMessage = v
    this.IerrorMessage = ''
  }
  showPhoneForm() {
    this.displayForgetForm = 'none'
    this.displaySignUpForm = 'none'
    this.displayLoginForm = 'none'
    this.displayPhoneForm = ''
    this.topbar_title = 'sign_in'
  }
  showLoginForm() {
    this.displayForgetForm = 'none'
    this.displaySignUpForm = 'none'
    this.displayLoginForm = ''
    this.displayPhoneForm = 'none'
    this.topbar_title = 'sign_in'
  }
  showForgetForm() {
    this.displayForgetForm = ''
    this.displayLoginForm = 'none'
    this.displaySignUpForm = 'none'
    this.displayPhoneForm = 'none'
    this.topbar_title = 'forget_password'
  }
  showSignUpForm() {
    this.displayForgetForm = 'none'
    this.displayLoginForm = 'none'
    this.displaySignUpForm = ''
    this.displayPhoneForm = 'none'
    this.topbar_title = 'register'
  }
  get map_edu_level() {
    let m = this.i18n.getStringMap('map_edu_level')
    let r: Array<{ key: string, value: string }> = []
    if (m) {
      m.forEach((v, k) => {
        r.push({ key: k, value: v })
      })
    }
    return r
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public win: WindowService,
    public storage: AngularFireStorage,
    public afFunctions: AngularFireFunctions
  ) {


  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.initRecaptcha()
    this.showLoginForm()
    if (this.data && this.data.initOpen) {
      let initOpen = this.data.initOpen
      if (initOpen == 1) {
        this.showSignUpForm()
      }
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  initRecaptcha() {

    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('get-phone-login-code-button', {
      'size': 'invisible',
      'callback': async function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('aoskd')
        console.log(response)


      }
    });

    this.windowRef.recaptchaVerifier.render()

  }
  async sendPhoneCode() {
    this.errorMessage = ''
    this.notifyMessage = ''
    if (this.inputPhoneNumber && this.inputPhoneNumber.toString().length == 8) {
      console.log('exec sendPhoneCode')
      this.afAuth.auth.languageCode = 'fr'
      this.afAuth.auth
        .signInWithPhoneNumber(
          `+852${this.inputPhoneNumber}`,
          this.windowRef.recaptchaVerifier)
        .then((result) => {
          console.log(result)
          let vcode = result.verificationId
          if (vcode) {
            this.notifyMessage = 'One-Time Code sent.'
            this.verificationId = vcode
            this.windowRef.confirmationResult = result
          } else {
            this.errorMessage = 'Something wrong when sending SMS.'
          }
        }).catch((err) => {
          console.log(err)
          this.errorMessage = err.message
        })

    } else {
      this.errorMessage = 'Invaild Phone Number.'
    }
  }
  async goPhoneLogin() {
    this.errorMessage = ''
    this.notifyMessage = ''
    if (!this.verificationId || !this.windowRef.confirmationResult) {
      this.errorMessage = 'Enter phone number and click "Get Code" first.'
    } else if (!this.inputPhoneCode) {
      this.errorMessage = 'Enter your One-Time Passcode.'
    } else {
      let confirmationResult: firebase.auth.ConfirmationResult = this.windowRef.confirmationResult
      confirmationResult.confirm(this.inputPhoneCode)
        .then(user => {
          console.log(user)
          if (user.user.uid) {
            //logged on 
            this.dialogRef.close(true)
          } else {
            this.errorMessage = 'cannot login, please check.'
          }
        }).catch(e => {
          console.log(e)
          this.errorMessage = e.message
        })
    }
  }
  emailSignUp() {
    this.errorMessage = ''
    this.notifyMessage = ''
    this.canShowUpdateInfo = false
    if (!this.inputRegEmail.includes('@') || !this.inputRegEmail.includes('.')) {
      // not valid email
      alert(this.i18n.get('error_msg_invalid_email_address'))
    } else if (!this.inputRegPassword) {
      alert(this.i18n.get('error_msg_please_enter_password'))
    } else if (!this.inputRegConfirmPassword) {
      alert(this.i18n.get('error_msg_please_enter_confirm_password'))
    } else if (this.inputRegConfirmPassword != this.inputRegPassword) {
      alert(this.i18n.get('error_msg_password_mismatch'))
    // } else if (!this.userInfo) {
    //   alert(this.i18n.get('err_msg_enter_your_data'))
    } else if (!this.userInfo.fullname) {
      alert(this.i18n.get('err_msg_enter_your_fullname'))
    } else if (this.userInfo.fullname.length < 3) {
      alert(this.i18n.get('err_msg_invalid_fullname'))
    // } else if (!this.userInfo.workedYears) {
    //   alert(this.i18n.get('err_msg_select_your_worked_years'))
    // } else if (!this.userInfo.jobType) {
    //   alert(this.i18n.get('err_msg_select_job_type'))
    // } else if (!this.userInfo.jobTitle) {
    //   alert(this.i18n.get('err_msg_selct_job_title'))
    // } else if (this.userInfo.jobTitle.length < 3) {
    //   alert(this.i18n.get('err_msg_invalid_job_title'))
    // } else if (!this.userInfo.workedFrom || !this.userInfo.workedTo) {
    //   alert(this.i18n.get('err_msg_enter_your_work_date_range'))
    // } else if (!this.userInfo.educationLevel) {
    //   alert(this.i18n.get('err_msg_select_edu_level'))
    // } else if (this.isRegisterForFindJob && this.userInfo.expectedSalary <= 0) {
    //   this.errorMessage = this.i18n.get('err_msg_invalid_expect_salary')
    // } else if (this.isRegisterForFindJob && !this.userInfo.privacySetting) {
    //   this.errorMessage = this.i18n.get('err_msg_choose_privacy_setting')
    // } else if (this.isRegisterForFindJob && !this.userInfo.interest_to_ca) {
    //   this.errorMessage = this.i18n.get('err_msg_choose_interest_ca')
    } else if (!this.userRegisterFor.find_job && !this.userRegisterFor.become_advisor && !this.userRegisterFor.career_advisor_service) {
      this.errorMessage = this.i18n.get('err_reg_form_register_for')
    } else if (!this.regFormChecked1 || !this.regFormChecked2) {
      this.errorMessage = this.i18n.get('err_reg_form_checkboxes')
    } else {
      this.afAuth.auth.createUserWithEmailAndPassword(this.inputRegEmail, this.inputRegPassword)
        .then(async (user) => {
          console.log(user)
          if (user.user.uid) {
            // register success!
            user.user.sendEmailVerification()
            this.notifyMessage = this.i18n.get('notify_msg_verify_mail_sent')
            this.inputRegConfirmPassword = ''
            this.inputRegPassword = ''
            this.inputRegEmail = ''

            let ref = await this.firestore
              .collection(dbKeys.user_info)
              .doc(user.user.uid)

            this.userInfo.register_for_find_jobs = this.userRegisterFor.find_job
            this.userInfo.register_for_become_advisor = this.userRegisterFor.become_advisor
            this.userInfo.register_for_career_advisor_service = this.userRegisterFor.career_advisor_service

            await ref.set(this.userInfo)

            await this.afAuth.auth.currentUser.updateProfile({
              displayName: this.userInfo.fullname
            })
            this.showLoginForm()
            const calltable = this.afFunctions.httpsCallable(cloudFunctionsKeys.checkIsInitAdmin)
            let token = await user.user.getIdToken(true)
            calltable({ token: token })
              .subscribe(
                async (next) => {
                  console.log(next)
                },
                async (error) => {
                  console.log(error)
                }
              )

            await this.afAuth.auth.signOut()
            this.canShowUpdateInfo = true
          } else {
            alert('Register failed.')
          }
        }).catch(e => {
          console.error(e)
          alert(e.message)
        })
    }
  }
  emailLogin() {
    if (!this.inputLoginEmail.includes('@') || !this.inputLoginEmail.includes('.')) {
      this.errorMessage = 'invalid email, please check!'
    } else if (!this.inputLoginPassword) {
      this.errorMessage = 'Please enter your password!'
    } else {
      this.afAuth.auth.signInWithEmailAndPassword(this.inputLoginEmail, this.inputLoginPassword)
        .then(auth => {
          if (auth && auth.user && auth.user.uid) {
            if (auth.user.emailVerified) {
              this.dialogRef.close(true)
            } else {
              auth.user.sendEmailVerification()
              this.errorMessage = 'Verify mail is send to your mailbox, please check.'
              this.afAuth.auth.signOut()
            }
          } else {
            this.errorMessage = 'Login failed!'
          }
        }).catch(e => {
          console.error(e)
          let msg = this.i18n.getFirebaseErrorMessage(e.code)
          if (msg) {
            this.errorMessage = msg
          } else {
            this.errorMessage = e.message
          }
        })
    }
  }
  forgetPassword() {
    this.errorMessage = ''
    this.notifyMessage = ''
    if (this.inputForgetPasswordEmail.includes('@') && this.inputForgetPasswordEmail.includes('.')) {
      this.afAuth.auth.sendPasswordResetEmail(this.inputForgetPasswordEmail)
        .then(data => {
          console.log(data)
          this.notifyMessage = 'Mail sent, please check your inbox.'
        })
        .catch(error => {
          console.error(error)
          this.errorMessage = error.message
        })
    } else {
      this.errorMessage = 'Invalid Email address.'
    }
  }
  closeAndOpenBusiness() {
    this.dialogRef.close('business')
  }
  closeThisDialog() {
    this.dialogRef.close(false)
  }


  parseTimestamp(timestamp: firebase.firestore.Timestamp): string {
    //console.log(timestamp)
    let date = new Date(timestamp.seconds * 1000)
    let strDate = `${date.toISOString().split('T')[0]}`
    return strDate
  }
  // controller
  parseDate(dateString: string): firebase.firestore.Timestamp {
    console.log(dateString)
    if (dateString) {
      let date = new Date(dateString)
      let seconds = parseInt((date.getTime() / 1000).toString())
      return new firebase.firestore.Timestamp(seconds, 0)
    }
    return null;
  }

  public uploadFile(event: any): void {
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        alert(this.i18n.get('err_msg_file_too_large'))
        return
      } else if (!isCvFileTypeAllowed(file.type)) {
        alert(this.i18n.get('err_msg_disallow_file_type'))
        return
      }
      try {
        const path = `${this.key_storage_user_uploaded_cv}/temp/${(new Date()).toISOString()}_${makeid(10)}_${file.name}`;
        const fileRef: AngularFireStorageReference = this.storage.ref(
          path
        );
        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        this.percentage = task.percentageChanges()
        task
          .snapshotChanges()
          .pipe(catchError(err => {
            alert(err.message)
            throw err;
          }))
          .pipe(
            finalize(() => {

            })
          )
          .subscribe((data) => {
            console.log('abc')
            fileRef.getDownloadURL().subscribe(downloadURL => {
              console.log(downloadURL)
              //this.userInfo.cvURL = downloadURL
              this.userInfo.cvURL = path
              this.downloadURL = downloadURL
              //console.log(path)
              //console.log(this.userInfo.cvURL)
              fileRef.getMetadata().subscribe(d => {
                //console.log(d)
                let p = d.fullPath
                let tIndex = p.lastIndexOf('/') + 1
                if (tIndex >= 0) this.downloadFilename = p.substring(tIndex)
              })
              console.log(this.userInfo.cvURL)
            });
          });
      } catch (e) {
        alert(e.message)
        console.error(e)
      }
    }
  }
}
