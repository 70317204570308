import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { AngularFireUploadTask, AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserInfo } from 'src/app/class/user-info';
import { catchError, finalize } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { auth } from 'firebase/app';
import { AngularFireFunctions } from '@angular/fire/functions';
import { makeid, cloudFunctionsKeys } from 'src/app/class/common-objects.enum';

@Component({
  selector: 'app-business-login-dialog',
  templateUrl: './business-login-dialog.component.html',
  styleUrls: ['./business-login-dialog.component.less']
})
export class BusinessLoginDialogComponent implements OnInit {
  i18n = new I18n()
  errorMessage = ''
  notifyMessage = ''

  currentUser : auth.UserCredential = null
  allowFileType = ["image/png", "image/jpeg", "image/jpg"]
  allowFileSize = 10 * 1024 * 1024

  private key_storage_uploaded_logo = 'company_uploaded_logo'
  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;
  downloadFilename: string
  
  // State for dropzone CSS toggling
  isHovering: boolean;
  isRegBtnLoading = false;
  private isDbNoData = true

  inputRegCompanyName = ''
  inputRegCompanyDesc = ''
  inputRegEmail = ''
  inputRegPassword = ''
  inputRegConfirmPassword = ''
  inputPhotoURL = ''
  constructor(
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public storage : AngularFireStorage,
    public afFunctions : AngularFireFunctions,
    public dialogRef : MatDialogRef<BusinessLoginDialogComponent>
  ) { }
  async ngOnInit() {
    //this.currentUser =  await this.afAuth.auth.signInAnonymously()
  }

  public updateDownloadURL() {
    let path = this.inputPhotoURL
    console.log(path)
    if (!path) return
    const fileRef: AngularFireStorageReference = this.storage.ref(
      path
    )
    let sub  = fileRef.getDownloadURL().subscribe(downloadURL => {
      //this.userInfo.cvURL = downloadURL
      this.downloadURL = downloadURL
      console.log(path)
      //console.log(this.userInfo.cvURL)
      console.log(downloadURL)
      fileRef.getMetadata().subscribe(d => {
        //console.log(d)
        let p = d.fullPath
        let tIndex = p.lastIndexOf('/') + 1
        if (tIndex >= 0) this.downloadFilename = p.substring(tIndex)
      })
      sub.unsubscribe()
    });

  }
  public uploadFile(event: any): void {
    this.errorMessage = ''
    this.notifyMessage = ''
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get('err_msg_file_too_large')
        return
      } else if (!this.allowFileType.includes(file.type)) {
        this.errorMessage = this.i18n.get('err_msg_disallow_file_type')
        return
      }
      try {
        this.isRegBtnLoading = true;
        const path = `${this.key_storage_uploaded_logo}/temp/${(new Date().toISOString())}_${makeid(10)}_${file.name}`;
      
        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        console.log(path)
        this.percentage = task.percentageChanges()
        task.snapshotChanges()
          .pipe( finalize(()=>{
            console.log("8964")
            const fileRef: AngularFireStorageReference = this.storage.ref(
              path
            )
            fileRef.getDownloadURL().subscribe(downloadURL => {
              //this.userInfo.cvURL = downloadURL
              this.inputPhotoURL = path
              this.isRegBtnLoading = false;
              console.log(this.inputPhotoURL)
              this.downloadURL = downloadURL
              this.updateDownloadURL()
              //sub.unsubscribe()
            });
          }),catchError(err => {
            this.errorMessage = err.message
            this.isRegBtnLoading = false;
            throw err;
          })).subscribe()
      } catch (e) {
        this.isRegBtnLoading = false;
        this.errorMessage = e.message
        console.error(e)
      }
    }
  }
  async emailSignUp(){
    this.errorMessage = ''
    this.notifyMessage = ''
    if(!this.inputRegCompanyName){
      this.errorMessage = this.i18n.get('error_msg_invalid_company_name')
    }else if(!this.inputRegCompanyDesc){
      this.errorMessage = this.i18n.get('error_msg_invalid_company_desc')
    }else if(this.inputRegCompanyDesc && this.inputRegCompanyDesc.length < 10){
      this.errorMessage = this.i18n.get('error_msg_comapny_desc_too_short')
    }else if (!this.inputRegEmail.includes('@') || !this.inputRegEmail.includes('.')) {
      // not valid email
      this.errorMessage = this.i18n.get('error_msg_invalid_email_address')
    } else if (!this.inputRegPassword) {
      this.errorMessage = this.i18n.get('error_msg_please_enter_password')
    } else if (!this.inputRegConfirmPassword) {
      this.errorMessage = this.i18n.get('error_msg_please_enter_confirm_password')
    } else if (this.inputRegConfirmPassword != this.inputRegPassword) {
      this.errorMessage = this.i18n.get('error_msg_password_mismatch')
    } else {
      // all pass, try register.
      this.isRegBtnLoading = true;
      let data = {
        name: this.inputRegCompanyName,
        desc: this.inputRegCompanyDesc,
        email: this.inputRegEmail,
        password: this.inputRegConfirmPassword,
        photoURL: this.downloadURL
      }
      this.notifyMessage = this.i18n.get('loading')
      let sub = this.afFunctions.httpsCallable(cloudFunctionsKeys.registerCompanyAccount)(data)
      .subscribe(async result=>{
        console.log(result)
        if(result === true){
          this.notifyMessage = this.i18n.get('company_info_submitted_wait_approved')
          this.inputRegCompanyDesc = ''
          this.inputRegCompanyName = ''
          this.inputRegPassword = ''
          this.inputRegConfirmPassword = ''
          this.inputRegEmail = ''
          //this.afAuth.auth.signOut()
        }else if(typeof(result) == 'string'){
          this.errorMessage =`ERRROR|錯誤: ${result}`
        }else{
          this.errorMessage ='ERROR|錯誤'
        }
        this.isRegBtnLoading = false
        if(sub) sub.unsubscribe()
      })
    }
  }
  closeThisDialog(){
    this.dialogRef.close(false)
  }
  toLogin(){
    this.dialogRef.close('normal')
  }
}
