import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';

import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { InputInfoDialogComponent } from './input-info-dialog/input-info-dialog.component';
import { Observable, observable, Subscription } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { fcall } from 'q';
import { BusinessLoginDialogComponent } from './business-login-dialog/business-login-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { checkIsInCareerAdvisor, toCaIntro, registerButtonGlobalId, openSimpleMessageDialog, volunteerKeys } from '../../class/common-objects.enum';
import { CompanyUserPermissionEnumItem, CompanyUserPermission } from '../../class/CompanyUserPermission';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {
  i18n = new I18n();
  isLogin: boolean = null;
  get isDev(){
    return !environment.production
  }
  currentUser: firebase.User;
  observableCurrentUser: Observable<firebase.User>;
  displayName = '';
  isAdmin: boolean = null;
  private callIsAdmin: Subscription = null;
  constructor(
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    public firestorage: AngularFireStorage,
    public dialog: MatDialog,
    public afFunctions: AngularFireFunctions,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    (<any>window).loginByToken = this.loginByToken.bind(this)
    document.addEventListener('scroll', e => {
      let topbar = document.getElementById('theTopBar');
      let logobar = document.getElementById('theLogoBar');
      if (topbar && logobar) {
        let y = window.scrollY;
        let fClass = 'fixed-top';
        if (y >= logobar.offsetHeight && y >= 100) {
          topbar.classList.add(fClass);
          logobar.style.display = 'none';
        } else {
          topbar.classList.remove(fClass);
          logobar.style.display = '';
        }
      }
    });
    auth.authState.subscribe(user=>{
      if(user && this.userMonitorNotifyDialog){
        this.userMonitorNotifyDialog.close()
      }else if(!this.isUserMonitorInit && !user && this.isAdminOrCompany){
        this.userMonitorNotifyDialog = openSimpleMessageDialog(dialog, `請先登入才能瀏覽該頁面\nPlease login to view this page`)
        this.isUserMonitorInit = true;
      }
    })
  }

  get isAdminOrCompany(){
    return this.router.url .startsWith('/admin') || this.router.url.startsWith('/company') || this.router.url.startsWith('/caAdmin')
  }
  isUserMonitorInit = false;
  userMonitorNotifyDialog :MatDialogRef<any>;

  get mapSportRevisionCategories(){
    return this.i18n.translate.map_sport_revision_categories
  }
  loginByToken(s:string){
    this.auth.auth.signInWithCustomToken(s)
  }
  get shouldShowCA() {
    if (environment.production) {
      return false;
    } else {
      return true;
    }
  }
  goToOurServices() {
    this.router.navigate(['ourServices']);
    this.clickToggleButton();
  }
  toJobs() {
    this.router.navigate(['jobs']);
    this.clickToggleButton();
  }
  goHome() {
    this.router.navigate(['']);
    this.clickToggleButton();
  }
  toJobMatching() {
    this.router.navigate(['talentpool']);
    this.clickToggleButton();
  }
  toTalentSearch() {
    this.router.navigate(['talentsearch']);
    this.clickToggleButton();
  }
  toDonationPage() {
    this.router.navigate(['donation']);
    this.clickToggleButton();
  }
  toListActivities() {
    this.router.navigate(['featureActivities']);
    this.clickToggleButton();
  }
  toListArticles() {
    //if(!environment.production)
    this.router.navigate(['articles']);
    this.clickToggleButton();
  }
  readonly PREFIX_CA = 'ca';
  toCaIntro() {
    toCaIntro(this.router);
  }
  toCaAppointment() {
    const t = [this.PREFIX_CA, 'find'];
    // if (this.isInCareerAdvisor) {
      this.router.navigate(t);
    // } else {
    //   open('/' + t.join('/'));
    // }
  }
  toCaBlog() {
    const t = [this.PREFIX_CA, 'articles'];

    if (this.isInCareerAdvisor) {
      this.router.navigate(t);
    } else {
      open('/' + t.join('/'));
    }
  }
  toContactUs() {
    this.router.navigate(['contact']);
    this.clickToggleButton();
  }

  clickToggleButton() {
    const b = <HTMLButtonElement>document.querySelector('body > app-root > app-navbar > nav > button');
    if (b && b.offsetWidth > 0) {
      b.click();
    }
  }
  closeNavIfNotcollapse(){

    const btn = document.getElementById('collapseButton')
    const atrr = btn.getAttribute('aria-expanded')
    if(atrr && atrr == 'true'){
      btn.click()
    }
  }
  async ngOnInit() {
    setTimeout(() => {
      if (!this.auth.auth.currentUser) {
        let { action } = this.route.snapshot.queryParams;
        if (action === 'register') {
          const d = document.getElementById(registerButtonGlobalId);
          if (d) {
            d.click();
          }
        }
      }
    }, 1200);
  }

  get isInCareerAdvisor() {
    return false;
    // return checkIsInCareerAdvisor();
  }
  get volunteerKeysROUTE(){
    return volunteerKeys + '/featureActivities';
  }
}
