import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { TheActivity } from 'src/app/class/the-activity';
import { AngularFirestore } from '@angular/fire/firestore';
import { checkIsVlunteerEvent, dbKeys, openSimpleMessageDialog, unsubscribeAll, volunteerKeys } from 'src/app/class/common-objects.enum';
import { Subscription } from 'rxjs';
import { firestore } from 'firebase/app';
import { CompanyInfo } from 'src/app/class/company-info';
import { AddUpdateMyActivityDialogComponent } from '../../company/my-activities/add-update-my-activity-dialog/add-update-my-activity-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { checkIsInCareerAdvisor } from '../../../class/common-objects.enum';
import { Query } from '@firebase/firestore-types';
import { I } from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-list-pending-activities',
  templateUrl: './admin-list-pending-activities.component.html',
  styleUrls: ['./admin-list-pending-activities.component.less']
})
export class AdminListPendingActivitiesComponent implements OnInit {
  i18n = new I18n();
  activities: Array<{
    activity: TheActivity,
    company: CompanyInfo,
    isFeatured: boolean | null;
  }> = null;
  lastFilterStatus = 'pending';
  private statusArray = [
    'pending',
    'active',
    'deactive',
    'all'
  ];
  constructor(
    public firestore: AngularFirestore,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {
    this.onStatusFilteringChange('pending');

  }
  get keyCollection() {
    if (checkIsVlunteerEvent) {
      return dbKeys.vol_eve_activities;
    } else if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_the_activities;
    } else {
      return dbKeys.the_activities;
    }
  }
  ngOnInit() {
  }


  private sub0OnVisibleChange: Subscription = null;
  onVisibleChange(activity: TheActivity) {
    unsubscribeAll([this.sub0OnVisibleChange])
    if (activity?.id) {
      this.firestore.collection(this.keyCollection)
        .doc(activity.id)
        .update({
          visible: !activity.visible
        })
        .then(() => {
          this.snackbar.open(this.i18n.translate.action_success)
        })
        .catch(() => {
          this.snackbar.open(this.i18n.translate.action_failed)
        })
    }
  }
  private filteringSubscription: Subscription = null;
  onStatusFilteringChange(newValue: string) {
    if (this.statusArray.includes(newValue)) {
      this.lastFilterStatus = newValue;
      if (this.filteringSubscription) this.filteringSubscription.unsubscribe();
      this.filteringSubscription = this.firestore.collection(this.keyCollection, (ref => {
        let query: Query = ref;
        if (newValue !== 'all') {
          query = ref.where('status', '==', newValue);
        }
        return query.orderBy('deadline', 'desc');
      })).get().subscribe(async result => {
        if (result && !result.empty) {
          this.activities = [];
          for (const d of result.docs) {
            const doc = d.data();
            const activity = <TheActivity>doc;
            try {
              activity.createdAt = (doc.createdAt as firestore.Timestamp).toDate();
              activity.updatedAt = (doc.updatedAt as firestore.Timestamp).toDate();
              activity.startAt = (doc.startAt as firestore.Timestamp).toDate();
              activity.endAt = (doc.endAt as firestore.Timestamp).toDate();
              activity.deadline = (doc.deadline as firestore.Timestamp).toDate();
            } catch (e) {
              console.error(e);
            }
            const r = await this.firestore.collection(dbKeys.company_info).doc(activity.creator_uid).get().toPromise();
            let company: CompanyInfo = null;
            if (r && r.exists) {
              company = <CompanyInfo>r.data();
            }
            const featured = await this.getFeaturedStatus(activity.id);
            this.activities.push({
              activity: activity,
              company: company,
              isFeatured: featured
            });
            console.log(this.activities);
          }
        } else {
          this.activities = [];
        }
      });
    }
  }
  async getFeaturedStatus(id: string) {
    try {
      const r = await this.firestore.collection(this.keyCollection).doc(id).get().toPromise();
      if (r && r.exists && r.data() && r.data().isFeatured) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      openSimpleMessageDialog(this.dialog, `發生錯誤！\nError Caused!\n${e.message}`);
      return false;
    }
  }
  async setFeaturedStatus(id: string, isFeatured: boolean) {
    let activity = this.activities.find((a: {
      activity: TheActivity,
      company: CompanyInfo,
      isFeatured: boolean | null;
    }) => a.activity.id === id);
    activity.isFeatured = null;
    try {
      const r = await this.firestore.collection(this.keyCollection).doc(id).update({
        isFeatured: isFeatured
      });
      activity.isFeatured = isFeatured;
    } catch (e) {
      console.error(e);
      openSimpleMessageDialog(this.dialog, `發生錯誤！\nError Caused!\n${e.message}`);
    }
  }
  async changeStatus(id: string, newStatus: 'pending' | 'active' | 'deactive') {
    if (!id.trim()) return;
    try {
      await this.firestore.collection(this.keyCollection).doc(id.trim()).update({
        status: newStatus
      });
      this.onStatusFilteringChange(this.lastFilterStatus);
      openSimpleMessageDialog(this.dialog, this.i18n.get('notify_msg_action_success'));
    } catch (e) {
      console.error(e);
      openSimpleMessageDialog(this.dialog, `發生錯誤！\nError Caused!\n${e.message}`);
    }
  }

  editActivity(id: string) {
    id = id.trim();
    const ref = this.dialog.open(AddUpdateMyActivityDialogComponent, {
      data: {
        id: id
      }
    });
    ref.afterClosed().subscribe(d => {
      if (d) {
        this.onStatusFilteringChange(this.lastFilterStatus);
      }
    });
  }
  openActivityPreview(id: string) {
    if (checkIsVlunteerEvent()) {
      open(`/${volunteerKeys}/${id}`)
    } else if (checkIsInCareerAdvisor()) {
      open(`/ca/listActivities/${id}`);
    } else {
      open(`/listActivities/${id}`);
    }

  }
  stringToDate(str: string): Date | '' {
    if (str) {
      return new Date(str);
    } else {
      return '';
    }
  }

  // get appliPrefix() {
  //   if (checkIsVlunteerEvent()) {
  //     return `/${volunteerKeys}`
  //   } else if (checkIsInCareerAdvisor()) {
  //     return '/caAdmin';
  //   } else {
  //     return '/admin';
  //   }
  // }
  openApplication(id: string) {
    const url = this.prefixListApplication + id;
    open(url);
  }
  toApplicationList(id: string) {
    //listActivityApplication
    const url = `${this.prefixListApplication}${id}`;
    open(url);
  }

  checkInVolunteerEditing() {
    return location.pathname.includes(volunteerKeys)
  }
  get prefixListApplication() {
    if (this.checkInVolunteerEditing()) {
      return `/admin/list${volunteerKeys}ActivityApplication/`
    } else if (checkIsInCareerAdvisor()) {
      return '/caAdmin/listActivityApplication/'
    } else {
      return '/admin/listActivityApplication/'
    }
  }
}
