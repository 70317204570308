import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { TheAdvisorBookingRecord } from 'src/app/class/theAdvisorBooking';
import { firestore } from 'firebase/app';
import { TheAdvisor } from 'src/app/class/the-advisor';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-view-single-appointment',
  templateUrl: './view-single-appointment.component.html',
  styleUrls: ['./view-single-appointment.component.less']
})
export class ViewSingleAppointmentComponent implements OnInit {
  i18n = new I18n()
  appointmentId = ''
  record: TheAdvisorBookingRecord = null
  advisor: TheAdvisor = null

  constructor(
    public route: ActivatedRoute,
    public afFirestore: AngularFirestore,
    public router: Router,
  ) {
    this.appointmentId = route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
    this.getItem()
  }

  async getItem() {
    try {
      const r = await this.afFirestore.collection(dbKeys.advisor_appointments).doc(this.appointmentId).get().toPromise()
      if (r && r.exists) {
        const a = <any>r.data()
        const n = <TheAdvisorBookingRecord>a
        n.createAt = (<firestore.Timestamp>a.createAt).toDate()
        n.expect_datetime = (<firestore.Timestamp>a.expect_datetime).toDate()
        this.record = n
        if (this.record.advisor_id) {
          const x = await this.afFirestore.collection(dbKeys.the_advisors).doc(this.record.advisor_id).get().toPromise()
          if (x && x.exists) {
            const b = <any>x.data()
            const z = <TheAdvisor>b
            z.createAt = (<firestore.Timestamp>b.createAt).toDate()
            z.updateAt = (<firestore.Timestamp>b.updateAt).toDate()
            this.advisor = z
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  navigateToPayment() {
    this.router.navigate(['ca', 'advisorOrder', this.record.id])
  }
}
