<div class="thisBody card">
    <div class="thisHeader">
        <h5>
            {{i18n.get('menu_mainpage_bottom_items')}}
            <button class="btn btn-light" (click)="openAddDialog()">
                {{i18n.get('add')}}
            </button>
        </h5>
        <div class="thisTableOuter">
            <table border="1" matSort>
                <tr>
                    <td>{{i18n.get("placeholder_job_ads_title")}}</td>
                    <td>{{i18n.get('contents')}}</td>
                    <td>{{i18n.get('button_text')}}</td>
                    <td>{{i18n.get('button_url')}}</td>
                    <td>{{i18n.get('image')}}</td>
                    <td>{{i18n.get('edit')}}</td>
                    <td>{{i18n.get('remove')}}</td>
                </tr>
                <tr  *ngIf="!items && items === null">
                    <td colspan="5">
                        {{i18n.get('loading')}}
                    </td>
                </tr>
                <tr *ngIf="items && items != null && items.length <= 0">
                    <td colspan="5">
                        {{i18n.get('nothing_to_show')}}
                    </td>
                </tr>
                <ng-container *ngIf="items != null && items && items.length && items.length > 0">
                    <tr *ngFor="let item of items">
                        <td style="white-space: pre-wrap;">{{item.title}}</td>
                        <td style="white-space: pre-wrap;">{{item.content}}</td>
                        <td>{{item.buttonText}}</td>
                        <td>{{item.buttonURL}}</td>
                        <td  style="min-width: 80px;"   >
                            <a [href]="item.imageURL" target="_blank">
                                {{i18n.get('view')}}
                            </a>
                        </td>
                        <td  style="min-width: 80px;">
                            <a class="btn btn-light" (click)="openUpdateDialog(item.id)">{{i18n.get('edit')}}</a>
                        </td>
                        <td>
                            <a class="btn btn-light" (click)="onRemove(item.id)" style="min-width: 80px;">
                                {{i18n.get('remove')}}
                            </a>
                        </td>
                    </tr>
                </ng-container>
                
            </table>
        </div>
    </div>
</div>