<div class="thisBody">
    
   <div class="innerBody card">
    <h3 *ngIf="!advisor && !record">{{i18n.get('loading')}}</h3>
    <div class="content" *ngIf="advisor && record">
        <app-appointment-card [advisor]="advisor" [record]="record"></app-appointment-card>
        <hr>
        <div class="paymentPart" *ngIf="record.status =='not_paid'">
            <h5>{{i18n.get('pay_by_credit_card_for_appointment_fee')}}</h5>

            <div class="card-container" id="card_container" >
                <div class="display-card"></div>
                <form card container=".display-card" card-width="50px" [(id)]="payFormId" formatting="false"
                    debug="true">
                    <!--
                    [messages]="messages"
                    [placeholders]="placeholders" [masks]="masks" 
                -->
                    <br>
                    <div class="input-list">
                        <mat-form-field class="example-full-width" style="width: 45%; margin-right: 4%;">
                            <input type="text" name="phoneNo" matInput
                                [placeholder]="i18n.get('hongkong_mobile_number') + ' (+852)'" maxlength="8"
                                [(ngModel)]="formData.phoneNumber" required />
                        </mat-form-field>
                        <mat-form-field class="example-full-width" style="width: 45%;">
                            <input type="email" name="email" matInput [placeholder]="i18n.get('email')" required
                                [(ngModel)]="formData.email" />
                        </mat-form-field>
                        <mat-form-field class="example-full-width" style="width: 95%;">
                            <input type="text" name="number" card-number matInput maxlength="19"
                                [placeholder]="i18n.get('credit_debit_card_number')" required
                                [(ngModel)]="formData.cardNumber" />
                        </mat-form-field>

                        <mat-form-field class="example-full-width" style="width: 95%;">
                            <input matInput type="text" name="fullname" card-name
                                [placeholder]="i18n.get('card_holder_fullname')" required
                                [(ngModel)]="formData.name" />
                        </mat-form-field>
                        <mat-form-field class="example-full-width" style="width: 70%;">
                            <input matInput type="text" name="expiry" card-expiry
                                [placeholder]="i18n.get('expiryDate')+ ' (MM/YYYY)'" required
                                [(ngModel)]="formData.expDate" />
                        </mat-form-field>
                        <mat-form-field class="example-full-width" style="margin-left: 5%; width: 20%;">
                            <input matInput type="text" name="cvc" card-cvc placeholder="CVC" required
                                [(ngModel)]="formData.cvc" maxlength="4" />
                        </mat-form-field>
                    </div>
                </form>


                <button class="btn btn-primary submitButton" (click)="tryPay()"
                    [disabled]="handingPayments">{{i18n.get('pay')}}</button>
            </div>
            <div class="powered-by-stripe">
                <span>{{i18n.get('powered_by_stripe')}} <a
                        href="https://stripe.com/docs/security/stripe">{{i18n.get('know_more')}}</a></span>
                <img src='/assets/powered-by-stripe.png'>
            </div>
        </div>
    </div>
   </div>
</div>