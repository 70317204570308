import { Component, Input, OnInit } from '@angular/core';
import { ISportCourse } from 'src/app/class/ISportCourse';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-sport-course-card',
  templateUrl: './sport-course-card.component.html',
  styleUrls: ['./sport-course-card.component.less']
})
export class SportCourseCardComponent implements OnInit {
  @Input() course:ISportCourse = null
  i18n = new I18n()
  constructor() { }

  ngOnInit() {
  }

  compareFor30DayLater = new Date((new Date().getTime() + (1000 * 60 * 60 *24 * 45)))
  get isActiveCourse(){
      if(this.course && this.course.holdDateTime && this.course.holdDateTime.toDate() <= this.compareFor30DayLater){
        return true
      }
      return false
  }

  get bgColor(){
    if(this.course.type == 'A'){
      return '#2f3d77'
    }else if(this.course.type == 'B'){
      return '#ed2870'
    }
  }
  get btnColor(){
    
    if(this.course.type == 'A'){
      return 'accent'
    }else if(this.course.type == 'B'){
      return 'primary'
    }
    return 'info'
  }
}
