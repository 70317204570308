<div>
  <div *ngIf="showHKJCLogo" flex="align-center justify-center">
    <img class="bottomLogo" src="/assets/HRCA_project-06-06.png" />
  </div>
  <div flex="justify-evenly" class="upper">
    <div flex="align-center justify-center">
      <div class="text">主辦機構<br>Organized By</div>
      <div>
        <img class="logo" src="/assets/HRCA_Logo-01.png" />
        <img class="logo" src="/assets/ca1-2-360.png" />
      </div>
    </div>
    <div *ngIf="showHKJCLogo" flex="align-center justify-center">
      <div class="text">捐助機構<br>Funded By</div>
      <div>
        <a href="https://charities.hkjc.com/charities/chinese/charities-trust/index.aspx" target="_blank">
          <img class="logo" src="/assets/Trust_Print_FullColor_coated_B_H.JPG" />
        </a>
      </div>
    </div>
  </div>
</div>