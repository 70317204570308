<h3 (click)="closeThisDialog()" style="cursor: pointer;">X</h3>

<mat-dialog-content class="logreg-forms">
    <h5 *ngIf="isUpdate">Updating Slider {{initId}}</h5>
    <h5 *ngIf="!isUpdate">Add Slider</h5>
    <div>
        <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
            {{notifyMessage}}
        </div>
    </div>
    <div class="form-signup">

        <input type="text" id="user-companyName" [(ngModel)]="imageSlide.title" class="form-control" placeholder="Title"
            required autofocus="">
        <input type="text" id="user-companyDesc" [(ngModel)]="imageSlide.content" class="form-control"
            placeholder="Content" required autofocus="">
        <input type="text" id="user-email" [(ngModel)]="imageSlide.buttonText" class="form-control"
            placeholder="Button Text" required autofocus="">
        <input type="url" id="user-pass" [(ngModel)]="imageSlide.buttonURL" class="form-control"
            placeholder="Button URL" required autofocus="">

        <div class="dropzone" dropZone (dropped)="uploadFile($event)" [class.hovering]="isHovering">



            <h5>Background Image</h5>

            <div class="file">
                <label class="file-label">


                    <input class="file-input" type="file" (change)="uploadFile($event)" accept=".jpg,.jpeg,.png">

                </label>
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="fa fa-upload"></i>
                    </span>
                    <span class="file-label">
                        <span>
                            <br>
                            <div *ngIf="percentage | async as pct">
                                <span *ngIf="pct<=99">
                                    <progress class="progress is-info" [value]="pct" max="100">
                                    </progress>

                                    {{ pct | number }}%
                                </span>

                            </div>
                            <span *ngIf="downloadURL">
                                <img class="previewIcon" [src]="downloadURL">
                            </span>

                        </span>
                    </span>
                </span>

            </div>
        </div>
        <br>
        <button class="btn btn-primary btn-block" (click)="onSubmit()"><i class="fas fa-user-plus"></i>
            <span *ngIf="!isUpdate">
                    {{i18n.get('add')}}
            </span>
            <span *ngIf="isUpdate">
                    {{i18n.get('update')}}
            </span>
        </button>
        <br>

    </div>
</mat-dialog-content>
