import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { environment } from 'src/environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';
import { makeid, cloudFunctionsKeys } from 'src/app/class/common-objects.enum';
import { openSimpleMessageDialog } from '../../class/common-objects.enum';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-donation-to-us',
  templateUrl: './donation-to-us.component.html',
  styleUrls: ['./donation-to-us.component.less']
})
export class DonationToUsComponent implements OnInit {
  i18n = new I18n()
  payFormId = `pay_form_id___${makeid(10)}__`
  constructor(
    public afFunctions: AngularFireFunctions,
    public dialog : MatDialog
  ) { }
  amount = 100
  handingPayments: boolean = null
  donateButtonDisabled = false
  formData = {
    phoneNumber: '',
    email: '',
    cardNumber: '',
    name: '',
    expDate: '',
    cvc: ''
  }
  onSubmit(d) {
    console.log(d)
  }
  ngOnInit() {
    (<any>window).Stripe.setPublishableKey(environment.stripePublicKey);

  }
  get payLanguage() {
    if (this.i18n.currentLocate == 'en') {
      return 'en'
    } else if (this.i18n.currentLocate == 'hant') {
      return 'zh'
    } else {
      return this.i18n.currentLocate
    }
  }
  showHidePayForm(show: boolean) {
    const pay = <HTMLDivElement>document.getElementById('price_container')
    const card = <HTMLDivElement>document.getElementById('card_container')
    if (show) {
      card.style.display = ''
      pay.style.display = 'none'
    } else {
      card.style.display = 'none'
      pay.style.display = ''
    }
  }
  goPay() {
    if (this.amount >= 10) {
      this.showHidePayForm(true)
    } else {
      openSimpleMessageDialog(this.dialog, this.i18n.get('donation_amount_too_low'))
    }
  }
  tryPay() {
    const form = <HTMLFormElement>document.getElementById(this.payFormId)
    if (form) {
      if (form.reportValidity()) {
        const formData = this.formData
        if (formData.cardNumber && formData.cvc && formData.email && formData.expDate && formData.name && formData.phoneNumber) {
          formData.cardNumber = formData.cardNumber.split(' ').join('')
          let expMonth = ''
          let expYear = ''
          const e = formData.expDate.split('/')
          if (e.length == 2) {
            expMonth = e[0].trim()
            expYear = e[1].trim()
          }
          const now = new Date()

          console.log(formData)
          if (formData.cardNumber.length != 16) {
            openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_credit_debit_card_information'))
          } else if (!formData.email.includes('.') || !formData.email.includes('@')) {
            openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_email_address'))
          } else if (formData.name.length <= 2) {
            openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_name'))
          } else if (parseInt(expYear) < now.getFullYear() || (parseInt(expYear) == now.getFullYear() && parseInt(expMonth) < now.getMonth() + 1)) {
            openSimpleMessageDialog(this.dialog, this.i18n.get('please_enter_valid_exp_date'))
          } else {
            this.donateButtonDisabled = true
            const self = this;
            (<any>window).Stripe.card.createToken({
              number: formData.cardNumber,
              cvc: formData.cvc,
              exp_month: expMonth,
              exp_year: expYear
            }, (status, response) => {

              // Grab the form:
              //var $form = $('#payment-form');

              if (response.error) {
                openSimpleMessageDialog(this.dialog, '錯誤！\nERROR!\nCODE: ' + response.error.message)

              } else {
                var token = response.id;

                console.log(token)

                self.afFunctions.httpsCallable(cloudFunctionsKeys.handleDonationsToStripe)({
                  chargeToken: token,
                  phoneNumber: this.formData.phoneNumber,
                  name: this.formData.name,
                  email: formData.email,
                  amount: this.amount * 100
                }).subscribe(async result => {
                  console.log(result)
                  if (result === 'success') {
                    openSimpleMessageDialog(this.dialog, this.i18n.get('donation_success'))
                    location.reload()
                  } else {
                    openSimpleMessageDialog(this.dialog, `Error Message from Stripe | 來自Stripe的錯誤信息：\n${result}`)
                  }
                  this.donateButtonDisabled = false
                })

              }
            });

          }
        } else {
          openSimpleMessageDialog(this.dialog, this.i18n.get('plese_enter_all_information_to_donate'))
        }
      } else {
        //alert(this.i18n.get('plese_enter_all_information_to_donate'))
      }
    } else {
      openSimpleMessageDialog(this.dialog, `Something error, please reload!\n產生了預期外的問題，請嘗試重新載入。`)
    }
  }
}


