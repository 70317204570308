export enum enumWhatsappTemplates{
    confirmation_of_ca_advising_to_young="confirmation_of_ca_advising_to_young",
    reminder_before_few_hour_to_young="reminder_before_few_hour_to_young",
    consultation_done_or_not_to_young="consultation_done_or_not_to_young",
    evaluation_form_to_young="evaluation_form_to_young",
    done_and_thanks_to_young="done_and_thanks_to_young",
    will_reschedule_question_to_young="will_reschedule_question_to_young",

    confirmation_of_ca_advising_to_advisor="confirmation_of_ca_advising_to_advisor",
    reminder_before_few_hour_to_advisor="reminder_before_few_hour_to_advisor",
    consultation_done_or_not_to_advisor="consultation_done_or_not_to_advisor",
    consultation_is_done_message_to_advisor="consultation_is_done_message_to_advisor",
    consultation_not_done_message_to_advisor="consultation_not_done_message_to_advisor",
}