import { Component, OnInit, Input } from '@angular/core';
import { TheActivity, ActivityApplication } from 'src/app/class/the-activity';
import { CompanyInfo } from 'src/app/class/company-info';
import { I18n } from 'src/app/i18n/i18n';
import { makeid, dbKeys, checkIsInCareerAdvisor, openSimpleMessageDialog, checkIsVlunteerEvent } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-apply-activity-parts',
  templateUrl: './apply-activity-parts.component.html',
  styleUrls: ['./apply-activity-parts.component.less']
})
export class ApplyActivityPartsComponent implements OnInit {
  thisFormId = `apply_activity_forms_uid_${makeid(12)}`
  i18n = new I18n()
  displayForm = false
  jwtHelper = new JwtHelperService()
  currentUser: firebase.User = null
  @Input() mainActivity: {
    activity: TheActivity,
    companyInfo: CompanyInfo
  } = null
  formData = {
    name: '',
    phoneNumber: '',
    email: '',
    applyFormChecked3: false,
  }

  get keyCollection() {
    if (checkIsVlunteerEvent()) {
      return dbKeys.vol_eve_activities
    } else if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_activity_application
    } else {
      return dbKeys.activity_application
    }
  }
  isApplySuccess = false
  constructor(
    public firestore: AngularFirestore,
    public auth: AngularFireAuth,
    public dialog: MatDialog
  ) {
    auth.authState.subscribe(async user => {
      if (user) {
        let token = await user.getIdToken()
        let dToken = this.jwtHelper.decodeToken(token)

        if (!dToken.isAdmin && !dToken.isCompany) {
          if (user.displayName) this.formData.name = user.displayName
          if (user.phoneNumber) this.formData.phoneNumber = user.phoneNumber
          if (user.email) this.formData.email = user.email
        }

      } else {
        this.formData.name = ''
        this.formData.name = ''
        this.formData.phoneNumber = ''
      }
    })
  }

  ngOnInit() {
  }
  displayFormFunction() {
    this.displayForm = true
  }
  async onSubmit() {
    const form = <HTMLFormElement>document.getElementById(this.thisFormId)
    if (form.reportValidity()) {
      console.log(this.formData)
      const application: ActivityApplication = {
        id: '',
        user_id: '',
        activity_id: this.mainActivity.activity.id,
        status: 'pending',
        name: this.formData.name,
        phoneNumber: this.formData.phoneNumber,
        email: this.formData.email,
      }
      if (this.currentUser && this.currentUser.uid) {
        application.user_id = this.currentUser.uid
      }
      try {
        const result = await this.firestore.collection(this.keyCollection).add(application)
        result.update({ id: result.id })
        openSimpleMessageDialog(this.dialog, this.i18n.get('notify_msg_action_success'))
        this.isApplySuccess = true
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `ERROR|錯誤：${e.message}`)
      }
    }

  }
}
