<div>
  <div>
    <h3 *ngIf="!advisor && !record">{{ i18n.get("loading") }}</h3>
    <div class="content" *ngIf="advisor && record">
      <h3 style="text-align: center">{{ i18n.get("booking_record") }}</h3>
      <table>
        <tr>
          <td>{{ i18n.get("find_ca") }}</td>
          <td>{{ advisor.name }}</td>
        </tr>
        <tr>
          <td>{{ i18n.get("placeholder_consultion_fee") }}</td>
          <td>{{ advisor.fee | advisorFee }}</td>
        </tr>
        <tr>
          <td>{{ i18n.get("supported_appointment_format") }}</td>
          <td>{{ i18n.get(record.format) }}</td>
        </tr>
        <tr>
          <td>{{ i18n.get("job_table_status") }}</td>
          <td>{{ record.status | advisorAppointmentStatus }}</td>
        </tr>
        <tr>
          <td>{{ i18n.get("placeholder_name") }}</td>
          <td>{{ record.name }}</td>
        </tr>
        <tr>
          <td>{{ i18n.get("placeholder_phone_number") }}</td>
          <td>{{ record.phone }}</td>
        </tr>
        <tr>
          <td>{{ i18n.get("placeholder_email") }}</td>
          <td>{{ record.email }}</td>
        </tr>
        <tr>
          <td>{{ i18n.get("expect_book_datetime") }}</td>
          <td>{{ record.expect_datetime | dateTimeText }}</td>
        </tr>
        <tr *ngIf="record.remark">
          <td>{{ i18n.get("remarks") }}</td>
          <td style="white-space: pre-wrap">{{ record.remark }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>