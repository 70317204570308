import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DiagnosticCategory } from 'typescript';
import { checkIsInCareerAdvisor, dbKeys } from '../class/common-objects.enum';
import { IFaqItem } from '../class/IFaqItem';
import { I18n } from '../i18n/i18n';
import { SelectQnaTagDialogComponent } from './select-qna-tag-dialog/select-qna-tag-dialog.component';

@Component({
  selector: 'app-list-qna',
  templateUrl: './list-qna.component.html',
  styleUrls: ['./list-qna.component.less']
})
export class ListQnaComponent implements OnInit {
  i18n = new I18n()
  faqItems: IFaqItem[] = null
  currentTag = ''
  constructor(
    private afFirestore: AngularFirestore,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute:ActivatedRoute,
  ) {
    activatedRoute.params.subscribe(params=>{
      console.log('new param', params)
      const tagId = params?.tag_id
      if(tagId){
        this.currentTag = tagId
      }else{
        this.currentTag = ''
      }
      this.restartFetchFaq()
    })
   }

  ngOnInit() {
    this.restartFetchFaq()
  }

  sub0FetchFaq: Subscription
  restartFetchFaq() {
    if (this.sub0FetchFaq) this.sub0FetchFaq.unsubscribe()

    this.sub0FetchFaq = this.afFirestore.collection<IFaqItem>(dbKeys.faq, 
      ref=>{
        if(this.currentTag){
          return ref.where('tags','array-contains', this.currentTag).where('visible','==', true).orderBy('updateDateTime','desc')
        }
        return ref.where('visible','==', true).orderBy('updateDateTime','desc');
      })
      .valueChanges().subscribe(v => {
        console.log('new faqs', v)
        this.faqItems = v || [];

      })
  }

  openSelectTagDialog() {
    this.dialog.open(SelectQnaTagDialogComponent, {
      data: {

      }
    }).afterClosed().subscribe(v => {
      if (v === null) {
        // clear
      } else if (v && typeof v == 'string') {
        this.navigateToTag(v)
      }
    })
  }
  get routeCaPrefix(){
    return checkIsInCareerAdvisor() ? '/ca' :''
  }
  get baseRoute(){

   return  checkIsInCareerAdvisor() ? ['ca'] : []
  }
  clearTag(){
    const r = this.baseRoute
    r.push(...[
      'qna'
    ])
    this.router.navigate(r)
  }
  navigateToTag(v:string){

        // tag selected
        console.log('tag selected... ', v)
        const r = this.baseRoute
        r.push(...[
          'qna',
          'tag',
          v
        ])
        this.router.navigate(r)
  }
}
