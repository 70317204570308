import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '../../../../i18n/i18n';
import { FormControl } from '@angular/forms';
// import { CompanyUserPermissionEnumItem } from 'src/app/class/CompanyUserPermission';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys } from '../../../../class/common-objects.enum';
import { AdminPermission, AdminPermissionEnumItem, OrderedAdminPermissions } from 'src/app/class//AdminPermission';

@Component({
  selector: 'app-change-admin-permission-dialog',
  templateUrl: './change-admin-permission-dialog.component.html',
  styleUrls: ['./change-admin-permission-dialog.component.less']
})
export class ChangeAdminPermissionDialogComponent implements OnInit {
  uid = ''
  i18n = new I18n()
  permissionsControl = new FormControl()
  permissions: Array<AdminPermissionEnumItem> = []
  orderedPermissions: Array<AdminPermissionEnumItem> = OrderedAdminPermissions
  constructor(
    public dialogRef: MatDialogRef<ChangeAdminPermissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    public afFirestore: AngularFirestore
  ) {
    if (data && data.uid) {
      this.uid = data.uid
      this.getPermissions()
    } else {
      this.closeDialog()
    }
  }
  get title() {
    return `修改權限/EditPermissions`
  }
  ngOnInit() {
  }
  async getPermissions() {
    this.afFirestore.collection(dbKeys.admin_permissions)
      .doc(this.uid).get().toPromise()
      .then(d => {
        if (d && d.exists) {
          const data = <AdminPermission>d.data()
          if (data && data.permissions && data.permissions.length > 0) {
            this.permissions = data.permissions
          }
        }
      }).catch(e => {
        console.error(e)
      })
  }
  async onSubmit() {
    try {
      const ref = this.afFirestore.collection(dbKeys.admin_permissions)
        .doc(this.uid)

      const p: AdminPermission = {
        uid: this.uid,
        permissions: this.permissions
      }
      await ref.set(p)
      alert('Update success!\n更新權限成功！')
      this.closeDialog()

    } catch (e) {
      console.error(e)
      alert(`ERROR | 錯誤: \n${e.message}`)
    }

  }
  closeDialog() {
    this.dialogRef.close()
  }
}
