<div class="all">
    <h3>課程報名記錄</h3>
    <mat-progress-bar mode="buffer" *ngIf="!course">
    </mat-progress-bar>
    <div class="body" *ngIf="course">
        <div class="info">

            <div class="tb">
                <div class="t">ID</div>
                <div class="c">：</div>
                <div class="b">{{course.id}}</div>
            </div>

            <div class="tb">
                <div class="t">狀態</div>
                <div class="c">：</div>
                <div class="b">{{i18n.get(course.status)}}</div>
            </div>
            <div class="tb">
                <div class="t">姓名</div>
                <div class="c">：</div>
                <div class="b">{{course.name}}</div>
            </div>
            <div class="tb">
                <div class="t">電話</div>
                <div class="c">：</div>
                <div class="b">{{course.phoneNumber}}</div>
            </div>
            <div class="tb">
                <div class="t">電郵</div>
                <div class="c">：</div>
                <div class="b">{{course.email}}</div>
            </div>
            <div class="tb">
                <div class="t">地址</div>
                <div class="c">：</div>
                <div class="b">{{course.address}}</div>
            </div>
            <div class="tb">
                <div class="t">備註</div>
                <div class="c">：</div>
                <div class="b">{{course.remarks}}</div>
            </div>

<hr>


            <div class="tb">
                <div class="t">年齡</div>
                <div class="c">：</div>
                <div class="b">{{course.age}}</div>
            </div>

            <div class="tb">
                <div class="t">每週運動次數</div>
                <div class="c">：</div>
                <div class="b">{{i18n.translate.map_health_questionire_sport_time_per_week.get(course.sport_time_per_week)}}</div>
            </div>

            <div class="tb">
                <div class="t">每次運動時長</div>
                <div class="c">：</div>
                <div class="b">{{i18n.translate.map_health_questionire_sport_minute_per_time.get(course.sport_minute_per_time)}}</div>
            </div>

            <div class="tb">
                <div class="t">做過什麼運動</div>
                <div class="c">：</div>
                <div class="b">{{course.what_sport_do}}</div>
            </div>


        </div>
    </div>
</div>