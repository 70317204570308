import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'advisorFee'
})
export class AdvisorFeePipe implements PipeTransform {

  transform(fee: number): string {
    if (fee) {
      return `$${fee}`;
    }
    return `Free of charge`
  }

}
