<div class="thisBody">
    <h4 (click)="closeDialog()" style="cursor: pointer;">X</h4>
    <mat-dialog-content class="thisContent">
        <h5 *ngIf="!isUpdate">Add Bottom Item</h5>
        <h5 *ngIf="isUpdate">Update Item {{imageSlide.id}}</h5>

    <div class="form">

    <div class="form-signup">

            <textarea type="text" id="user-companyName" [(ngModel)]="imageSlide.title" class="form-control" placeholder="Title"
                required autofocus=""></textarea>
            <textarea type="text" id="user-companyDesc" [(ngModel)]="imageSlide.content" class="form-control"
                placeholder="Content" required autofocus=""></textarea>
            <input type="text" id="user-email" [(ngModel)]="imageSlide.buttonText" class="form-control"
                placeholder="Button Text" required autofocus="">
            <input type="url" id="user-pass" [(ngModel)]="imageSlide.buttonURL" class="form-control"
                placeholder="Button URL" required autofocus="">

            <div class="dropzone" dropZone (dropped)="uploadFile($event)" [class.hovering]="isHovering">



                <h5>Background Image</h5>

                <div class="file">
                    <label class="file-label">


                        <input class="file-input" type="file" (change)="uploadFile($event)" accept=".jpg,.jpeg,.png">

                    </label>
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fa fa-upload"></i>
                        </span>
                        <span class="file-label">
                            <span>
                                <br>
                                <div *ngIf="percentage | async as pct">
                                    <span *ngIf="pct<=99">
                                        <progress class="progress is-info" [value]="pct" max="100">
                                        </progress>

                                        {{ pct | number }}%
                                    </span>

                                </div>
                                <span *ngIf="downloadURL">
                                    <img class="previewIcon" [src]="downloadURL">
                                </span>

                            </span>
                        </span>
                    </span>

                </div>
            </div>
            <br>
            <button class="btn btn-primary btn-block" (click)="onSubmit()"><i class="fas fa-user-plus"></i>
                <span *ngIf="isUpdate">
                    {{i18n.get('update')}}
                </span>
                <span *ngIf="!isUpdate">
                    {{i18n.get('add')}}
                </span>
            </button>
            <br>

        </div>
    </div>
  </mat-dialog-content>
    </div>
