import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-raw-data-download',
  templateUrl: './raw-data-download.component.html',
  styleUrls: ['./raw-data-download.component.less']
})
export class RawDataDownloadComponent implements OnInit {
  arrLogs: string[] = [
  ]
  get textFromLogsArray() {
    const arrCopy = this.arrLogs.slice();
    return arrCopy.reverse().join("\n");
  }
  constructor(

  ) { }

  ngOnInit() {
  }


  appendLine(s: string,) {
    this.arrLogs.push(s)
  }
  downloadCSV(e:IDownloadCsvMask) {
    const filename = e.filename;
    const rows = e.content;
    let processRow = function (row) {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        };
        let result = innerValue.replace(/"/g, "”");
        if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0)
          finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }


  }
}


export interface IDownloadCsvMask {
  filename: string,
  content: string[][],
}