import { isCvFileTypeAllowed, makeid } from 'src/app/class/common-objects.enum';
import { Component, OnInit, Inject } from '@angular/core';
import { I18n } from '../../../../../i18n/i18n';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TheAdvisor } from '../../../../../class/the-advisor';
import { TheAdvisorBookingRecord, TheAdvisorBookingRecordStatus } from '../../../../../class/theAdvisorBooking';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { dbKeys } from '../../../../../class/common-objects.enum';
import { Router } from '@angular/router';
import { UserInfo } from '../../../../../class/user-info';
import { observable, computed } from 'mobx-angular';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-make-appointment-dialog',
  templateUrl: './make-appointment-dialog.component.html',
  styleUrls: ['./make-appointment-dialog.component.less']
})
export class MakeAppointmentDialogComponent implements OnInit {
  i18n = new I18n()
  initId = ''
  formId = `this_add_appointment_form_id___${makeid(19)}`
  @observable advisor: TheAdvisor = null
  @computed get formats() {
    if (!this.advisor) return []

    let formats = [];
    for (let k of ['phone_call', 'face_to_face', 'zoom_call']) {
      if (this.advisor[k]) {
        formats.push(k)
      }
    }
    return formats;
  }


  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;
  downloadFilename: string

  allowFileSize = 10 * 1024 * 1024


  currentUser: firebase.User = null
  userInfo: UserInfo = null
  record: TheAdvisorBookingRecord = {
    id: '',
    createAt: new Date(),
    creator_id: '',
    advisor_id: '',
    status: TheAdvisorBookingRecordStatus.wait_confirm,
    name: '',
    phone: '',
    email: '',
    remark: '',
    charge_id: '',
    locate: this.i18n.currentLocate,
    expect_datetime: new Date(),
    format: 'phone_call',

    cv_url: '',

    isConformationSent: false,
    isBeforeReminderSent:false,
    isAfterReminderSent:false,
  }
  min: Date = null

  get pickerMode() {
    const width = outerWidth | window.outerWidth | 0
    if (width >= 900) {
      return 'popup'
    } else {
      return 'dialog'
    }
  }

  isOpenLogged = false;
  logRecordId = ''
  isFinishLogged = false;
  constructor(
    public dialogRef: MatDialogRef<MakeAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    public afAuth: AngularFireAuth,
    public afFirestore: AngularFirestore,
    public router: Router,
    private logger: LoggingUtilsService,
    private storage:AngularFireStorage,
  ) {
    if (data && data.id) {
      this.initId = data.id
      this.refreshItem()
      logger.log(LoggingKey.ca_appointment_filling_and_submit, data.id)
        .then(v => {
          if (v && typeof v == 'string' && !this.isOpenLogged) {
            this.logRecordId = v;
            this.isOpenLogged = true;
          }
        })
    } else {
      dialogRef.close()
    }
    afAuth.authState.subscribe(user => {
      if (user && user.uid) {
        this.record.creator_id = user.uid
        this.currentUser = user
        this.tryPrefillUserInfo()
      } else {
        this.record.creator_id = ''
      }
    })
  }

  async tryPrefillUserInfo() {
    if (this.currentUser && this.currentUser.uid) {
      try {
        const uid = this.currentUser.uid || ''
        const snapInfo = await this.afFirestore.collection(dbKeys.user_info)
          .doc(uid)
          .get().toPromise()
        if (snapInfo && snapInfo.exists) {
          const info = <UserInfo>snapInfo.data()
          this.record.name = this.currentUser.displayName
          this.record.email = this.currentUser.email
          const phone = this.currentUser.phoneNumber
          if (phone.startsWith('+852')) {
            this.record.phone = phone.substring(4)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  get title() {
    if (this.advisor && this.advisor.name)
      return `${this.i18n.get('book')} ${this.advisor.name}`
    else
      return this.i18n.get('book')
  }

  ngOnInit() {
    const now = new Date()
    let min = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 3)
    if (min.getDay() === 6) {
      min = new Date(min.getFullYear(), min.getMonth(), min.getDate() + 2)
    } else if (min.getDay() === 0) {
      min = new Date(min.getFullYear(), min.getMonth(), min.getDate() + 1)
    }
    this.min = new Date(min)
    this.record.expect_datetime = new Date(min)
  }

  async onSubmit() {
    const form = <HTMLFormElement>document.getElementById(this.formId)
    if (form && form.reportValidity()) {
      try {
        console.log(this.record)
        const res = await this.afFirestore.collection(dbKeys.advisor_appointments).add(this.record)
        if (res && res.id) {
          await res.update({
            id: res.id
          })
        }
        this.toViewAppointment(res.id)
        this.dialogRef.close()
        if (this.isOpenLogged && !this.isFinishLogged && this.logRecordId) {
          this.logger.logFinished(this.logRecordId, true, res.id)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  toViewAppointment(id: string) {
    if (id)
      this.router.navigate(['ca', 'appointment', id])
  }

  toOrder(id: string) {
    if (id)
      this.router.navigate(['ca', 'advisorOrder', id])
  }

  sub0: Subscription = null
  refreshItem() {
    if (this.sub0) {
      try {
        this.sub0.unsubscribe()
      } catch (e) { }
    }
    if (this.initId) {
      this.afFirestore.collection(dbKeys.the_advisors).doc(this.initId).valueChanges()
        .subscribe(d => {
          const a = <any>d
          const res = <TheAdvisor>a
          res.createAt = (<firestore.Timestamp>a.createAt).toDate()
          res.updateAt = (<firestore.Timestamp>a.updateAt).toDate()
          if (!res.phone_call && res.face_to_face) {
            this.record.format = 'face_to_face'
          }
          this.advisor = res
          this.record.advisor_id = this.advisor.id
        })
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  private key_storage_user_uploaded_cv = 'user_uploaded_cv'
  public uploadFile(event: any): void {
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        alert(this.i18n.get('err_msg_file_too_large'))
        return
      } else if (!isCvFileTypeAllowed(file.type)) {
        alert(this.i18n.get('err_msg_disallow_file_type'))
        return
      }
      try {
        const path = `${this.key_storage_user_uploaded_cv}/temp/${(new Date()).toISOString()}_${makeid(10)}_${file.name}`;
        const fileRef: AngularFireStorageReference = this.storage.ref(
          path
        );
        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        this.percentage = task.percentageChanges()
        task
          .snapshotChanges()
          .pipe(catchError(err => {
            alert(err.message)
            throw err;
          }))
          .pipe(
            finalize(() => {

            })
          )
          .subscribe((data) => {
            console.log('abc')
            fileRef.getDownloadURL().subscribe(downloadURL => {
              console.log(downloadURL)
              //this.userInfo.cvURL = downloadURL
              // this.userInfo.cvURL = path
              this.downloadURL = downloadURL
              this.record.cv_url = downloadURL
              //console.log(path)
              //console.log(this.userInfo.cvURL)
              fileRef.getMetadata().subscribe(d => {
                //console.log(d)
                let p = d.fullPath
                let tIndex = p.lastIndexOf('/') + 1
                if (tIndex >= 0) this.downloadFilename = p.substring(tIndex)
              })
              // console.log(this.userInfo.cvURL)
            });
          });
      } catch (e) {
        alert(e.message)
        console.error(e)
      }
    }
  }
}
