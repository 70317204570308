<hr />
<p class="thisTitle" style="display: none">
  {{ i18n.get("job_position_apply") }}
</p>
<div *ngIf="!mainJob">
  {{ i18n.get("nothing_to_show") }}
</div>
<div
  *ngIf="
    mainJob &&
    mainJob != null &&
    !mainJob.job.isApplicationHidden &&
    !displayForm
  "
>
  <button class="btn btn-primary btn-action" (click)="displayFormFunction()">
    {{ i18n.get("apply_now") }}
  </button>
</div>
<div *ngIf="isApplySuccess">
  {{ i18n.get("job_application_success") }}
</div>
<div *ngIf="mainJob && mainJob != null && displayForm && !isApplySuccess">
  <form [id]="thisFormId" class="apply-form" (submit)="onSubmit()">
    <div class="input-group mb-3">
      <label>{{ i18n.get("name") }}</label>
      <input
        type="text"
        class="form-control"
        [placeholder]="i18n.get('placeholder_name')"
        [(ngModel)]="formData.name"
        [ngModelOptions]="{ standalone: true }"
        aria-label="Username"
        aria-describedby="basic-addon1"
        required
      />
    </div>
    <div class="input-group mb-3">
      <label>{{ i18n.get("placeholder_mobile_phone") }}</label>
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">+852</span>
      </div>
      <input
        type="number"
        class="form-control"
        [placeholder]="i18n.get('placeholder_phone_number')"
        [(ngModel)]="formData.phoneNumber"
        [ngModelOptions]="{ standalone: true }"
        aria-describedby="basic-addon1"
        min="10000000"
        max="99999999"
        required
      />
    </div>
    <div class="input-group mb-3">
      <label>{{ i18n.get("placeholder_email_address") }}</label>
      <input
        type="email"
        class="form-control"
        [placeholder]="i18n.get('placeholder_email')"
        aria-label="Username"
        [(ngModel)]="formData.email"
        [ngModelOptions]="{ standalone: true }"
        aria-describedby="basic-addon1"
        required
      />
    </div>
    <div>
      <h5>{{ i18n.get("your_cv_title") }}</h5>

      <div class="file">
        <label class="file-label">
          <b>{{ i18n.get("update_cv_limit_description") }}</b>
          <br />

          <input
            class="file-input btn btn-light"
            type="file"
            (change)="uploadFile($event)"
            accept=".doc,.docx,.odf,.pdf,.jpg,.png"
          />
        </label>
        <span class="file-cta">
          <span class="file-icon">
            <i class="fa fa-upload"></i>
          </span>
          <span class="file-label">
            <span>
              <br />
              <div *ngIf="percentage | async as pct">
                <span *ngIf="pct <= 99">
                  <progress
                    class="progress is-info"
                    [value]="pct"
                    max="100"
                  ></progress>

                  {{ pct | number }}%
                </span>
              </div>
              <span *ngIf="formData.cvUrl">
                {{ i18n.get("message_was_uploaded") }}<br />
                <a [href]="formData.cvUrl" target="_blank">{{
                  i18n.get("button_view_file")
                }}</a>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <br />
    <ul>
      <li class="termBullet">{{ i18n.get("apply_job_term_1") }}</li>
      <li class="termBullet">{{ i18n.get("apply_job_term_2") }}</li>
    </ul>
    <input
      type="submit"
      class="btn btn-primary btn-action"
      [value]="i18n.get('submit')"
    />
  </form>
</div>
