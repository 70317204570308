import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { JobAds } from 'src/app/class/job-ads';
import { CompanyInfo } from 'src/app/class/company-info';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, SelectOptionData, JobSearchHistroy, makeid, shuffle } from 'src/app/class/common-objects.enum';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageUtils } from 'src/app/LocalStorage/local-storage-utils';
import { firestore } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { analyticsEventKeys } from '../../../class/common-objects.enum';
import { TheArticle } from "src/app/class/the-article";
import { I } from '@angular/cdk/keycodes';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';

@Component({
  selector: 'app-list-jobs',
  templateUrl: './list-jobs.component.html',
  styleUrls: ['./list-jobs.component.less']
})
export class ListJobsComponent implements OnInit {
  errorMessage = ''
  notifyMessage = ''
  private _thisId = ''
  searchIndutry: string = ''
  searchLocation: string = ''
  searchEmploymentForm: string = ''
  isInit = true
  isNotP1 = true
  localStorage: LocalStorageUtils = new LocalStorageUtils()
  clickSearchHiddenButtonId = `clickSearchHiddenButtonId_${makeid(10)}`
  articles: Array<TheArticle> = null;
  get searchHistory() {
    return this.localStorage.getJobSearchHistory().slice(0, 5)
  }
  get thisId() { return this._thisId }

  set thisId(newId: string) {
    this._thisId = newId
    if (this._thisId) {
      this.updateMainContent(this._thisId)
    }
  }
  get resultCount() {
    if (this.displayJobs) {
      return this.displayJobs.filter(v => v.display).length
    } else {
      return 0
    }
  }
  get getMainDisplay(): { main: '' | 'none', list: '' | 'none' } {
    if (this.isInit || !this.isNotP1) {
      return {
        main: 'none',
        list: ''
      }
    } else {
      return {
        main: '',
        list: 'none'
      }
    }
  }
  i18n = new I18n()
  _mainJob: {
    companyInfo: CompanyInfo,
    job: JobAds
  } = undefined
  get mainJob() {
    return this._mainJob;
  }
  set mainJob(v: {
    companyInfo: CompanyInfo,
    job: JobAds
  }) {
    if(v && v.job && v.job.id){
      console.log('vvvvvvvvv',v.job.id)
      this.logger.log(LoggingKey.job_view, v.job.id)
    }
    this._mainJob = v;
  }
  constructor(
    public firestore: AngularFirestore,
    public route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    public analytics: AngularFireAnalytics,
    private logger:LoggingUtilsService,
  ) { }
  searchKeyword = ''
  displayJobs: Array<{ display: boolean, companyInfo: CompanyInfo, job: JobAds }> = null
  ngOnInit() {
    this.updateAllJobAds()
    this.fetchIdFromUrl()
    this.updateOptions()
    this.updateArticles()
  }

  searchHistoryToString(history: JobSearchHistroy) {
    let arr = [
      this.i18n.getStringMap('map_job_type').get(history.industry) || history.industry,
      this.i18n.getStringMap('map_job_location').get(history.location) || history.location,
      this.i18n.getStringMap('map_job_employment_forms').get(history.empForm) || history.empForm
    ]
    let outArr = arr.filter(v => !!v)
    if (outArr && outArr.length && history.keyword) {
      return history.keyword + ": " + outArr.join(', ')
    } else if (outArr && outArr.length) {
      return outArr.join(', ')
    } else if (history.keyword) {
      return history.keyword
    } else {
      return '所有/ALL'
    }
  }
  onSearchHistoryClick(history: JobSearchHistroy) {
    this.searchKeyword = history.keyword
    this.searchIndutry = history.industry
    this.searchLocation = history.location
    this.searchEmploymentForm = history.empForm
    //console.log(history)
    this.searchQuery(true)
  }
  updateOptions() {

    this.updateSearchOptionsIndustry()
    this.updateSearchOptionsLocation()
    this.updateSearchOptionsEmploymentForm()
  }
  fetchIdFromUrl() {
    let sub: Subscription = null
    sub = this.route.params.subscribe(params => {
      console.log(params)
      if (params && params.id) {
        const id = params.id
        if (id == 'p0') {
          this.isNotP1 = true
        } else if (id == 'p1') {
          this.isNotP1 = false
        } else {
          this.thisId = params.id
          this.isInit = true
        }
      }
    })
  }
  get leftRightDisplay(): { left: '' | 'none', right: '' | 'none' } {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    //console.log(width)
    if (width >= 1000) {
      return {
        left: '',
        right: ''
      }
    } else {
      if (this.thisId && this.mainJob) {
        return {
          left: 'none',
          right: ''
        }
      } else {
        return {
          left: '',
          right: 'none'
        }
      }
    }
  }
  updateAllJobAds() {

    let sub = this.firestore.collection(dbKeys.job_ads,
      (ref => ref.where('status', '==', 'active')))
      .valueChanges()
      .subscribe(async (result: Array<JobAds>) => {
        this.displayJobs = []
        console.log(result)
        if (result && result.length > 0) {
          for (let job of result) {

            let snap = await this.firestore.collection(dbKeys.company_info).doc<CompanyInfo>(job.creator_uid).get().toPromise()
            if (snap.exists) {
              let companyInfo: CompanyInfo = <CompanyInfo>snap.data()
              const d: any = job.deadline
              const dtime: firestore.Timestamp = d
              job.deadline = dtime.toDate()
              this.displayJobs.push({
                display: true,
                companyInfo: companyInfo,
                job: job
              })
            }
          }
        } else {
          this.displayJobs = []
        }
        console.log(this.displayJobs)
        this.updateOptions()
        sub.unsubscribe()
      })
  }
  async searchQuery(skipHistory?: boolean) {
    const cUser = this.afAuth.auth.currentUser
    let uid = ''
    if (cUser && cUser.uid) {
      uid = cUser.uid
    }
    this.isInit = true
    if (!skipHistory) {
      const history: JobSearchHistroy = {
        searcherId: uid,
        keyword: this.searchKeyword || "",
        location: this.searchLocation || "",
        industry: this.searchIndutry || "",
        empForm: this.searchEmploymentForm
      }
      console.log(history)
      this.localStorage.addJobSearchHistory(history)
      await this.firestore.collection(dbKeys.search_record_logs).add(history)
    }
    console.log(this.searchKeyword)
    console.log({
      indutry: this.searchIndutry,
      location: this.searchLocation,
      employmentForm: this.searchEmploymentForm
    })
    let inArray: string[] = this.searchKeyword.split(' ')
    let keywords: Array<string> = inArray
    if (inArray && inArray.length > 0) {
      keywords = inArray.filter(value => !!value.trim())
    }
    for (let i = 0; i < this.displayJobs.length; i++) {
      this.displayJobs[i].display = true
    }
    if (true) {
      for (let i in this.displayJobs) {
        let job = this.displayJobs[i]
        let isHitWords = false
        let isHitFilters = false
        if ((job.job.industry == this.searchIndutry || !this.searchIndutry) &&
          (job.job.location == this.searchLocation || !this.searchLocation) &&
          (job.job.employmentForms == this.searchEmploymentForm || !this.searchEmploymentForm)
        ) {
          isHitFilters = true
        }
        console.log({
          isHitFilters: isHitFilters,

        })
        console.log(keywords)
        if (keywords && keywords.length) {
          for (let keyword of keywords) {
            if (isHitFilters && (
              job.job.title.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
              job.job.position.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
              job.job.location.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
              job.job.welfare.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
              job.companyInfo.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
              job.companyInfo.description.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
            )
            ) {

              // if any, turn false
              isHitWords = true
              break
            }
          }
        } else {
          isHitWords = isHitFilters
        }
        this.displayJobs[i].display = isHitWords
      }
    }

    console.log(this.displayJobs)
  }
  onLeftClick(id: string) {
    this.thisId = id
    history.pushState(null, null, `/jobs/${id}`)
  }
  async updateMainContent(id: string) {
    if (!id) {
      this.mainJob = undefined
      return
    }
    this.mainJob = null

    try {
      let jobSnap = await this.firestore.collection(dbKeys.job_ads).doc(id).get().toPromise()
      if (jobSnap.exists) {
        let job = <JobAds>jobSnap.data()
        let companySnap = await this.firestore.collection(dbKeys.company_info).doc(job.creator_uid).get().toPromise()
        const d = <firestore.Timestamp>jobSnap.data().deadline
        job.deadline = d.toDate()
        if (companySnap.exists) {
          let companyInfo = <CompanyInfo>companySnap.data()
          this.mainJob = {
            job: job,
            companyInfo: companyInfo
          }
          this.analytics.logEvent(analyticsEventKeys.job_view, {
            job_id: job.id,
            job_title: job.title,
            job_company_id: companyInfo.uid,
            job_company_name: companyInfo.name
          })
        }
      }
    } catch (e) {
      console.error(e)
      this.mainJob = null
    }
  }
  searchOptionsIndustry: Array<SelectOptionData> = []
  updateSearchOptionsIndustry() {
    const arrSelectOptionsList: Array<SelectOptionData> = [{
      key: "",
      name: 'All/所有行業'
    }]
    const map = this.i18n.getStringMap('map_job_type')
    if (map) {
      map.forEach((v, k) => {
        arrSelectOptionsList.push({
          key: k,
          name: v
        })
      })
    }
    // trush below
    this.searchOptionsIndustry = arrSelectOptionsList

  }
  searchOptionsLocation: Array<SelectOptionData> = []
  updateSearchOptionsLocation() {
    const arrSelectOptionsList: Array<SelectOptionData> = []
    const map = this.i18n.getStringMap('map_job_location')
    if (map) {
      map.forEach((v, k) => {
        arrSelectOptionsList.push({
          key: k,
          name: v
        })
      })
    }
    this.searchOptionsLocation = arrSelectOptionsList
  }
  searchOptionsEmploymentForm: Array<SelectOptionData> = []
  updateSearchOptionsEmploymentForm() {
    const arrSelectOptionsList: Array<SelectOptionData> = [{
      key: null,
      name: "All/任意受僱形式"
    }]
    const map = this.i18n.getStringMap('map_job_employment_forms')
    if (map) {
      map.forEach((v, k) => {
        arrSelectOptionsList.push({
          key: k,
          name: v
        })
      })
    }
    this.searchOptionsEmploymentForm = arrSelectOptionsList
  }

  clearMainContent() {
    history.replaceState(null, null, '/jobs')
    this.thisId = ''
    this.updateMainContent('')
  }

  subscriptionArticles: Subscription = null;
  updateArticles() {
    if (this.subscriptionArticles != null) {
      this.subscriptionArticles.unsubscribe();
      this.subscriptionArticles = null;
    }

    this.subscriptionArticles = this.firestore
      .collection(dbKeys.blog_articles, (r) => r.where("status", "==", "active").orderBy("createAt", "desc"))
      .valueChanges()
      .subscribe((values) => {
        if (values && values.length > 0) {
          const arrArticles: Array<TheArticle> = [];
          for (const data of values) {
            const d: any = data;
            const article: TheArticle = d;
            article.createAt = (<firebase.firestore.Timestamp>(
              d.createAt
            )).toDate();
            article.updateAt = (<firebase.firestore.Timestamp>(
              d.updateAt
            )).toDate();
            arrArticles.push(article);
          }
          this.articles = shuffle(arrArticles).slice(0, 3);
        } else {
          this.articles = [];
        }
      });
  }
}
