import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sport-leader-apply',
  templateUrl: './sport-leader-apply.component.html',
  styleUrls: ['./sport-leader-apply.component.less']
})
export class SportLeaderApplyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openForm(){
    open('https://forms.gle/NaARM12SKNFRg3YcA')
  }
  openWhatsapp(){
    open('https://api.whatsapp.com/send/?phone=85266865812&text=%E6%88%91%E6%83%B3%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A%E6%9C%89%E9%97%9C+%E8%B3%BD%E9%A6%AC%E6%9C%83%E6%A8%82%E5%8B%95%E4%B8%80%E6%97%8F%E8%A8%88%E5%8A%83+-+%E6%A8%82%E5%8B%95%E5%8D%80%E9%95%B7')
  }
}
