<div class="card thisBody">
    <h5>Normal User List</h5>
    <div class="quickGoDiv">
        <mat-form-field class="example-full-width">
            <input type="number" min=10000000 max=99999999 matInput placeholder="Go Profile by phoneNo." [(ngModel)]="queryPhoneNumber">
          </mat-form-field>
          <button class="btn btn-light" (click)="goProfileByPhoneNumber()">GO</button>
          <div>OR</div>
          <mat-form-field class="example-full-width">
            <input type="email" matInput placeholder="Go Profile by E-Mail" [(ngModel)]="queryEmail">
          </mat-form-field>
          <button class="btn btn-light" (click)="goProfileByEmail()">GO</button>

    <h5>Filtering/條件篩選</h5>
    <div class="search_list">
        <div class="search_item search_filter_items">
    <mat-form-field>
        <mat-label>{{i18n.get('user_register_for')}}</mat-label>
        <mat-select [(ngModel)]="filterRules.registerFor" >
            <mat-option>ALl/所有</mat-option>
          <mat-option *ngFor="let o of i18n.getStringMap('map_register_for') | keyvalue" [value]="o.key">{{o.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{i18n.get('worked_years')}}</mat-label>
        <mat-select [(ngModel)]="filterRules.workYear" >
            <mat-option>ALl/所有</mat-option>
          <mat-option *ngFor="let o of i18n.getStringMap('map_work_years') | keyvalue" [value]="o.key">{{o.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{i18n.get('worked_type')}}</mat-label>
        <mat-select [(ngModel)]="filterRules.workType" >
            <mat-option>ALl/所有</mat-option>
          <mat-option *ngFor="let o of i18n.getStringMap('map_job_type') | keyvalue" [value]="o.key">{{o.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{i18n.get('edu_level')}}</mat-label>
        <mat-select [(ngModel)]="filterRules.eduLevel" >
            <mat-option>ALl/所有</mat-option>
          <mat-option *ngFor="let o of i18n.getStringMap('map_edu_level') | keyvalue" [value]="o.key">{{o.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="search_item">
            <button class="btn btn-info" (click)="onFiltering()">
                Search/搜尋
            </button>
            </div>
        </div>
    </div>
    <div class="mainTable">
        <table border="1">
            <tr>
                <td>UID</td>
                <td>Name</td>
                <td>Phone No.</td>
                <td>E-Mail</td>
                <td>Register For</td>
                <td>Worked Years</td>
                <td>Job Type</td>
                <td>Edu. Lv.</td>
                <td>View Profile</td>
            </tr>
            <tr *ngIf="userRecords === null">
                <td colspan="4">
                    Loading...
                </td>
            </tr>
            <tr *ngIf="userRecords !== null && userRecords.length <= 0">
                <td colspan="4">
                    Nothing.
                </td>
            </tr>
            <ng-container *ngIf="userRecords && userRecords.length > 0 ">

            <tr *ngFor="let record of userRecords">
                <td>{{record.uid}}</td>
                <td>{{record.displayName}}</td>
                <td>{{record.phoneNumber}}</td>
                <td>{{record.email}}</td>
                <td>{{i18n.getStringMap('map_register_for').get(record.registerFor) || ""}}</td>
                <td>{{i18n.getStringMap('map_work_years').get(record.workedYears) || ""}}</td>
                <td>{{i18n.getStringMap('map_job_type').get(record.jobType) || ""}}</td>
                <td>{{i18n.getStringMap('map_edu_level').get(record.educationLevel) || ""}}</td>
                <td>
                    <a class="btn btn-light" (click)="openViewProfile(record.uid)">
                        View Profile
                    </a>
                </td>
            </tr>
        </ng-container>
        </table>
    </div>
</div>
