import { Component, OnInit } from '@angular/core';
import { ImageSlide, CaImageSlide } from '../../../class/common-objects.enum';

@Component({
  selector: 'app-ca-main-slider',
  templateUrl: './ca-main-slider.component.html',
  styleUrls: ['./ca-main-slider.component.less']
})
export class CaMainSliderComponent implements OnInit {
  images: Array<CaImageSlide> = [
    {
      id: '',
      imageURL: '/assets/ca_cover.png',
      topText: 'Coaching｜Networking｜Consultation',
      midTitle: 'CAREER ADVISOR',
      visible:false,
      bottomText: '​Retired senior management professionals help you to  create a brighter future'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
