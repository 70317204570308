<div class="container1">
  <!----------- Footer ------------>
  <footer class="footer-bs" style="margin: 0">
    <div class="row">
      <div class="col-md-8 footer-social animated fadeInDown">
        <h4>{{ i18n.get("footer_contact_us") }}</h4>
        <div innerHTML="{{ i18n.get('footer_address') }}"></div>
        <br>
        <div>{{i18n.get('footer_tel')}}2833 6755</div>
        <div>{{i18n.get('footer_whatsapp')}}<a href="https://api.whatsapp.com/send?phone=85266865812" rel="nofollow noreferrer noopener" target="_blank">6686 5812</a></div>
        <div>{{i18n.get('footer_email')}}<a href="mailto:info@happy-retired.org">info@happy-retired.org</a></div>
        <div>{{lisenseNumber}}</div>
      </div>
      <div class="col-md-2 footer-social animated fadeInDown">
        <h4>{{ i18n.get("footer_know_more") }}</h4>
        <ul>
          <li>
            <a href="#">{{ i18n.get("footer_about_us") }}</a>
          </li>
          <li>
            <a href="/contact" [target]="isInCareerAdvisor ? 'blank' : ''">{{ i18n.get("footer_contact_us") }}</a>
          </li>
          <li>
            <a href="/terms" [target]="isInCareerAdvisor ? 'blank' : ''">{{ i18n.get("footer_terms_of_use") }}</a>
          </li>
          <li>
            <a href="/privacy" [target]="isInCareerAdvisor ? 'blank' : ''">{{ i18n.get("footer_privacy_policy") }}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-2 footer-social animated fadeInDown">
        <h4>{{i18n.get('footer_follow_us')}}</h4>
        <ul>
          <li><a [href]="sns.linkedin" target="blank">LinkedIn</a></li>
          <li><a [href]="sns.facebook" target="blank">Facebook</a></li>
          <li><a [href]="sns.instagram" target="blank">Instagram</a></li>
          <li><a [href]="sns.whatsapp" target="blank">WhatsApp</a></li>
        </ul>
      </div>
    </div>
    <br /><br />
    <section
      style="background-color: #3c3d41; text-align: center; margin: 10px auto"
    >
      <p>Copyright &copy; {{ thisYear }} Happy-Retired Charity Actions, All right reserved.</p>
      <p>
        Developed by
        <u
          ><a href="https://evol.studio" style="color: white"
            >Len Chan, Evol.Studio</a
          ></u
        >
      </p>
      <p>Last Build: {{ lastBuildTime }}</p>
    </section>
  </footer>
</div>
