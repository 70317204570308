import { Component, Input, OnInit } from '@angular/core';
import { TheAdvisorBookingRecord } from 'src/app/class/theAdvisorBooking';
import { TheAdvisor } from 'src/app/class/the-advisor';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.less']
})
export class AppointmentCardComponent implements OnInit {
  i18n = new I18n()
  
  @Input()
  record: TheAdvisorBookingRecord
  @Input()
  advisor: TheAdvisor

  constructor() {}

  ngOnInit() {
  }
}
