<div class="all">
    <h3>{{title}}</h3>
    <mat-dialog-content>
        <form #thisForm>

            <mat-form-field class="example-full-width">
                <mat-label>COURSE_TYPE</mat-label>
                <mat-select [(ngModel)]="course.type" (selectionChange)="onTypeChange($event.value)" required [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let t of allType" [value]="t">
                        {{t}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>COURSE_TITLE</mat-label>
                <input matInput placeholder="ExPlzza" [(ngModel)]="course.title" name="title" required [ngModelOptions]="{standalone: true}">
            </mat-form-field>


            <mat-form-field class="example-full-width">
                <mat-label>COURSE_SUBTITLTE</mat-label>
                <input matInput placeholder="ExPlzza" [(ngModel)]="course.subtitle" name="subtitle" required [ngModelOptions]="{standalone: true}">
            </mat-form-field>


            <mat-form-field class="example-full-width">
                <mat-label>COURSE_DESCRIPTION</mat-label>
                <input matInput placeholder="ExPlzza" [(ngModel)]="course.description" name="description" required [ngModelOptions]="{standalone: true}">
            </mat-form-field>


            <mat-form-field class="example-full-width">
                <mat-label>course_count_name</mat-label>
                <input matInput placeholder="ExPlzza" [(ngModel)]="course.countName" name="countName" required [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field [owlDateTimeTrigger]="startAt" class="full-width">
                <input matInput [placeholder]="i18n.get('add_activity_placeholder_start_at')" [owlDateTime]="startAt"
                    [(ngModel)]="localHoldDateTime" [ngModelOptions]="{ standalone: true }" (ngModelChange)="onDateTimeChange($event)" required />
                <owl-date-time [pickerMode]="pickerMode" #startAt></owl-date-time>
            </mat-form-field>


            <mat-form-field class="example-full-width">
                <mat-label>holdDateText</mat-label>
                <input matInput placeholder="ExPlzza" [(ngModel)]="course.holdDateText" name="holdDateText" required [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>holdTimeText</mat-label>
                <input matInput placeholder="ExPlzza" [(ngModel)]="course.holdTimeText" name="holdTimeText" required [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>location</mat-label>
                <input matInput placeholder="ExPlzza" [(ngModel)]="course.location" name="location" required [ngModelOptions]="{standalone: true}">
            </mat-form-field>
<hr>

<mat-form-field class="example-full-width">
    <mat-label>Coming_Notice</mat-label>
    <input matInput placeholder="ExPlzza" [(ngModel)]="course.comingUpNotice" name="comingUpNotice" required [ngModelOptions]="{standalone: true}">
</mat-form-field>
<mat-form-field class="example-full-width">
    <mat-label>Alt. Reserve URL</mat-label>
    <input matInput  type="url" placeholder="ExPlzza" [(ngModel)]="course.altReserveLink" name="altReserveLink" [ngModelOptions]="{standalone: true}">
</mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>CANCEL</button>
        <button mat-button color="primary" (click)="onSubmit()">
            Submit
        </button>
    </mat-dialog-actions>
</div>