import { Component, OnInit, Inject } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { AdsBanner, makeid, dbKeys, AdsBannerType } from 'src/app/class/common-objects.enum';
import { AngularFireUploadTask, AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { catchError, finalize } from 'rxjs/operators';
import { inject } from '@angular/core/testing';
import { stringify } from 'querystring';

@Component({
  selector: 'app-add-admin-ads-banner-dialog',
  templateUrl: './add-admin-ads-banner-dialog.component.html',
  styleUrls: ['./add-admin-ads-banner-dialog.component.less']
})
export class AddAdminAdsBannerDialogComponent implements OnInit {
  errorMessage = ''
  notifyMessage = ''
  i18n = new I18n()
  adsBanner: AdsBanner = {
    id: '',
    type: '',
    imageURL: '',
    clickURL: ''
  }

  allowFileType = ["image/png", "image/jpeg", "image/jpg"]
  allowFileSize = 10 * 1024 * 1024
  key_storage_mainpage_slider = 'image_storage_mainpage_slider'
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  downloadFilename: string
  mapType: AdsBannerType[] = [AdsBannerType.w300h250]
  // State for dropzone CSS toggling
  isHovering: boolean;
  initId = ''
  get isUpdate() {
    return !!this.initId
  }
  constructor(
    public dialogRef: MatDialogRef<AddAdminAdsBannerDialogComponent>,
    public storage: AngularFireStorage,
    public firestore: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data)

    if (data && data.id) {
      this.initId = data.id
      firestore.collection(dbKeys.global_ads_banner).doc(data.id).valueChanges().subscribe(async d => {
        if (d) {
          let doc: AdsBanner = <AdsBanner>d
          if (doc) {
            console.log(d)
            this.downloadURL = await storage.ref(doc.imageURL).getDownloadURL().toPromise()
            console.log(doc)
            this.adsBanner = doc
          }


        }

      })
    }
  }

  ngOnInit() {
  }

  public uploadFile(event: any): void {
    this.errorMessage = ''
    this.notifyMessage = ''
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get('err_msg_file_too_large')
        return
      } else if (!this.allowFileType.includes(file.type)) {
        this.errorMessage = this.i18n.get('err_msg_disallow_file_type')
        return
      }
      try {
        const path = `${this.key_storage_mainpage_slider}/${(new Date().toISOString())}_${makeid(10)}_${file.name}`;

        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        console.log(path)
        this.percentage = task.percentageChanges()
        let sub = task
          .snapshotChanges()
          .pipe(catchError(err => {
            this.errorMessage = err.message
            throw err;
          }))
          .pipe(

            finalize(() => {
              const fileRef: AngularFireStorageReference = this.storage.ref(
                path
              )
              fileRef.getDownloadURL().subscribe(downloadURL => {
                //this.userInfo.cvURL = downloadURL

                this.adsBanner.imageURL = path
                this.downloadURL = downloadURL
                //this.updateDownloadURL(path)
                sub.unsubscribe()
              });
            })
          )
          .subscribe((data) => {
            console.log(data)

          })
      } catch (e) {
        this.errorMessage = e.message
        console.error(e)
      }
    }
  }
  async onSubmit() {
    console.log(this.adsBanner)
    if (!this.adsBanner.clickURL) {
      alert('請輸入點擊鏈結！')
    } else if (!this.adsBanner.type) {
      alert('請選擇類別！')
    } else if (!this.adsBanner.imageURL) {
      alert('請上載圖片！')
    } else {

      try {
        if (this.isUpdate) {
          await this.firestore.collection(dbKeys.global_ads_banner).doc(this.initId).update(this.adsBanner)
          alert('修改成功！\nUpdate Success!')
          this.dialogRef.close(true)
        } else {
          let result = await this.firestore.collection(dbKeys.global_ads_banner).add(this.adsBanner)
          await result.update({
            id: result.id
          })
          alert('新增成功！\nAdded Success!')
        }
        this.dialogRef.close(true)
      } catch (e) {
        alert(`上傳失敗: ${e.message}`)
      }
    }
  }
  closeThisDialog() {
    this.dialogRef.close()
  }

}
