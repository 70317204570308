import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.less']
})
export class OurServicesComponent implements OnInit {
  i18n = new I18n()
  constructor() { }

  ngOnInit() {
  }

}
