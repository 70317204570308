<div class="thisBody card">
  <h5>
    <span>{{ i18n.get("my_job_ads_action") }}</span>
    <a class="btn btn-light" (click)="openAddUpdateDialog()">{{
      i18n.get("add")
    }}</a>
    <!-- <a class="btn btn-light" (click)="updateJobs()">{{ i18n.get("reload") }}</a> -->
  </h5>
  <div>
    <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
    <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
      {{ notifyMessage }}
    </div>
  </div>
  <div *ngIf="jobs === null">
    {{ i18n.get("loading") }}
  </div>
  <div *ngIf="jobs !== null && jobs.length <= 0">
    {{ i18n.get("nothing_to_show") }}
  </div>
  <div *ngIf="jobs && jobs.length > 0">
    <table border="1">
      <tr>
        <td>  </td>
        <td>{{ i18n.get("job_table_title") }}</td>
        <td>{{ i18n.translate.created_at }}</td>
        <td>{{ i18n.get("job_table_status") }}</td>
        <td> Create DateTime </td>
        <td>{{ i18n.get("job_table_position") }}</td>
        <td>{{ i18n.get("job_table_deadline") }}</td>
        <td>{{ i18n.get("placeholder_job_ads_is_application_hidden") }}</td>
        <td>{{ i18n.get("edit") }}</td>
        <td>{{ i18n.get("job_table_job_preview") }}</td>
      </tr>
      <tr *ngFor="let job of jobs; index as i">
        <td>{{i+1}}</td>
        <td>{{ job.title }}</td>
        <td>

          <span *ngIf="job && job.createDateTime && job.createDateTime.toDate">
            {{job.createDateTime.toDate() | date:'yyyy-MM-dd hh:mm:ss'}}
          </span>
        </td>
        <td>{{ job.status }}</td>
        <td> 
          <span *ngIf="job.createDateTime && job.createDateTime.toDate">
            {{job.createDateTime.toDate() | date:'yyyy-MM-dd hh:mm:ss' }}
          </span>
         </td>
        <td>{{ job.position }}</td>
        <td>{{ job.deadline | expiryDate }}</td>
        <td>{{ !!job.isApplicationHidden }}</td>
        <td>
          <a class="btn btn-light" (click)="openUpdateDialog(job)">
            {{ i18n.get("edit") }}
          </a>
        </td>
        <td>
          <a class="btn btn-light" target="_blank" [href]="'/jobs/' + job.id">
            {{ i18n.get("job_table_job_preview") }}
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>