import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import {
  registerButtonGlobalId,
  submitCvGlobalId,
  openSimpleMessageDialog,
  openActionsDialog,
  dbKeys,
} from "src/app/class/common-objects.enum";
import { AngularFireAuth } from "@angular/fire/auth";
import { I18n } from "src/app/i18n/i18n";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActionsDialogActionItem } from 'src/app/universal-dialog/actions-dialog/actions-dialog.component';

@Component({
  selector: "app-main-pager",
  templateUrl: "./main-pager.component.html",
  styleUrls: ["./main-pager.component.less"],
})
export class MainPagerComponent implements OnInit {
  i18n = new I18n();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    public auth: AngularFireAuth,
    public router: Router,
    public afFirestore: AngularFirestore
  ) { }

  get keyCollection() {
    return dbKeys.blog_articles;
  }
  ngOnInit() { }

  goSearch() {
    this.onSearch.emit();
  }

  openRegisterdialog() {
    const d = document.getElementById(registerButtonGlobalId);
    if (d) {
      d.click();
    }
  }

  bottomListItems = [
    {
      img: "/assets/job-list-bottom-icons/登記成為會員.png",
      text: this.i18n.get('jobs_register_member'),
      clickCallback: () => {
        if (!this.auth.auth.currentUser) {
          this.openRegisterdialog();
        } else {
          openSimpleMessageDialog(
            this.dialog,
            this.i18n.get("you_had_loggged_on")
          );
        }
      },
    },
    {
      img: "/assets/job-list-bottom-icons/提交個人CV.png",
      text: this.i18n.get('jobs_submit_cv'),
      clickCallback: () => {
        if (!this.auth.auth.currentUser) {
          openActionsDialog(
            this.dialog,
            this.i18n.get("you_have_not_login"),
            [{
              callback: () => {
                this.dialog.closeAll();
                this.openRegisterdialog();
              },
              text: this.i18n.get('register'),
              isPrimary: true,
            }],
          );
        } else {
          const d = document.getElementById(submitCvGlobalId);
          if (d) {
            d.click();
          }
        }
      },
    },
    {
      img: "/assets/job-list-bottom-icons/查看職位空缺.png",
      text: this.i18n.get('jobs_browse_jobs'),
      clickCallback: () => {
        this.router.navigate(["/jobs", "p1"]);
        //this.onSearch.emit()
      },
    },
  ];
}
