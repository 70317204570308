<div class="thisBody">
    <div class="thisHeader">
        <h4>{{i18n.get('menu_admin_job_matching')}}
            <a class="btn btn-light" (click)="openAddUpdateDialog(null)">{{i18n.get('add')}}</a>
        </h4>
    </div>
    <br>
    <div class="thisContent">
        <div *ngIf="talents === null">
            {{i18n.get('loading')}}
        </div>
        <div *ngIf="talents && talents.length<= 0">
            {{i18n.get('nothing_to_show')}}
        </div>
        <div class="tableDiv">
            <table *ngIf="talents && talents.length > 0" border="1">
                <tr>
                    <td>ID</td>
                    <td>{{i18n.get('placeholder_job_edu_level')}}</td>
                    <td>{{i18n.get('professional_qualification')}}</td>
                    <td>{{i18n.get('industry')}}</td>
                    <td>{{i18n.get('expertise')}}</td>
                    <td>{{i18n.get('work_experience')}}</td>
                    <td>{{i18n.get('edit')}}</td>
                    <td>{{i18n.get('remove')}}</td>
                </tr>
                <tr *ngFor="let item of talents">
                    <td style="min-width: 200px;">{{item.id}}</td>
                    <td style="min-width: 200px;">
                        <ul *ngIf="item.academicQualification && item.academicQualification.length && item.academicQualification.length > 0">
                            <li *ngFor="let o of item.academicQualification">
                                {{i18n.getStringMapWithLocate('map_edu_level', item.locate).get(o)||o}}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul *ngIf="item.professionalQualification">
                            <li *ngFor="let o of textToArray(item.professionalQualification)">
                                {{o}}
                            </li>
                        </ul>
                    </td>
                    <td style="min-width: 200px;">
                        {{getIndustryNameById(item.domain, item.locate)}}
                    </td>
                    <td>

                      <ul>
                        <li *ngFor="let o of item.expertises">
                            {{getSubIndustryNameById(o, item.locate)}}
                        </li>
                    </ul>
                    </td>
                    <td>
                        <ul>
                            <li *ngFor="let o of textToArray(item.experience)">
                                {{o}}
                            </li>
                        </ul>
                    </td>
                    <td style="min-width: 120px;">
                        <a class="btn btn-light" (click)="openAddUpdateDialog(item.id)">{{i18n.getStringWithLocate('edit', item.locate)}}</a>
                    </td>
                    <td style="min-width: 120px;">
                        <a (click)="onRemove(item.id)" class="btn btn-light">{{i18n.getStringWithLocate('remove', item.locate)}}</a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
