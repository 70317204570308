<mat-card>
  <mat-card-title>{{ i18n.getStringWithLocate("member", item.locate) }} {{ item.id }}</mat-card-title>
  <mat-card-content>
    <div>
      <div flex>
        <div class="label">
          {{ i18n.getStringWithLocate("suitable_position", item.locate) }}:
        </div>
        <div>
          {{ arrSubIndustriesToOoList(item.subIndustries, item.locate) || item.subIndustries }}
        </div>
      </div>
      <div flex>
        <div class="label">
          {{ i18n.getStringWithLocate("expertise", item.locate) }}:
        </div>
        <div>
          {{ arrSubIndustriesToOoList(item.expertises, item.locate) || item.expertises }}
        </div>
      </div>
    </div>
    <div class="thisSummary" *ngIf="item.summary">
      <div class="label">
        {{ i18n.getStringWithLocate("candidate_summary", item.locate) }}
      </div>
      <ul>
        <li *ngFor="let o of textToArray(item.summary).slice(0, MAX_SUMMARY_DISPLAY)">{{ o }}</li>
      </ul>
    </div>
  </mat-card-content>
  <mat-card-actions class="actions">
    <button class="btn btn-yellow actionItem">
      {{ i18n.getStringWithLocate("view_details", item.locate) }}
    </button>
  </mat-card-actions>
</mat-card>