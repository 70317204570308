<div class="thisBody">
  <h4>Activity Application of {{ activity ? activity.title : thisId }}</h4>
  <div *ngIf="applications == null">
    {{ i18n.get("loading") }}
  </div>
  <div *ngIf="applications && applications.length == 0">
    {{ i18n.get("nothing_to_show") }}
  </div>
  <table border="1" *ngIf="applications && applications.length > 0">
    <tr>
      <td>Application ID</td>
      <td>Create DateTime</td>
      <td>Status</td>
      <td>User Profile</td>
      <td>Name</td>
      <td>Application Submission Time</td>
      <td>Phone Number</td>
      <td>E-Mail</td>
      <td *ngIf="isAdmin">Change Status</td>
      <td *ngIf="isAdmin">HR Remarks</td>
    </tr>
    <tr *ngFor="let item of applications">
      <td>{{ item.id }}</td>
      <td>{{ item && item.createDateTime && item.createDateTime.toDate && item.createDateTime.toDate().toLocaleString? item.createDateTime.toDate().toLocaleString()
        :'' }}</td>
      <td>{{ item.status }}</td>
      <td>
        <span class="btn btn-light" *ngIf="item.user_id" (click)="toUserProfile(item.user_id)">
          View Profile
        </span>

        <span *ngIf="!item.user_id"> x </span>
      </td>
      <td>{{ item.name }}</td>
      <td>{{ item.createDateTime | dateTimeText }}</td>
      <td>{{ item.phoneNumber }}</td>
      <td>{{ item.email }}</td>
      <td *ngIf="isAdmin">
        <span *ngIf="item.status == 'pending'">
          <a class="btn btn-light" (click)="changeStatus(item.id, 'done')">
            Mark Done
          </a>
        </span>

        <span *ngIf="item.status == 'done'">
          <a class="btn btn-light" (click)="changeStatus(item.id, 'pending')">
            Mark Pending
          </a>
        </span>
      </td>
      <td *ngIf="isAdmin">
        <textarea (change)="onTextareaChange(item.id, $event.target.value)">{{
          hrmarks.get(item.id)
        }}</textarea>
        <button class="btn btn-primary" (click)="onTextareaSubmit(item.id)">
          Update
        </button>
      </td>
    </tr>
  </table>
</div>