import { SimpleYesConfirmDialogComponent } from './../universal-dialog/simple-yes-confirm-dialog/simple-yes-confirm-dialog.component';

import { JwtHelperService } from '@auth0/angular-jwt';
import { firestore } from 'firebase/app';
// import 'firebase/firestore';

import { i18n_locate } from '../i18n/i18n_const';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SimpleMessageDialogComponent } from '../universal-dialog/simple-message-dialog/simple-message-dialog.component';
import { ActionsDialogComponent, ActionsDialogActionItem } from 'src/app/universal-dialog/actions-dialog/actions-dialog.component';
import { IndustryCatItem } from './IndustryCatItem';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { Subscription } from 'rxjs';
// import { environment } from 'src/environments/environment';

import getVideoId from 'get-video-id';
export enum CommonObjects {
}
export interface SelectOptionData {
  key: string,
  name: string;
}

export interface ImageSlide {
  id: string,
  imageURL: string,
  buttonText: string,
  buttonURL: string,
  title: string,
  type: enumImagesSildeType,
  visible: boolean,
  content: string;
}
export enum enumImagesSildeType {
  homepage = 'homepage',
  ca_homepage = 'ca_homepage',
  sport_intro = "sport_intro",
  ca_booking = "ca_booking",
  ole_intro = "ole_intro",
}

export interface CaImageSlide {
  id: string,
  imageURL: string,
  topText: string,
  midTitle: string,
  bottomText: string,
  visible:boolean;
}
export interface AdminUserProfileRemarks {
  id: string,
  uid: string,
  createdTime: firestore.Timestamp | Date | string | '',
  date: firestore.Timestamp | Date | string | '',
  subject: string,
  message: string;
}
export enum dbKeys {
  user_info = 'user_info',
  company_info = 'company_info',
  job_ads = 'job_ads',
  mainpage_slider = 'mainpage_slider',
  job_application = "job_application",
  job_application_hrmarks = "job_application_hrmarks",
  global_ads_banner = "golbal_ads_banner",
  admin_user_remarks = "admin_user_remarks",
  mainpage_bottom_items = "mainpage_bottom_items",
  map_users_records = "map_users_records",
  search_record_logs = "search_record_logs",
  job_matching_items = "job_matching_items",
  the_activities = "the_activities",
  ca_the_activities = "ca_the_activities",
  activity_application = "activity_application",
  ca_activity_application = "ca_activity_application",
  activity_application_hrmarks = "activity_application_hrmarks",
  ca_activity_application_hrmarks = "ca_activity_application_hrmarks",
  cache_list_admins = "cache_list_admins",
  cache_list_company = "cache_list_company",
  blog_articles = "blog_articles",
  ca_blog_articles = "ca_blog_articles",
  sport_revisions = "sport_revisions",
  featured_articles = "featured_articles",
  the_advisors = "the_advisors",
  advisor_appointments = "advisor_appointments",
  map_industry_cats = "map_industry_cats",
  comapny_permissions = "comapny_permissions",
  admin_permissions = 'admin_permissions',
  content = 'content',
  faq = "faq",
  faqTags = "faqTags",
  logging = "logging",
  logging_report = "logging_report",
  whatsapp_templates = "whatsapp_templates",
  whatsapp_auto_messages = "whatsapp_auto_messages",
  sport_rewines = "sport_rewines",
  sport_articles = "sport_articles",
  sport_courses ="sport_courses",
  sport_course_applications ="sport_course_applications",
  blog_article_read_counts="blog_article_read_counts",
  vol_eve_activities='vol_eve_activities',
}
export enum storageKeys {
  article_images = "article_images",
  tmp = "tmp"
}
export enum AdsBannerType {
  w300h250 = '300x250'
}
export interface UserRecordInMap {
  uid: string,
  displayName: string,
  phoneNumber: string,
  email: string,
  disabled: boolean,
  educationLevel: string,
  expectedSalary: number,
  fullname: string,
  jobTitle: string,
  jobType: string,
  privacySetting: string,
  workedFrom: firestore.Timestamp,
  workedTo: firestore.Timestamp,
  registerFor: string,
  workedYears: string,
  cvURL: string,

}
export interface AdsBanner {
  id: string,
  type: AdsBannerType | '';
  imageURL: string,
  clickURL: string,
}

export interface JobSearchHistroy {
  searcherId: string,
  keyword: string,
  industry: string,
  location: string,
  empForm: string,
  createDateTime?: firestore.Timestamp,
}
export function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export interface MemberTalentItem {
  id: string,
  summary: string,
  academicQualification: string[],
  professionalQualification: string,
  domain: string,
  expertises: string[],
  experience: string,
  employment: string[],
  createAt: firebase.firestore.Timestamp | Date,
  updatedAt: firebase.firestore.Timestamp | Date,
  languages: string[],
  subIndustries: string[],
  languages_other: string,
  it_skills: string[],
  it_skills_other: string,
  availability: string,
  locate: i18n_locate,
  certDetails: string,
  diplomaDetails: string,
  hdDetails: string,
  bscDetails: string,
  masterDetails: string,
}
export interface JobApplication {
  id: string,
  user_id: string,
  job_id: string,
  status: 'pending' | 'done';
  name: string,
  phoneNumber: string,
  email: string,
  cvURL: string,
}

export function getVideoFromUrl(url: string) {
  return getVideoId(url)
}
export function getYoutubeCoverFromUrl(url: string) {
  const o = getVideoFromUrl(url)
  if (o?.service == 'youtube') {
    return `https://img.youtube.com/vi/${o.id}/hqdefault.jpg`
  }
  return ''
}
export const SnsUrl = {
  facebook: "https://www.facebook.com/hrcacharity/",
  instagram: "https://www.instagram.com/hrca_career/?igshid=108eeubpyhfat",
  linkedin: "https://www.linkedin.com/in/happy-retired-%E6%A8%82%E6%B4%BB%E6%96%B0%E4%B8%AD%E5%B9%B4-40b377116",
  whatsapp: "https://wa.me/85266865812"
};

export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function textToArray(text: string) {
  if (text && text.includes('\n')) {
    return text.split('\n');
  } else {
    return [text];
  }
}
export function downloadCSV(csv, filename) {
  var csvFile;
  var downloadLink;
  // CSV file
  csvFile = new Blob([csv], { type: "text/csv" });
  // Download link
  downloadLink = document.createElement("a");
  // File name
  downloadLink.download = filename;
  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);
  // Hide download link
  downloadLink.style.display = "none";
  // Add the link to DOM
  document.body.appendChild(downloadLink);
  // Click download link
  downloadLink.click();
}

export function exportTableToCSVAndDownloadIt(tableId: string, filename: string, ingoreColFromLast: number) {

  var csv = [];
  const date = new Date()
  // const filename = `exported+${date.toISOString()}.csv`
  var rows = document.getElementById(tableId).querySelectorAll("tr");
  for (var i = 0; i < rows.length; i++) {
    var row = [], cols = rows[i].querySelectorAll("td");
    for (var j = 0; j < cols.length - ingoreColFromLast; j++) {
      const toAddText = `"${cols[j].innerText.replace(/\"/, `''`)}"`;

      row.push(toAddText);
    }
    csv.push(row.join(","));
  }
  // Download CSV file
  downloadCSV(csv.join("\n"), filename);
}
export enum analyticsEventKeys {
  ads_click = "ads_click",
  article_view = "article_view",
  ca_article_view = "ca_article_view",
  activity_view = "activity_view",
  ca_activity_view = "ca_activity_view",
  job_view = "job_view",
  job_matching_view = "job_match_view",
  advisors_view = "advisors_view",
  vol_eve_view="vol_eve_view",
}
export function fillOnLeft(num: number, fillingString: string, length: number): string {
  const s = num.toString();
  if (s.length >= length) {
    return s;
  } else {
    let toFillString = num.toString();
    while (toFillString.length < length) {
      toFillString = fillingString + toFillString;
    }
    return toFillString;
  }
}
export const jwtHelper = new JwtHelperService();
export const registerButtonGlobalId = `registerButtonGlobalId_${makeid(8)}`;
export const loginButtonGlobalId = `loginButtonGlobalId${makeid(8)}`;
export const submitCvGlobalId = `submitCvGlobalId${makeid(8)}`;

export function checkIsInCareerAdvisor() {
  return !!location.pathname.startsWith('/ca');
}
export const volunteerKeys='volu_eve'
export function checkIsVlunteerEvent() {
  return !!location.pathname.startsWith('/'+volunteerKeys);
}

export function checkIsInCareerAdvisorAdmin() {
  // return false
  return !!location.pathname.startsWith('/caAdmin');
}



export function checkIsInSportRevision() {
  return !!location.pathname.startsWith('/sport_revision');
}

export function checkIsInSportRevisionAdmin() {
  // return false
  return !!location.pathname.startsWith('/sport_revision_admin');
}

export function openSimpleMessageDialog(dialog: MatDialog, message: string): MatDialogRef<any> {
  if (dialog && message) {
 const d=   dialog.open(SimpleMessageDialogComponent, {
      data: {
        message: message
      }
    });
    return d;
  } else {
    return null;
  }
}

export function openActionsDialog(dialog: MatDialog, message: string, actionItems: ActionsDialogActionItem[]): boolean {
  if (dialog && message) {
    dialog.open(ActionsDialogComponent, {
      data: {
        message,
        actionItems,
      }
    });
    return true;
  } else {
    return false;
  }
}

export function openSimpleYesDialog(dialog: MatDialog, message: string, yesCallback: () => void): boolean {
  if (dialog && message) {
    dialog.open(SimpleYesConfirmDialogComponent, {
      data: {
        message: message,
        callback: yesCallback
      }
    });
    return true;
  } else {
    return false;
  }
}
export function getIndustryNameByCurrentLocate(industry: IndustryCatItem, locate: i18n_locate) {
  if (!industry) {
    return '';
  }
  if (locate) {

    if (locate == i18n_locate.hant) {
      return industry.hant_name || "";
    } else {
      return industry.en_name || "";
    }
  } else {

    if (this.i18n.currentLocate == i18n_locate.hant) {
      return industry.hant_name || "";
    } else {
      return industry.en_name || "";
    }
  }
}

export interface SimpleImageTextItem {
  image: string,
  text: string;
}
export function getLastdayOfMonth(y, m) {
  return new Date(y, m + 1, 0).getDate();
}
export enum cloudFunctionsKeys {
  getAllCompanies = 'getAllCompanies1',
  approveCompany = 'approveCompany1',
  banCompanyAccount = 'banCompanyAccount1',
  registerCompanyAccount = 'registerCompanyAccount1',

  addNewAdmin = 'addNewAdmin1',
  banAdminAccount = 'banAdminAccount1',
  checkIsInitAdmin = 'checkIsInitAdmin1',

  confirmAdvisorAppointment = 'confirmAdvisorAppointment1',

  removeAnonymousAccount = 'removeAnonymousAccount1',

  handleAdvisorBookingPayment = 'handleAdvisorBookingPayment1',
  handleDonationsToStripe = 'handleDonationsToStripe1',
  sendNewslatterMail = 'sendNewslatterMail1',
  sendContactUsMail = 'sendContactUsMail1',

  updateAllJobApplicationCount = 'updateAllJobApplicationCount1',
  updateAllAdvisorAppointmentCount = 'updateAllAdvisorAppointmentCount1',
}

const allowCvFileType = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  'image/png',
  'image/jpeg',
];

export function isCvFileTypeAllowed(fileType) {
  return allowCvFileType.includes(fileType);
}

const prefixCa = 'ca';
export function toCaIntro(router) {
  const t = [prefixCa];
  // if (checkIsInCareerAdvisor()) {
  router.navigate(t);
  // } else {
  // if (environment.production) {
  // open("https://www.career-advisor.network/")
  // } else {
  // open('/' + t.join('/'));
  // }
  // }
}

export function unsubscribeAll(subs: Subscription[]) {
  if (subs && subs.length > 0) {
    for (let s of subs) {
      try {
        s.unsubscribe()
      } catch (e) { }
    }
  }
}