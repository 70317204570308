import { Component, OnInit, Inject } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { TheActivity } from 'src/app/class/the-activity';
import { AngularFireAuth } from '@angular/fire/auth';
import { makeid, dbKeys } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { jwtHelper, checkIsInCareerAdvisor } from '../../../../class/common-objects.enum';
import { CKEditorUploadAdapter, CKEFontColors, CKEDefaultToolbar } from '../../../../class/CKEditorUploadCommonAdapter';
import { i18n_locate } from '../../../../i18n/i18n_const';
// import * as ClassicEditor from 'ckeditor5-build-classic-with-font';
import * as ClassicEditor from 'src/ckeditor/ckeditor';

@Component({
  selector: 'app-add-update-my-activity-dialog',
  templateUrl: './add-update-my-activity-dialog.component.html',
  styleUrls: ['./add-update-my-activity-dialog.component.less']
})
export class AddUpdateMyActivityDialogComponent implements OnInit {

  get keyNameCollection() {
  if(this.checkInVolunteerEditing()){
    return dbKeys.vol_eve_activities
  } else  if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_the_activities
    } else {
      return dbKeys.the_activities
    }

  }
  allowFileType = ["image/png", "image/jpeg", "image/jpg"]
  allowFileSize = 10 * 1024 * 1024
  percentage: Observable<number>;
  keyUploadChooseId = `keyUploadChooseId_${makeid(9)}`
  private key_storage_uploaded_logo = 'company_uploaded_logo'
  i18n = new I18n()
  formId = `this_add_update_my_activity_${makeid(11)}`
  initId = ''
  uploadImageContainerId = `uploadImageContainerId_${makeid(12)}`

  public ckeditor;

  inputPhotoURL = ''
  currentUser: firebase.User = null

  // Main task 
  task: AngularFireUploadTask;

  snapshot: Observable<any>;

  // Download URL
  get downloadURL() {
    return this.activity.coverURL;
  }
  set downloadURL(v: string) {
    this.activity.coverURL = v
  }
  downloadFilename: string

  // State for dropzone CSS toggling
  isHovering: boolean;
  isRegBtnLoading = false;
  TheUploadAdapterPlugin(eventData) {
    console.log(JSON.stringify(Array.from(eventData.ui.componentFactory.names())))
    console.log((Array.from(eventData.ui.componentFactory.names())))
    console.log('TheUploadAdapterPlugin called');
    const s = this
    console.log(JSON.stringify(eventData.config.toolbar))
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log(btoa(loader.file));
      return new CKEditorUploadAdapter(loader, s.storage);
    };
  }
  ckeConfig = {
    language: this.froalaLanguage,
    uiColor: '#AADC6E',
    fontColor: CKEFontColors,
    fontBackgroundColor: CKEFontColors,

    toolbar: CKEDefaultToolbar
  }

  get froalaLanguage() {
    if (this.i18n.currentLocate == i18n_locate.en) {
      return 'en'
    } else {
      return 'zh'
    }
  }
  get isUpdate() {
    if (this.initId) {
      return true
    } else {
      return false
    }
  }
  get thisTitle() {
    if (this.isUpdate) {
      return `${this.i18n.get('edit')} ${this.initId}`
    } else {
      return this.i18n.get('add_activity_title')
    }
  }

  checkInVolunteerEditing() {
    return location.pathname.includes('myVoluEveActivities') || location.pathname.includes('volu_eve')
  }
  get categories(): Map<string, string> {
    if (this.checkInVolunteerEditing()) {
      return this.i18n.getStringMap('map_vol_eve_activity_categories')
    } else {

      return this.i18n.getStringMap('map_activity_categories')
    }
  }
  get locations(): Map<string, string> {
    return this.i18n.getStringMap('map_job_location')
  }
  get pickerMode() {
    const width = outerWidth | window.outerWidth | 0
    if (width >= 900) {
      return 'popup'
    } else {
      return 'dialog'
    }
  }
  activity: TheActivity = {
    id: '',
    creator_uid: this.afAuth.auth.currentUser.uid,
    title: '',
    status: 'pending',
    category: '',
    description: '',
    location: '',
    visible: false,
    address: '',
    price: '',
    startAt: '',
    endAt: '',
    deadline: '',
    createdAt: '',
    updatedAt: '',
    speakers: '',
    coverURL: '',
    isFeatured: false,
    quota: 0,
    priority: 0,

  }
  constructor(
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<AddUpdateMyActivityDialogComponent>,
    public storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {

    this.ckeditor = ClassicEditor;
    if (data && data.id) {
      this.initId = data.id
      this.tryInitForUpdateMethod(data.id)
    }
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(auth => {
      if (auth && auth.uid) {
        this.currentUser = auth
      } else {
        this.currentUser = null
      }
    })
  }
  async tryInitForUpdateMethod(id: string) {
    console.log(id)
    if (this.isUpdate) {
      const doc = await this.firestore.collection(this.keyNameCollection).doc(id).get().toPromise()
      console.log(doc.ref.path)
      if (doc && doc.exists) {
        this.activity = <TheActivity>doc.data()
        this.activity.createdAt = (doc.data().createdAt as firebase.firestore.Timestamp).toDate()
        this.activity.updatedAt = (doc.data().updatedAt as firebase.firestore.Timestamp).toDate()
        this.activity.startAt = (doc.data().startAt as firebase.firestore.Timestamp).toDate()
        this.activity.endAt = (doc.data().endAt as firebase.firestore.Timestamp).toDate()
        this.activity.deadline = (doc.data().deadline as firebase.firestore.Timestamp).toDate()

      } else {
        alert('not exist\n文件不存在')
        this.dialogRef.close()
      }
    }
  }
  addBoldText(color: string) {
    let addText = prompt("請輸入文字\nPlease enter text.");
    if (addText) {
      let toAddText = ''
      if (color) {
        toAddText = `<b style="color: ${color};">${addText}</b>`
      } else {
        toAddText = `<b>${addText}</b>`
      }
      this.activity.description += toAddText
    }
  }
  addColorText() {
    let color = prompt("請輸入色碼\nPlease enter color hex code.\nCommons|常用：\nred, gree, blue, black, white, cyan, gray");
    let addText = prompt("請輸入文字\nPlease enter text.");
    let toAddText = ''
    toAddText = `<span style="color: ${color};">${addText}</span>`
    this.activity.description += toAddText


  }
  checkUploadCoverValidity() {
    const r = <HTMLInputElement>document.getElementById(this.keyUploadChooseId)
    if (this.activity.coverURL) {
      r.required = false
      return true
    } else {
      r.required = true
      return r.reportValidity()
    }
  }
  onSubmit() {
    const form = <HTMLFormElement>document.getElementById(this.formId)
    if (form.reportValidity() && this.checkUploadCoverValidity()) {
      const startAt: Date | '' = this.activity.startAt
      const endAt: Date | '' = this.activity.endAt
      const deadline: Date | '' = this.activity.deadline
      const currentDate: Date = new Date()

      if (!startAt || !endAt || !deadline) {
        alert(this.i18n.get('invalid_start_end_or_deadline'))
      } else if (startAt.getTime() >= endAt.getTime()) {
        alert(this.i18n.get('msg_invalid_start_date_and_or_end_date'))
      } else if (deadline.getTime() > endAt.getTime()) {
        alert(this.i18n.get('msg_deadline_is_later_than_start_date'))
      } else if (endAt.getTime() < currentDate.getTime()) {
        alert(this.i18n.get('msg_start_date_or_end_date_on_pass'))
      } else if (deadline.getTime() < currentDate.getTime()) {
        alert(this.i18n.get('invalid_start_end_or_deadline'))
      } else {
        // success.
        if (this.isUpdate) {
          this.onUpdate()
        } else {
          this.onAdd()
        }
      }
    }
  }
  async onAdd() {
    try {
      this.activity.createdAt = new Date()
      this.activity.updatedAt = new Date()
      const ref = await this.firestore.collection(this.keyNameCollection).add(this.activity)
      ref.update({ id: ref.id })
      alert(this.i18n.get('notify_msg_created_pending_approval'))
      this.dialogRef.close(true)
    } catch (e) {
      console.error(e)
      alert(`ERROR|錯誤：${e.message}`)
    }
  }
  async onUpdate() {
    if (!this.initId) return
    try {
      console.log('a')
      this.activity.updatedAt = new Date()
      let token = await this.afAuth.auth.currentUser.getIdToken(true)
      //console.log(token)
      console.log('b')
      let dToken = jwtHelper.decodeToken(token)
      if (!dToken.isAdmin) this.activity.status = 'pending'
      const ref = await this.firestore
        .collection(this.keyNameCollection)
        .doc(this.initId)
        .update(this.activity)
      alert(this.i18n.get('notify_msg_created_pending_approval'))
      this.dialogRef.close(true)
    } catch (e) {
      console.error(e)
      alert(`ERROR|錯誤：${e.message}`)
    }
  }
  stringToDate(str: string): Date | '' {
    if (str) {
      return new Date(str)
    } else {
      return ''
    }
  }
  dateToString(d: Date | ''): string {
    if (d) {
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}, ${d.getHours()}:${d.getMinutes()}`
    }
  }
  clickUploader() {
    const d = <HTMLInputElement>document.getElementById(this.uploadImageContainerId)
    d.click()
  }
  public uploadFile(event: any): void {
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        alert(this.i18n.get('err_msg_file_too_large'))
        return
      } else if (!this.allowFileType.includes(file.type)) {
        alert(this.i18n.get('err_msg_disallow_file_type'))
        return
      }
      try {
        const path = `${this.key_storage_uploaded_logo}/temp/${(new Date().toISOString())}_${makeid(10)}_${file.name}`;

        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        console.log(path)
        this.percentage = task.percentageChanges()
        let sub = task
          .snapshotChanges()
          .pipe(catchError(err => {
            alert(`錯誤|ERROR：${err.message}`)
            throw err;
          }))

          .pipe(

            finalize(async () => {
              const fileRef: AngularFireStorageReference = this.storage.ref(
                path
              )
              const dlUrl = await fileRef.getDownloadURL().toPromise()
              if (dlUrl) {
                //const htmlImg = `\n<img class="uploadImg" src="${dlUrl}">`
                //this.activity.description = this.activity.description + htmlImg
                //const uploadContainer = <HTMLInputElement>document.getElementById(this.uploadImageContainerId)
                //uploadContainer.value = ''
                this.activity.coverURL = dlUrl
              }

            }))
          .subscribe((data) => {
            console.log(data)

          })
      } catch (e) {
        alert(`錯誤|ERROR：${e.message}`)
        console.error(e)
      }
    }
  }


  public updateDownloadURL() {
    let path = this.inputPhotoURL
    console.log(path)
    if (!path) return
    const fileRef: AngularFireStorageReference = this.storage.ref(
      path
    )
    let sub = fileRef.getDownloadURL().subscribe(downloadURL => {
      //this.userInfo.cvURL = downloadURL
      this.downloadURL = downloadURL
      console.log(path)
      //console.log(this.userInfo.cvURL)
      console.log(downloadURL)
      fileRef.getMetadata().subscribe(d => {
        //console.log(d)
        let p = d.fullPath
        let tIndex = p.lastIndexOf('/') + 1
        if (tIndex >= 0) this.downloadFilename = p.substring(tIndex)
      })
      sub.unsubscribe()
    });

  }
  closeDialog() {
    this.dialogRef.close()
  }

}
