import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ImageSlide, dbKeys, enumImagesSildeType } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-main-page-slider',
  templateUrl: './main-page-slider.component.html',
  styleUrls: ['./main-page-slider.component.less']
})
export class MainPageSliderComponent implements OnInit, OnChanges {

  constructor(
    public firestore: AngularFirestore,
    public storage: AngularFireStorage
  ) { }

  @Input() pageType: enumImagesSildeType = enumImagesSildeType.homepage
  ngOnInit() {
    // this.updateImages()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updateImages()
  }
  get randomImage() { return `https://picsum.photos/500/300?random&t=${Math.random()}` }
  get randomSqureImage() { return `https://picsum.photos/500/500?random&t=${Math.random()}` }
  images: Array<ImageSlide> = []
  async updateImages() {
    let result = await this.firestore.collection(dbKeys.mainpage_slider,
      ref => ref.where('type', '==', this.pageType)
      .where('visible','==',true)
    )
      .get().toPromise()
    if (result && result.docs) {

      this.images = []
      console.log('all sliders', result.docs.length)
      for (let doc of result.docs) {
        if (doc && doc.exists && doc.data) {
          let d: ImageSlide = <ImageSlide>doc.data()
          d.imageURL = await this.storage.ref(d.imageURL).getDownloadURL().toPromise()
          if (!d.id) {
            d.id = doc.id
          }
          this.images.push(d)
          console.log('all sliders', this.images)
        }
      }
    }
  }

  navigateTo(item: ImageSlide) {
    if (item.buttonURL) {

      window.open(item.buttonURL, '_blank')
    }
  }

}
