import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatSlideToggleChange, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { IFaqItem } from 'src/app/class/IFaqItem';
import { I18n } from 'src/app/i18n/i18n';
import { AddChangeQnaDialogComponent } from './add-change-qna-dialog/add-change-qna-dialog.component';

@Component({
  selector: 'app-manage-qna',
  templateUrl: './manage-qna.component.html',
  styleUrls: ['./manage-qna.component.less']
})
export class ManageQnaComponent implements OnInit {
  i18n = new I18n()
  faqItems: IFaqItem[] = null
  constructor(
    private afFirestore: AngularFirestore,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.restartFetchFaq()
  }

  openAddChangeDialog(id?: string,) {
    this.dialog.open(AddChangeQnaDialogComponent, {
      data: {
        id: id || null
      }
    }).afterClosed().subscribe(v => {

    })
  }
  sub0FetchFaq: Subscription
  restartFetchFaq() {
    if (this.sub0FetchFaq) {
      this.sub0FetchFaq.unsubscribe()
    }
    this.sub0FetchFaq = this.afFirestore.collection<IFaqItem>(dbKeys.faq, ref=>ref.orderBy('updateDateTime','desc'))
      .valueChanges().subscribe(v => {
        this.faqItems = v || [];
      })
  }


  changeVisible(id: string, newStatus: MatSlideToggleChange,) {
    console.log(id, !!newStatus.checked)
    this.afFirestore.collection(dbKeys.faq)
      .doc(id)
      .update({
        visible: !!newStatus.checked
      }).then(v => {
        this.snackbar.open(this.i18n.translate.action_success)
      }).catch(e => {
        console.error("changeVisible", e)
        this.snackbar.open(this.i18n.translate.action_failed)
      })
  }

}
