<div class="card">
    <h5>
        <span>{{i18n.get('admin_job_application_list')}}</span>
        <a class="btn btn-light" (click)="reloadTable()">{{i18n.get('reload')}}</a>
    </h5>
    <div>
        <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
            {{notifyMessage}}
        </div>
    </div>
    <div class="topFilter" style="display: none;">
        <mat-form-field>
            <b>{{i18n.get('company_filtering')}}</b>
            <mat-select #this_list_job_company_info_ab010 required [(value)]="companyInfoValue"
                (selectionChange)="onCompanyFilterChange(this_list_job_company_info_ab010.value)">
                <mat-option value="all">All</mat-option>
                <mat-option [value]="item.job.id" *ngFor="let item of jobs">
                    {{item.job.title}}({{item.companyInfo.name}})</mat-option>

            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="arrApplications == null">
        {{i18n.get('loading')}}
    </div>
    <div *ngIf="arrApplications!= null && arrApplications.length <= 0">
        {{i18n.get('nothing_to_show')}}
    </div>
    <div class="scrolling-wrapper">
        <div *ngIf="arrApplications">
            <table border="1" matSort >
                <tr>
                    <th mat-sort-header="title">{{i18n.get('unique_id')}}</th>
                    <th mat-sort-header="uid">{{i18n.get('user_id')}}</th>
                    <th mat-sort-header="name">{{i18n.get('placeholder_fullname')}}</th>
                    <th mat-sort-header="phone">{{i18n.get('placeholder_phone_number')}}</th>
                    <th mat-sort-header="email">{{i18n.get('placeholder_email')}}</th>
                    <th mat-sort-header="status">{{i18n.get('job_table_status')}}</th>
                    <th mat-sort-header="content">{{i18n.get('job_table_title')}}</th>
                    <th mat-sort-header="Image">CV</th>
                    <th>{{i18n.get('admin_management')}}</th>
                    <th>HRMarks</th>
                </tr>
                <tr *ngIf="resultCount <= 0">
                    <td colspan="10">
                        {{i18n.get('nothing_to_show')}}
                    </td>
                </tr>
                <ng-container *ngFor="let o of arrApplications; index as i">
                    <tr *ngIf="o.display">
                        <td>{{o.application.id}}</td>
                        <td>
                            <a class="btn btn-light" (click)="openUserProfile(o.application.user_id)" *ngIf="o.application.user_id">
                                {{i18n.get('view')}}
                            </a>
                            <span *ngIf="!o.application.user_id">x</span>
                        </td>
                        <td>{{o.application.name}}</td>
                        <td>{{o.application.phoneNumber}}</td>
                        <td>{{o.application.email}}</td>
                        <td>{{o.application.status}}</td>
                        <td><a [href]="'/jobs/'+o.job.id" target="_blank">{{o.job.title}} </a></td>

                        <td>
                            <a [href]="o.application.cvURL" target="_blank" *ngIf="o.application.cvURL">View</a>
                        </td>
                        <td>
                            <button class="btn btn-primary" *ngIf="o.application.status=='pending'"
                                (click)="changeStatus(i, o.application.id, 'done')">
                                {{i18n.get('set_to_done')}}
                            </button>
                            <button class="btn btn-light" *ngIf="o.application.status=='done'"
                                (click)="changeStatus(i, o.application.id, 'pending')">
                                {{i18n.get('set_to_pending')}}
                            </button>
                        </td>
                        <td>
                            <textarea [(ngModel)]="o.hrmarks"></textarea>
                            <button class="btn btn-primary btn-sm"
                                (click)="updateHrremarks(o.application.id, o.hrmarks)">{{i18n.get('apply')}}</button>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</div>