import { fillOnLeft } from 'src/app/class/common-objects.enum';
import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, checkIsInCareerAdvisorAdmin, openSimpleMessageDialog } from '../../../class/common-objects.enum';
import { TheArticle } from '../../../class/the-article';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-list-articles',
  templateUrl: './list-articles.component.html',
  styleUrls: ['./list-articles.component.less']
})
export class ListArticlesComponent implements OnInit {
  i18n = new I18n();
  arrArticles: Array<TheArticle> = null;
  constructor(
    public router: Router,
    public firestore: AngularFirestore,
    public dialog: MatDialog
  ) { }
  ngOnInit() {
    this.firestore.collection(this.keyFirestoreCollection, (ref => ref.orderBy('createAt', 'desc'))).valueChanges().subscribe(arr => {

      this.arrArticles = [];
      arr.forEach(t => {

        const r: any = t;
        const article: TheArticle = <TheArticle>r;
        article.updateAt = (r.updateAt as firebase.firestore.Timestamp).toDate();
        article.createAt = (r.createAt as firebase.firestore.Timestamp).toDate();
        this.arrArticles.push(article);
        console.log(this.arrArticles);
      });
    });
  }
  get adminPrefixNav() {
    if (checkIsInCareerAdvisorAdmin()) {
      return 'caAdmin';
    } else {
      return 'admin';
    }
  }
  get singleArticlePrefix() {
    if (checkIsInCareerAdvisorAdmin()) {
      return 'ca/article';
    } else {
      const url = this.router.url
      if (url.startsWith('/admin/sportRewine')) {
        // it is sport rewine
        return 'sportRewine'
      } else if (url.startsWith('/admin/sportRevisions')) {
        return 'sportRevision'
      } else if (url.startsWith('/admin/sportArticles')) {
        return 'sportArticle'
      } else {
        return 'article';
      }
    }
  }

  get thisPageTitle(){
    
    const url = this.router.url
    if (url.startsWith('/admin/sportRewine')) {
      // it is sport rewine
      return this.i18n.translate.sport_rewines
    } else if (url.startsWith('/admin/sportRevisions')) {
      return this.i18n.translate.sport_revistions
    } else if (url.startsWith('/admin/sportArticles')) {
      return this.i18n.translate.sport_articles
    }

    return this.i18n.translate.menu_list_articles
  }
  get keyFirestoreCollection() {
    if (checkIsInCareerAdvisorAdmin()) {
      return dbKeys.ca_blog_articles;
    } else {
      const url = this.router.url
      if (url.startsWith('/admin/sportRewine')) {
        // it is sport rewine
        return dbKeys.sport_rewines
      } else if (url.startsWith('/admin/sportRevisions')) {
        return dbKeys.sport_revisions
      } else if (url.startsWith('/admin/sportArticles')) {
        return dbKeys.sport_articles
      } else {
        return dbKeys.blog_articles;
      }
    }
  }
  get secondRoutePrefix() {
    const url = this.router.url
    if (url.startsWith('/admin/sportRewine')) {
      // it is sport rewine
      return 'sportRewines'
    } else if (url.startsWith('/admin/sportRevisions')) {
      return 'sportRevisions'
    } else if (url.startsWith('/admin/sportArticles')) {
      return 'sportArticles'
    } else {
      return 'listArticles'
    }
  }
  toAddPage() {
    this.secondRoutePrefix
    this.router.navigate([this.adminPrefixNav, this.secondRoutePrefix, 'add']);
  }
  toEdit(id: string) {
    this.router.navigate([this.adminPrefixNav, this.secondRoutePrefix, 'update', id]);
  }
  dateToDisplayString(date: Date) {
    return `${date.getFullYear()}-${fillOnLeft(date.getMonth() + 1, '0', 2)}-${fillOnLeft(date.getDate(), '0', 2)} ${fillOnLeft(date.getHours(), '0', 2)}:${fillOnLeft(date.getMinutes(), '0', 2)}:${fillOnLeft(date.getSeconds(), '0', 2)}`;
  }
  async setStatus(index: number, id: string, newStatus: 'active' | 'deactive') {
    if (id && newStatus) {
      try {
        await this.firestore.collection(this.keyFirestoreCollection).doc(id).update({
          status: newStatus
        });
        this.arrArticles[index].status = newStatus;
      } catch (e) {
        console.error(e);
        openSimpleMessageDialog(this.dialog, `操作失敗！\nAction failed.\nCODE: ${e.message}`);
      }
    }
  }
}
