import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { IWhatsappAutoResponse } from 'src/app/class/IWhatsappAutoResponse';
import { I18n } from 'src/app/i18n/i18n';
import { AddEditWhatsappResponseDialogComponent } from './add-edit-whatsapp-response-dialog/add-edit-whatsapp-response-dialog.component';

@Component({
  selector: 'app-manage-whatsapp-auto-response',
  templateUrl: './manage-whatsapp-auto-response.component.html',
  styleUrls: ['./manage-whatsapp-auto-response.component.less']
})
export class ManageWhatsappAutoResponseComponent implements OnInit {
  i18n = new I18n()
  autoResponses: IWhatsappAutoResponse[] = null
  isSwitchLoading = false
  constructor(
    private dialog: MatDialog,
    private afFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.restartAutoResponse()
  }


  sub0GetAutoResponses: Subscription
  restartAutoResponse() {
    if (this.sub0GetAutoResponses) {
      try {
        this.sub0GetAutoResponses.unsubscribe()
      } catch (e) {
        console.error(e)
      }
    }

    // start now 
    this.sub0GetAutoResponses = this.afFirestore.collection<IWhatsappAutoResponse>(dbKeys.whatsapp_auto_messages, ref =>
      ref
        .orderBy('createDateTime', 'desc'))
      .valueChanges().subscribe(v => {
        console.log('whatsapp_auto_messages json', v)
        this.autoResponses = v || []
      })


  }
  openAddChangeDialog(item?: IWhatsappAutoResponse) {
    const dialogRef = this.dialog.open(AddEditWhatsappResponseDialogComponent, {
      data: {
        item: item || null
      }
    })
    dialogRef.afterClosed().subscribe(v => {
    })
  }
  onActiveChange(item: IWhatsappAutoResponse) {
    this.isSwitchLoading = true;
    this.afFirestore.collection(dbKeys.whatsapp_auto_messages)
      .doc(item.id)
      .set({
        isActive: !item.isActive
      }, {
        merge: true
      })
      .then(v => {
        this.isSwitchLoading = false;
        this.snackbar.open(this.i18n.translate.action_success)
      }).catch(e => {
        console.error('onActiveChange', e)
        this.isSwitchLoading = false;
        this.snackbar.open(this.i18n.translate.action_failed + ':' + this.i18n.getFirebaseErrorMessage(e.code))
      })
  }
}
