<div class="thisBody">
    <div class="thisTitle">
        <span>{{i18n.get('my_activities_action')}}</span>
        <button class="btn btn-light" (click)="addDialog()">{{i18n.get('add')}}</button>
    </div>
    <div class="thisContents">
        <div class="content">
            <div *ngIf="!activities && activities==null">
                {{i18n.get('loading')}}
            </div>
            <div *ngIf="activities && activities.length <=0">
                {{i18n.get('nothing_to_show')}}
            </div>
            <table *ngIf="activities && activities.length > 0" border='1'>
                <tr>
                    <td>{{i18n.get('add_activity_placeholder_title')}}</td>
                    <td>{{i18n.get('job_table_status')}}</td>
                    <td>{{i18n.get('add_activity_placeholder_categories')}}</td>
                    <td>{{i18n.get('add_activity_placeholder_location')}}</td>
                    <td>{{i18n.get('add_activity_placeholder_start_at')}}</td>
                    <td>{{i18n.get('add_activity_placeholder_end_at')}}</td>
                    <td>{{i18n.get('add_activity_placeholder_application_deadling')}}</td>
                    <td>{{i18n.get('edit')}}</td>
                    <td>{{i18n.get('apply_status')}}</td>
                    <td>{{i18n.get('job_table_job_preview')}}</td>
                </tr>
                <tr *ngFor="let item of activities">
                    <td>{{item.title}}</td>
                    <td>{{item.status}}</td>
                    <td>{{categories.get(item.category)}}</td>
                    <td>{{locations.get(item.location)}}</td>
                    <td>{{dateToString(item.startAt)}}</td>
                    <td>{{dateToString(item.endAt)}}</td>
                    <td>{{dateToString(item.deadline)}}</td>
                    <td>
                        <a (click)="editActivity(item.id)" class="btn btn-light">{{i18n.get('edit')}}</a>
                    </td>
                    <td><a class="btn btn-link" target="_blank" [href]="prefixListApplication+item.id">{{i18n.get('view')}}</a></td>
                    <td>
                      <a [routerLink]="caPrefixToDirectLink  + item.id">{{i18n.get('view')}}</a>
                    </td>
                  </tr>
            </table>
        </div>
    </div>
</div>
