import { En } from './translates/en';
import { Hant } from './translates/hant';
import { LanguageInterface, AvaliableLocateInterface } from './language-interface';
import { LocalStorageUtils } from '../LocalStorage/local-storage-utils';
import { avaliable_locate, i18n_locate } from './i18n_const'
export class I18n {
    avaliable_locate = avaliable_locate
    private localStorageUtils = new LocalStorageUtils()
    constructor(
        private forceLanguage?: i18n_locate
    ) {

    }
    get locateDetails() {
        return avaliable_locate.filter((d) => {
            return d.key == this.currentLocate
        })[0] || avaliable_locate[0]
    }
    get currentLocate() {
        if (this.forceLanguage)
            return this.forceLanguage;
        else
            return i18n_locate[this.localStorageUtils.currentLocate]
    }
    get(key: string): string {
        return this.getTransate(key) || key
    }

    getNoSpace(key: string): string {
        const s = this.get(key)
        return s.replace(/\r/, '')
    }
    getFirebaseErrorMessage(code: string) {
        let translateMap = this.getStringMap('firebaseError')
        let msg = translateMap.get(code) || ''
        return msg
    }
    getStringWithLocate(key: string, locate0: i18n_locate): String {
        // console.log(key)
        return this.getTransateWithLocate(key, locate0)
    }
    getArray(key: string): Array<string> {
        return this.getTransate(key) || []
    }
    getStringMap(key: string): Map<string, string> {
        return this.getTransate(key) || new Map()
    }

    getStringMapWithLocate(key: string, locate: i18n_locate): Map<string, string> {
        return this.getTransateWithLocate(key, locate) || new Map()
    }

    setCurrentLocateFromString(value: string) {
        console.log('new locale', value)

        this.localStorageUtils.currentLocate = i18n_locate[value] || i18n_locate.en

    }
    set currentLocate(value: i18n_locate) {
        this.localStorageUtils.currentLocate = value
    }
    getTransate(key: string): any {
        let locate = this.getLocateInstance(this.currentLocate)
        return this.getTranslateInternal(locate, key)
    }
    getTransateWithLocate(key: string, locate0: i18n_locate): any {
        let locate = this.getLocateInstance(locate0)
        return this.getTranslateInternal(locate, key)
    }

    getLocateInstance(locate: i18n_locate): LanguageInterface {
        if (locate == i18n_locate.hant) {
            return Hant
        } else {
            return En
        }
    }
    get translate() {
        return this.getLocateInstance(this.currentLocate)
    }
    private getTranslateInternal(locate: LanguageInterface, key: string): string {
        // console.log(locate[key])
        return locate[key]
    }
}
