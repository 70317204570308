<div class="background">
  <div class="container-wrap">
    <div class="main container">
      <div class="row">
        <div class="col-12">
          <div class="talent-search-model-title">
            A HOLISTIC MODEL<br />
            Achieving the Happy-Retired Mission Through a Holistic Approach
          </div>

          <div
            flex="justify-center align-center"
            class="talent-search-model-image"
          >
            <div class="pre" flex-item="grow-1">
              With the support of <br />
              charity funding
            </div>
            <div flex-item="grow-3"><img src="/assets/service-3.png" /></div>
            <div flex-item="grow-1" flex="align-end justify-end">
              <div class="post">
                For a fair and<br />
                reasonable fee
              </div>
            </div>
          </div>
          <div flex="justify-center align-center">
            <div class="talent-search-model-bottom">
              Charity Funding + <br />
              Corporate Support <br />
              1 + 1 > 2
            </div>
          </div>
          <div class="talent-search-model-end">
            Fostering a Culture of Corporate Social Responsibility
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
