<ngb-carousel *ngIf="images">
  <ng-template ngbSlide *ngFor="let item of images">
    <div
      class="picsum-img-wrapper"
      *ngIf="
        item.imageURL &&
          item.buttonURL &&
          !item.title &&
          !item.content &&
          !item.buttonText;
        else normal_div_slider
      "
    >
      <a [href]="item.buttonURL">
        <img
          [src]="item.imageURL"
          class="carousel-image"
          alt="Random first slide"
        />
      </a>
    </div>
    <ng-template #normal_div_slider>
      <div class="picsum-img-wrapper d-none d-lg-block">
        <img
          [src]="item.imageURL"
          class="carousel-image"
          alt="Random first slide"
        />
      </div>
      <div class="picsum-img-wrapper d-block d-lg-none" (click)="navigateTo(item)">
        <img
          [src]="item.imageURL"
          class="carousel-image"
          alt="Random first slide"
        />
      </div>
      <div class="carousel-caption d-none d-lg-block">
        <p>
          <span class="leftRightSpacer">
            <span class="left">
            </span>
            <span class="right">
              <span class="right-content">
                <h3>{{ item.title }}</h3>
                <span class="content">{{ item.content }}</span>
                <br />
                <br />
                <a
                  class="btn btn-light"
                  *ngIf="item.buttonText && item.buttonURL"
                  [href]="item.buttonURL"
                  target="_blank"
                  >{{ item.buttonText }}</a
                >
              </span>
            </span>
          </span>
        </p>
      </div>

      <div class="carousel-caption carousel-caption-mobo d-lg-none  d-block">
        <p>
            <span class="ce">
              <span class="ce-content">
                <h3>{{ item.title }}</h3>
                <span class="content">{{ item.content }}</span>
                <br />
                <!-- <a
                  class="btn btn-light"
                  *ngIf="item.buttonText && item.buttonURL"
                  [href]="item.buttonURL"
                  target="_blank"
                  >{{ item.buttonText }}</a
                > -->
              </span>
            </span>
        </p>
      </div>
    </ng-template>
  </ng-template>
</ngb-carousel>
