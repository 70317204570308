import { i18n_locate } from '../i18n/i18n_const';
export interface TheAdvisorBookingRecord{
    id: string,
    createAt: Date,
    creator_id: string,
    advisor_id: string,
    status: TheAdvisorBookingRecordStatus,
    name: string,
    phone: string,
    email: string,
    expect_datetime: Date,
    remark: string,
    charge_id: string,
    locate: i18n_locate
    format: 'phone_call' | 'face_to_face' | 'zoom_call',

    isConformationSent: boolean,
    isBeforeReminderSent:boolean,
    isAfterReminderSent:boolean,
    cv_url :string,
}
export enum TheAdvisorBookingRecordStatus{
    not_paid="not_paid",
    wait_confirm="wait_confirm",
    confirmed="confirmed",
    refunded="refunded",
}