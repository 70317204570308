import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { TheAdvisorBookingRecord } from 'src/app/class/theAdvisorBooking';
import { IDownloadCsvMask } from '../raw-data-download.component';



@Component({
  selector: 'app-dl-raw-career-advisor-appointment-logs',
  templateUrl: './dl-raw-career-advisor-appointment-logs.component.html',
  styleUrls: ['./dl-raw-career-advisor-appointment-logs.component.less']
})
export class DlRawCareerAdvisorAppointmentLogsComponent implements OnInit {

  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_career_advisor_appointments'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.advisor_appointments)
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
              'appointment_id',
              'advisor_id',
              'status',
              'name',
              'phone_number',
              'email',
              'creator_uid',
              'locate',
              'format',
              'remark',
              'stripe_charge_id',
              'createDateTime',
                            

            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <TheAdvisorBookingRecord>docs.data()
            if (record && record.id) {
              arrOutput.push([
                record?.id||'',
                record?.advisor_id||'',
                record?.status||'',
                record?.name||'',
                record?.phone||'',
                record?.email||'',
                record?.creator_id||'',
                record?.locate||'',
                record?.format||'',
                record?.remark||'',
                record?.charge_id||'',
                (<firestore.Timestamp>(<any>record?.createAt)).toDate()?.toLocaleString()||'',


              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }
}
