<div class="thisBody">
  <div class="thisHeader">
    <span class="closeButton" (click)="closeThisDialog()" style="cursor: pointer;">X</span>
    <span class="thisTitle">{{title}}</span>
  </div>
  <mat-dialog-content>
    <form [(id)]="formId">

      <mat-form-field class="example-full-width">
        <input matInput [placeholder]="i18n.get('unique_id')" [(ngModel)]="talent.id"
          [ngModelOptions]="{standalone: true}" [disabled]="isUpdate" required>
      </mat-form-field>

      <b>*{{i18n.get('leave_unique_id_blank_to_random_id')}}</b>

      <br>
      <br>

      <mat-form-field class="example-full-width">
        <textarea matInput [placeholder]="i18n.get('candidate_summary')" required [(ngModel)]="talent.summary"
          [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{i18n.get('placeholder_job_edu_level')}}</mat-label>
        <mat-select [(ngModel)]="talent.academicQualification" multiple required [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let i of mapAcademicQualification|keyvalue" [value]="i.key">{{i.value}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- edu details start -->
      <mat-form-field class="example-full-width" *ngIf="talent.academicQualification.includes('4certificate')">
        <textarea matInput [placeholder]="i18n.get('details') + '：' + mapAcademicQualification.get('4certificate')"
          [(ngModel)]="talent.certDetails" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field class="example-full-width" *ngIf="talent.academicQualification.includes('5diploma')">
        <textarea matInput [placeholder]="i18n.get('details') + '：' + mapAcademicQualification.get('5diploma')"
          [(ngModel)]="talent.diplomaDetails" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field class="example-full-width" *ngIf="talent.academicQualification.includes('6adgree_hd')">
        <textarea matInput [placeholder]="i18n.get('details') + '：' + mapAcademicQualification.get('6adgree_hd')"
          [(ngModel)]="talent.hdDetails" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field class="example-full-width" *ngIf="talent.academicQualification.includes('7bsc')">
        <textarea matInput [placeholder]="i18n.get('details') + '：' + mapAcademicQualification.get('7bsc')"
          [(ngModel)]="talent.bscDetails" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field class="example-full-width" *ngIf="talent.academicQualification.includes('8over_bsc')">
        <textarea matInput [placeholder]="i18n.get('details') + '：' + mapAcademicQualification.get('8over_bsc')"
          [(ngModel)]="talent.masterDetails" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <!-- edu details end -->
      <mat-form-field class="example-full-width">
        <textarea matInput [placeholder]="i18n.get('professional_qualification')" required
          [(ngModel)]="talent.professionalQualification" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{i18n.get('industry')}}</mat-label>
        <select [(ngModel)]="talent.domain" matNativeControl required [ngModelOptions]="{standalone: true}">
          <option *ngFor="let i of topIndustries" [value]="i.id">{{_getIndustryNameByCurrentLocate(i, talent.locate)}}</option>
        </select>
      </mat-form-field>


      <mat-form-field class="example-full-width">
        <mat-label>{{i18n.get('expertise')}}</mat-label>
        <mat-select [(ngModel)]="talent.expertises" multiple required [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let i of getFilteredSubIndustries(talent.domain)" [value]="i.id">{{_getIndustryNameByCurrentLocate(i, talent.locate)}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <textarea matInput [placeholder]="i18n.get('work_experience')" required [(ngModel)]="talent.experience"
          [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{i18n.get('languages')}}</mat-label>
        <mat-select [(ngModel)]="talent.languages" multiple required [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let i of mapQualLanguages|keyvalue" [value]="i.key">{{i.value}}</mat-option>
          <mat-option value="other">{{i18n.get('other')}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-full-width" *ngIf="shouldDisplayLanguageTextbox">
        <textarea matInput [placeholder]="i18n.get('languages')+'： '+i18n.get('other')" required
          [(ngModel)]="talent.languages_other" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{i18n.get('it_skills')}}</mat-label>
        <mat-select [(ngModel)]="talent.it_skills" multiple required [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let i of mapItSkills|keyvalue" [value]="i.key">{{i.value}}</mat-option>
          <mat-option value="other">{{i18n.getStringWithLocate('other', talent.locate)}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-full-width" *ngIf="shouldDisplayItSkillTextbox">
        <textarea matInput [placeholder]="i18n.get('it_skills')+'： '+i18n.get('other')" required
          [(ngModel)]="talent.it_skills_other" [ngModelOptions]="{standalone: true}" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{i18n.get('placeholder_job_employmentForm')}}</mat-label>
        <mat-select multiple [(ngModel)]="talent.employment" matNativeControl required [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let i of mapEmploymentForm|keyvalue" [value]="i.key">{{i.value}}</mat-option>
        </mat-select>
      </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput [placeholder]="i18n.get('work_availability')" [(ngModel)]="talent.availability" required
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{i18n.get('sub_industry')}}</mat-label>
          <mat-select [(ngModel)]="talent.subIndustries" multiple required [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let i of subIndustries" [value]="i.id">
              {{_getIndustryNameByCurrentLocate(i, talent.locate)}} ({{_getIndustryNameByCurrentLocate(topIndustriesMap.get(i.parent_id), talent.locate)}})
            </mat-option>
          </mat-select>
        </mat-form-field>

      <mat-form-field>
        <mat-label>{{i18n.get('locate_language')}}</mat-label>
        <mat-select [(ngModel)]="talent.locate" required [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let i of i18n.avaliable_locate" [value]="i.key">{{i.longName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <button class="btn btn-info submitButton" (click)="onSubmit()">{{i18n.get('submit')}}</button>
</div>
