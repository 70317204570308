<div class="thisBody">
  <div class="content">
    <h4>{{ i18n.get("menu_ca_list_appoint_advisor") }}</h4>
    <br />
    <h5 *ngIf="!records || !advisors">
      {{ i18n.get("loading") }}
    </h5>
    <h5
      *ngIf="
        (records && records.length == 0) || (advisors && advisors.size <= 0)
      "
    >
      {{ i18n.get("nothing_to_show") }}
    </h5>
    <table
      border="1"
      *ngIf="records && records.length > 0 && advisors && advisors.size > 0"
    >
      <tr>
        <td>{{ i18n.get("placeholder_name") }}</td>
        <td>{{ i18n.get("job_table_status") }}</td>
        <td>{{ i18n.get("supported_appointment_format") }}</td>
        <td>{{ i18n.get("placeholder_phone_number") }}</td>
        <td>{{ i18n.get("placeholder_email") }}</td>
        <td>{{ i18n.get("expect_book_datetime") }}</td>
        <td>{{ i18n.get("cv_url") }}</td>
        <td>{{ i18n.get("remarks") }}</td>
        <td>{{ i18n.get("admin_management") }}</td>
      </tr>
      <tr *ngFor="let record of records">
        <td>{{ record.name }}</td>
        <td>{{ record.status | advisorAppointmentStatus }}</td>
        <td>{{ i18n.get(record.format) }}</td>
        <td>
          <a target="_blank" [href]="'tel:+852' + record.phone"
            >+852-{{ record.phone }}</a
          >
        </td>
        <td>
          <a target="_blank" [href]="'mailto:' + record.email">{{
            record.email
          }}</a>
        </td>
        <td>
          <mat-form-field
            class="full-width"
            [owlDateTimeTrigger]="expect_datetime"
          >
            <input
              matInput
              [owlDateTime]="expect_datetime"
              [disabled]="record.status != 'wait_confirm'"
              [(ngModel)]="record.expect_datetime"
              (ngModelChange)="changeAppointmentDate($event, record.id)"
              [ngModelOptions]="{ standalone: true }"
            />
            <owl-date-time pickerMode="popup" #expect_datetime></owl-date-time>
          </mat-form-field>
        </td>
        <!-- <td>{{record.expect_datetime | dateTimeText}}</td> -->
        <td>
          <a *ngIf="record.cv_url" target="_blank" [href]="record.cv_url">
            View CV
          </a>
        </td>
        <td>
          <mat-form-field class="example-full-width">
            <mat-label>{{ i18n.get("remarks") }}</mat-label>
            <textarea
              matInput
              [placeholder]="i18n.get('remarks')"
              [(ngModel)]="record.remark"
              (ngModelChange)="mapIsRemarkChange.set(record.id, true)"
            ></textarea>
          </mat-form-field>

          <button
            class="btn btn-primary"
            *ngIf="mapIsRemarkChange.get(record.id)"
            (click)="changeRemark(record.id, record.remark)"
          >
            {{ i18n.get("submit") }}
          </button>
          <button
            class="btn btn-light"
            disabled
            *ngIf="!mapIsRemarkChange.get(record.id)"
          >
            {{ i18n.get("submit") }}
          </button>
        </td>
        <td>
          <button
            class="btn btn-light"
            *ngIf="record.status == 'not_paid'"
            (click)="changeStatus(record.id, 'wait_confirm')"
          >
            {{ i18n.get("mark_paid") }}
          </button>
          <button
            class="btn btn-light"
            *ngIf="record.status == 'wait_confirm'"
            (click)="confirmAppointment(record.id)"
          >
            {{ i18n.get("mark_confirmed") }}
          </button>
          <button
            class="btn btn-light"
            *ngIf="record.status == 'confirmed'"
            (click)="changeStatus(record.id, 'pending_refunded')"
          >
            {{ i18n.get("mark_refund") }}
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
