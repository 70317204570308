import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import firebase from 'firebase';
import { Observable } from 'rxjs';
import { dbKeys, isCvFileTypeAllowed, makeid, openSimpleMessageDialog } from 'src/app/class/common-objects.enum';
import { IFaqItem, IFaqItemContent } from 'src/app/class/IFaqItem';
import { I18n } from 'src/app/i18n/i18n';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-change-qna-dialog',
  templateUrl: './add-change-qna-dialog.component.html',
  styleUrls: ['./add-change-qna-dialog.component.less']
})
export class AddChangeQnaDialogComponent implements OnInit {
  @ViewChild('form') formRef: ElementRef<HTMLFormElement>
  i18n = new I18n()
  currentUser: firebase.User = null
  initId = ''
  shouldFilterTags: string[] = []

  filterdTags: Observable<string[]>;

  get lowercaseForkOfTags() {
    console.log('lowercase', this.faqItem.tags.map((v) => v.toLowerCase()))
    return this.faqItem.tags.map((v) => v.toLowerCase())
  }
  @ViewChild('tagInput') fruitInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  newTagControl = new FormControl();
  @ViewChild('coverImageUploadButton') coverImageUploadButton: ElementRef<HTMLInputElement>
  clickCoverUpload() {
    this.coverImageUploadButton?.nativeElement?.click();

  }
  get isEdit() {
    return !!this.initId
  }

  allowFileType = ["image/png", "image/jpeg", "image/jpg"]
  allowFileSize = 10 * 1024 * 1024
  percentage: Observable<number>;
  isCoverUploading = false
  keyUploadChooseId = `keyUploadChooseId_${makeid(9)}`
  private key_storage_uploaded_logo = 'company_uploaded_logo'
  arrContent: string[] = []
  faqItem: IFaqItem = {
    title: '',
    contents: [],
    visible: true,
    coverImage: '',
    tags: [],
  }
  constructor(
    private afAuth: AngularFireAuth,
    private afFirestore: AngularFirestore,
    private afStorage: AngularFireStorage,
    private afFunctions: AngularFireFunctions,
    private dialogRef: MatDialogRef<AddChangeQnaDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.filterdTags = this.newTagControl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.shouldFilterTags.filter(v=>!this.lowercaseForkOfTags.includes(v)))),
    );
    afAuth.authState.subscribe(user => {
      this.currentUser = user?.uid ? user : null
    })
    if (data && data.id) {
      this.initId = data.id
      afFirestore.collection(dbKeys.faq)
        .doc<IFaqItem>(this.initId)
        .get().toPromise()
        .then(v => {
          if (v && v.exists) {
            const data = <IFaqItem>v.data()
            this.faqItem = data;
            if (!this.faqItem.tags) this.faqItem.tags = [];
            this.arrContent = this.faqItem.contents.map((v, i) => v.content)
          } else {
            dialogRef.close()
          }
        })
        .catch(e => {
          console.error(e)
          dialogRef.close()
        })
    }

    afFirestore.collection(dbKeys.faqTags)
      .valueChanges().subscribe(v => {
        if (v && v.length > 0) {
          this.shouldFilterTags = v.map((v: any) => v.name)
        } else {
          this.shouldFilterTags = [];
        }
      })
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log('shouldFilterTags', this.shouldFilterTags)
    const willReturnArray = this.shouldFilterTags.filter(fruit => {
      return fruit.toLowerCase().includes(filterValue)
    });
    const filteredInCurrentArray = willReturnArray.filter(v => {
      console.log(`${v} hav and ${!this.lowercaseForkOfTags.includes(v)}`)

      return !this.lowercaseForkOfTags.includes(v)
    })
    console.log('filterd ' + filterValue + ' in ', this.lowercaseForkOfTags)
    return filteredInCurrentArray;
  }

  ngOnInit() {
    this.addContentLine()
  }

  onSubmit() {
    if (this.formRef && this.formRef.nativeElement && this.formRef.nativeElement.reportValidity()) {
      console.log(this.faqItem)
      console.log('content array', this.arrContent)
      if(!this.faqItem.coverImage){
        
        openSimpleMessageDialog(this.dialog, this.i18n.translate.please_upload_cover_image)
        return;
      }

      for (const [i, x] of this.faqItem.contents.entries()) {
        try {
          const t = this.arrContent[i]
          if (t) {
            this.faqItem.contents[i].content = t;
          }
        } catch (e) { }
      }

      console.log('riped array', this.faqItem)

      // end of copy, do some magic

      if (this.initId) {
        // is edit mode
        this.afFirestore.collection(dbKeys.faq)
          .doc(this.initId)
          .update(this.faqItem)
          .then(v => {
            openSimpleMessageDialog(this.dialog, this.i18n.translate.action_success,)
            this.dialogRef.close()
          }).catch(e => {
            openSimpleMessageDialog(this.dialog, this.i18n.translate.action_failed,)
          })
      } else {
        this.afFirestore.collection(dbKeys.faq)
          .add(this.faqItem)
          .then(v => {
            openSimpleMessageDialog(this.dialog, this.i18n.translate.action_success,)
            this.dialogRef.close()
          }).catch(e => {
            openSimpleMessageDialog(this.dialog, this.i18n.translate.action_failed,)
          })
      }
    }
  }
  updateContentText(i: number, text: string) {
    console.log(i, text)
    this.arrContent[i] = text;
  }

  getContentText(i: number) {
    const text = this.arrContent[i];
    // console.log('text ' + i, text)
    return text || '';
  }
  addContentLine() {
    // console.log('abc', this.faqItem)
    const y = {
      isSelfSide: true,
      content: '123',
    }
    const arr = this.faqItem.contents

    arr.push(y)

    this.faqItem.contents = arr;
    this.arrContent.push(null)

  }
  removeContentItem(item: IFaqItemContent) {
    if (this.faqItem.contents.length > 0) {
      this.faqItem.contents = this.faqItem.contents.filter(e => e != item)
    }
  }

  public async uploadFile(event: any): Promise<void> {
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target;
      const file = target.files[i];
      console.log(file);
      if (file.size > this.allowFileSize) {
        alert(this.i18n.get('err_msg_file_too_large'));
        return;
      } else if (!this.allowFileType.includes(file.type)) {
        alert(this.i18n.get('err_msg_disallow_file_type'));
        return;
      }
      try {
        this.isCoverUploading = true;
        let now = new Date();
        const path = `tmp/${this.currentUser.uid}/${now.toISOString()}_${makeid(18)}_${file.name}`;
        const fileRef: AngularFireStorageReference = this.afStorage.ref(
          path
        );
        const task: AngularFireUploadTask = this.afStorage.upload(
          path,
          file
        );
        this.percentage = task.percentageChanges();
        let result = await task.snapshotChanges().toPromise();
        let dlURL = await fileRef.getDownloadURL().toPromise();
        console.log(result);
        console.log(dlURL);

        this.faqItem.coverImage = dlURL
        this.coverImageUploadButton.nativeElement.value = null

        this.isCoverUploading = false;
      } catch (e) {
        this.isCoverUploading = false;
        openSimpleMessageDialog(this.dialog, e.message);
        console.error(e);
      }
    }
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();


    // Add our fruit
    if ((value || '').trim()) {
      if(!this.faqItem.tags.includes(value.trim())) {
        this.faqItem.tags.push(value.trim());
      }
    }

    // Clear the input value
    event.input.value = '';

    this.newTagControl.setValue(null);
  }

  removeTag(tag: string) {
    this.faqItem.tags = this.faqItem.tags.filter(e => e != tag)
  }

  selectedOptionalTag(event: MatAutocompleteSelectedEvent): void {
    if (!this.faqItem.tags.includes(event.option.viewValue)) {
      this.faqItem.tags.push(event.option.viewValue);
    }
    this.fruitInput.nativeElement.value = '';
    this.newTagControl.setValue(null);
  }


}
