import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { toCaIntro } from 'src/app/class/common-objects.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-page-we-provide',
  templateUrl: './main-page-we-provide.component.html',
  styleUrls: ['./main-page-we-provide.component.less']
})
export class MainPageWeProvideComponent implements OnInit {
  i18n = new I18n()

  constructor(private router: Router) { }

  ngOnInit() {
  }

  toCaIntro() {
    toCaIntro(this.router);
  }
}
