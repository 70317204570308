import { Component, OnInit } from '@angular/core';
import { enumImagesSildeType } from 'src/app/class/common-objects.enum';

@Component({
  selector: 'app-ca-ole-intro',
  templateUrl: './ca-ole-intro.component.html',
  styleUrls: ['./ca-ole-intro.component.less']
})
export class CaOleIntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  get silderPageType(){
    return enumImagesSildeType.ole_intro
  }

}
