import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { enumWhatsappTemplates } from 'src/app/class/WhatsappTemplatesEnumKeys';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-edit-whatsapp-templates-dialog',
  templateUrl: './edit-whatsapp-templates-dialog.component.html',
  styleUrls: ['./edit-whatsapp-templates-dialog.component.less']
})
export class EditWhatsappTemplatesDialogComponent implements OnInit {
  get enumWhatsappTemplates(){
    return enumWhatsappTemplates
  }
  thisId :enumWhatsappTemplates
  content = ''
  i18n = new I18n()
  isLoading = false;
  @ViewChild('form') thisFormRef : ElementRef<HTMLFormElement>
  get title(){
    return `${this.i18n.translate.edit} ${this.i18n.translate.map_whatsapp_templates_key_translates.get(this.thisId)}`
  }
  get textareaWidth(){
    return this.content.split('\n').length || 1;
  }
  constructor(
    private dialogRef:MatDialogRef<EditWhatsappTemplatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private afFirestore:AngularFirestore,
    private snackbar:MatSnackBar,
  ) {
    if(data?.id){
      this.thisId = <enumWhatsappTemplates> data.id;
      this.fetchData();
    }else{
      dialogRef.close();
    }
   }

  ngOnInit() {
  }

  fetchData(){
    this.afFirestore.collection(dbKeys.whatsapp_templates)
    .doc(this.thisId)
    .get().subscribe(v=>{
      if(v && v.exists && v.data()?.content){
        this.content = v.data().content || ''
      }
    },e=>{
      console.error('error cased', e)
    })
  }
  submitForm(){
    if(this.thisFormRef?.nativeElement?.reportValidity()){
      console.log(this.content)
      this.isLoading = true;
      this.afFirestore.collection(dbKeys.whatsapp_templates)
      .doc(this.thisId)
      .set({
        id: this.thisId,
        updateDateTime: new Date(),
        content: this.content || '',
        key: this.thisId,
      },{
        merge: true,
      })
      .then(v=>{
        this.dialogRef.close();
        this.snackbar.open(this.i18n.translate.action_success)
      })
      .catch(e=>{
        console.error(e)
        this.snackbar.open(`${this.i18n.translate.error_msg_action_failed}: ${e?.code}`)
      })
    }
  }
}
