// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import LastBuildDate from './lastBuildDate.json'
let lastBuildDate = "UNKNOWN"
if(LastBuildDate && LastBuildDate.lastBuildDate){
  lastBuildDate =  LastBuildDate.lastBuildDate
}
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAwxSUym88pyinsnIzOAFGG4pwE-7led5k",
    authDomain: "happy-retired-jobdb.firebaseapp.com",
    databaseURL: "https://happy-retired-jobdb.firebaseio.com",
    projectId: "happy-retired-jobdb",
    storageBucket: "happy-retired-jobdb.appspot.com",
    messagingSenderId: "448749725662",
    appId: "1:448749725662:web:47a7a290b0cfcd5c031f4f",
    measurementId: "G-JY9L3366DK"
  },
  stripePublicKey: "pk_test_naLYYaAOqIJ6ZEO8xw6Gf73900MoBRfBsf",
  //stripePublicKey: "pk_test_el3CXfW5fSUexLwpDlXTXzGm",
  lastBuildTime: lastBuildDate
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
