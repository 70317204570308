<div class="all">
    <div class="header">
        <h1>{{i18n.getTransate('manage_qna_list')}}</h1>
        <div class="actions">
            <button mat-raised-button color="primary" (click)="openAddChangeDialog()">
                {{i18n.translate.add}}
            </button>
        </div>
    </div>
    <br>
    <div class="content">
        <mat-spinner *ngIf="faqItems === null">
        </mat-spinner>
        <div class="nothing" *ngIf="faqItems && faqItems.length <= 0">
            {{i18n.translate.nothing_to_show}}
        </div>

        <div class="table" *ngIf="faqItems && faqItems.length > 0">

            <table border="1">
                <tr>
                    <td>ID</td>
                    <td>{{i18n.translate.title}}</td>
                    <td>{{i18n.translate.visible}}</td>
                    <td>{{i18n.translate.content}}</td>
                    <td>{{i18n.translate.tags}}</td>
                    <td>{{i18n.translate.coverImage}}</td>
                    <td>{{i18n.translate.edit}}</td>
                </tr>
                <tr *ngFor="let faq of faqItems">
                    <td>{{faq.id}}</td>
                    <td>{{faq.title}}</td>
                    <td>
                        <mat-slide-toggle [checked]="faq.visible" (change)="changeVisible(faq.id, $event)">{{faq.visible?i18n.translate.visible:i18n.translate.hidden}}</mat-slide-toggle>
                    </td>
                    <td>
                        <ul>
                            <li *ngFor="let item of faq.contents">
                                {{item.isSelfSide?'Q':"A"}}: {{item.content}}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <mat-chip-list aria-label="Fish selection" *ngIf="faq.tags">
                            <mat-chip [selectable]="false" *ngFor="let t of faq.tags">{{t}}</mat-chip>
                          </mat-chip-list>
                    </td>
                    <td>
                        <a [href]="faq.coverImage" target="_blank">
                            <img [src]="faq.coverImage" height="150" />
                        </a>
                    </td>
                    <td>
                        <button mat-raised-button color="info" (click)="openAddChangeDialog(faq.id)">{{i18n.translate.edit}}</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>