<div class="banner">
  <img [src]="coverUrl" />
  <div class="selectContainer" flex="column">
    <div flex-item="grow-8">
      <!-- <h2>{{ i18n.get("job_matching_title") }}</h2> -->
    </div>
    <div flex-item="grow-1">
      <!-- <h5 style="color: black" innerHtml="{{ i18n.get('job_select_description') }}"></h5> -->
    </div>
    <div flex-item="grow-1">
      <div flex="justify-center">
        <div class="input-group sm-2 selectControlGroup">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">
              <i class="fas fa-filter"></i>
            </label>
          </div>
          <select
            class="form-control"
            id="selectFilter0JobMatching"
            (change)="onFilteringSelectChange($event.target.value)"
          >
            <option value="" disabled>{{ i18n.get("select_job_type") }}</option>
            <option value="_all">所有/All</option>
            <option *ngFor="let o of topIndustries | keyvalue" [value]="o.key">
              {{ getIndustryNameById(o.key) }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="thisBody">
  <!-- <app-bottom></app-bottom> -->
  <div class="memberList">
    <div class="memberItem">
      <div>
        <div>
          <ng-container
            *ngIf="
              arrTalentItems &&
              arrTalentItems != null &&
              arrTalentItems.length > 0
            "
          >
            <ngx-masonry [options]="masonryOptions">
              <app-talentpool-card
              class="grid-sizer"
              ngxMasonryItem
              *ngFor="let item of arrTalentItems; index as i" 
              [item]="item" 
              [subIndustries]="subIndustries"
              (click)="openDeatailDialog(item.id)"></app-talentpool-card>
            </ngx-masonry>
          </ng-container>

          <ng-container
            *ngIf="
              (!arrTalentItems && arrTalentItems !== null) ||
              (arrTalentItems && arrTalentItems.length <= 0)
            "
          >
            <tr>
              <td colspan="7">
                <div class="nothingContainer">
                  <h4>{{ i18n.get("nothing_to_show") }}</h4>
                </div>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="arrTalentItems === null">
            <tr>
              <td colspan="7">
                <div class="nothingContainer">
                  <h4>{{ i18n.get("loading") }}</h4>
                </div>
              </td>
            </tr>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="nextContainer">
    <a class="btn btn-yellow" (click)="getAllTalentItems()">
      <span style="color: white">{{ i18n.get("view_more") }}</span>
    </a>
  </div>
  <hr />
  <div class="contactForm">
    <div class="inner">
      <h5>{{ i18n.get("need_employee_question") }}</h5>
      <button class="btn btn-blue" (click)="openContactUsDialog()">
        {{ i18n.get("contact_us") }}
      </button>
    </div>
  </div>
</div>
