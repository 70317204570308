import { Component, OnInit } from '@angular/core';
import { I18n } from '../../../../i18n/i18n';
import { i18n_locate } from '../../../../i18n/i18n_const';

@Component({
  selector: 'app-list-appointment-categories',
  templateUrl: './list-appointment-categories.component.html',
  styleUrls: ['./list-appointment-categories.component.less']
})
export class ListAppointmentCategoriesComponent implements OnInit {
  i18n = new I18n()
  get mapIndusty(){
    return this.i18n.getStringMap('map_job_type')
  }
  constructor() {
    console.log(this.mapIndusty)
   }

  ngOnInit() {
  }

}
