import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-talent-search-service',
  templateUrl: './talent-search-service.component.html',
  styleUrls: ['./talent-search-service.component.less']
})
export class TalentSearchServiceComponent implements OnInit {

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
  }

  toModel() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['talentsearch/model'])
    );

    window.open(url, '_blank');
  }
}
