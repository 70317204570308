<div class="thisBody">
  <h5 class="card thisTitle" style="display: none">
    <span>{{ i18n.get("menu_list_all_job_ads") }}</span>
  </h5>
</div>
<div class="search-div">
  <div class="s003 tBackgroundOutter">
    <form class="tBackgroundInnter">
      <div class="below-options">
        <div class="searchTitle">
          工作配對 | JOB MATCHING
          <hr style="width: 140px; background-color: transparent; margin-top: 5px" />
        </div>
        <span style="display: inline-block" class="selectFilterOuter">
          <div class="input-group sm-2 selectFilter0JobMatching">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">
                <i class="fas fa-search"></i>
              </label>
            </div>
            <input
              class="form-control"
              type="text"
              [(ngModel)]="searchKeyword"
              [ngModelOptions]="{ standalone: true }"
              [placeholder]="i18n.get('placeholder_keywords')"
            />
          </div>
        </span>
        <span style="display: inline-block" class="selectFilterOuter">
          <div class="input-group sm-2 selectFilter0JobMatching">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">
                <i class="fas fa-industry"></i>
              </label>
            </div>
            <select
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="searchIndutry"
            >
              <option value="" disabled>
                {{ i18n.get("option_indutry") }}
              </option>

              <option *ngFor="let o of searchOptionsIndustry" [value]="o.key">
                {{ i18n.getStringMap("map_job_type").get(o.name) || o.name }}
              </option>
            </select>
          </div>
        </span>

        <span style="display: inline-block" class="selectFilterOuter">
          <div class="input-group sm-2 selectFilter0JobMatching">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">
                <i class="fas fa-map-marked-alt"></i>
              </label>
            </div>
            <select
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="searchLocation"
            >
              <option value="" disabled>
                {{ i18n.get("option_location") }}
              </option>
              <option value="">所有/All</option>
              <option *ngFor="let o of searchOptionsLocation" [value]="o.key">
                {{ o.name }}
              </option>
            </select>
          </div>
        </span>
        <span style="display: inline-block" class="selectFilterOuter">
          <div class="input-group sm-2 selectFilter0JobMatching">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">
                <i class="fas fa-user-clock"></i>
              </label>
            </div>
            <select
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="searchEmploymentForm"
            >
              <option value="" disabled>
                {{ i18n.get("option_employment_way") }}
              </option>
              <option
                *ngFor="let o of searchOptionsEmploymentForm"
                [value]="o.key"
              >
                {{ o.name }}
              </option>
            </select>
          </div>
        </span>

        <div class="searchButtonDiv">
          <button class="searchButton btn" (click)="searchQuery()">
            <span>
              {{ i18n.get("search") }}
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="search-history">
    <span class="search-history-inner">
      <span class="left btn-sm">
        <b>{{ i18n.get("search_history") }}</b>
      </span>
      <span class="scrollable">
        <span class="btn-sm" *ngIf="!searchHistory.length">{{
          i18n.get("nothing_to_show")
        }}</span>
        <span
          class="btn btn-info btn-sm"
          *ngFor="let item of searchHistory"
          (click)="onSearchHistoryClick(item)"
        >
          {{ searchHistoryToString(item) }}
        </span>
      </span>
    </span>
  </div>
</div>
<div class="content">
  <div>
    <div
      class="alert alert-danger errorMessage"
      role="alert"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <div
      class="alert alert-info errorMessage"
      role="alert"
      *ngIf="notifyMessage"
    >
      {{ notifyMessage }}
    </div>
  </div>
  <app-main-pager
    [style.display]="getMainDisplay.main"
    (onSearch)="searchQuery()"
  ></app-main-pager>
  <div class="wholeList" [style.display]="getMainDisplay.list" flex="jsutify-between">
    <div class="left-card-list" [style.display]="leftRightDisplay.left" [ngClass]="{card: displayJobs === null || (displayJobs.length === 0 || resultCount <= 0)}">
      <h3 *ngIf="displayJobs === null" class="h3Title">{{ i18n.get("loading") }}</h3>
      <h3 *ngIf="
        displayJobs !== null && (displayJobs.length <= 0 || resultCount <= 0)
      " class="h3Title">{{ i18n.get("nothing_to_show") }}</h3>
      <ng-container *ngIf="displayJobs && displayJobs.length > 0 && resultCount">
        <div *ngFor="let item of displayJobs">
          <div class="card left-card" *ngIf="item.display">
            <div class="card-body" (click)="onLeftClick(item.job.id)">
              <div class="title">
                {{ item.job.title }}
              </div>
              <!--<div class="creator">
                              {{item.companyInfo.name}}
                          </div>-->
  
              <div class="location">
                <i class="fas fa-map-marker-alt"></i>
                <span>
                  {{
                    i18n
                      .getStringMap("map_job_location")
                      .get(item.job.location) || item.job.location
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="card right-card" [style.display]="leftRightDisplay.right" flex-item="grow-1">
      <ng-container *ngIf="!thisId">
        <div class="notSelected" [innerHTML]="i18n.get('job_result_prefix')+resultCount+i18n.get('job_result_suffix')"></div>
      </ng-container>
      <ng-container *ngIf="mainJob === null && resultCount">
        <div class="notSelected">{{ i18n.get("loading") }}</div>
      </ng-container>
      <ng-container *ngIf="mainJob">
        <button
          class="btn btn-info backToJobListButton"
          (click)="clearMainContent()"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
        <div class="main_content">
          <div class="headCompany">
            <div class="leftCompanyIcon" *ngIf="mainJob.companyInfo.photoURL">
              <img class="companyIcon" [src]="mainJob.companyInfo.photoURL" />
            </div>
            <div class="rightCompanyName">
              <h3>{{ mainJob.companyInfo.name }}</h3>
              <p class="pre-wrapped descriptionContainer">{{ (mainJob.companyInfo.description) }}</p>
            </div>
          </div>
          <hr />
          <div flex="justify-between">
            <div class="left" flex-item="grow-1">
              <h3>{{ mainJob.job.title }}</h3>
              <div>
                <!--<label>{{i18n.get('placeholder_job_ads_description')}}</label>-->
                <br />
                <div class="pre-wrapped">{{ mainJob.job.topDescription || "N/A" }}</div>
              </div>
              <br />
              <div class="bulletFlex" flex>
                <div class="bulletPoint" *ngIf="mainJob.job.employmentForms">
                  <i class="fas fa-file-signature"></i>
                  <span
                    >{{
                      i18n
                        .getStringMap("map_job_employment_forms")
                        .get(mainJob.job.employmentForms) ||
                        mainJob.job.employmentForms
                    }}
                  </span>
                </div>
                <div class="bulletPoint" *ngIf="mainJob.job.industry">
                  <i class="fas fa-briefcase"></i>
                  <span>{{
                    i18n.getStringMap("map_job_type").get(mainJob.job.industry) ||
                      mainJob.job.industry
                  }}</span>
                </div>
                <div class="bulletPoint" *ngIf="mainJob.job.location">
                  <i class="fas fa-map-marker-alt"></i>
                  <span
                    >{{
                      i18n
                        .getStringMap("map_job_location")
                        .get(mainJob.job.location) || mainJob.job.location
                    }}
                  </span>
                </div>
                <div class="bulletPoint" *ngIf="mainJob.job.salary">
                  <i class="fas fa-dollar-sign"></i>
                  <span>{{ mainJob.job.salary }}</span>
                </div>
                <div class="bulletPoint" *ngIf="mainJob.job.welfare">
                  <i class="fas fa-gift"></i>
                  <span>{{ mainJob.job.welfare }}</span>
                </div>
                <div class="bulletPoint" *ngIf="mainJob.job.workday">
                  <i class="far fa-clock"></i>
                  <span>{{ mainJob.job.workday }}</span>
                </div>
                <div class="bulletPoint" *ngIf="mainJob.job.position">
                  <i class="fas fa-user-md"></i>
                  <span>{{ mainJob.job.position }}</span>
                </div>
                <div class="bulletPoint" *ngIf="mainJob.job.education">
                  <i class="fas fa-school"></i>
                  <span>{{ mainJob.job.education }}</span>
                </div>
              </div>
              <br />
              <div class="ulContainer">
                <label>{{ i18n.get("placeholder_job_ads_duties") }}</label>
                <ul>
                  <span *ngFor="let o of mainJob.job.duties">
                    <li *ngIf="o && o != ''">
                      {{ o }}
                    </li>
                  </span>
                </ul>
              </div>
              <br />
              <div class="ulContainer">
                <label>{{ i18n.get("placeholder_job_ads_requirements") }}</label>
                <ul>
                  <span *ngFor="let o of mainJob.job.requirements">
                    <li *ngIf="o && o != ''">
                      {{ o }}
                    </li>
                  </span>
                </ul>
              </div>
              <br />
              <div class="buttomPadding pre-wrapped">{{ mainJob.job.bottomDescription || "N/A" }}</div>
              <br />
              <div class="buttomPadding">
                <label>{{ i18n.get("placeholder_job_ads_deadline") }}</label>
                <span> {{ mainJob.job.deadline | date: "yyyy-MM-dd" }}</span>
              </div>

              <app-apply-job-parts [mainJob]="mainJob"></app-apply-job-parts>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="card relatedArticles d-none d-xl-block">
      <div>
        <div class="title">
          {{ i18n.get("jobs_latest_title") }}
        </div>
        <div class="desc">
          {{ i18n.get("jobs_latest_subtitle") }}
        </div>
      </div>
      <div>
        <div class="text-center" *ngIf="articles == null">
          {{ i18n.get("loading") }}
        </div>
      </div>
      <div>
        <div
          class="text-center"
          *ngIf="articles != null && articles.length <= 0"
        >
          {{ i18n.get("nothing_to_show") }}
        </div>
      </div>
      <ng-container *ngIf="articles && articles.length > 0">
        <app-blog-card
          *ngFor="let o of articles; index as i"
          [blog]="o"
          class="blogCard"
          flex
        ></app-blog-card>
      </ng-container>
    </div>
  </div>
</div>

<div
  [style.display]="getMainDisplay.list"
  class="relatedArticles container-fluid d-xl-none"
>
  <div class="row">
    <div class="col">
      <div class="title text-center">
        {{ i18n.get("jobs_latest_title") }}
      </div>
      <div class="desc text-center">
        {{ i18n.get("jobs_latest_subtitle") }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center" *ngIf="articles == null">
      {{ i18n.get("loading") }}
    </div>
  </div>
  <div class="row">
    <div
      class="col-12 text-center"
      *ngIf="articles != null && articles.length <= 0"
    >
      {{ i18n.get("nothing_to_show") }}
    </div>
  </div>
  <app-card-container
    [articles]="articles"
    *ngIf="articles && articles.length > 0"
  ></app-card-container>
</div>
