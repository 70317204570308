export interface AdminPermission{
  uid: string,
  permissions: Array<AdminPermissionEnumItem>
}
export enum AdminPermissionEnumItem{
  admin_edit_user = 'admin_edit_user',
  admin_edit_admin = 'admin_edit_admin',
  admin_edit_company = 'admin_edit_company',
  admin_edit_ads_banner = 'admin_edit_ads_banner',
  admin_edit_mainpage = 'admin_edit_mainpage',
  admin_edit_job = 'admin_edit_job',
  admin_edit_industry = 'admin_edit_industry',
  admin_edit_job_matching = 'admin_edit_job_matching',
  admin_edit_activity = 'admin_edit_activity',
  admin_edit_article = 'admin_edit_article',
  admin_edit_advisor = 'admin_edit_advisor',
  admin_edit_ca_article = 'admin_edit_ca_article',
  admin_edit_ca_activity = 'admin_edit_ca_activity',
  admin_edit_qna_management = "admin_edit_qna_management",
  admin_download_raw_data="admin_download_raw_data",
  admin_manage_whatsapp_templates="admin_manage_whatsapp_templates",
  admin_manage_whatsapp_auto_response="admin_manage_whatsapp_auto_response",
  admin_sport_articles="admin_sport_articles",
  admin_sport_rewines="admin_sport_rewines",
  admin_sport_revisions="admin_sport_revisions",
  admin_courses_edit="admin_courses_edit",
}
export const OrderedAdminPermissions = [
  AdminPermissionEnumItem.admin_edit_user,
  AdminPermissionEnumItem.admin_edit_admin,
  AdminPermissionEnumItem.admin_edit_company,
  AdminPermissionEnumItem.admin_edit_ads_banner,
  AdminPermissionEnumItem.admin_edit_mainpage,
  AdminPermissionEnumItem.admin_edit_job,
  AdminPermissionEnumItem.admin_edit_industry,
  AdminPermissionEnumItem.admin_edit_job_matching,
  AdminPermissionEnumItem.admin_edit_activity,
  AdminPermissionEnumItem.admin_edit_article,
  AdminPermissionEnumItem.admin_edit_advisor,
  AdminPermissionEnumItem.admin_edit_ca_article,
  AdminPermissionEnumItem.admin_edit_ca_activity,
  AdminPermissionEnumItem.admin_download_raw_data,
  AdminPermissionEnumItem.admin_manage_whatsapp_auto_response,
  AdminPermissionEnumItem.admin_sport_articles,
  AdminPermissionEnumItem.admin_sport_rewines,
  AdminPermissionEnumItem.admin_sport_revisions,
  AdminPermissionEnumItem.admin_courses_edit,
  
]