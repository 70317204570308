import { loginButtonGlobalId, unsubscribeAll, volunteerKeys } from './../../../class/common-objects.enum';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';
import { I18n } from 'src/app/i18n/i18n';
import { Observable, Subscription } from 'rxjs';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { InputInfoDialogComponent } from '../input-info-dialog/input-info-dialog.component';
import { Router } from '@angular/router';
import { BusinessLoginDialogComponent } from '../business-login-dialog/business-login-dialog.component';
import { JwtHelperService } from "@auth0/angular-jwt";
import { registerButtonGlobalId, submitCvGlobalId } from 'src/app/class/common-objects.enum';
import { UpdateCompanyInfoDialogComponent } from '../../../pages/company/update-company-info-dialog/update-company-info-dialog.component';
import { CompanyUserPermission, CompanyUserPermissionEnumItem } from '../../../class/CompanyUserPermission';
import { AdminPermission, AdminPermissionEnumItem } from 'src/app/class/AdminPermission';
import { dbKeys, cloudFunctionsKeys } from '../../../class/common-objects.enum';
@Component({
  selector: 'app-login-dropdown',
  templateUrl: './login-dropdown.component.html',
  styleUrls: ['./login-dropdown.component.less']
})
export class LoginDropdownComponent implements OnInit, OnDestroy {
  jwtHelper = new JwtHelperService()
  i18n = new I18n()
  isLogin: boolean = null
  currentUser: firebase.User
  observableCurrentUser: Observable<firebase.User>
  displayName = ''
  isAdmin: boolean = null
  isCompany: boolean = null
  isInitAdmin: boolean = null
  private callIsAdmin: Subscription = null
  isBusinessDialogOpened = false
  isUserInited = true

  companyPermissionItems = CompanyUserPermissionEnumItem
  companyPermissions: CompanyUserPermission = null
  arrCompanyUserPermissionEnumItem = Object.values(CompanyUserPermissionEnumItem)

  adminPermissionItems = AdminPermissionEnumItem
  adminPermissions: AdminPermission = null
  arrAdminPermissionEnumItem = Object.values(AdminPermissionEnumItem)
  constructor(
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    public firestorage: AngularFireStorage,
    public dialog: MatDialog,
    public afFunctions: AngularFireFunctions,
    public router: Router
  ) { }


  closeNavIfNotcollapse() {

    const btn = document.getElementById('collapseButton')
    const atrr = btn.getAttribute('aria-expanded')
    if (atrr && atrr == 'true') {
      btn.click()
    }
  }
  sub_authstate: Subscription
  updateUser() {
    this.observableCurrentUser = this.auth.authState
    unsubscribeAll([this.sub_authstate])
    this.sub_authstate = this.auth.authState.subscribe(async auth => {
      if (auth) {

        console.log(auth)
        this.isLogin = !!auth.uid
        this.currentUser = auth
        this.displayName = this.currentUser.displayName || this.currentUser.uid
        let token = await auth.getIdToken(true)
        //console.log(token)
        let dToken = this.jwtHelper.decodeToken(token)
        if (dToken) {
          this.isAdmin = dToken.isAdmin || null
          this.isCompany = dToken.isCompany || null
          this.isInitAdmin = dToken.isInitAdmin || null

          console.log('checkIsInitAdmin1', dToken)

        }
        if (!this.isBusinessDialogOpened && this.isUserInited) {
          this.isUserInited = false
          this.destoryAnonumous()

        }
        this.checkIfNoUserInfo()
        this.getCompanyPermissions()
        this.getAdminPermissions()
      } else {
        this.isLogin = false
        this.currentUser = null
      }
    })
  }

  async ngOnInit() {
    this.updateUser()
  }

  subCompanyPermissions: Subscription = null
  getCompanyPermissions() {
    if (this.subCompanyPermissions) {
      try {
        this.subCompanyPermissions.unsubscribe()
      } catch (e) { }
    }
    if (!this.currentUser) {
      return
    }
    this.subCompanyPermissions = this.firestore.collection(dbKeys.comapny_permissions)
      .doc(this.currentUser.uid)
      .valueChanges()
      .subscribe(d => {
        if (d) {
          const data = <CompanyUserPermission>d
          // console.log(data)
          if (data && data.uid && data.permissions) {
            this.companyPermissions = data
            console.log('company permission', this.companyPermissions.permissions)
            this.companyPermissions.permissions = data.permissions
          }
        } else {
          this.companyPermissions = {
            uid: this.currentUser.uid,
            permissions: []
          }
        }
      })
  }

  subAdminPermissions: Subscription = null
  subselfAdminInfo: Subscription = null;
  cache_self_admin_info: any = null;
  getAdminPermissions() {
    if (this.subAdminPermissions) {
      try {
        this.subAdminPermissions.unsubscribe()
      } catch (e) { }
    }
    if (this.subselfAdminInfo) {
      try {
        this.subselfAdminInfo.unsubscribe()
      } catch (e) { }
    }
    if (!this.currentUser) {
      return
    }
    this.subselfAdminInfo = this.firestore.collection(dbKeys.cache_list_admins).doc(this.currentUser.uid).valueChanges().subscribe(result => {
      this.cache_self_admin_info = result;
      this.isInitAdmin = !!this.cache_self_admin_info.isInitAdmin;
    })
    console.log('admin permission', this.currentUser.uid)
    this.subAdminPermissions = this.firestore.collection(dbKeys.admin_permissions)
      .doc(this.currentUser.uid)
      .valueChanges()
      .subscribe(d => {
        console.log('admin permission', d)
        if (d) {
          const data = <AdminPermission>d
          if (data && data.uid && data.permissions) {
            this.adminPermissions = data
            this.adminPermissions.permissions = data.permissions
          }
        } else {
          this.adminPermissions = {
            uid: this.currentUser.uid,
            permissions: []
          }
        }
      })
  }

  onLoginClick(initOpen: number) {
    if (initOpen == 2) {
      this.openBusinessDialog()
      return
    }
    let dialogRef = this.dialog.open(LoginDialogComponent, {
      data: { initOpen: initOpen }
    });
    let sub = dialogRef.afterClosed().subscribe(isSuccessed => {
      if (isSuccessed == 'business') {
        this.openBusinessDialog()
      } else if (isSuccessed) {
        this.checkIfNoUserInfo()
      }
      sub.unsubscribe()
    })
  }
  checkCompanyPermissionIfAny() {
    for (const p of this.arrCompanyUserPermissionEnumItem) {
      const isHave = this.checkCompanyPermission(p)
      if (isHave) {
        return true;
        break;
      }
    }
    return false;
  }
  checkCompanyPermission(permission: CompanyUserPermissionEnumItem) {
    // console.log(permission)
    // console.log(this.companyPermissions)
    if (this.companyPermissions && this.companyPermissions.permissions && this.companyPermissions.permissions.length > 0) {
      // console.log(this.companyPermissions.permissions.includes(permission))
      return this.companyPermissions.permissions.includes(permission)
    } else {
      return false
    }
  }
  checkAdminPermissionIfAny() {
    for (const p of this.arrAdminPermissionEnumItem) {
      const isHave = this.checkAdminPermission(p)
      if (isHave) {
        return true;
        break;
      }
    }
    return false;
  }
  checkAdminPermission(permission: AdminPermissionEnumItem) {
    if (this.isInitAdmin) return true;
    if (this.adminPermissions && this.adminPermissions.permissions && this.adminPermissions.permissions.length > 0) {
      return this.adminPermissions.permissions.includes(permission)
    } else {
      return false
    }
  }

  openBusinessDialog() {
    this.isBusinessDialogOpened = true
    let dialogRef = this.dialog.open(BusinessLoginDialogComponent, {})
    let sub = dialogRef.afterClosed().subscribe(async result => {
      console.log(result)
      if (result) {
        this.isBusinessDialogOpened = false
      }
      if (result == 'normal') {
        this.onLoginClick(0)
      } else {
        this.destoryAnonumous()
      }
    })
  }
  checkIfNoUserInfo() {
    if (!this.currentUser) return
    if (this.currentUser.isAnonymous) return
    this.firestore.collection('user_info').doc(this.currentUser.uid).get().subscribe(async data => {
      if (data.exists) {
        // if data exists do nothing.
        data
      } else {
        // if data not exists, we assume user not fill her user basic info.

        let token = await this.auth.auth.currentUser.getIdToken(true)

        let dToken = this.jwtHelper.decodeToken(token)
        let isAdmin = dToken.isAdmin || null
        let isCompany = dToken.isCompany || null
        if (!isAdmin && !isCompany) {
          this.openInputInfoDialog()
        }
      }
    })
  }
  itsDialog = false
  async openInputInfoDialog(canClose?: boolean) {
    let cUser = await this.auth.auth.currentUser
    if (cUser && cUser.uid && !cUser.isAnonymous) {
      let token = await this.auth.auth.currentUser.getIdToken(true)

      let dToken = this.jwtHelper.decodeToken(token)
      let isAdmin = dToken.isAdmin || null
      let isCompany = dToken.isCompany || null
      console.log(isAdmin)

      console.log(isCompany)
      if (this.itsDialog) {
        return
      }
      if (!isAdmin && !isCompany) {
        let opt: MatDialogConfig = {}
        this.itsDialog = true
        const dialog = this.dialog.open(InputInfoDialogComponent, {
          disableClose: !canClose,
        })
        dialog.afterClosed().subscribe(() => {
          this.itsDialog = false;
        })
      } else if (isCompany) {
        const dialog = this.dialog.open(UpdateCompanyInfoDialogComponent, null)

        dialog.afterClosed().subscribe(() => {
          this.itsDialog = false;
        })
      }
    }
  }
  toListAdmin() {
    this.router.navigate(['admin', 'listAdmin'])
  }
  toListCompany() {
    this.router.navigate(['admin', 'listCompany'])
  }
  toCompanySelfJobAds() {
    this.router.navigate(['company', 'myJobAds'])
  }
  toCompanySelfActivities() {
    this.router.navigate(['company', 'myActivities'])
  }
  toPendingJobs() {
    this.router.navigate(['admin', 'pendingJobs'])
  }
  toPendingActivities() {
    this.router.navigate(['admin', 'adminListPendingActivities'])
  }
  toPendingVolActivities() {
    this.router.navigate(['admin',  'adminListPendingVolActivities_'+volunteerKeys])
  }
  toAdminEditSlider() {
    this.router.navigate(['admin', 'adminHomepageConfig'])
  }
  toAdminJobApplications() {
    this.router.navigate(['admin', 'jobApplications'])
  }
  toAdminAdsBannerList() {
    this.router.navigate(['admin', 'adsBannerList'])
  }
  toAdminMainpageBottomItems() {
    this.router.navigate(['admin', 'mainpageBottomItems'])
  }
  toListNormalUsers() {
    this.router.navigate(['admin', 'listNormalUser'])
  }
  toAdminListArticles() {
    this.router.navigate(['admin', 'listArticles'])
  }
  toAdminTalent() {
    this.router.navigate(['admin', 'adminJobMatching'])
  }
  toAdminListAdvisors() {
    this.router.navigate(['admin', 'listAdvisors'])
  }
  logout() {
    this.auth.auth.signOut()
    if (location.pathname.startsWith('/admin') || location.pathname.startsWith('/company')) {
      location.reload()
    }
    if (this.callIsAdmin) this.callIsAdmin.unsubscribe()
  }
  async destoryAnonumous() {

    if (this.currentUser && this.currentUser.isAnonymous) {
      let token = await this.currentUser.getIdToken(true)
      let sub = this.afFunctions.httpsCallable(cloudFunctionsKeys.removeAnonymousAccount)({
        token: token
      }).subscribe(result => {
        console.log(result)
        if (sub) sub.unsubscribe()
      })
      this.auth.auth.signOut()
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destoryAnonumous()
  }
  reloadPage() {
    location.reload()
  }
  get registerButtonGlobalId() {
    return registerButtonGlobalId
  }
  get loginButtonGlobalId() {
    return loginButtonGlobalId
  }
  get submitCvGlobalId() {
    return submitCvGlobalId
  }
}
