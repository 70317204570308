<div class="thisBody">
  <div class="header">
    <span class="close" (click)="closeDialog()">X</span>
    <span class="title">{{ title }}</span>
  </div>
  <mat-dialog-content>
    <form (submit)="onSubmit()" [(id)]="formId">
      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_name") }}</mat-label>
        <input
          matInput
          [placeholder]="i18n.get('placeholder_name')"
          [(ngModel)]="record.name"
          required
          name="name"
        />
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>{{
          i18n.get("placeholder_phone_number") + "(+852)"
        }}</mat-label>
        <input
          type="number"
          matInput
          [placeholder]="i18n.get('placeholder_phone_number')"
          [(ngModel)]="record.phone"
          required
          name="phone"
          min="10000000"
          max="99999999"
        />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_email") }}</mat-label>
        <input
          type="email"
          matInput
          [placeholder]="i18n.get('placeholder_email')"
          [(ngModel)]="record.email"
          required
          name="email"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
      <mat-form-field
        class="example-full-width"
        [owlDateTimeTrigger]="applicationDeadline"
      >
        <input
          matInput
          [min]="min"
          [placeholder]="i18n.get('expect_book_datetime')"
          [owlDateTime]="applicationDeadline"
          [(ngModel)]="record.expect_datetime"
          [ngModelOptions]="{ standalone: true }"
          required
        />
        <owl-date-time
          [pickerMode]="pickerMode"
          #applicationDeadline
        ></owl-date-time>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("remarks") }}</mat-label>
        <textarea
          matInput
          [placeholder]="i18n.get('remarks')"
          [(ngModel)]="record.remark"
          name="remark"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </mat-form-field>
      <div flex="column" class="example-full-width">
        <label>{{ i18n.get('supported_appointment_format') }}</label>
        <mat-radio-group
          color="primary"
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="record.format"
          [ngModelOptions]="{ standalone: true }">
          <mat-radio-button class="example-radio-button" *ngFor="let format of formats" [value]="format">
            {{i18n.get(format)}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

    <div class="dropzone" dropZone (dropped)="uploadFile($event)">
      <h5>{{ i18n.get("your_cv_optional_title") }}</h5>

      <div class="file">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            (change)="uploadFile($event)"
            accept=".doc,.docx,.odf,.pdf,.jpg,.png"
            style="width: 100%"
          />
        </label>
        <span class="file-cta">
          <span class="file-icon">
            <i class="fa fa-upload"></i>
          </span>
          <span class="file-label">
            <span>
              <br />
              <div *ngIf="percentage | async as pct">
                <span *ngIf="pct <= 99">
                  <progress
                    class="progress is-info"
                    [value]="pct"
                    max="100"
                  ></progress>

                  {{ pct | number }}%
                </span>
                <span *ngIf="pct == 100">
                  {{ i18n.get("message_was_uploaded") }}
                </span>
              </div>
            </span>
          </span>
        </span>
      </div>
    </div>

    <div>
      <b>{{ i18n.get("update_cv_limit_description") }}</b>
      <p>{{ i18n.get("privacy_dclaimer") }}</p>
    </div>
    
      <input
        class="btn btn-primary submitButton"
        type="submit"
        [value]="i18n.get('submit')"
      />
    </form>
  </mat-dialog-content>
</div>
