<div class="thisWhole">
    <h3>{{i18n.get('choose_your_industry')}}</h3>
    <div class="catList">

        <mat-card class="catItem catAll" routerLink="/ca/find/">
            <mat-card-title>{{i18n.get('all')}}</mat-card-title>
        </mat-card>
        <mat-card class="catItem" *ngFor="let item of mapIndusty|keyvalue" [routerLink]="'/ca/find/'+item.key">
            <mat-card-title>{{item.value}}</mat-card-title>
        </mat-card>
    </div>
</div>