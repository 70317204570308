import { fillOnLeft } from 'src/app/class/common-objects.enum';
import { Component, OnInit } from '@angular/core';
import { I18n } from '../../i18n/i18n';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { dbKeys, checkIsInCareerAdvisor } from '../../class/common-objects.enum';
import { TheArticle } from '../../class/the-article';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleTagService } from '../../class/TitleTagService';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.less']
})
export class ArticlesComponent implements OnInit {
  i18n = new I18n()
  articles: Array<TheArticle> = null
  featureArticles: Map<string, Map<string, TheArticle>> = new Map()

  constructor(
    private titleTagService: TitleTagService,
    public firestore: AngularFirestore,
    public router: Router,
    public activatedRoute : ActivatedRoute
  ) { }
  currentCategory = ''
  get isFeturePage(){
    if(this.currentCategory && this.currentCategory != ''){
      return false
    }else{
      return true
    }
  }
  get keyCollection(){
    if(checkIsInCareerAdvisor()){
      return dbKeys.ca_blog_articles
    }else{
      return dbKeys.blog_articles
    }
  }
  get keyNavPrefix(){
    if(checkIsInCareerAdvisor()){
      return 'ca/articles'
    }else{
      return 'articles'
    }
  }
  get keyNavSinglePrefix(){
    if(checkIsInCareerAdvisor()){
      return 'ca/article'
    }else{
      return 'article'
    }
  }
  articleCategoryLink(categoryKey) {
    return `/${checkIsInCareerAdvisor() ? 'ca/' : ''}articles/category/${categoryKey}`
  }
  ngOnInit() {
    const nCategory = this.activatedRoute.snapshot.paramMap.get('category')
    console.log(nCategory)
    if(nCategory) this.currentCategory = nCategory
    this.updateArticles(this.currentCategory)
    this.updateFeatureArticles()
  }
  async onCategoriesChange(){
    console.log(this.currentCategory)

    if(this.currentCategory) this.router.navigate([this.keyNavPrefix,'category', this.currentCategory])
    else this.router.navigate([this.keyNavPrefix])
    this.updateArticles(this.currentCategory)
  }
  subscriptionArticles : Subscription = null
  updateArticles(category: string){
    if(this.subscriptionArticles != null){
      this.subscriptionArticles.unsubscribe()
      this.subscriptionArticles = null

    }


    this.subscriptionArticles = this.firestore.collection(this.keyCollection, (r => {

       let ref = r.where('status', '==', 'active')
       if(category && category != '_all'){
         ref = ref.where('category', '==', category)
       }
       return ref.orderBy('createAt', 'desc')
    }))
      .valueChanges().subscribe(values => {
        if (values && values.length > 0) {
          this.articles = []
          values.forEach(data => {
            const d: any = data
            const article: TheArticle = d
            article.createAt = (<firebase.firestore.Timestamp>d.createAt).toDate()
            article.updateAt = (<firebase.firestore.Timestamp>d.updateAt).toDate()
            this.articles.push(article)
          })
        } else {
          this.articles = []
        }

      })
  }
  async updateFeatureArticles(){
    // Iniy Cat List

    this.mapArticleCategories.forEach((catName, catId)=>{
      this.featureArticles.set(catId, null)
      this.firestore.collection(this.keyCollection, (rawRef=>{
        let ref = rawRef.where('status', '==', 'active')
        .where('category', '==', catId)
          .orderBy('createAt', 'desc')
          .limit(3)


        return ref
      })).get().toPromise()
      .then(d=>{
        // get data success
        console.log(catId)
        console.log(d)
        if(d && !d.empty && d.size > 0 && d.docs){
          const mapArticles = new Map<string, TheArticle>()
          for(const snap of d.docs){
            if(snap && snap.exists){
             const data = snap.data()
             if(data && data.id){

            const d: any = data
            const article: TheArticle = d
            article.createAt = (<firebase.firestore.Timestamp>d.createAt).toDate()
            article.updateAt = (<firebase.firestore.Timestamp>d.updateAt).toDate()
            mapArticles.set(article.id, article)
             }
            }
          }
          this.featureArticles.set(catId, mapArticles)
          console.log(this.featureArticles)
        }else{
          this.featureArticles.set(catId, (new Map()))
        }
      }).catch(e=>{
        console.error(e)
        this.featureArticles.set(catId, (new Map()))
      })
    })
  }
  toArticle(id: string) {

    if (id) this.router.navigate([this.keyNavSinglePrefix, id])
  }
  get mapArticleCategories() {
    return this.i18n.getStringMap('map_blog_categories')
  }
  dateToDisplayString(date: Date) {
    return `${date.getFullYear()}-${fillOnLeft(date.getMonth() + 1, '0', 2)}-${fillOnLeft(date.getDate(), '0', 2)} ${fillOnLeft(date.getHours(), '0', 2)}:${fillOnLeft(date.getMinutes(), '0', 2)}:${fillOnLeft(date.getSeconds(), '0', 2)}`
  }
}
