<div class="outer">
    <div class="thisAll">
        <div class="header">
            <img class="title_img large" src="/assets/job_matching_icons/Headbanner/Job Matching - Introduction cropped.png" />
            <img class="title_img small" src="/assets/job_matching_icons/Headbanner/Job Matching - Introduction cropped.png" />
        </div>

        <div class="thisBody">
            <div class="about_us section">
                <div class="section_title">
                    {{i18n.translate.about_us}} >>
                </div>
                <div class="section_body">
                    <span class="about_us_hori_pretext">
                        {{i18n.translate.jobMatchingIntro_content1}}:
                    </span>


                    <div class="about_us_de_block">
                        <div class="item_de_block_outer">
                            <div class="item_de_block">

                                <div class="line0">
                                    <div class="item_de_block_title">
                                        {{i18n.translate.employer.toUpperCase()}}
                                    </div>
                                    <div class="item_de_cover" style="padding: 5px;">
                                        <img src="/assets/job_matching_icons/僱主.png" />
                                    </div>
                                </div>
                                <div class="Item_de_points">
                                    <ul>
                                        <li>
                                            {{i18n.translate.job_matching_about_us_emp_point_0}}
                                        </li>

                                        <li>
                                            {{i18n.translate.job_matching_about_us_emp_point_1}}
                                        </li>

                                        <li>
                                            {{i18n.translate.job_matching_about_us_emp_point_2}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="item_de_block_outer">
                            <div class="item_de_block">
                                <div class="line0">
                                    <div class="item_de_block_title">
                                        {{i18n.translate.young_olds.toUpperCase()}}
                                    </div>
                                    <div class="item_de_cover" style="padding: 5px;">
                                        <img src="/assets/job_matching_icons/新中年.webp" />
                                    </div>
                                </div>
                                <div class="Item_de_points">
                                    <ul>
                                        <li>
                                            {{i18n.translate.job_matching_about_us_young_point_0}}
                                        </li>

                                        <li>
                                            {{i18n.translate.job_matching_about_us_young_point_1}}
                                        </li>

                                        <li>
                                            {{i18n.translate.job_matching_about_us_young_point_2}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="provide section">
                <div class="section_title">
                    {{i18n.translate.service_scopes}} >>
                </div>
                <div class="section_body">
                    <div class="provide_outer">
                        <div class="provide_inner">
                            <div class="provide_item" *ngFor="let item of thisImageItems">
                                <div [routerLink]="item.clickUrl">
                                    <div class="icon">
                                        <img [src]="item.image"  width="130" height="130"/>
                                    </div>
                                    <div class="title">
                                        {{item.title()}}
                                    </div>
                                    <div class="content">
                                        {{item.content()}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mission section">
                <div class="section_title">
                    {{i18n.translate.mission}} >>
                </div>
                <div class="section_body">
                    <p>
                        {{i18n.translate.jobMatchingIntro_content2}}
                    </p>
                </div>
            </div>


            <div class="contact_button">
                <a routerLink="/contact">
                    <button>
                        {{i18n.translate.contact_us}}
                    </button>
                </a>
            </div>

            <ng-container *ngIf="showProjectDescription">
                <div flex="justify-evenly" class="upper">
                    <div flex="align-center justify-center" flex-item="grow-5">
                        <div>
                            <img class="bottomLogo" src="/assets/HRCA_project-06-06.png" />
                        </div>
                    </div>
                    <div flex="align-center justify-center center_spacing">
                        <div class="text">主辦機構<br>Organized By</div>
                        <div>
                            <img class="logo" src="/assets/HRCA_Logo-01.png" />
                        </div>
                    </div>
                    <div flex="align-center justify-center" flex-item="grow-3">
                        <div class="text">捐助機構<br>Funded By</div>
                        <div>
                            <a href="https://charities.hkjc.com/charities/chinese/charities-trust/index.aspx"
                                target="_blank">
                                <img class="logo" src="/assets/Trust_Print_FullColor_coated_B_H.JPG" />
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>



        </div>
    </div>