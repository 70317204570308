import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, MemberTalentItem, UserRecordInMap } from 'src/app/class/common-objects.enum';
import { IndustryCatItem } from 'src/app/class/IndustryCatItem';
import { IDownloadCsvMask } from '../raw-data-download.component';

@Component({
  selector: 'app-dl-raw-telentpool-categories',
  templateUrl: './dl-raw-telentpool-categories.component.html',
  styleUrls: ['./dl-raw-telentpool-categories.component.less']
})
export class DlRawTelentpoolCategoriesComponent implements OnInit {
  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_talentpool_industries'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.map_industry_cats)
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
              "industry_id",
              "en",
              "hant",
              "parent_industry_id",



            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <IndustryCatItem>docs.data()
            if (record && record.id) {
              arrOutput.push([
                record?.id||'',
                record?.en_name||'',
                record?.hant_name||'',
                record?.parent_id || '<none>',
              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }


}
