import { Component, OnDestroy, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { JobAds } from 'src/app/class/job-ads';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, unsubscribeAll } from 'src/app/class/common-objects.enum';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { AddUpdateJobAdsComponent } from '../dialog/add-update-job-ads/add-update-job-ads.component';
import { firestore } from 'firebase/app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-job-ads',
  templateUrl: './my-job-ads.component.html',
  styleUrls: ['./my-job-ads.component.less']
})
export class MyJobAdsComponent implements OnInit,OnDestroy {
  errorMessage = '';
  notifyMessage = '';
  i18n = new I18n();
  jobs: JobAds[] = null;
  currentUser: firebase.User = null;
  constructor(
    public firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let isInit = false;
    this.afAuth.authState.subscribe(u => {
      if (u && u.uid) {
        this.currentUser = u;
        if (!isInit) {

          this.updateJobs();
          isInit = true;
        }
      } else {
        this.currentUser = null;
      }
    });
  }
  ngOnDestroy(): void {
      unsubscribeAll([
        this.subUpdateJobs,
        
      ])
  }
  subUpdateJobs: Subscription
  async updateJobs() {
    this.jobs = null;
    if (!this.currentUser) return;
    let uid = this.currentUser.uid;
    unsubscribeAll([this.subUpdateJobs])
    this.subUpdateJobs = this.firestore
      .collection<JobAds>(dbKeys.job_ads, ref => ref
        .where('creator_uid', '==', uid)
        // .orderBy('createDateTime', 'desc')
        .orderBy('title','asc')
      )
      .valueChanges().subscribe((dbJobAds) => {
        console.log(dbJobAds);
        this.jobs = [];
        for (const v of dbJobAds) {
          let job = v;
          const d: any = v.deadline;
          const deadtime: firestore.Timestamp = d;
          if (deadtime) {
            job.deadline = deadtime.toDate();
          } else {
            job.deadline = new Date();
          }
          this.jobs.push(job);
        }
      }, (e) => {
        console.error(e);
        this.jobs = [];
      });

  }
  openAddUpdateDialog() {
    let ref = this.dialog.open(AddUpdateJobAdsComponent, {

    });
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.notifyMessage = this.i18n.get('notify_msg_created_pending_approval');
        // this.updateJobs();
      }
    });
  }

  openUpdateDialog(job: JobAds) {
    if (!job) { return job; }
    let ref = this.dialog.open(AddUpdateJobAdsComponent, {
      data: {
        initJob: job
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.notifyMessage = this.i18n.get('notify_msg_created_pending_approval');
        // this.updateJobs();
      }
    });
  }
}
