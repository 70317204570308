import { Component, OnInit } from '@angular/core';
import { enumImagesSildeType } from 'src/app/class/common-objects.enum';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-happy-sport-intro',
  templateUrl: './happy-sport-intro.component.html',
  styleUrls: ['./happy-sport-intro.component.less']
})
export class HappySportIntroComponent implements OnInit {

  i18n = new I18n()
  providesObjects: {
    img: string,
    text: string,
    url: string,
  }[] = [
      { img: '/assets/sport_intro/1.png', text: '區長訓練', url: '/sport-leader-apply' },
      { img: '/assets/sport_intro/2.png', text: '體能活動', url: '/sport-courses' },
      { img: '/assets/sport_intro/3.png', text: '日常小組訓練', url: '/sportRevisions/category/group_revision' },
      { img: '/assets/sport_intro/4.png', text: '分享健康資訊', url: '/sportArticles' },
    ]
  constructor() { }

  ngOnInit() {
  }


  get silderPageType() {
    return enumImagesSildeType.sport_intro
  }
}
