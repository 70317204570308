<div class="thisHeaderOuter" [ngClass]="{ca: isInCareerAdvisor}">
  <div class="thisTitle">
    <ng-container>{{thisTitle}}</ng-container>
    <hr style="width: 0; background-color: transparent;" />
  </div>
  <div class="selfDescripeListOuter" *ngIf="isCaarticles">
    <div class="selfDescripeList">
      <div class="selfDescripeItem">
        {{i18n.translate.ca_online_cla_02}}
      </div>

    </div>
  </div>
  <div class="selfDescripeListOuter" *ngIf="!hideForNotOriginal">
    <div class="selfDescripeList" *ngIf="!isInCareerAdvisor">
      <div class="selfDescripeItem">{{ i18n.get("career_info") }}</div>
      <div class="selfDescripeItem">•</div>
      <div class="selfDescripeItem">{{ i18n.get("youth_old_interview") }}</div>
      <div class="selfDescripeItem">•</div>
      <div class="selfDescripeItem">{{ i18n.get("hrca_update_news") }}</div>
    </div>
    <div class="SubSUBselfDescripeListOuter" *ngIf="isInCareerAdvisor">
      {{i18n.translate.ca_online_cla_02}} 
    </div>
  </div>
  <div class="dOuter">
    <span style="display: inline-block;" class="selectFilterOuter">
      <div class="input-group sm-2 selectFilter0JobMatching">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">
            <i class="fas fa-filter"></i>
          </label>
        </div>
        <select class="form-control" id="selectFilter0Article0" [(ngModel)]="currentCategory"
          (change)="onCategoriesChange()">
          <option value="">{{ i18n.get("article_categories") }}</option>
          <option value="_all">所有/All</option>
          <option *ngFor="let o of mapArticleCategories | keyvalue" [value]="o.key">
            {{ o.value }}
          </option>
        </select>
      </div>
    </span>
  </div>
  <div class="underLink" *ngIf="!isInCareerAdvisor && !hideForNotOriginal">
    <a class="underLinkHref" routerLink="/jobs">
      {{ i18n.get("know_update_requitment_now") }}
      <i class="fas fa-arrow-right"></i>
    </a>
  </div>
</div>

<div class="content container">
  <ng-container *ngIf="isFeturePage">
    <div class="row">
      <div class="col noResultsDescription" *ngIf="featureArticles.size == 0">
        {{ i18n.get("loading") }}
      </div>
    </div>
    <ng-container *ngIf="featureArticles.size > 0">
      <ng-container *ngFor="let cat of mapArticleCategories | keyvalue">
        <div class="row">
          <div class="col" flex>
            <div class="catTitle"
              [ngClass]="{ eventInfo: cat.key == 'event_news', careerInfo: cat.key == 'career_advisor', ca: isInCareerAdvisor }"
              flex-item="grow-1">
              {{ cat.value }}
            </div>
            <div class="catEnter btn btn-info"
              [ngClass]="{ eventInfo: cat.key == 'event_news', careerInfo: cat.key == 'career_advisor', ca: isInCareerAdvisor }"
              [routerLink]="articleCategoryLink(cat.key)">
              {{ i18n.get("more") }} {{ cat.value }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col noResultsDescription" *ngIf="featureArticles.get(cat.key) == null">
            {{ i18n.get("loading") }}
          </div>
        </div>
        <div *ngIf="
            featureArticles.get(cat.key) != null &&
            featureArticles.get(cat.key).size == 0
          " class="row">
          <div class="col noResultsDescription">
            {{ i18n.get("nothing_to_show") }}
          </div>
        </div>
        <app-card-container [articles]="getFeatureArticlesArray(cat.key)" *ngIf="featureArticles.get(cat.key) != null &&
        featureArticles.get(cat.key).size > 0"></app-card-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!isFeturePage">
    <div class="row">
      <div class="col" flex>
        <div class="catTitle"
          [ngClass]="{ eventInfo: currentCategory == 'event_news', careerInfo: currentCategory == 'career_advisor', ca: isInCareerAdvisor}"
          flex-item="grow-1">
          {{ mapArticleCategories.get(currentCategory) }}
        </div>
      </div>
    </div>
    <div *ngIf="articles === null" class="row">
      <div class="col noResultsDescription">
        {{ i18n.get("loading") }}...
      </div>
    </div>
    <div *ngIf="articles && articles.length === 0" class="row">
      <div class="col noResultsDescription">
        {{ i18n.get("nothing_to_show") }}
      </div>
    </div>
    <app-card-container [articles]="articles" *ngIf="articles && articles.length > 0"></app-card-container>
  </ng-container>
</div>