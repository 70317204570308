import { Pipe, PipeTransform } from '@angular/core';
import { firestore as Firestore } from 'firebase/app';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'firestampTimestmpAsDate'
})
export class FirestampTimestmpAsDatePipe implements PipeTransform {

  transform(firestoreTimestamp: Firestore.Timestamp, format: string = 'yyyy-MM-dd HH:mm:ss'): any {
    if (!firestoreTimestamp) return '';

    let pipe = new DatePipe('en-US');
    return pipe.transform(firestoreTimestamp.toDate(), format);
  }

}
