<div class="card">
    <h5>
        <span>{{i18n.get('list_admin_title')}}</span>
        <a class="btn btn-light" (click)="addAdminDialog()">{{i18n.get('add')}}</a>
        <a class="btn btn-light" (click)="updateAdminUsers()">{{i18n.get('reload')}}</a>
    </h5>
    <div>
        <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
            {{notifyMessage}}
        </div>
    </div>
    <div *ngIf="arrAdminUsers == null">
        {{i18n.get('loading')}} {{loadingCurrent}} / {{loadingTotal}}
    </div>
    <div *ngIf="arrAdminUsers!= null && arrAdminUsers.length <= 0">
        {{i18n.get('nothing_to_show')}}
    </div>
    <div class="scrolling-wrapper">
        <div *ngIf="arrAdminUsers">
            <table border="1" matSort (matSortChange)="sortData($event)">
                <tr>
                    <th >{{i18n.get('captial_UID')}}</th>
                    <th >{{i18n.get('email')}}</th>
                    <th >{{i18n.get('create_time')}}</th>
                    <th >{{i18n.get('last_sign_in')}}</th>
                    <th>{{i18n.get('permissions')}}</th>
                    <th>{{i18n.get('account_banned')}}？</th>
                    <th >{{i18n.get('account_action')}}</th>
                </tr>

                <tr *ngFor="let o of sortedData">
                    <td>{{o.uid}}</td>
                    <td>{{o.email}}</td>
                    <td>{{formatGmtDate(o.metadata.creationTime)}}</td>
                    <td>{{formatGmtDate(o.metadata.lastSignInTime)}}</td>
                    <td>
                        <button [disabled]="o.isInitAdmin" class="btn btn-light" (click)="openPermissionDialog(o.uid)">{{i18n.get('edit')}}</button>
                    </td>
                    <td>
                        <span *ngIf="o.disabled"><i class="fas fa-check"></i></span>
                        <span *ngIf="!o.disabled"><i class="fas fa-times"></i></span>
                    </td>
                    <td>
                        <span *ngIf="!o.isInitAdmin && !isBtnLoading">
                            <span *ngIf="o.disabled" class="btn btn-light" (click)="toBan(o.email, false)">
                               {{ i18n.get('unban')}}
                            </span>
                            <span *ngIf="!o.disabled" class="btn btn-light" (click)="toBan(o.email, true)">
                                 {{i18n.get('ban')}}
                            </span>
                        </span>
                        <span *ngIf="!o.isInitAdmin && isBtnLoading" class="btn btn-light">
                            ⌛
                        </span>
                        <span *ngIf="o.isInitAdmin">x</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>