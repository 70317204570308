import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { checkIsInCareerAdvisor, dbKeys } from 'src/app/class/common-objects.enum';
import { IFaqItem } from 'src/app/class/IFaqItem';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-single-qna',
  templateUrl: './single-qna.component.html',
  styleUrls: ['./single-qna.component.less']
})
export class SingleQnaComponent implements OnInit {
  i18n = new I18n()
  faqItem: IFaqItem = undefined
  initId = ''
  constructor(
    private afFirestore: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    activatedRoute.paramMap.subscribe(para => {
      const id = para.get('id')
      if (id) {
        this.initId = id;
        this.restartGetFaq()
      }
    })
  }

  ngOnInit() {
  }


  renderMessageIfUrl(m: string) {
    const msg = m + "\n"


    return msg.replace(/(http.*?\s)/, "<a target='_blank' href=\"$1\">$1</a>").replace(/\n/, '<br>')
  }
  sub0GetFaq: Subscription
  restartGetFaq() {
    if (this.sub0GetFaq) this.sub0GetFaq.unsubscribe()
    this.faqItem = null
    this.afFirestore.collection(dbKeys.faq)
      .doc<IFaqItem>(this.initId)
      .valueChanges()
      .subscribe(v => {
        this.faqItem = v;
      })
  }
  navigateToTag(v: string) {

    // tag selected
    console.log('tag selected... ', v)
    const r = this.baseRoute
    r.push(...[
      'qna',
      'tag',
      v
    ])
    this.router.navigate(r)
  }

  get baseRoute() {

    return checkIsInCareerAdvisor() ? ['ca'] : []
  }

}
