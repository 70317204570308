import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { IWhatsappAutoResponse } from 'src/app/class/IWhatsappAutoResponse';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-add-edit-whatsapp-response-dialog',
  templateUrl: './add-edit-whatsapp-response-dialog.component.html',
  styleUrls: ['./add-edit-whatsapp-response-dialog.component.less']
})
export class AddEditWhatsappResponseDialogComponent implements OnInit {
  i18n = new I18n()
  isLoading = false;
  initId = ''
  @ViewChild('form') form : ElementRef<HTMLFormElement>
  get isEditMode(){
    return !!this.initId
  }
  autoResponse : IWhatsappAutoResponse={

    description: '',
    matcher:'',
    responseMessage: '',
    isActive: true,
    hitTimes: 0,
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) data : any,
    private dialogRef:MatDialogRef<AddEditWhatsappResponseDialogComponent>,
    private afFirestore:AngularFirestore,
    private snackbar :MatSnackBar,
  ) {
    if(data && data.item?.id){
      this.autoResponse = data.item 
      this.initId = data.item.id
    }
   }

  ngOnInit() {
  }

  onSubmit(){
    if(this.form?.nativeElement?.reportValidity()){
      if(this.isEditMode){
        this.isLoading = true;
        this.afFirestore.collection(dbKeys.whatsapp_auto_messages)
        .doc(this.initId)
        .set(this.autoResponse,
          {
            merge: true,
          })
        .then(v=>{
          this.isLoading = false;
          this.dialogRef.close(this.initId)
          this.snackbar.open(this.i18n.translate.action_success)
        })
        .catch(e=>{
          console.error('got error when add edit', e)
          this.snackbar.open(this.i18n.getFirebaseErrorMessage(e.code))
        })
      }else{
        this.isLoading = true;
        this.afFirestore.collection(dbKeys.whatsapp_auto_messages)
        .add(this.autoResponse)
        .then(v=>{
          this.isLoading = false;
          this.dialogRef.close(v.id)
          this.snackbar.open(this.i18n.translate.action_success)
        })
        .catch(e=>{
          console.error('got error when add edit', e)
          this.snackbar.open(this.i18n.getFirebaseErrorMessage(e.code))
        })
      }
      }
  }

}
