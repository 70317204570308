<h3 class="thisTitle">
    <div (click)="closeThisDialog()" class="l" style="cursor: pointer;">X</div>
    <div class="r">{{i18n.get('register')}}</div>
</h3>
<br>
<div class="logreg-forms">
    <div>
        <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
            {{notifyMessage}}
        </div>
    </div>
    <div class="form-signup">

        <input type="text" id="user-companyName" [(ngModel)]="inputRegCompanyName" class="form-control"
            [placeholder]="i18n.get('placeholder_company_name')" required autofocus="">
        <input type="text" id="user-companyDesc" [(ngModel)]="inputRegCompanyDesc" class="form-control"
            [placeholder]="i18n.get('placeholder_company_desc')" required autofocus="">
        <input type="email" id="user-email" [(ngModel)]="inputRegEmail" class="form-control"
            [placeholder]="i18n.get('placeholder_email_address')" required autofocus="">
        <input type="password" id="user-pass" [(ngModel)]="inputRegPassword" class="form-control"
            [placeholder]="i18n.get('placeholder_password')" required autofocus="">
        <input type="password" id="user-repeatpass" [(ngModel)]="inputRegConfirmPassword" class="form-control"
            [placeholder]="i18n.get('placeholder_repeat_password')" required autofocus="">

        <div class="dropzone" dropZone (dropped)="uploadFile($event)" [class.hovering]="isHovering">

            <br>

            <h5>{{i18n.get('your_company_logo_title')}}</h5>

            <div class="file">
                <label class="file-label">


                    <input class="file-input" type="file" (change)="uploadFile($event)" accept=".jpg,.jpeg,.png">

                </label>
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="fa fa-upload"></i>
                    </span>
                    <span class="file-label">
                        <span>
                            <br>
                            <div *ngIf="percentage | async as pct">
                                <span *ngIf="pct<=99">
                                    <progress class="progress is-info" [value]="pct" max="100">
                                    </progress>

                                    {{ pct | number }}%
                                </span>
                                <span *ngIf="pct==100">
                                    <img class="previewIcon" [src]="downloadURL">
                                </span>
                            </div>

                        </span>
                    </span>
                </span>

            </div>
        </div>
        <br>
        <button class="btn btn-primary btn-block" (click)="emailSignUp()" [(disabled)]="isRegBtnLoading"><i class="fas fa-user-plus"></i>
            {{i18n.get('register')}}
        </button>
        <br>
        <p style="text-align:center">{{i18n.get('captial_or')}}</p>

        <button class="btn btn-link btn-block btn-a" (click)="toLogin()"><i
                class="fas fa-sign-in-alt"></i>{{i18n.get('have_account_login_now')}}</button>
        <br>

    </div>
</div>