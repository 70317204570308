import { analyticsEventKeys, getVideoFromUrl, unsubscribeAll } from './../../../class/common-objects.enum';
import { fillOnLeft, makeid } from 'src/app/class/common-objects.enum';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, checkIsInCareerAdvisor, shuffle } from '../../../class/common-objects.enum';
import { TheArticle, TheArticleGallery } from '../../../class/the-article';
import { I18n } from '../../../i18n/i18n';
import { EmbedVideoService } from 'ngx-embed-video';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TitleTagService } from '../../../class/TitleTagService';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { User } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-single-article',
  templateUrl: './view-single-article.component.html',
  styleUrls: ['./view-single-article.component.less']
})
export class ViewSingleArticleComponent implements OnInit {
  i18n = new I18n()
  thisActicleId = ''
  article: TheArticle = undefined
  sameCatOtherArticles: TheArticle[] = []
  articleContentId = `articleContent_${makeid(13)}__articleContent`
  mainGalleryVideoUrl :SafeResourceUrl= ''
  currentUser :User = null
  get keyCollection() {
    if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_blog_articles
    } else {
      const url = this.router.url
      if (url.startsWith('/sportArticle')) {
        return dbKeys.sport_articles
      } else if (url.startsWith('/sportRewine')) {
        return dbKeys.sport_rewines
      } else if (url.startsWith('/sportRevision')) {
        return dbKeys.sport_revisions
      } else {
        return dbKeys.blog_articles
      }
    }
  }
  constructor(
    private titleTagService: TitleTagService,
    public activatedRoute: ActivatedRoute,
    public firestore: AngularFirestore,
    public embedService: EmbedVideoService,
    public router: Router,
    public analytics: AngularFireAnalytics,
    public logger: LoggingUtilsService,
    private domSanitizer:DomSanitizer,
    private afAuth:AngularFireAuth,
  ) {
    afAuth.authState.subscribe(user=>{
      this.currentUser = user || null
    })
   }

  currentGalleryItem: TheArticleGallery = null
  setCurrentGalleryItem(v: TheArticleGallery) {
    this.currentGalleryItem = v
    if(this.currentGalleryItem.type == 'youtube'){
    this.mainGalleryVideoUrl = this.getEmbedUrlByYoutubeLink(v.url)
    }
  }
  get analyticsViewKey() {
    if (checkIsInCareerAdvisor()) {
      return analyticsEventKeys.article_view
    } else {
      return analyticsEventKeys.ca_article_view
    }
  }
  htmlRestyling(html: string) {
    const a = document.getElementsByClassName('image-style-side')
    if (a && a.length > 0) {
      for (const i in a) {
        const o = <HTMLDivElement>a[i]
        if (o && o.style) {
          if (window.screen.width > 700) {
            o.style.cssFloat = 'right'
            o.style.maxWidth = '50%'
            o.style.marginLeft = '1.5em'
          } else {

            o.style.cssFloat = ''
            o.style.maxWidth = ''
            o.style.marginLeft = ''
          }
        }
      }
    }
    return html
  }
  timer: any = null;
  oembedToIframe() {
    if (!this.timer) clearInterval(this.timer)
    setInterval(() => {
      const collections = document.getElementsByClassName('media')
      for (let q of Array.from(collections)) {
        //console.log(q)
        const x = q.getElementsByTagName('oembed')[0]
        if (x) {
          const url = x.getAttribute('url')
          let html: string = this.embedService.embed(url).toString()
          html = html.substring(html.indexOf('<iframe'))
          let dkey = 'iframe>'
          html = html.substring(0, html.lastIndexOf(dkey) + dkey.length)
          //console.log(html.toString())

          x.outerHTML = html
        }
        const y = q.getElementsByTagName('iframe')[0]
        if (y) {

          y.width = q.clientWidth + 'px'
          y.height = (q.clientWidth / 16 * 9) + 'px'
          //console.log(y.width)
          //console.log(y.height)
        }
      }
    }, 1000)
  }
  async updateOtherArticles() {
    this.firestore.collection(this.keyCollection, (ref => {
      return ref.where('category', '==', this.article.category)
        .where('status', '==', 'active')
    })).get().toPromise()
      .then(d => {
        if (d && d.size > 0) {
          this.sameCatOtherArticles = []
          for (const snap of d.docs) {
            if (snap && snap.exists && snap.id != this.article.id) {
              const data: any = snap.data()
              const a: TheArticle = <TheArticle>data
              a.createAt = (<firebase.firestore.Timestamp>(data.createAt || data.createDateTime)).toDate()
              a.updateAt = (<firebase.firestore.Timestamp>(data.updateAt || data.updateDateTime)).toDate()
              this.sameCatOtherArticles.push(a)
            }
          }

        } else {
          this.sameCatOtherArticles = []
        }
        this.sameCatOtherArticles = shuffle(this.sameCatOtherArticles)
      })
  }
  loadArticle() {
    this.article = undefined
    if (this.thisActicleId) {
      this.firestore.collection(this.keyCollection).doc(this.thisActicleId).valueChanges().subscribe(async d => {
        console.log(d)
        const data: any = d
        const a: TheArticle = <TheArticle>data
        a.createAt = (<firebase.firestore.Timestamp>(data.createAt || data.createDateTime)).toDate()
        a.updateAt = (<firebase.firestore.Timestamp>(data.updateAt || data.updateDateTime)).toDate()
        this.article = a
        console.log(this.article)
        if (this.article?.gallery?.[0]) {
          this.setCurrentGalleryItem( this.article.gallery[0] )
        }
        this.oembedToIframe()
        this.updateOtherArticles()
        this.realodReadCount()
        this.addReadCount()
        this.titleTagService.setTitle(`${this.article.title} - Articles - Happy-Retired Charity Action`)
        this.titleTagService.setSocialMediaTags(location.href, `${this.article.title} - Articles - Happy-Retired Charity Action`
          , "no description", this.article.coverURL)
        this.analytics.logEvent(this.analyticsViewKey, {
          article_title: this.article.title,
          article_id: this.article.id
        })
      })
    } else {
      this.article = null
    }
  }
  logged = false
  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.thisActicleId = routeParams.id
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      this.loadArticle()

      // go log
      if (!this.logged) {
        this.logged = true
        this.logger.log(LoggingKey.article_view, this.thisActicleId)
      }
    });

    setInterval(() => {
      const d = document.getElementById(this.articleContentId)
      if (d) {
        const arrImg = d.getElementsByTagName('img')
        if (arrImg && arrImg.length > 0) {
          for (const i in arrImg.item) {
            const img = <HTMLImageElement>arrImg.item[i]
            img.style.maxWidth = '100%'
          }
        }
      }
    }, 300)
  }

  dateToDisplayString(date: Date) {
    return `${date.getFullYear()}-${fillOnLeft(date.getMonth() + 1, '0', 2)}-${fillOnLeft(date.getDate(), '0', 2)} ${fillOnLeft(date.getHours(), '0', 2)}:${fillOnLeft(date.getMinutes(), '0', 2)}:${fillOnLeft(date.getSeconds(), '0', 2)}`
  }
  tryGoBack() {
    const canGoBack = !!document.referrer
    history.back()

  }

  toArticle(id: string) {

    if (id) this.router.navigate([this.keyNavSinglePrefix, id])
  }

  get mapArticleCategories() {
    return this.i18n.getStringMap('map_blog_categories')
  }
  get keyNavSinglePrefix() {
    if (checkIsInCareerAdvisor()) {
      return 'ca/article'
    } else {
      return 'article'
    }
  }

  // TODO: remove when no bugs for blog article related modification
  get isInCareerAdvisor() {
    return checkIsInCareerAdvisor();
  }

  getEmbedUrlByYoutubeLink(url: string) {

    if (url) {
      const o = getVideoFromUrl(url)
      if (o.service == 'youtube') {
        const id = o.id
        if (id) {
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${o.id}`)
        }
      }
    }
    return ''
  }

  isAddedRead = false
  addReadCount(){
    if(this.isAddedRead){
      return;
    }
    this.isAddedRead = true
    this.firestore.collection(dbKeys.blog_article_read_counts)
    .add({
      type:this.keyCollection,
      article_id: this.article.id,
      uid: this.currentUser?.uid || ''
    }).then(v=>[

    ])
    .catch(e=>{
      console.error(e)
    })
  }

  articleReadCounts = 0
  sub0articleReadCount:Subscription
  realodReadCount(){
    unsubscribeAll([this.sub0articleReadCount])
    this.sub0articleReadCount = this.firestore.collection(dbKeys.blog_article_read_counts,
      ref=>ref.where('article_id','==',this.article.id))
      .valueChanges().subscribe(v=>{
        this.articleReadCounts = v.length
      })
    
  }
}

