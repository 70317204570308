<div class="all">
    <div class="header">
        <img src="/assets/sport_intro/sp_apply/cover.png" />

    </div>

    <div class="body">
        <div class="front">
            我們的訓練最適合:
            <div class="icons">
                <div class="item">
                    <img src="/assets/sport_intro/sp_apply/1.png" />
                    <span class="text">想強健體魄的你</span>
                </div>
                <div class="item">
                    <img src="/assets/sport_intro/sp_apply/2.png" />
                    <span class="text">未下定決心做運動的你</span>
                </div>
                <div class="item">
                    <img src="/assets/sport_intro/sp_apply/3.png" />
                    <span class="text">不知什麼運動適合自己的你</span>
                </div>
                <div class="item">
                    <img src="/assets/sport_intro/sp_apply/4.png" />
                    <span class="text">想結識朋友齊做運動的你</span>
                </div>
            </div>
            <p>
                不少中外研究均表示新中年保持適量、持之以恆的運動可改善體魄，提升活動能力之餘，更有機會減少中年常見身體毛病。因此，近年越來越多新中年認識運動的好處，由零開始、一
                步一步養成運動習慣。
            </p>
            <p>
                由賽馬會慈善信託基金資助、樂活新中年慈善動力(”HRCA”) 舉辦的「賽馬會樂動一族」計劃，特別為新中年設計兩款全新體能課程，分別適合想預防、改善三高人士，或想提升
                骨骼肌力及減輕痛症人士、並培養定期恆常運動的習慣。
            </p>
            <p>
                免費體能課程:我們會於香港區、九龍、新界東及新界西，舉辦特定體能課程(共4堂)，費用全免。體能課程均由「中國香港體適能總會」設計，並由專業教練負責訓練。
            </p>


        </div>

        新中年可選任何一項課程及地區：
        <div class="cardOuter">

            <div class="cards">
                <div class="classA_Outer">
                    <div class="classA card">
                        <ng-container *ngFor="let item of arrClassA">
                            <app-sport-course-card [course]="item"></app-sport-course-card>
                        </ng-container>

                    </div>
                </div>

                <div class="classA_Outer">
                    <div class="classB card">
                        <ng-container *ngFor="let item of arrClassB">
                            <app-sport-course-card [course]="item"></app-sport-course-card>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        持續鍛練:完成體能課程後，參加者可繼續參加每週一次小組運動，與志同道合的朋友，練習體能課程動作、伸展訓練、步行、緩步跑等，可參與最多5個月，以培養
        持續、定期運動的習慣。
        <div class="bottomContacts">
            <div class="left">
                小組運動均由受過訓練的「樂動區長」帶領，費用全免。
                <br>
                *建議參加者以智能手機、或健康手帶下載運動及健康程式。
                <br>
                **參加者須先繳交$500按金，在完成4堂體能課程 及 出席75%以上、每月最少3次的小組運動後，會獲全數退還。
            </div>
            <div class="right">
                <a class="contact_button" href="https://api.whatsapp.com/send/?phone=85266865812&text=%E6%88%91%E6%83%B3%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A%E6%9C%89%E9%97%9C+%E8%B3%BD%E9%A6%AC%E6%9C%83%E6%A8%82%E5%8B%95%E4%B8%80%E6%97%8F%E8%A8%88%E5%8A%83+-+%E9%AB%94%E8%83%BD%E8%AA%B2%E7%A8%8B" target="_blank">
                    WhatsApp我們
                </a>
            </div>
        </div>
    </div>

    <div class="footer" style="display: none;">
        <div class="imgList">
            <img src="/assets/sport_intro/sp_apply/f1.png" />
            <img src="/assets/sport_intro/sp_apply/f2.png" />
            <img src="/assets/sport_intro/sp_apply/f3.png" />
            <img src="/assets/sport_intro/sp_apply/f4.png" />
        </div>
    </div>
</div>