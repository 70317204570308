import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ActivityApplication, TheActivity } from 'src/app/class/the-activity';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, openSimpleMessageDialog, volunteerKeys } from 'src/app/class/common-objects.enum';
import { I18n } from 'src/app/i18n/i18n';
import { jwtHelper, checkIsInCareerAdvisor } from '../../../class/common-objects.enum';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-list-activities-application-by-activity',
  templateUrl: './list-activities-application-by-activity.component.html',
  styleUrls: ['./list-activities-application-by-activity.component.less']
})
export class ListActivitiesApplicationByActivityComponent implements OnInit {
  i18n = new I18n();
  thisId: string = '';
  activity: TheActivity = null;
  isAdmin = false;
  constructor(
    public route: ActivatedRoute,
    public firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchIdFromUrl();
    this.updateIsAdmin();
  }
  get activityKey() {
    if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_the_activities;
    } else {
      return dbKeys.the_activities;
    }
  }

  checkInVolunteerEditing() {
    return location.pathname.includes(volunteerKeys)
  }
  get keyCollection() {
    if (this.checkInVolunteerEditing()) {
      return dbKeys.vol_eve_activities;
    } else if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_activity_application;
    } else {
      return dbKeys.activity_application;
    }
  }
  get keyRenarkCollection() {
    if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_activity_application_hrmarks;
    } else {
      return dbKeys.activity_application_hrmarks;
    }
  }
  applications: Array<ActivityApplication> = null;
  hrmarks: Map<string, string> = new Map();
  fetchIdFromUrl() {
    let sub: Subscription = null;
    sub = this.route.params.subscribe(params => {
      console.log(params);
      if (params && params.id) {
        this.thisId = params.id;

        this.firestore.collection(this.activityKey).doc(this.thisId).get().toPromise().then(result => {
          if (result && result.exists) {
            const data = <TheActivity>result.data();
            this.activity = data;
          }
        });

        console.log(this.thisId);
        this.firestore.collection(this.keyCollection,
          ref => {
            return ref.where('activity_id', '==', this.thisId).orderBy('name');
          }).get().subscribe(appl1 => {
            console.log(appl1);
            if (appl1 && !appl1.empty) {
              this.applications = [];
              for (const a of appl1.docs) {
                console.log(a);
                if (a && a.exists) {
                  // console.log(a.data());
                  const data = a.data();
                  const application = <ActivityApplication>data;
                  console.log(data.createDateTime);
                  if (data.createDateTime) {
                    application.createDateTime = data.createDateTime.toDate();
                  }
                  // application.createDateTime = data.createDateTime ? '' : data.createDateTime.toDate();
                  this.applications.push(application);
                  // this.applications.set(application.id, application)
                  this.initHrmarks(application.id);
                }
              }
              this.applications.sort((a, b) => (a.name).localeCompare(b.name));
            } else {
              this.applications = [];
            }

          });
      }
      if (sub) sub.unsubscribe();
    });
  }

  updateIsAdmin() {
    this.afAuth.authState.subscribe(async user => {

      //console.log(token)
      let token = await user.getIdToken();
      let dToken = jwtHelper.decodeToken(token);
      if (dToken) {
        this.isAdmin = dToken.isAdmin || false;
      }
    });
  }
  async initHrmarks(id: string) {
    if (!id) return;
    const result = await this.firestore.collection(this.keyRenarkCollection).doc(id).get().toPromise();
    if (result && result.exists) {
      const str = <string>result.data().hrmarks;
      this.hrmarks.set(id, str);
    } else {
      this.hrmarks.set(id, '');
    }
  }
  onTextareaChange(id: string, newHrmarks: string) {
    this.hrmarks.set(id, newHrmarks);
  }
  onTextareaSubmit(id: string) {
    this.updateHrmarks(id, this.hrmarks.get(id));
  }
  async updateHrmarks(id: string, newHrmarks: string) {

    if (!id || !newHrmarks) return;

    try {
      await this.firestore.collection(this.keyRenarkCollection).doc(id).set({
        hrmarks: newHrmarks
      });
      openSimpleMessageDialog(this.dialog, "Action success\n操作成功！");
    } catch (e) {
      console.error(e);
      openSimpleMessageDialog(this.dialog, `ERROR|錯誤：${e.message}`);
    }


  }
  async changeStatus(id: string, newStatus: 'pending' | 'done') {
    if (id) {
      try {
        await this.firestore.collection(this.keyCollection).doc(id).update({
          status: newStatus
        });
        openSimpleMessageDialog(this.dialog, `Action Success!\n操作成功！`);
        const r = this.applications.find(a => a.id === id);
        if (r) {
          r.status = newStatus;
        }
      } catch (e) {
        console.error(e);
        openSimpleMessageDialog(this.dialog, `ERROR|錯誤：${e.message}`);
      }
    }
  }
  toUserProfile(uid: string) {
    const url = `/admin/showUserProfile/${uid}`;
    open(url);
  }
}
