import { Component, OnInit, Input } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.less']
})
export class ActivityCardComponent implements OnInit {
  i18n = new I18n()

  @Input()
  activity: any;

  constructor() { }

  ngOnInit() {
    console.log('activity')
    console.log(this.activity)
  }

}
