import { Component, OnInit, ViewChild } from "@angular/core";
import { I18n } from "../../../i18n/i18n";
import { Subscription } from "rxjs";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { TheAdvisor } from "src/app/class/the-advisor";
import { dbKeys, enumImagesSildeType } from "../../../class/common-objects.enum";
import { firestore } from "firebase/app";
import { ActivatedRoute, Router } from "@angular/router";
import { IndustryCatItem } from "../../../class/IndustryCatItem";
import { i18n_locate } from "src/app/i18n/i18n_const";
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';

const ITEMS_PER_PAGE = 6;

@Component({
  selector: "app-ca-get-appointment",
  templateUrl: "./ca-get-appointment.component.html",
  styleUrls: ["./ca-get-appointment.component.less"],
})

export class CaGetAppointmentComponent implements OnInit {
  i18n = new I18n();
  selectValue = "";

  topIndustries: Map<string, IndustryCatItem> = new Map();
  subIndustries: Map<string, IndustryCatItem> = new Map();

  @ViewChild(NgxMasonryComponent, {static: false}) masonry: NgxMasonryComponent;
  masonryOptions: NgxMasonryOptions = {
    columnWidth: '.grid-sizer',
  }

  initCatId = "";
  constructor(
    public firestore: AngularFirestore,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    const catId = activatedRoute.snapshot.paramMap.get("id");
    if (catId) {
      this.initCatId = catId;
    }
  }

  updateMasonry() {
    this.masonry.reloadItems();
    this.masonry.layout(); 
  }

  advisors: Array<TheAdvisor> = [];
  showLoading = true;
  showMore = true;
  ngOnInit() {
    this.query = this.query.bind(this)

    this.updateCatFromInitCatId();
    this.subIndustriesUpdate();
    this.refetchAdvisors();
  }

  lastDoc: firebase.firestore.QueryDocumentSnapshot = null
  query(refRaw) {
    let ref: Query = refRaw.orderBy("updateAt", "desc")
      .where('status', '==', 'active');

    if (this.selectValue) {
      ref = ref.where("industry", '==', this.selectValue);
    }

    if (this.lastDoc) {
      ref = ref.startAfter(this.lastDoc)
    }
    return ref.limit(ITEMS_PER_PAGE);
  }

  async refetchAdvisors() {
    this.showLoading = true;
    this.showMore = true;
    this.lastDoc = null;
    this.advisors = [];

    return this.fetchAdvisors();
  }
  async fetchAdvisors() {
    let result: firebase.firestore.QuerySnapshot = await this.firestore
      .collection(dbKeys.the_advisors, this.query)
      .get().toPromise();

    if (result) {
      this.showLoading = false;

      if (result.docs.length > 0) {
        let validDocNo = 0;
        for (const doc of result.docs) {
          if (doc && doc.exists) {
            const a: any = doc.data();
            const advisor = <TheAdvisor>a;
            advisor.createAt = (<firestore.Timestamp>a.createAt).toDate();
            advisor.updateAt = (<firestore.Timestamp>a.updateAt).toDate();
            this.advisors.push(advisor);
            validDocNo ++;
          }
          this.lastDoc = doc
        }
        if (validDocNo < ITEMS_PER_PAGE) {
          this.showMore = false;
        }
      } else {
        this.showMore = false;
      }
    }
  }
  onFilterChange() {
    this.refetchAdvisors();
    history.pushState(null, null, `/ca/find/${this.selectValue}`);
  }
  goToDetails(id: string) {
    if (id) this.router.navigate(["ca", "advisor", id]);
  }

  sub01: Subscription = null;
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe();
      } catch (e) {}
    }
    this.firestore
      .collection(dbKeys.map_industry_cats)
      .valueChanges()
      .subscribe((d) => {
        if (d && d.length > 0) {
          for (let o of d) {
            const any0: any = <any>o;
            const industry: IndustryCatItem = <IndustryCatItem>any0;
            if (industry && industry.id) {
              if (industry.parent_id) {
                this.subIndustries.set(industry.id, industry);
              } else {
                this.topIndustries.set(industry.id, industry);
              }
            }
          }
        }
      });
  }
  updateCatFromInitCatId() {
    if (this.initCatId) {
      this.selectValue = this.initCatId;
      this.initCatId = "";
    }
  }

  getIndustryNameById(id: string) {
    const s = this.topIndustries.get(id);
    if (s && s.id) {
      const industry = s;
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name;
      } else {
        return industry.en_name;
      }
    }
    return "其他/Other";
  }

  onFilteringSelectChange(newValue: string) {
    this.selectValue = newValue;

    this.onFilterChange();
  }
  get pageType(){
    return enumImagesSildeType.ca_booking
  }
}
