import { Component, OnInit, Inject } from '@angular/core';
import { MemberTalentItem, makeid, dbKeys } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18n } from 'src/app/i18n/i18n';
import { FormControl } from '@angular/forms';
import { i18n_locate } from 'src/app/i18n/i18n_const';
import { IndustryCatItem } from 'src/app/class/IndustryCatItem';
import { getIndustryNameByCurrentLocate } from '../../../../class/common-objects.enum';
import { Subscription } from 'rxjs';
import { observable, computed } from 'mobx-angular';

@Component({
  selector: 'app-add-update-admin-job-matching',
  templateUrl: './add-update-admin-job-matching.component.html',
  styleUrls: ['./add-update-admin-job-matching.component.less']
})
export class AddUpdateAdminJobMatchingComponent implements OnInit {
  private initId = null
  i18n = new I18n()

  topIndustriesMap: Map<string, IndustryCatItem> = new Map()
  @observable topIndustries: Array<IndustryCatItem> = []
  @observable _subIndustries: Array<IndustryCatItem> = []
  @computed get subIndustries() {
    return this._subIndustries.filter(i => this.topIndustries.filter(t => t.id === i.parent_id).length);
  }
  expertiseOptions: Array<IndustryCatItem> = []
  get isUpdate() {
    if (this.initId) {
      return true
    } else {
      return false
    }
  }
  eduLevelFormControl = new FormControl()
  get title() {
    if (this.initId) {
      return `${this.i18n.get('edit')} ${this.initId}`
    } else {
      return `${this.i18n.get('add')}...`
    }
  }
  get mapQualLanguages() {
    return this.i18n.getStringMapWithLocate('map_qual_languages', this.talent.locate)
  }
  get mapIndustry() {
    return this.i18n.getStringMapWithLocate('map_job_type', this.talent.locate)
  }
  get mapItSkills() {
    return this.i18n.getStringMapWithLocate('map_it_skills', this.talent.locate)
  }
  formId = `this_add_update_job_matching_${makeid(12)}`
  talent: MemberTalentItem = {
    id: '',
    summary: '',
    academicQualification: [],
    professionalQualification: '',
    domain: '',
    expertises: [],
    experience: '',
    subIndustries: [],
    employment: [],
    createAt: new Date(),
    updatedAt: new Date(),
    languages: [],
    languages_other: '',
    it_skills: [],
    it_skills_other: '',
    availability: '',
    locate: i18n_locate.en,
    certDetails: '',
    hdDetails: '',
    bscDetails: '',
    masterDetails: '',
    diplomaDetails: ''
  }
  getFilteredSubIndustries(topId: string) {
    const result: Array<IndustryCatItem> = []
    for (const s of this._subIndustries) {
      if (s.parent_id == topId) {
        result.push(s)
      }
    }
    return result
  }
  get shouldDisplayLanguageTextbox() {
    if (this.talent.languages && this.talent.languages.indexOf('other') >= 0) {
      return true
    } else {
      this.talent.languages_other = ''
      return false
    }
  }
  get shouldDisplayItSkillTextbox() {
    if (this.talent.it_skills && this.talent.it_skills.indexOf('other') >= 0) {
      return true
    } else {
      this.talent.it_skills_other = ''
      return false
    }
  }
  get mapAcademicQualification() {
    return this.i18n.getStringMapWithLocate('map_edu_level', this.talent.locate)
  }
  get mapEmploymentForm() {
    return this.i18n.getStringMapWithLocate('map_job_employment_forms', this.talent.locate)
  }
  constructor(
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<AddUpdateAdminJobMatchingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (data && data.id) {
      this.tryInitForUpdate(data.id)
    }
    this.subIndustriesUpdate()
  }

  ngOnInit() {
  }
  sub01: Subscription = null
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe()
      } catch (e) { }
    }
    this.firestore.collection(dbKeys.map_industry_cats)
      .valueChanges().subscribe(d => {
        if (d && d.length > 0) {
          this.topIndustries = []
          this._subIndustries = []
          this.topIndustriesMap.clear()
          for (let o of d) {
            const any0: any = <any>o
            const industry: IndustryCatItem = <IndustryCatItem>any0
            if (industry && industry.id) {
              if (industry.parent_id) {
                this._subIndustries.push(industry)
              } else {
                this.topIndustries.push(industry)
                this.topIndustriesMap.set(industry.id, industry)
              }
            }
          }
        }
      })
  }

  updateExpertiseOptions(parentId: string) {
    this.expertiseOptions = []
    if (!parentId) {
      return
    }
    for (const v of this._subIndustries) {
      if (v && v.parent_id == parentId) {
        this.expertiseOptions.push(v)
      }
    }
  }
  async tryInitForUpdate(id: string) {
    const d = await this.firestore.collection(dbKeys.job_matching_items).doc(id).get().toPromise()
    if (d.exists && d.data()) {
      this.talent = <MemberTalentItem>d.data()
      this.initId = id
    }
  }

  async onSubmit() {
    console.log(this.isUpdate)
    const form = <HTMLFormElement>document.getElementById(this.formId)
    if (form.reportValidity()) {
      if (this.isUpdate) {
        this.onUpdate()
      } else {
        this.onAdd()
      }
    }
  }
  async onUpdate() {
    const ref = this.firestore.collection(dbKeys.job_matching_items).doc(this.initId)
    try {
      const d = await ref.get().toPromise()
      this.talent.createAt = d.data().createAt
      this.talent.updatedAt = new Date()
      await ref.update(this.talent)
      alert(`action success!`)
      this.dialogRef.close()
    } catch (e) {
      console.error(e)
      alert(`ERROR: ${e.message}`)
    }
  }
  async onAdd() {
    this.talent.updatedAt = new Date()
    this.talent.updatedAt = new Date()
    if (this.talent.id) {
      const r = await this.firestore.collection(dbKeys.job_matching_items).doc(this.talent.id).get().toPromise()
      if (r.exists) {
        alert('This Unique ID already exist!')
        return
      }
    }
    // id not exist, continute.

    try {
      if (this.talent.id) {
        await this.firestore.collection(dbKeys.job_matching_items).doc(this.talent.id).set(this.talent)
      } else {
        const ref = await this.firestore.collection(dbKeys.job_matching_items).add(this.talent)
        await this.firestore.collection(dbKeys.job_matching_items).doc(ref.id).update({ id: ref.id })
      }
      alert('Action success!')
      this.dialogRef.close()
    } catch (e) {
      console.error(e)
      alert(`Action failed: ${e.message}`)
    }
  }
  closeThisDialog() {
    this.dialogRef.close()
  }
  _getIndustryNameByCurrentLocate = getIndustryNameByCurrentLocate
}
