<h2 class="thisTitle">
  <span class="titleText">{{i18n.get('menu_donation_to_us')}}
    <hr style="width: 110px; background-color: black;">
  </span>

</h2>
<div class="thisBody">

  <div class="contents">
    <p>樂活新中年慈善動力(Happy-Retired Charity Action)是香港稅局認可的慈善機構 (
      <a href="http://www.ird.gov.hk/cgi-bin/irdnew/ach/search.cgi?lang=e&id=91/14571">稅局檔案號碼：91/14571</a>
      )。<br>捐款HKD100或以上，我們會提供捐款收據，讓你作扣稅申請之用。
    </p>
    <!--
        <p style="color: #ED6124; font-weight: bold;">
            2017年，我們會重點促進「新中年」向大專學生的傳承，協助大專學生好好裝備自己，投身夢想工作。
            <br>
            <a href="https://www.happy-retired.com/volunteer-details/20170315231338/">按此瀏覽「職場起跑線」計劃</a>
        </p>
        ​-->

    <h5 class="bigTitle">捐款方法</h5>
    <div>
      <div>1. 銀行入數
        <br>
        <ul>
          <li>請入數到中國銀行戶口 （戶口號碼：012-721-1-015415-6 戶口名稱：Happy-Retired Charity Action
            Limited）</li>
          <li>然後在入數紙上寫上「HRCA捐款」、捐款人的姓名和聯絡電話，將入數紙拍照及電郵到<a target="_blank"
              href="mailto:info@happy-retired.org">info@happy-retired.org</a>
            或WhatsApp到<a target="_blank" href="https://wa.me/85268429033">66865812</a></li>
          <li>我們會聯絡捐款人及提供捐款收據</li>
        </ul>
      </div>

<br>
      <div>2. 郵寄支票
        <br>
        <ul>
          <li>請在支票抬頭寫上 " Happy-Retired Charity Action
            Limited"</li>
          <li>在支票背面寫上「HRCA捐款」、捐款人的姓名和聯絡電話</li>
          <li>郵寄到「香港九龍長沙灣道760-762號香港紗廠第五期4樓C室」</li>
          <li>我們會聯絡捐款人及提供捐款收據</li>
        </ul>
      </div>
      <div>

        3. 網上捐款
        <br>
        <br>
        <div id="price_container">
          <mat-form-field class="example-full-width">
            <input matInput [placeholder]="i18n.get('donation_amount_with_dest')" [(ngModel)]="amount" type="number"
              min="10">

          </mat-form-field>

          <button class="btn btn-primary submitButton" (click)="goPay()"
            [disabled]="handingPayments">{{i18n.get('continute')}}</button>
        </div>
        <div class="card-container" id="card_container" style="display: none;">
          <div class="display-card"></div>
          <form card container=".display-card" card-width="50px" [(id)]="payFormId" formatting="false" debug="true">
            <!--
                        [messages]="messages"
                        [placeholders]="placeholders" [masks]="masks"
                    -->
            <br>
            <div class="input-list">
              <mat-form-field class="example-full-width" style="width: 45%; margin-right: 4%;">
                <input type="text" name="phoneNo" matInput
                  [placeholder]="i18n.get('hongkong_mobile_number') + ' (+852)'" maxlength="8"
                  [(ngModel)]="formData.phoneNumber" required />
              </mat-form-field>
              <mat-form-field class="example-full-width" style="width: 45%;">
                <input type="email" name="email" matInput [placeholder]="i18n.get('email')" required
                  [(ngModel)]="formData.email" />
              </mat-form-field>
              <mat-form-field class="example-full-width" style="width: 95%;">
                <input type="text" name="number" card-number matInput maxlength="19"
                  [placeholder]="i18n.get('credit_debit_card_number')" required [(ngModel)]="formData.cardNumber" />
              </mat-form-field>

              <mat-form-field class="example-full-width" style="width: 95%;">
                <input matInput type="text" name="fullname" card-name [placeholder]="i18n.get('card_holder_fullname')"
                  required [(ngModel)]="formData.name" />
              </mat-form-field>
              <mat-form-field class="example-full-width" style="width: 70%;">
                <input matInput type="text" name="expiry" card-expiry
                  [placeholder]="i18n.get('expiryDate')+ ' (MM/YYYY)'" required [(ngModel)]="formData.expDate" />
              </mat-form-field>
              <mat-form-field class="example-full-width" style="margin-left: 5%; width: 20%;">
                <input matInput type="text" name="cvc" card-cvc placeholder="CVC" required [(ngModel)]="formData.cvc"
                  maxlength="4" />
              </mat-form-field>
            </div>
          </form>


          <button class="btn btn-primary submitButton" (click)="tryPay()" [(disabled)]="donateButtonDisabled"
            [disabled]="handingPayments">{{i18n.get('pay')}}</button>
        </div>
        <div class="powered-by-stripe">
          <span>{{i18n.get('powered_by_stripe')}} <a
              href="https://stripe.com/docs/security/stripe">{{i18n.get('know_more')}}</a></span>
          <img src='/assets/powered-by-stripe.png'>
        </div>
      </div>
    </div>
  </div>
</div>
