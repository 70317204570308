<div class="thisBody" id="theLogoBar">
  <div class="firstLine">
    <div class="logoDiv" (click)="toHomePage()">
      <img class="logo" src="/assets/HRCA_Logo-01.png" />
    </div>
    <div class="navbar navbar-expand-lg navbar-light thisNavStyleClass">
      <div class="container">
        <app-login-dropdown></app-login-dropdown>
      </div>
    </div>
  </div>

  <div class="bottom-content">
    <span class="left-title" (click)="toHomePage()">
      <div *ngIf="!isInCareerAdvisor" class="first-title">Happy-Retired Charity Action</div>
    </span>

    <div class="right-icons">
      <a target="_blank" [href]="sns.linkedin"
        ><i class="fab fa-linkedin" style="color: #0e76a8;"></i
      ></a>
      <a target="_blank" [href]="sns.facebook"
        ><i class="fab fa-facebook-square" style="color: #4267b2;"></i
      ></a>
      <a target="_blank" [href]="sns.instagram" class="instagramWrap"
        ><i class="fab fa-instagram"></i
      ></a>
      <a target="_blank" [href]="sns.whatsapp"
        ><i class="fab fa-whatsapp-square" style="color: #075e54;"></i
      ></a>
    </div>
  </div>
</div>
