import { Component, Input, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { MatDialog } from '@angular/material';
import { AddMainpageBottomItemDialogComponent } from './add-mainpage-bottom-item-dialog/add-mainpage-bottom-item-dialog.component';
import { ImageSlide, dbKeys, openSimpleMessageDialog, enumImagesSildeType } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-config-mainpage-bottom-items',
  templateUrl: './config-mainpage-bottom-items.component.html',
  styleUrls: ['./config-mainpage-bottom-items.component.less']
})
export class ConfigMainpageBottomItemsComponent implements OnInit {
  i18n = new I18n()
  constructor(
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    public storage: AngularFireStorage
  ) { }
  items: ImageSlide[] = null
  ngOnInit() {
    this.updateTable()
  }
  @Input() pageType:enumImagesSildeType = enumImagesSildeType.homepage
  async updateTable() {
    const result = await this.firestore.collection(dbKeys.mainpage_bottom_items).get().toPromise()
    if (result && result.docs) {
      this.items = []
      for (const doc of result.docs) {
        if (doc && doc.exists) {
          const slide: ImageSlide = <ImageSlide>doc.data()
          if (slide && slide.id) {

            slide.imageURL = await this.storage.ref(slide.imageURL).getDownloadURL().toPromise()
            if (!slide.id) {
              slide.id = doc.id
            }
            this.items.push(slide)
          }
        }
      }
    }
  }
  async onRemove(id: string) {
    if (id) {
      try {
        await this.firestore.collection(dbKeys.mainpage_bottom_items).doc(id).delete()
        openSimpleMessageDialog(this.dialog, 'success')
        await this.updateTable()
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, 'failed.')
      }
    }
  }
  openUpdateDialog(id:string){
    const ref = this.dialog.open(AddMainpageBottomItemDialogComponent, {
      data:{
        id: id
      }
    })
    ref.afterClosed().subscribe(d => {
      if (d === true) {
        this.updateTable()
      }
    })
  }
  
  openAddDialog() {
    const ref = this.dialog.open(AddMainpageBottomItemDialogComponent, {})
    ref.afterClosed().subscribe(d => {
      if (d === true) {
        this.updateTable()
      }
    })
  }
}
