import { Component, OnInit } from '@angular/core';
import { enumImagesSildeType } from '../class/common-objects.enum';
import { I18n } from '../i18n/i18n';
// import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.less']
})
export class MainpageComponent implements OnInit {
  i18n = new I18n()

  constructor() {
  }

  ngOnInit() {
  }

  get showProjectDescription(): boolean {
    return true
    // return !environment.production
  }
  get silderPageType(){
    return enumImagesSildeType.homepage
  }
}
