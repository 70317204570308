import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { I18n } from '../../i18n/i18n';

@Component({
  selector: 'app-simple-yes-confirm-dialog',
  templateUrl: './simple-yes-confirm-dialog.component.html',
  styleUrls: ['./simple-yes-confirm-dialog.component.less']
})
export class SimpleYesConfirmDialogComponent implements OnInit {
  i18n = new I18n()
  message = ''
  callback : ()=>void = null
  constructor(
    @Inject(MAT_DIALOG_DATA) data : any,
    public dialogRef : MatDialogRef<SimpleYesConfirmDialogComponent>
  ) {
    if(data && data.message && data.callback){
      this.message = data.message
      this.callback = data.callback
    }else{
      dialogRef.close()
    }
   }

  ngOnInit() {
  }

  onNoClick(){
    this.dialogRef.close()
  }
  onYesClick(){
    this.callback()
    this.dialogRef.close()
  }
}
