<mat-card>
  <mat-card-title>{{ getIndustryNameById(advisor.industry) || advisor.industry }}</mat-card-title>
  <mat-card-content>
    <div flex>
      <div flex-item="grow-0" class="label">
        {{ i18n.get("advisor") }}:
      </div>
      <div flex-item="grow-6">
        {{ advisor.name }}
      </div>
    </div>
    <div class="label">
      {{ i18n.get("placeholder_expertise") }}:
    </div>
    <ul>
      <li *ngFor="let item of textToArray(advisor.expertise).slice(0, MAX_EXPERTISE_DISPLAY)">
        {{ item }}
      </li>
    </ul>
  </mat-card-content>
  <mat-card-actions class="actions">
    <button
      class="btn btn-yellow actionItem"
      (click)="goToDetails(advisor.id)"
    >
      {{ i18n.get("view_details") }}
    </button>
  </mat-card-actions>
</mat-card>