import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestComponent } from './test/test.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { AngularFireModule } from '@angular/fire';
import { NgbCheckBox, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgImageSliderModule } from 'ng-image-slider';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { LoginDialogComponent } from './layout/navbar/login-dialog/login-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './layout/footer/footer.component';
import { InputInfoDialogComponent } from './layout/navbar/input-info-dialog/input-info-dialog.component';
import { DropZoneDirective } from './drop-zone.directive';
import { LoginDropdownComponent } from './layout/navbar/login-dropdown/login-dropdown.component';
import { TopLogoBarComponent } from './layout/navbar/top-logo-bar/top-logo-bar.component';
import { ListAdminComponent } from './pages/admin/list-admin/list-admin.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AddAdminDialogComponent } from './pages/admin/dialog/add-admin-dialog/add-admin-dialog.component';
import { BusinessLoginDialogComponent } from './layout/navbar/business-login-dialog/business-login-dialog.component';
import { ListCompanyComponent } from './pages/admin/list-company/list-company.component';
import { MyJobAdsComponent } from './pages/company/my-job-ads/my-job-ads.component';
import { AddUpdateJobAdsComponent } from './pages/company/dialog/add-update-job-ads/add-update-job-ads.component';
import { ListPendingJobsComponent } from './pages/admin/list-pending-jobs/list-pending-jobs.component';
import { OurServicesComponent } from './pages/about/our-services/our-services.component';
import { ApplyJobPartsComponent } from './pages/jobs/list-jobs/apply-job-parts/apply-job-parts.component';
import { ListJobsComponent } from './pages/jobs/list-jobs/list-jobs.component';
import { MainPageSliderComponent } from './mainpage/main-page-slider/main-page-slider.component';
import { MainPageWeProvideComponent } from './mainpage/main-page-we-provide/main-page-we-provide.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MainPagerComponent } from './pages/jobs/list-jobs/main-pager/main-pager.component';
import { AdminMainpageSliderEditComponent } from './pages/admin/admin-mainpage-slider-edit/admin-mainpage-slider-edit.component';
import { AddMainpageDialogSliderComponent } from './pages/admin/admin-mainpage-slider-edit/add-mainpage-dialog-slider/add-mainpage-dialog-slider.component';
import { AdminListJobApplicationsComponent } from './pages/admin/admin-list-job-applications/admin-list-job-applications.component';
import { ListJobMatchingComponent } from './pages/list-job-matching/list-job-matching.component';
import { AdminAdsListComponent } from './pages/admin/admin-ads-list/admin-ads-list.component';
import { AddAdminAdsBannerDialogComponent } from './pages/admin/admin-ads-list/add-admin-ads-banner-dialog/add-admin-ads-banner-dialog.component';
import { RandomAdsBannerW300h250Component } from './pages/parts/random-ads-banner-w300h250/random-ads-banner-w300h250.component';
import { ContactUsDialogComponent } from './pages/list-job-matching/contact-us-dialog/contact-us-dialog.component';
import { AdminShowUserProfileComponent } from './pages/admin/admin-show-user-profile/admin-show-user-profile.component';
import { ConfigMainpageBottomItemsComponent } from './pages/admin/config-mainpage-bottom-items/config-mainpage-bottom-items.component';
import { AddMainpageBottomItemDialogComponent } from './pages/admin/config-mainpage-bottom-items/add-mainpage-bottom-item-dialog/add-mainpage-bottom-item-dialog.component';
import { ListNormalUserAdminComponent } from './pages/admin/list-normal-user-admin/list-normal-user-admin.component';
import { AdminHomepageComponent } from './pages/admin/admin-homepage/admin-homepage.component';
import { AdminListJobMatchingComponent } from './pages/admin/admin-list-job-matching/admin-list-job-matching.component';
import { AddUpdateAdminJobMatchingComponent } from './pages/admin/admin-list-job-matching/add-update-admin-job-matching/add-update-admin-job-matching.component';
import { DonationToUsComponent } from './pages/donation-to-us/donation-to-us.component';
import { MyActivitiesComponent } from './pages/company/my-activities/my-activities.component';
import { AddUpdateMyActivityDialogComponent } from './pages/company/my-activities/add-update-my-activity-dialog/add-update-my-activity-dialog.component';
import { AdminListPendingActivitiesComponent } from './pages/admin/admin-list-pending-activities/admin-list-pending-activities.component';
import { ListActivitiesComponent } from './pages/list-activities/list-activities.component';
import { ApplyActivityPartsComponent } from './pages/list-activities/apply-activity-parts/apply-activity-parts.component';
import { ListActivitiesApplicationByActivityComponent } from './pages/admin/list-activities-application-by-activity/list-activities-application-by-activity.component';

import { ListArticlesComponent } from './pages/admin/list-articles/list-articles.component';
import { AddArticlesComponent } from './pages/admin/list-articles/add-articles/add-articles.component';
import { HttpClientModule } from '@angular/common/http';
import { EmbedVideo } from 'ngx-embed-video';
import { UpdateCompanyInfoDialogComponent } from './pages/company/update-company-info-dialog/update-company-info-dialog.component';
import { HtmlPipe, ResoucePipe } from './class/HtmlPipe';
import { ViewSingleArticleComponent } from './pages/articles/view-single-article/view-single-article.component';
import { ArticlesComponent } from './pages/articles/articles.component';
import { MatCardModule, MatListModule, MatProgressSpinnerModule } from '@angular/material';
import { ViewSingleJobMatchingRecordComponent } from './pages/list-job-matching/view-single-job-matching-record/view-single-job-matching-record.component';
import { CaHomepageComponent } from './pages/ca/ca-homepage/ca-homepage.component';
import { CaGetAppointmentComponent } from './pages/ca/ca-get-appointment/ca-get-appointment.component';
import { CaBlogComponent } from './pages/ca/ca-blog/ca-blog.component';
import { CaMainSliderComponent } from './pages/ca/ca-main-slider/ca-main-slider.component';
import { CaAdminListAdvisorComponent } from './pages/admin/ca-admin-list-advisor/ca-admin-list-advisor.component';
import { AddAdminListAdvisorDialogComponent } from './pages/admin/ca-admin-list-advisor/add-admin-list-advisor-dialog/add-admin-list-advisor-dialog.component';
import { ViewSingleAdvisorComponent } from './pages/ca/ca-get-appointment/view-single-advisor/view-single-advisor.component';
import { MakeAppointmentDialogComponent } from './pages/ca/ca-get-appointment/view-single-advisor/make-appointment-dialog/make-appointment-dialog.component';
import { CaAppointmentOrderDetailsComponent } from './pages/ca/ca-get-appointment/ca-appointment-order-details/ca-appointment-order-details.component';
import { CaListAppointmentAdvisorComponent } from './pages/admin/ca-list-appointment-advisor/ca-list-appointment-advisor.component';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG } from '@angular/fire/analytics';
import { ListAppointmentCategoriesComponent } from './pages/ca/ca-get-appointment/list-appointment-categories/list-appointment-categories.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { SimpleMessageDialogComponent } from './universal-dialog/simple-message-dialog/simple-message-dialog.component';
import { AddEditIndustryCategoriesComponent } from './pages/admin/add-edit-industry-categories/add-edit-industry-categories.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SimpleYesConfirmDialogComponent } from './universal-dialog/simple-yes-confirm-dialog/simple-yes-confirm-dialog.component';
import { ViewSingleTalentpoolComponent } from './pages/list-job-matching/view-single-talentpool/view-single-talentpool.component';
import { ChangeCompanyPermissionDialogComponent } from './pages/admin/list-company/change-company-permission-dialog/change-company-permission-dialog.component';
import { ChangeAdminPermissionDialogComponent } from './pages/admin/list-admin/change-admin-permission-dialog/change-admin-permission-dialog.component';
import { CaBlogsComponent } from './pages/ca/ca-blogs/ca-blogs.component';
import { BlogCardComponent } from './pages/ca/ca-blogs/blog-card.component';
import { ArticleDatePipe } from './pipes/article-date.pipe';
import { ArticleCategoryPipe } from './pipes/article-category.pipe';
import { CaAdvisorCardComponent } from './pages/ca/ca-get-appointment/ca-advisor-card.component';
import { CardContainerComponent } from './pages/articles/blog/card-container.component';
import { ExpiryDatePipe } from './pipes/expiry-date.pipe';

import { NgxMasonryModule } from 'ngx-masonry';
import { TalentpoolCardComponent } from './pages/list-job-matching/talentpool-card.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AdminContentComponent } from './pages/admin/admin-content/admin-content.component';
import { ContentComponent } from './pages/content/content.component';
import { BottomComponent } from './mainpage/bottom.component';
import { MainTitleComponent } from './mainpage/main-title.component';
import { ActionsDialogComponent } from './universal-dialog/actions-dialog/actions-dialog.component';
import { FirestampTimestmpAsDatePipe } from './pipes/firestamp-timestmp-as-date.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { JoinCaTeamComponent } from './pages/ca/join-ca-team/join-ca-team.component';
import { ViewSingleAppointmentComponent } from './pages/ca/ca-get-appointment/view-single-appointment.component';
import { AdvisorAppointmentStatusPipe } from './pipes/advisor-appointment-status.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { AppointmentCardComponent } from './pages/ca/ca-get-appointment/appointment-card.component';
import { AdvisorFeePipe } from './pipes/advisor-fee.pipe';
import { AdvisorAppointmentFormatPipe } from './pipes/advisor-appointment-format.pipe';
import { ActivityCardComponent } from './pages/list-activities/activity-card.component';
import { TalentSearchComponent } from './pages/talent-search/talent-search.component';
import { TalentSearchServiceComponent } from './pages/talent-search/talent-search-service.component';
import { BigButtonComponent } from './components/big-button.component';
import { TalentSearchModelComponent } from './pages/talent-search/talent-search-model.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ManageQnaComponent } from './admin/manage-qna/manage-qna.component';
import { AddChangeQnaDialogComponent } from './admin/manage-qna/add-change-qna-dialog/add-change-qna-dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {MatIconModule} from '@angular/material/icon';
import { ListQnaComponent } from './list-qna/list-qna.component';
import { SingleQnaComponent } from './list-qna/single-qna/single-qna.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatChipsModule} from '@angular/material/chips';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SelectQnaTagDialogComponent } from './list-qna/select-qna-tag-dialog/select-qna-tag-dialog.component';
import { RawDataDownloadComponent } from './raw-data-download/raw-data-download.component';
import { DlRawUserInfoComponent } from './raw-data-download/dl-raw-user-info/dl-raw-user-info.component';
import { DlRawCaDeailClickComponent } from './raw-data-download/dl-raw-ca-deail-click/dl-raw-ca-deail-click.component';
import { DlRawCareerAdvisorMetadataComponent } from './raw-data-download/dl-raw-career-advisor-metadata/dl-raw-career-advisor-metadata.component';
import { DlRawCareerAdvisorAppointmentLogsComponent } from './raw-data-download/dl-raw-career-advisor-appointment-logs/dl-raw-career-advisor-appointment-logs.component';
import { DlRawArticleViewLogsComponent } from './raw-data-download/dl-raw-article-view-logs/dl-raw-article-view-logs.component';
import { DlRawArticlesComponent } from './raw-data-download/dl-raw-articles/dl-raw-articles.component';
import { DlRawArticlesCategoryChangeLogsComponent } from './raw-data-download/dl-raw-articles-category-change-logs/dl-raw-articles-category-change-logs.component';
import { DlRawEventViewClickLogsComponent } from './raw-data-download/dl-raw-event-view-click-logs/dl-raw-event-view-click-logs.component';
import { DlRawEventsInfoComponent } from './raw-data-download/dl-raw-events-info/dl-raw-events-info.component';
import { DlRawTelentpoolViewComponent } from './raw-data-download/dl-raw-telentpool-view/dl-raw-telentpool-view.component';
import { DlRawTelentpoolCategorySearchComponent } from './raw-data-download/dl-raw-telentpool-category-search/dl-raw-telentpool-category-search.component';
import { DlRawTelentpoolInfoComponent } from './raw-data-download/dl-raw-telentpool-info/dl-raw-telentpool-info.component';
import { DlRawTelentpoolCategoriesComponent } from './raw-data-download/dl-raw-telentpool-categories/dl-raw-telentpool-categories.component';
import { DlRawJobViewClickLogsComponent } from './raw-data-download/dl-raw-job-view-click-logs/dl-raw-job-view-click-logs.component';
import { DlRawJobSearchLogsComponent } from './raw-data-download/dl-raw-job-search-logs/dl-raw-job-search-logs.component';
import { DlRawJobReservationLogsComponent } from './raw-data-download/dl-raw-job-reservation-logs/dl-raw-job-reservation-logs.component';
import { DlRawJobInfoComponent } from './raw-data-download/dl-raw-job-info/dl-raw-job-info.component';
import { DlRawJobReservationsComponent } from './raw-data-download/dl-raw-job-reservations/dl-raw-job-reservations.component';
import { AdminManageWhatsappTemplatesComponent } from './admin-manage-whatsapp-templates/admin-manage-whatsapp-templates.component';
import { EditWhatsappTemplatesDialogComponent } from './admin-manage-whatsapp-templates/edit-whatsapp-templates-dialog/edit-whatsapp-templates-dialog.component';
import { ManageWhatsappAutoResponseComponent } from './admin/manage-whatsapp-auto-response/manage-whatsapp-auto-response.component';
import { AddEditWhatsappResponseDialogComponent } from './admin/manage-whatsapp-auto-response/add-edit-whatsapp-response-dialog/add-edit-whatsapp-response-dialog.component'
import * as $ from "jquery";
import { JobMachingIntroComponent } from './job-maching-intro/job-maching-intro.component';
import { HappySportIntroComponent } from './sport/happy-sport-intro/happy-sport-intro.component';
import { SportLeaderApplyComponent } from './sport-leader-apply/sport-leader-apply.component';
import { AdminSportCourseEditComponent } from './admin/admin-sport-course-edit/admin-sport-course-edit.component';
import { AddEditSportCourseDialogComponent } from './admin/admin-sport-course-edit/add-edit-sport-course-dialog/add-edit-sport-course-dialog.component';
import { SportCoursesComponent } from './sport-courses/sport-courses.component';
import { SportCourseCardComponent } from './sport-courses/sport-course-card/sport-course-card.component';
import { ApplySportCourseComponent } from './sport-courses/apply-sport-course/apply-sport-course.component';
import { SportCourseApplicationComponent } from './sport-courses/sport-course-application/sport-course-application.component';
import { SportCourseApplicationsListingComponent } from './admin/sport-course-applications-listing/sport-course-applications-listing.component';
import { CaOleIntroComponent } from './pages/ca/ca-ole-intro/ca-ole-intro.component';
@NgModule({
  declarations: [
    HtmlPipe,
    ResoucePipe,
    AppComponent,
    TestComponent,
    MainpageComponent,
    NavbarComponent,
    LoginDialogComponent,
    FooterComponent,
    InputInfoDialogComponent,
    DropZoneDirective,
    TopLogoBarComponent,
    LoginDropdownComponent,
    ListAdminComponent,
    AddAdminDialogComponent,
    BusinessLoginDialogComponent,
    ListCompanyComponent,
    MyJobAdsComponent,
    AddUpdateJobAdsComponent,
    ListJobsComponent,
    ListPendingJobsComponent,
    OurServicesComponent,
    ApplyJobPartsComponent,
    MainPageSliderComponent,
    MainPageWeProvideComponent,
    MainPagerComponent,
    AdminMainpageSliderEditComponent,
    AddMainpageDialogSliderComponent,
    AdminListJobApplicationsComponent,
    ListJobMatchingComponent,
    AdminAdsListComponent,
    AddAdminAdsBannerDialogComponent,
    RandomAdsBannerW300h250Component,
    ContactUsDialogComponent,
    AdminShowUserProfileComponent,
    ConfigMainpageBottomItemsComponent,
    AddMainpageBottomItemDialogComponent,
    ListNormalUserAdminComponent,
    AdminHomepageComponent,
    AdminListJobMatchingComponent,
    AddUpdateAdminJobMatchingComponent,
    DonationToUsComponent,
    MyActivitiesComponent,
    AddUpdateMyActivityDialogComponent,
    AdminListPendingActivitiesComponent,
    ListActivitiesComponent,
    ApplyActivityPartsComponent,
    ListActivitiesApplicationByActivityComponent,
    ListArticlesComponent,
    AddArticlesComponent,
    UpdateCompanyInfoDialogComponent,
    ViewSingleArticleComponent,
    ArticlesComponent,
    ViewSingleJobMatchingRecordComponent,
    CaHomepageComponent,
    CaGetAppointmentComponent,
    CaBlogComponent,
    CaMainSliderComponent,
    CaAdminListAdvisorComponent,
    AddAdminListAdvisorDialogComponent,
    ViewSingleAdvisorComponent,
    MakeAppointmentDialogComponent,
    CaAppointmentOrderDetailsComponent,
    CaListAppointmentAdvisorComponent,
    ListAppointmentCategoriesComponent,
    SimpleMessageDialogComponent,
    AddEditIndustryCategoriesComponent,
    SimpleYesConfirmDialogComponent,
    ViewSingleTalentpoolComponent,
    ChangeCompanyPermissionDialogComponent,
    ChangeAdminPermissionDialogComponent,
    CaBlogsComponent,
    BlogCardComponent,
    ArticleDatePipe,
    ArticleCategoryPipe,
    CaAdvisorCardComponent,
    CardContainerComponent,
    ExpiryDatePipe,
    TalentpoolCardComponent,
    ContactUsComponent,
    AdminContentComponent,
    ContentComponent,
    BottomComponent,
    MainTitleComponent,
    ActionsDialogComponent,
    FirestampTimestmpAsDatePipe,
    JoinCaTeamComponent,
    ViewSingleAppointmentComponent,
    AdvisorAppointmentStatusPipe,
    DateTimePipe,
    AppointmentCardComponent,
    AdvisorFeePipe,
    AdvisorAppointmentFormatPipe,
    ActivityCardComponent,
    TalentSearchComponent,
    TalentSearchServiceComponent,
    BigButtonComponent,
    TalentSearchModelComponent,
    ManageQnaComponent,
    AddChangeQnaDialogComponent,
    ListQnaComponent,
    SingleQnaComponent,
    SelectQnaTagDialogComponent,
    RawDataDownloadComponent,
    DlRawUserInfoComponent,
    DlRawCaDeailClickComponent,
    DlRawCareerAdvisorMetadataComponent,
    DlRawCareerAdvisorAppointmentLogsComponent,
    DlRawArticleViewLogsComponent,
    DlRawArticlesComponent,
    DlRawArticlesCategoryChangeLogsComponent,
    DlRawEventViewClickLogsComponent,
    DlRawEventsInfoComponent,
    DlRawTelentpoolViewComponent,
    DlRawTelentpoolCategorySearchComponent,
    DlRawTelentpoolInfoComponent,
    DlRawTelentpoolCategoriesComponent,
    DlRawJobViewClickLogsComponent,
    DlRawJobSearchLogsComponent,
    DlRawJobReservationLogsComponent,
    DlRawJobInfoComponent,
    DlRawJobReservationsComponent,
    AdminManageWhatsappTemplatesComponent,
    EditWhatsappTemplatesDialogComponent,
    ManageWhatsappAutoResponseComponent,
    AddEditWhatsappResponseDialogComponent,
    JobMachingIntroComponent,
    HappySportIntroComponent,
    SportLeaderApplyComponent,
    AdminSportCourseEditComponent,
    AddEditSportCourseDialogComponent,
    SportCoursesComponent,
    SportCourseCardComponent,
    ApplySportCourseComponent,
    SportCourseApplicationComponent,
    SportCourseApplicationsListingComponent,
    CaOleIntroComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    NgImageSliderModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AppRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSortModule,
    MatProgressBarModule,
    FormsModule,
    NgbModule,
    MatChipsModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    //CardModule,
    CKEditorModule,
    MatListModule,
    EmbedVideo.forRoot(),
    AngularFireAnalyticsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxMasonryModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    LoginDialogComponent,
    InputInfoDialogComponent,
    AddAdminDialogComponent,
    BusinessLoginDialogComponent,
    AddUpdateJobAdsComponent,
    AddMainpageDialogSliderComponent,
    AddAdminAdsBannerDialogComponent,
    ContactUsDialogComponent,
    AddMainpageBottomItemDialogComponent,
    AddUpdateAdminJobMatchingComponent,
    AddUpdateMyActivityDialogComponent,
    UpdateCompanyInfoDialogComponent,
    ViewSingleJobMatchingRecordComponent,
    AddAdminListAdvisorDialogComponent,
    MakeAppointmentDialogComponent,
    ChangeCompanyPermissionDialogComponent
  ],
  providers: [AngularFireAuthGuard,
    {
      provide: SETTINGS,
      useValue: true || environment.production ? undefined : {
        host: 'localhost:8081',
        ssl: false
      }
    },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: CONFIG, useValue: {
        send_page_view: true,
        allow_ad_personalization_signals: true,
        anonymize_ip: false,
        DEBUG_MODE: true,
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
