import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-simple-message-dialog',
  templateUrl: './simple-message-dialog.component.html',
  styleUrls: ['./simple-message-dialog.component.less']
})
export class SimpleMessageDialogComponent implements OnInit {
  message= ''
  constructor(
    @Inject(MAT_DIALOG_DATA) data : any,
    public dialogRef : MatDialogRef<SimpleMessageDialogComponent>
  ) { 
    if(data && data.message){
      this.message = data.message
    }else{
      dialogRef.close()
    }
  }

  ngOnInit() {

  }
  onNoClick(){
    this.dialogRef.close()
  }

}
