import { storageKeys, makeid, dbKeys } from "src/app/class/common-objects.enum";
import { Component, OnInit, Inject } from "@angular/core";
import { I18n } from "../../../../i18n/i18n";
import { TheAdvisor } from "../../../../class/the-advisor";
import {
  AngularFireUploadTask,
  AngularFireStorage,
  AngularFireStorageReference,
} from "@angular/fire/storage";
import { Observable, Subscription } from "rxjs";
import { finalize, catchError } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { firestore } from "firebase/app";
import { IndustryCatItem } from "../../../../class/IndustryCatItem";
import { i18n_locate } from "src/app/i18n/i18n_const";

@Component({
  selector: "app-add-admin-list-advisor-dialog",
  templateUrl: "./add-admin-list-advisor-dialog.component.html",
  styleUrls: ["./add-admin-list-advisor-dialog.component.less"],
})

export class AddAdminListAdvisorDialogComponent implements OnInit {
  experience: string = '';

  i18n = new I18n();
  formId = `add_advisor_dialog${makeid(14)}`;
  advisor: TheAdvisor = {
    id: "",
    name: "",
    industry: "",
    subIndustries: [],
    expertise: "",
    duration_minutes: 30,
    fee: 0,
    work_experience: "",
    achievements: "",
    adviceFocus: "",
    createAt: new Date(),
    updateAt: new Date(),
    coverURL: "",
    status: "active",
    duration_text: "",
    fee_text: "",
    // experiences: [],
    experienceItems: [],
    phone_call: false,
    face_to_face: false,
    appointment_count: 0,
    whatsappNumber: '',
    zoom_call: false,
  };

  topIndustries: Map<string, IndustryCatItem> = new Map();
  subIndustries: Map<string, IndustryCatItem> = new Map();
  notifyMessage = "";
  errorMessage = "";
  coverUpload = "";
  inputPhotoURL = "";
  isHovering: boolean;
  currentUser: firebase.User = null;
  allowFileType = ["image/png", "image/jpeg", "image/jpg"];
  allowFileSize = 10 * 1024 * 1024;
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  isRegBtnLoading = false;
  initId = "";
  get isUpdate() {
    if (this.initId) {
      return true;
    } else {
      return false;
    }
  }
  get downloadURL() {
    return this.advisor.coverURL;
  }
  set downloadURL(v: string) {
    this.advisor.coverURL = v;
  }
  constructor(
    public storage: AngularFireStorage,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public dialogRef: MatDialogRef<AddAdminListAdvisorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data && data.id) {
      this.initId = data.id;
    }
    if (this.initId) {
      this.firestore
        .collection(dbKeys.the_advisors)
        .doc(this.initId)
        .get()
        .subscribe(async (d) => {
          if (d && d.exists) {
            const data = await d.data();
            const result = <TheAdvisor>data;
            result.createAt = (<firestore.Timestamp>data.createAt).toDate();
            result.updateAt = (<firestore.Timestamp>data.updateAt).toDate();
            if (!result.experienceItems) result.experienceItems = [];
            this.advisor = result;
          }
        });
    }
    this.subIndustriesUpdate();
    afAuth.authState.subscribe((u) => {
      if (u && u.uid) {
        this.currentUser = u;
      } else {
        this.currentUser = null;
      }
    });
  }

  ngOnInit() {}
  get title() {
    if (!this.isUpdate) return this.i18n.get("add_advisor");
    else return this.i18n.get("edit") + " " + this.advisor.name;
  }
  onSave() {
    if (this.isRegBtnLoading) {
      alert(`圖片上傳中，請稍候。\nPlease wait until upload task finished.`);
    }
    const form = <HTMLFormElement>document.getElementById(this.formId);
    if (form && form.reportValidity()) {
      if (!this.advisor.phone_call && !this.advisor.face_to_face) {
        return alert(`請選取最少一個預約方式\nPlease choose at least one format of appointment`);
      }
      if (!this.advisor.coverURL) {
        return alert(`請上傳封面圖片\nPlease upload the cover.`);
      }
      
      if (this.isUpdate) {
        this.onUpdate();
      } else {
        this.onAdd();
      }

    }
  }
  async onAdd() {
    try {
      this.advisor.createAt = new Date();
      this.advisor.updateAt = new Date();
      const d = await this.firestore
        .collection(dbKeys.the_advisors)
        .add(this.advisor);
      console.log(d.path);
      if (d && d.id) {
        await d.update({
          id: d.id,
        });
        alert("新增成功！\nAdded!");
        this.dialogRef.close();
      } else {
        throw "NETWORK_ERROR";
      }
    } catch (e) {
      console.error(e);
      alert(`ERROR! 發生錯誤！\n${e.message}`);
    }
  }
  async onUpdate() {
    try {
      this.advisor.updateAt = new Date();
      await this.firestore
        .collection(dbKeys.the_advisors)
        .doc(this.initId)
        .update(this.advisor);

      alert("修改成功！\nUpdated!");
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      alert(`ERROR! 發生錯誤！\n${e.message}`);
    }
  }
  public uploadFile(event: any): void {
    this.errorMessage = "";
    this.notifyMessage = "";
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target;
      const file = target.files[i];
      console.log(file);
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get("err_msg_file_too_large");
        return;
      } else if (!this.allowFileType.includes(file.type)) {
        this.errorMessage = this.i18n.get("err_msg_disallow_file_type");
        return;
      }
      try {
        this.isRegBtnLoading = true;
        const path = `${storageKeys.tmp}/${
          this.currentUser.uid
        }/${new Date().toISOString()}_${makeid(10)}_${file.name}`;

        const task: AngularFireUploadTask = this.storage.upload(path, file);
        console.log(path);
        this.percentage = task.percentageChanges();
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              const fileRef: AngularFireStorageReference = this.storage.ref(
                path
              );
              fileRef.getDownloadURL().subscribe((downloadURL) => {
                //this.userInfo.cvURL = downloadURL
                this.inputPhotoURL = path;
                this.isRegBtnLoading = false;
                console.log(this.inputPhotoURL);
                this.downloadURL = downloadURL;
                //this.updateDownloadURL()
                //sub.unsubscribe()
              });
            }),
            catchError((err) => {
              this.errorMessage = err.message;
              this.isRegBtnLoading = false;
              throw err;
            })
          )
          .subscribe();
      } catch (e) {
        this.isRegBtnLoading = false;
        this.errorMessage = e.message;
        console.error(e);
      }
    }
  }

  sub01: Subscription = null;
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe();
      } catch (e) {}
    }
    this.firestore
      .collection(dbKeys.map_industry_cats)
      .valueChanges()
      .subscribe((d) => {
        if (d && d.length > 0) {
          for (let o of d) {
            const any0: any = <any>o;
            const industry: IndustryCatItem = <IndustryCatItem>any0;
            if (industry && industry.id) {
              if (industry.parent_id) {
                this.subIndustries.set(industry.id, industry);
              } else {
                this.topIndustries.set(industry.id, industry);
              }
            }
          }
        }
      });
  }

  getSubIndustryNameById(id: string) {
    const s = this.subIndustries.get(id);
    if (s && s.id) {
      const industry = s;
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name;
      } else {
        return industry.en_name;
      }
    }
    return "其他/Other";
  }
  getIndustryNameById(id: string) {
    const s = this.topIndustries.get(id);
    if (s && s.id) {
      const industry = s;
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name;
      } else {
        return industry.en_name;
      }
    }
    return "其他/Other";
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addExperience() {
    let experience = this.experience.trim();
    if (!experience.length) return;
    this.advisor.experienceItems.push({
      company: experience,
      details: '',
    });

    this.experience = '';
  }

  removeItem(index: number) {
    this.advisor.experienceItems.splice(index, 1);
  }
}
