import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore, validateEventsArray } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfo } from 'src/app/class/user-info';
import * as firebase from 'firebase/app'
import { Observable, pipe, of } from 'rxjs';
import { AngularFireUploadTask, AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { finalize, catchError } from 'rxjs/operators';
import { I18n } from 'src/app/i18n/i18n';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { jwtHelper, isCvFileTypeAllowed } from '../../../class/common-objects.enum';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-input-info-dialog',
  templateUrl: './input-info-dialog.component.html',
  styleUrls: ['./input-info-dialog.component.less']
})
export class InputInfoDialogComponent implements OnInit {
  private cKeyUserInfo = 'user_info'
  private key_storage_user_uploaded_cv = 'user_uploaded_cv'
  get isRegisterForFindJob(){
    if(this.userInfo.registerFor == 'find_job'){
      return true
    }else{
      return false
    }
  }
  get findJobBlockDisplay(){
    if(this.isRegisterForFindJob){
      return ''
    }else{
      this.userInfo.privacySetting = ''
      this.userInfo.expectedSalary = ''
      this.userInfo.interest_to_ca = ''
      return 'none'
    }
  }
  userInfo: UserInfo = {
    fullname: '',
    workedYears: '',
    jobType: '',
    jobTitle: '',
    workedFrom: firebase.firestore.Timestamp.now(),
    workedTo: firebase.firestore.Timestamp.now(),
    educationLevel: '',
    expectedSalary: '',
    privacySetting: '',
    registerFor:'',
    interest_to_ca:"",
    cvURL: '',
    register_for_find_jobs: false,
    register_for_become_advisor: false,
    register_for_career_advisor_service: false,
  }
  private isDbNoData = true
  errorMessage = ''
  notifyMessage = ''
  currentUser: firebase.User
  allowFileSize = 10 * 1024 * 1024

  // Main task
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;
  downloadFilename: string

  // State for dropzone CSS toggling
  isHovering: boolean;
  i18n = new I18n()
  get closeDisplay() {
    if (this.dialogRef.disableClose) {
      return 'none'
    } else {
      return ''
    }
  }
  initId = ''
  constructor(

    public dialogRef: MatDialogRef<InputInfoDialogComponent>,
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    public afAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    if (data && data.initId) {
      this.initId = data.initId
      console.log(`initId: ${this.initId}`)
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }
  public updateDownloadURL() {
    let path = this.userInfo.cvURL
    if (!path) return
    const fileRef: AngularFireStorageReference = this.storage.ref(
      path
    )
    fileRef.getDownloadURL().subscribe(downloadURL => {
      //this.userInfo.cvURL = downloadURL
      this.downloadURL = downloadURL
      //console.log(path)
      //console.log(this.userInfo.cvURL)
      fileRef.getMetadata().subscribe(d => {
        //console.log(d)
        let p = d.fullPath
        let tIndex = p.lastIndexOf('/') + 1
        if (tIndex >= 0) this.downloadFilename = p.substring(tIndex)
      })
    });

  }
  public uploadFile(event: any): void {

    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get('err_msg_file_too_large')
        return
      } else if (!isCvFileTypeAllowed(file.type)) {
        this.errorMessage = this.i18n.get('err_msg_disallow_file_type')
        return
      }
      try {
        const path = `${this.key_storage_user_uploaded_cv}/${this.currentUser.uid}/${file.name}`;
        const fileRef: AngularFireStorageReference = this.storage.ref(
          path
        );
        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        this.percentage = task.percentageChanges()
        task
          .snapshotChanges()
          .pipe(catchError(err => {
            this.errorMessage = err.message
            throw err;
          }))
          .pipe(

            finalize(() => {
            })
          )
          .subscribe((data) => {
            console.log(data)
            fileRef.getDownloadURL().subscribe(downloadURL => {
              //this.userInfo.cvURL = downloadURL
              this.userInfo.cvURL = path
              console.log(this.userInfo.cvURL)
              this.updateDownloadURL()
            });
          });
      } catch (e) {
        this.errorMessage = e.message
        console.error(e)
      }
    }
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }


  ngOnInit() {
    this.afAuth.authState.subscribe(async auth => {
      if (auth) {
        this.currentUser = auth
        let ref = this.firestore.collection(this.cKeyUserInfo).doc<UserInfo>(this.currentUser.uid)
        let token = await this.currentUser.getIdToken()
        //console.log(token)
        let dToken = jwtHelper.decodeToken(token)
        if (dToken) {
          let isAdmin = dToken.isAdmin || null
          if (isAdmin && this.initId) {
            ref = this.firestore.collection(this.cKeyUserInfo).doc<UserInfo>(this.initId)
          }
        }
        ref.valueChanges().subscribe(data => {
          if (data) {
            this.userInfo = data
            this.isDbNoData = false
            this.updateDownloadURL()

          } else {
            this.isDbNoData = true
          }
        })
      } else {
        this.currentUser = null
        this.dialogRef.close(false)
      }
    })
  }
  async onSubmit() {
    if (!this.userInfo) {
      this.errorMessage = this.i18n.get('err_msg_enter_your_data')
    } else if (!this.userInfo.fullname) {
      this.errorMessage = this.i18n.get('err_msg_enter_your_fullname')
    } else if (this.userInfo.fullname.length < 3) {
      this.errorMessage = this.i18n.get('err_msg_invalid_fullname')
    } else if (!this.userInfo.workedYears) {
      this.errorMessage = this.i18n.get('err_msg_select_your_worked_years')
    } else if (!this.userInfo.jobType) {
      this.errorMessage = this.i18n.get('err_msg_select_job_type')
    } else if (!this.userInfo.jobTitle) {
      this.errorMessage = this.i18n.get('err_msg_selct_job_title')
    } else if (this.userInfo.jobTitle.length < 3) {
      this.errorMessage = this.i18n.get('err_msg_invalid_job_title')
    } else if (!this.userInfo.workedFrom || !this.userInfo.workedTo) {
      this.errorMessage = this.i18n.get('err_msg_enter_your_work_date_range')
    } else if (!this.userInfo.educationLevel) {
      this.errorMessage = this.i18n.get('err_msg_select_edu_level')
    } else if ( this.isRegisterForFindJob &&  this.userInfo.expectedSalary <= 0) {
      this.errorMessage = this.i18n.get('err_msg_invalid_expect_salary')
    } else if (this.isRegisterForFindJob && !this.userInfo.privacySetting) {
      this.errorMessage = this.i18n.get('err_msg_choose_privacy_setting')
    } else if(this.isRegisterForFindJob && !this.userInfo.interest_to_ca){
      this.errorMessage = this.i18n.get('err_msg_choose_interest_ca')
    }else {
      try {
        let ref = await this.firestore
          .collection(this.cKeyUserInfo)
          .doc(this.currentUser.uid)
        let token = await this.currentUser.getIdToken()
        //console.log(token)
        let dToken = jwtHelper.decodeToken(token)
        if (dToken) {
          let isAdmin = dToken.isAdmin || null
          if (isAdmin) {
            ref = this.firestore.collection(this.cKeyUserInfo).doc<UserInfo>(this.initId)
          } else {
            await this.afAuth.auth.currentUser.updateProfile({
              displayName: this.userInfo.fullname
            })
          }
        }
        if (this.isDbNoData) {
          await ref.set(this.userInfo)
        } else {
          await ref.update(this.userInfo)
        }

        this.dialogRef.close(true)
      } catch (e) {
        console.error(e)
        this.errorMessage = e.message
      }
    }
  }





  parseTimestamp(timestamp: firebase.firestore.Timestamp): string {
    //console.log(timestamp)
    if(timestamp){

    let date =  timestamp.toDate()
    let strDate = `${date.toISOString().split('T')[0]}`

    return strDate
    }
    return ''
  }
  // controller
  parseDate(dateString: string): firebase.firestore.Timestamp {
    console.log(dateString)
    if (dateString) {
      let date = new Date(dateString)
      let seconds = parseInt((date.getTime() / 1000).toString())
      return new firebase.firestore.Timestamp(seconds, 0)
    }
    return null;
  }
  closeThis() {
    this.dialogRef.close()
  }
  userRegisterForChange($event, key) {
    if ($event===true && key==='career_advisor_service') {
      this.userInfo.register_for_find_jobs = false;
      this.userInfo.register_for_become_advisor = false;
    } else {
      if (this.userInfo.register_for_career_advisor_service && key!=='career_advisor_service') {
        let that = this;
        setTimeout(() => {
          that.userInfo.register_for_find_jobs = false;
          that.userInfo.register_for_become_advisor = false;
        });
      }
    }
  }
}
