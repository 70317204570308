import { Component, OnInit, Input } from '@angular/core';
import { TheArticle } from 'src/app//class/the-article';

@Component({
  selector: 'app-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.less']
})
export class CardContainerComponent implements OnInit {
  @Input()
  articles: Array<TheArticle> = null;

  constructor() { }

  ngOnInit() {
  }

}
