<div class="thisBody">
  <div class="thisTitle">
    <span class="title">{{thisTitle}}</span>
  </div>
  <br>
  <div>
    <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
    <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
      {{notifyMessage}}
    </div>
  </div>
  <form class="thisForm">
    <mat-form-field class="example-full-width">
      <input matInput [placeholder]="i18n.get('title')" [(ngModel)]="article.title" required
        [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput [placeholder]="i18n.get('author')" [(ngModel)]="article.author"
        [ngModelOptions]="{standalone: true}">
    </mat-form-field>


    <mat-form-field class="example-full-width">
      <mat-label>{{i18n.get('article_categories')}}</mat-label>
      <mat-select [(ngModel)]="article.category" [ngModelOptions]="{standalone: true}" required>
        <mat-option></mat-option>
        <mat-option *ngFor="let item of mapCategories| keyvalue" [value]="item.key">
          {{item.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>

    <h5>Gallery</h5>
    <div class="galleryInput">
      <div class="preview">
        <div class="nothing" *ngIf="!article.gallery || article.gallery.length <= 0">
          Gallery is empty.
        </div>
        <div class="list" *ngIf="article.gallery && article.gallery.length > 0">
          <div class="item" *ngFor="let item of article.gallery;index as i">

            <a [href]="item.url" target="_blank"*ngIf="item.type == 'youtube'">
            <img  [src]="item.image" />
            </a>
            <a [href]="item.image" target="_blank" *ngIf="item.type =='image'">
              <img [src]="item.image" />
            </a>
            <div class="t">
              {{item.type}}
              <br>
              <button class="xButton" (click)="removeGalleryAt(i)">
                X
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button mat-button color="primary" (click)="addYoutubeLinkToGallery();">Add youtube link</button>
        <button mat-button color="primary" (click)="uploadGalleryInput.click()">upload image</button>

        <input  style="display:none;" #uploadGalleryInput class="file-input" type="file" multiple (change)="uploadGalleryImage($event)" accept=".jpg,.jpeg,.png">
        <mat-progress-bar
          mode="buffer"
          *ngIf="isGalleryImageDisable">
        </mat-progress-bar>
      </div>
    </div>
    
    <br>
    <div class="editorOuter">
      <ckeditor [editor]="ckeditor" [ngModelOptions]="{standalone: true}" [(ngModel)]="editorContent"
        (ready)="TheUploadAdapterPlugin($event)" [(config)]="ckeConfig"></ckeditor>
    </div>
    <div class="dropzone" dropZone (dropped)="uploadFile($event)" [class.hovering]="isHovering">

      <br>

      <h5>{{i18n.get('cover_image')}}</h5>

      <div class="file">
        <label class="file-label">


          <input class="file-input" type="file" (change)="uploadFile($event)" accept=".jpg,.jpeg,.png">

        </label>
        <span class="file-cta">
          <span class="file-icon">
            <i class="fa fa-upload"></i>
          </span>
          <span class="file-label">
            <span>
              <br>
              <div *ngIf="percentage | async as pct">
                <span *ngIf="pct<=99">
                  <progress class="progress is-info" [value]="pct" max="100">
                  </progress>

                  {{ pct | number }}%
                </span>
                <span *ngIf="pct==100 && !downloadURL">
                  Loading... | 載入中...
                </span>
              </div>

            </span>
          </span>
        </span>

        <img class="previewIcon" [src]="downloadURL" *ngIf="downloadURL">
      </div>
    </div>
    <br>
    <button class="btn btn-primary" (click)="saveArticle()">{{i18n.get('submit')}}</button>
  </form>
</div>