<div class="dialogBody">
  <h2
    [style.display]="closeDisplay"
    (click)="closeThis()"
    style="cursor: pointer"
  >
    X
  </h2>
  <div class="title">
    <h3>{{ i18n.get("input_user_info_title") }}</h3>
    <p>{{ i18n.get("input_user_info_subtitle") }}</p>
  </div>
  <div>
    <div
      class="alert alert-danger errorMessage"
      role="alert"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <div
      class="alert alert-info errorMessage"
      role="alert"
      *ngIf="notifyMessage"
    >
      {{ notifyMessage }}
    </div>
  </div>
  <mat-dialog-content>
    <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fa fa-user"></i> </span>
      </div>
      <input
        name=""
        class="form-control"
        placeholder="Full name"
        type="text"
        [(ngModel)]="userInfo.fullname"
      />
    </div>
    <!-- form-group// -->
    <!-- <h5>{{ i18n.get("last_job_title") }}</h5> -->
    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fas fa-briefcase"></i> </span>
      </div>

      <select class="custom-select" [(ngModel)]="userInfo.workedYears">
        <option value="">{{ i18n.get("input_select_worked_years") }}</option>
        <option value="1">
          {{ i18n.get("input_worked_year_prefix") }} 0 - 2
          {{ i18n.get("input_worked_year_postfix") }}
        </option>
        <option value="2">
          {{ i18n.get("input_worked_year_prefix") }} 3 - 7
          {{ i18n.get("input_worked_year_postfix") }}
        </option>
        <option value="3">
          {{ i18n.get("input_worked_year_prefix") }} 8 - 10
          {{ i18n.get("input_worked_year_postfix") }}
        </option>
        <option value="4">
          {{ i18n.get("input_worked_year_prefix") }} 11 - 15
          {{ i18n.get("input_worked_year_postfix") }}
        </option>
        <option value="5">
          {{ i18n.get("input_worked_year_prefix") }} 15 - 20
          {{ i18n.get("input_worked_year_postfix") }}
        </option>
        <option value="6">
          {{ i18n.get("input_worked_year_over_prefix") }} 20
          {{ i18n.get("input_worked_year_postfix") }}
        </option>
      </select>
    </div> -->
    <!-- form-group// -->
    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fa fa-building"></i> </span>
      </div>
      <select class="form-control" [(ngModel)]="userInfo.jobType">
        <option value="">{{ i18n.get("select_job_type") }}</option>
        <option
          *ngFor="let o of i18n.getStringMap('map_job_type') | keyvalue"
          [value]="o.key"
        >
          {{ o.value }}
        </option>
      </select>
    </div> -->
    <!-- form-group end.// -->
    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fas fa-user-md"></i> </span>
      </div>
      <input
        class="form-control"
        placeholder="Job Title"
        type="text"
        [(ngModel)]="userInfo.jobTitle"
      />
    </div> -->
    <!-- form-group// -->

    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="far fa-building"></i> </span>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text"> {{ i18n.get("work_from") }} </span>
      </div>
      <input
        class="form-control"
        placeholder="Company Info"
        type="date"
        [value]="parseTimestamp(userInfo.workedFrom)"
        (input)="userInfo.workedFrom = parseDate($event.target.value)"
      />
      <div class="input-group-prepend">
        <span class="input-group-text"> {{ i18n.get("work_to") }} </span>
      </div>

      <input
        class="form-control"
        placeholder="Company Info"
        type="date"
        [value]="parseTimestamp(userInfo.workedTo)"
        (input)="userInfo.workedTo = parseDate($event.target.value)"
      />
    </div> -->
    <!-- form-group// -->

    <!-- <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"> <i class="fas fa-school"></i> </span>
      </div>
      <select class="form-control" [(ngModel)]="userInfo.educationLevel">
        <option value="">{{ i18n.get("select_edu_level") }}</option>
        <option
          *ngFor="let o of i18n.getStringMap('map_edu_level') | keyvalue"
          [value]="o.key"
        >
          {{ o.value }}
        </option>
      </select>
    </div> -->
    <!-- form-group end.// -->

    <div style="width: 100%">
      <h5>{{ i18n.get("user_register_for") }}</h5>
      <div>
        <mat-checkbox
          color="primary"
          class="example-margin"
          [(ngModel)]="userInfo.register_for_find_jobs"
          (ngModelChange)="userRegisterForChange($event, 'find_job')"
          >{{
            i18n.getStringMap("map_register_for").get("find_job")
          }}</mat-checkbox
        >
      </div>
      <div>
        <mat-checkbox
          color="primary"
          class="example-margin"
          [(ngModel)]="userInfo.register_for_become_advisor"
          (ngModelChange)="userRegisterForChange($event, 'become_advisor')"
          >{{
            i18n.getStringMap("map_register_for").get("become_advisor")
          }}</mat-checkbox
        >
      </div>
      <div>
        <mat-checkbox
          color="primary"
          class="example-margin"
          [(ngModel)]="userInfo.register_for_career_advisor_service"
          (ngModelChange)="
            userRegisterForChange($event, 'career_advisor_service')
          "
          >{{
            i18n.getStringMap("map_register_for").get("career_advisor_service")
          }}</mat-checkbox
        >
      </div>
    </div>

    <div [style.display]="findJobBlockDisplay">
      <mat-form-field class="example-full-width">
        <mat-label>{{ i18n.get("placeholder_expect_salary") }}</mat-label>
        <input
          matInput
          type="number"
          [placeholder]="i18n.get('placeholder_expect_salary')"
          [(ngModel)]="userInfo.expectedSalary"
        />
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-select
          [placeholder]="i18n.get('show_your_profile_question')"
          [(ngModel)]="userInfo.privacySetting"
        >
          <mat-option
            *ngFor="
              let o of i18n.getStringMap('map_profile_privacy') | keyvalue
            "
            [value]="o.key"
          >
            {{ o.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-select
          [placeholder]="i18n.get('interest_to_be_ca')"
          [(ngModel)]="userInfo.interest_to_ca"
        >
          <mat-option
            *ngFor="
              let o of i18n.getStringMap('map_interest_tobe_ca') | keyvalue
            "
            [value]="o.key"
          >
            {{ o.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div
      class="dropzone"
      dropZone
      (hovered)="toggleHover($event)"
      (dropped)="uploadFile($event)"
      [class.hovering]="isHovering"
    >
      <h5>{{ i18n.get("your_cv_optional_title") }}</h5>

      <div class="file">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            (change)="uploadFile($event)"
            accept=".doc,.docx,.odf,.pdf,.jpg,.png"
          />
        </label>
        <span class="file-cta">
          <span class="file-icon">
            <i class="fa fa-upload"></i>
          </span>
          <span class="file-label">
            <span>
              <br />
              <div *ngIf="percentage | async as pct">
                <span *ngIf="pct <= 99">
                  <progress
                    class="progress is-info"
                    [value]="pct"
                    max="100"
                  ></progress>

                  {{ pct | number }}%
                </span>
                <span *ngIf="pct == 100">
                  {{ i18n.get("message_was_uploaded") }}
                </span>
              </div>
            </span>
          </span>
        </span>
      </div>
    </div>
    <div>
      <b>{{ i18n.get("update_cv_limit_description") }}</b>
      <p>{{ i18n.get("privacy_dclaimer") }}</p>
    </div> -->
    <div class="form-group" *ngIf="downloadURL">
      <br />
      <a [href]="downloadURL" download target="_blank"
        >{{ i18n.get("download_updated_cv") }} ({{ downloadFilename }})</a
      >
    </div>
  </mat-dialog-content>

  <div class="form-group" (click)="onSubmit()">
    <button class="btn btn-primary btn-block">
      {{ i18n.get("btn_update") }}
    </button>
  </div>
</div>
