<h3 (click)="closeThisDialog()" class="thisTitle">
        <span class="closeButton" style="cursor: pointer;">X</span>
        <div class="right">
                {{i18n.get('add_admin_title')}}
        </div>
</h3>
<br>
<br>
<div>
        <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
        </div>
        <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
                {{notifyMessage}}
        </div>
</div>
<div class="form-signup">

        <input type="email" id="user-email" [(ngModel)]="inputRegEmail" class="form-control"
                [placeholder]="i18n.get('placeholder_email_address')" required autofocus="">
        <br>
        <button class="btn btn-primary btn-block" (click)="onAddAdminClick()" [(disabled)]="isBtnLoading">
                
                <span *ngIf="!isBtnLoading"><i class="fas fa-user-plus"></i>{{i18n.get('add')}}</span>
                <span *ngIf="isBtnLoading"><i class="fas fa-hourglass"></i></span>
        </button>
</div>