<div class="thisBody">
  <div class="header">
      <span class="close" (click)="closeDialog()">X</span>
      <span class="title">{{title}}</span>
  </div>
  <mat-dialog-content>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>權限/Permissions</mat-label>
      <mat-select [formControl]="permissionsControl" multiple [(ngModel)]="permissions">
        <mat-option *ngFor="let p of orderedPermissions" [value]="p">{{i18n.getStringMap('map_admin_permission').get(p)}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <button class="btn btn-primary" (click)="onSubmit()">
      Update/更新
    </button>
  </mat-dialog-content>
</div>
