import { Pipe, PipeTransform } from '@angular/core';
import { fillOnLeft } from 'src/app/class/common-objects.enum';

@Pipe({
  name: 'expiryDate'
})
export class ExpiryDatePipe implements PipeTransform {

  transform(date: Date): any {
    return `${date.getFullYear()}-${fillOnLeft(date.getMonth() + 1, '0', 2)}-${fillOnLeft(date.getDate(), '0', 2)}`
  }

}
