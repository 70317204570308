import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { I18n } from '../i18n/i18n';

import { EditWhatsappTemplatesDialogComponent } from 'src/app/admin-manage-whatsapp-templates/edit-whatsapp-templates-dialog/edit-whatsapp-templates-dialog.component'
import { enumWhatsappTemplates } from '../class/WhatsappTemplatesEnumKeys';
@Component({
  selector: 'app-admin-manage-whatsapp-templates',
  templateUrl: './admin-manage-whatsapp-templates.component.html',
  styleUrls: ['./admin-manage-whatsapp-templates.component.less']
})
export class AdminManageWhatsappTemplatesComponent implements OnInit {
  i18n = new I18n()
  constructor(
    private afFirestore: AngularFirestore,
    private dialog: MatDialog,

  ) {

  }

  ngOnInit() {
  }

  openEditWhatsappTemplateDialog(key: enumWhatsappTemplates) {
    if (key) {
      this.dialog.open(EditWhatsappTemplatesDialogComponent, {
        data: {
          id: key || ''
        }
      })
    }
  }

}
