<div class="all">
    <div class="header">
        <div class="closeButton" style="cursor: pointer;" mat-dialog-close>
            X
        </div>
        <div class="title">
            {{title}}
        </div>
    </div>
    <div class="body">
        <mat-dialog-content>
            <table border=1 *ngIf="
            thisId == enumWhatsappTemplates.confirmation_of_ca_advising_to_advisor 
            || thisId == enumWhatsappTemplates.confirmation_of_ca_advising_to_young
            || thisId == enumWhatsappTemplates.reminder_before_few_hour_to_advisor
            || thisId == enumWhatsappTemplates.reminder_before_few_hour_to_young
            ">
                <tr>
                    <td>Replacer</td>
                    <td>Description</td>
                </tr>
                <tr>
                    <td>[[young_name]]</td>
                    <td>Young's Name</td>
                </tr>
                <tr>
                    <td>[[young_phone_number]]</td>
                    <td>Young's phone number</td>
                </tr>
                <tr>
                    <td>[[datetime]]</td>
                    <td>Date and time for that meeting</td>
                </tr>
                <tr>
                    <td>[[advisor_name]]</td>
                    <td>name of advisor</td>
                </tr>
                <tr>
                    <td>[[deliver_mode]]</td>
                    <td>Mode of delivery</td>
                </tr>
            </table>
            <form #form (submit)="submitForm()">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Templates</mat-label>
                    <textarea matInput autofocus required name="content" [rows]="textareaWidth" placeholder="Ex. It makes me feel..."  [(ngModel)]="content">{{content}}</textarea>
                </mat-form-field>
            </form>
        </mat-dialog-content>
    </div>

    <mat-dialog-actions>
        <button mat-button color="primary" mat-dialog-close [disabled]="isLoading">{{i18n.translate.cancel}}</button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button [disabled]="isLoading" color="accent" (click)="submitForm()">
            <span *ngIf="!isLoading">{{i18n.translate.submit}}</span>
            <mat-progress-bar mode="buffer" *ngIf="isLoading">
            </mat-progress-bar>
        </button>
    </mat-dialog-actions>
</div>