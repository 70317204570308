export interface ISportCourseApplication{
    id?: string,
    createDateTime?: string,
    updateDateTime?: string,
    uid: string,
    courseId: string,
    name: string,
    phoneNumber: string,
    email: string,
    address: string,
    remarks: string,
    status:ISportCourseApplicationStatus,
    age: string,
    sport_time_per_week: string,
    sport_minute_per_time: string,
    what_sport_do: string,
}
export enum ISportCourseApplicationStatus{
    pending='pending',
    accepted="accepted",
    canceled="canceled",
}