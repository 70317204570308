import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { checkIsInCareerAdvisor, getVideoFromUrl, getYoutubeCoverFromUrl, makeid } from 'src/app/class/common-objects.enum';
import { storageKeys } from './../../../../class/common-objects.enum';
import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { finalize, catchError } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { i18n_locate } from '../../../../i18n/i18n_const';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CKEditorUploadAdapter, CKEFontColors, CKEDefaultToolbar } from '../../../../class/CKEditorUploadCommonAdapter';
import { TheArticle } from '../../../../class/the-article';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, checkIsInCareerAdvisorAdmin, openSimpleMessageDialog } from '../../../../class/common-objects.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as ClassicEditor from 'src/ckeditor/ckeditor';
import 'src/ckeditor/translations/zh';

@Component({
  selector: 'app-add-articles',
  templateUrl: './add-articles.component.html',
  styleUrls: ['./add-articles.component.less']
})
export class AddArticlesComponent implements OnInit {
  i18n = new I18n()
  article: TheArticle = {
    id: '',
    title: '',
    content: '',
    coverURL: '',
    status: 'active',
    createAt: null,
    updateAt: null,
    category: "",
    author: '',
    gallery: [],
  }
  uploadingImageCount = 0
  get thisTitle() {
    if (this.isUpdate) {
      return this.i18n.get('update_article') + " " + this.initId
    } else {
      return this.i18n.get('add_article')
    }
  }
  froalaOptions = {}
  get editorContent() {
    return this.article.content
  }
  get mapCategories() {
    const url = this.router.url
    if (url.startsWith('/admin/sportRewine')) {
      return this.i18n.translate.map_sport_rewine_categoies
    } else if (url.startsWith('/admin/sportRevisions')) {
      return this.i18n.translate.map_sport_revision_categories
    } else if (url.startsWith('/admin/sportArticles')) {
      return this.i18n.translate.map_sport_article_categories
    } else {
      return this.i18n.getStringMap(checkIsInCareerAdvisor() ? 'map_ca_blog_categories' : 'map_blog_categories')
    }
  }
  set editorContent(v) {
    console.log(v)
    this.article.content = v
  }
  get froalaLanguage() {
    if (this.i18n.currentLocate == i18n_locate.en) {
      return 'en'
    } else {
      return 'zh'
    }
  }
  inputPhotoURL = ''
  currentUser: firebase.User = null
  allowFileType = ["image/png", "image/jpeg", "image/jpg"]
  allowFileSize = 10 * 1024 * 1024

  notifyMessage = ''
  errorMessage = ''
  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  get downloadURL() {
    return this.article.coverURL;
  }
  set downloadURL(v: string) {
    this.article.coverURL = v
  }
  downloadFilename: string

  // State for dropzone CSS toggling
  isHovering: boolean;
  isRegBtnLoading = false;
  private isDbNoData = true
  public ckeditor;

  get keyFirestoreCollection() {
    const url = this.router.url
    if (checkIsInCareerAdvisorAdmin()) {
      return dbKeys.ca_blog_articles
    } else {
      if (url.startsWith('/admin/sportArticle')) {
        return dbKeys.sport_articles
      } else if (url.startsWith('/admin/sportRewine')) {
        return dbKeys.sport_rewines
      } else if (url.startsWith('/admin/sportRevision')) {
        return dbKeys.sport_revisions
      } else {
        return dbKeys.blog_articles
      }
    }
  }
  TheUploadAdapterPlugin(eventData) {
    console.log(JSON.stringify(Array.from(eventData.ui.componentFactory.names())))
    console.log((Array.from(eventData.ui.componentFactory.names())))
    console.log('TheUploadAdapterPlugin called');
    const s = this
    console.log(JSON.stringify(eventData.config.toolbar))
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log(btoa(loader.file));
      return new CKEditorUploadAdapter(loader, s.storage);
    };
  }
  ckeConfig = {
    language: this.froalaLanguage,
    uiColor: '#AADC6E',
    fontColor: CKEFontColors,
    fontBackgroundColor: CKEFontColors,

    toolbar: CKEDefaultToolbar
  }
  initId = ''
  get isUpdate() {
    return !!this.initId
  }
  constructor(
    public storage: AngularFireStorage,
    public auth: AngularFireAuth,
    public afFunctions: AngularFireFunctions,
    public firestore: AngularFirestore,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    //(ClassicEditor.builtinPlugins as Array<any>) = [ColorUI]
    this.ckeditor = ClassicEditor;
    this.initId = activatedRoute.snapshot.params.id
    if (this.initId) {
      this.firestore.collection(this.keyFirestoreCollection).doc(this.initId).get().subscribe(snapshot => {
        if (snapshot && snapshot.exists) {
          const data = snapshot.data()
          if (data) {
            this.article = <TheArticle>data
            this.article.createAt = (<firebase.firestore.Timestamp>data.createAt).toDate()
            this.article.updateAt = (<firebase.firestore.Timestamp>data.updateAt).toDate()
            if (!this.article.gallery) {
              this.article.gallery = []
            }
          }
        }
      })
    }
  }
  ngOnInit() {

    this.auth.authState.subscribe(user => {
      if (user && user.uid) {
        this.currentUser = user
      } else {
        this.currentUser = null
      }
    })
  }
  public async saveArticle() {
    if (this.currentUser) {
      console.log(this.article)
      if (!this.article.title) {

      } else if (!this.article.content) {

      } else {
        try {
          if (this.isUpdate) {
            this.article.updateAt = new Date()
            await this.firestore.collection(this.keyFirestoreCollection).doc(this.article.id)
              .update(this.article)

            openSimpleMessageDialog(this.dialog, '更新成功！\Update Success!')
            if (checkIsInCareerAdvisorAdmin()) {
              this.router.navigate(['caAdmin', 'listArticles'])
            } else {
              const url = this.router.url
              let prefix = 'listArticles'
              if (url.startsWith('/admin/sportArticle')) {
                prefix = 'sportArticles'
              } else if (url.startsWith('/admin/sportRewine')) {
                prefix = 'sportRewines'
              } else if (url.startsWith('/admin/sportRevision')) {
                prefix = 'sportRevisions'
              }
              this.router.navigate(['admin', prefix])
            }
          } else {

            this.article.createAt = new Date()
            this.article.updateAt = new Date()
            this.article.status = 'active'
            const ref = await this.firestore.collection(this.keyFirestoreCollection).add(this.article)
            await ref.update({
              id: ref.id
            })
            openSimpleMessageDialog(this.dialog, '新增成功！\nAdded Success!')
            if (checkIsInCareerAdvisorAdmin()) {
              this.router.navigate(['caAdmin', 'listArticles'])
            } else {
              const url = this.router.url
              console.log(url)
              let prefix = 'listArticles'
              if (url.startsWith('/admin/sportArticle')) {
                prefix = 'sportArticles'
              } else if (url.startsWith('/admin/sportRewine')) {
                prefix = 'sportRewines'
              } else if (url.startsWith('/admin/sportRevision')) {
                prefix = 'sportRevisions'
              }
              this.router.navigate(['admin', prefix])
              console.log(prefix)
            }
          }
        } catch (e) {
          console.error(e)
          this.notifyMessage = ''
          this.errorMessage = `操作失敗\nFailed.\nCODE: ${e.message}`
        }
      }
    }
  }



  public updateDownloadURL() {
    let path = this.inputPhotoURL
    console.log(path)
    if (!path) return
    const fileRef: AngularFireStorageReference = this.storage.ref(
      path
    )
    let sub = fileRef.getDownloadURL().subscribe(downloadURL => {
      //this.userInfo.cvURL = downloadURL
      this.downloadURL = downloadURL
      console.log(path)
      //console.log(this.userInfo.cvURL)
      console.log(downloadURL)
      fileRef.getMetadata().subscribe(d => {
        //console.log(d)
        let p = d.fullPath
        let tIndex = p.lastIndexOf('/') + 1
        if (tIndex >= 0) this.downloadFilename = p.substring(tIndex)
      })
      sub.unsubscribe()
    });

  }
  public uploadFile(event: any): void {
    this.errorMessage = ''
    this.notifyMessage = ''
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get('err_msg_file_too_large')
        return
      } else if (!this.allowFileType.includes(file.type)) {
        this.errorMessage = this.i18n.get('err_msg_disallow_file_type')
        return
      }
      try {
        this.isRegBtnLoading = true;
        const path = `${storageKeys.tmp}/${this.currentUser.uid}/${(new Date().toISOString())}_${makeid(10)}_${file.name}`;
        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        console.log(path)
        this.percentage = task.percentageChanges()
        task.snapshotChanges()
          .pipe(finalize(() => {
            const fileRef: AngularFireStorageReference = this.storage.ref(
              path
            )
            fileRef.getDownloadURL().subscribe(downloadURL => {
              //this.userInfo.cvURL = downloadURL
              this.inputPhotoURL = path
              this.isRegBtnLoading = false;
              console.log(this.inputPhotoURL)
              this.downloadURL = downloadURL
              this.updateDownloadURL()
              //sub.unsubscribe()
            });
          }), catchError(err => {
            this.errorMessage = err.message
            this.isRegBtnLoading = false;
            throw err;
          })).subscribe()
      } catch (e) {
        this.isRegBtnLoading = false;
        this.errorMessage = e.message
        console.error(e)
      }
    }
  }
  addYoutubeLinkToGallery() {
    const userInput = prompt('youtube link...', '')
    if (userInput) {
      const urlmeta = getVideoFromUrl(userInput)
      if (urlmeta?.service == 'youtube' && urlmeta.id) {
        this.article.gallery.push({
          type:'youtube',
          url:userInput,
          image:this.getYoutubeCover(userInput)
        })
      }
    }
  }
  removeGalleryAt(i:number){
    this.article.gallery.splice(i,1)
  }
  getYoutubeCover(url: string){
    return getYoutubeCoverFromUrl(url)
  }
  isGalleryImageDisable = false;
  public uploadGalleryImage(event: any): void {
    console.log(event)
    this.errorMessage = ''
    this.notifyMessage = ''
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get('err_msg_file_too_large')
        return
      } else if (!this.allowFileType.includes(file.type)) {
        this.errorMessage = this.i18n.get('err_msg_disallow_file_type')
        return
      }

      this.isGalleryImageDisable = true;
      try {
        this.isRegBtnLoading = true;
        const path = `${storageKeys.tmp}/${this.currentUser.uid}/${(new Date().toISOString())}_${makeid(10)}_${file.name}`;
        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        console.log(path)
        this.percentage = task.percentageChanges()
        task.snapshotChanges()
          .pipe(finalize(() => {
            const fileRef: AngularFireStorageReference = this.storage.ref(
              path
            )
            fileRef.getDownloadURL().subscribe(downloadURL => {
              //this.userInfo.cvURL = downloadURL
              this.inputPhotoURL = path
              // this.isRegBtnLoading = false;
              console.log(this.inputPhotoURL)
              this.article.gallery.push({
                type:'image',
                image:downloadURL,
                url: ''
              })

              this.isGalleryImageDisable = false;
              //sub.unsubscribe()
            });
          }), catchError(err => {
            this.errorMessage = err.message
            this.isGalleryImageDisable = false;
            // this.isRegBtnLoading = false;
            throw err;
          })).subscribe()
      } catch (e) {
        // this.isRegBtnLoading = false;
        this.errorMessage = e.message
        console.error(e)
      }
    }
  }
  isVideo(url: string){
    if (url) {
      const urlmeta = getVideoFromUrl(url)
      if (urlmeta?.service == 'youtube' && urlmeta.id) {
        return true
      }
    }
    return false;
  }
}
