import { firestore } from "firebase";

export interface ISportCourse {
    id?: string,
    title: string,
    subtitle: string,
    description: string,
    countName: string,
    holdDateTime: firestore.Timestamp,
    holdDateText: string,
    holdTimeText: string,
    location: string,
    type:ISportCourseType,
    status:ISportCourseStatus,
    createDateTime?: firestore.Timestamp,
    comingUpNotice: string,
    altReserveLink?:string,
}
export enum ISportCourseType{
    A='A',
    B="B",
}
export enum ISportCourseStatus{
active='active',
deactive="deactive",
}