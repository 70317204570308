import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { ImageSlide, dbKeys, AdsBanner, openSimpleMessageDialog } from 'src/app/class/common-objects.enum';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AddMainpageDialogSliderComponent } from '../admin-mainpage-slider-edit/add-mainpage-dialog-slider/add-mainpage-dialog-slider.component';
import { AddAdminAdsBannerDialogComponent } from './add-admin-ads-banner-dialog/add-admin-ads-banner-dialog.component';

@Component({
  selector: 'app-admin-ads-list',
  templateUrl: './admin-ads-list.component.html',
  styleUrls: ['./admin-ads-list.component.less']
})
export class AdminAdsListComponent implements OnInit {
  errorMessage = ''
  notifyMessage = ''
  i18n = new I18n()
  arrImageSlides : Array<AdsBanner> = []
  constructor(
    public dialog : MatDialog,
    public firestore: AngularFirestore,
    public storage : AngularFireStorage
  ) { }

  ngOnInit() {
    this.reloadTable()
  }

  addRecordDialog(){
    let dialogRef = this.dialog.open(AddAdminAdsBannerDialogComponent,{

    })
    dialogRef.afterClosed().subscribe(result=>{
      if(result){
        this.reloadTable()
      }
    })
  }
  editRecordDialog(id:string){
    let dialogRef = this.dialog.open(AddAdminAdsBannerDialogComponent,{
      data:{
        id: id
      }
    })
    dialogRef.afterClosed().subscribe(result=>{
      if(result){
        this.reloadTable()
      }
    })
  }
  async reloadTable(){
    this.arrImageSlides  = []
    let results = await this.firestore.firestore.collection(dbKeys.global_ads_banner).get()
    if(results && results.docs){
      console.log(results.docs)
      for(let doc of results.docs){
        if(doc && doc.exists &&doc.data()){
          let d : AdsBanner = <AdsBanner> doc.data()
          if(d){
             d.imageURL = await this.storage.ref(d.imageURL).getDownloadURL().toPromise()
             if(!d.id){
                d.id = doc.id
             }
            this.arrImageSlides.push(d)
          }
        }
      }
    }
  }
  async removeSlide(id:string){
    try{
    let remove = await this.firestore
    .collection(dbKeys.global_ads_banner)
    .doc(id)
    .delete()
      openSimpleMessageDialog(this.dialog, "remmove success!")
      this.reloadTable()
    }catch(e){
      openSimpleMessageDialog(this.dialog, `remove failed: ${e.message}`)
    }
  }
  sortData(target){

  }
}
