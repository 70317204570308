<div class="thisBody">
    <div class="thisTitlte">
        <span class="titleContainer">{{thisPageTitle}}({{keyFirestoreCollection}})</span>
        <button (click)="toAddPage()" class="btn btn-light">{{i18n.get('add')}}</button>
    </div>
    <div>
        <div *ngIf="arrArticles === null">
            {{i18n.get('loading')}}
        </div>
        <div *ngIf="arrArticles!=null && arrArticles.length <= 0">
            {{i18n.get('nothing_to_show')}}
        </div>
        <div *ngIf="arrArticles && arrArticles.length > 0">
            <table border="1">
                <tr>
                    <td>{{i18n.get('title')}}</td>
                    <td>{{i18n.get('author')}}</td>
                    <td>{{i18n.get('job_table_status')}}</td>
                    <td>{{i18n.get('cover_image')}}</td>
                    <td>{{i18n.get('created_at')}}</td>
                    <td>{{i18n.get('updated_at')}}</td>
                    <td>{{i18n.get('edit')}}</td>
                    <td>{{i18n.get('job_table_job_preview')}}</td>
                    <td>{{i18n.get('admin_management')}}</td>
                </tr>
                <tr *ngFor="let o of arrArticles; index as i">
                    <td>{{o.title}}</td>
                    <td>{{o.author}}</td>
                    <td>{{o.status}}</td>
                    <td>
                        <a *ngIf="o.coverURL" [href]="o.coverURL" target="_blank">
                            <img [src]="o.coverURL" style="width: 100px;">
                        </a>
                    </td>
                    <td>{{dateToDisplayString(o.createAt)}}</td>
                    <td>{{dateToDisplayString(o.updateAt)}}</td>
                    <td><button class="btn btn-light" (click)="toEdit(o.id)">{{i18n.get('edit')}}</button></td>
                    <td><a [href]="'/'+singleArticlePrefix+'/'+o.id" target="_blank"><button class="btn btn-light">{{i18n.get('view')}}</button></a></td>
                    <td>
                        <button class="btn btn-light" *ngIf="o.status == 'deactive'" (click)="setStatus(i, o.id, 'active')">
                            {{i18n.get('set_to_active')}}
                        </button>
                        <button class="btn btn-light" *ngIf="o.status == 'active'" (click)="setStatus(i, o.id, 'deactive')">
                            {{i18n.get('set_to_deactive')}}
                        </button>
                    </td>

                </tr>
            </table>
        </div>
    </div>
</div>