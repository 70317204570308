import { analyticsEventKeys } from './../../../class/common-objects.enum';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, shuffle, AdsBanner } from 'src/app/class/common-objects.enum';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-random-ads-banner-w300h250',
  templateUrl: './random-ads-banner-w300h250.component.html',
  styleUrls: ['./random-ads-banner-w300h250.component.less']
})
export class RandomAdsBannerW300h250Component implements OnInit {

  constructor(
    public firestore : AngularFirestore,
    public storage: AngularFireStorage,
    public analytic: AngularFireAnalytics
  ) { }
  thisAdsBanner : AdsBanner = null
  async ngOnInit() {
    this.firestore
    .collection(dbKeys.global_ads_banner,ref=>ref.where('type', '==', '300x250'))
    .get().subscribe(async(result)=>{
      if(result && result.docs){
        let arr : firebase.firestore.QueryDocumentSnapshot[]= shuffle(result.docs)
        if(arr && arr.length >0){
        let o = arr[0]
        if(o && o.exists){
          let d = o.data()
          this.thisAdsBanner  = <AdsBanner> d
          this.thisAdsBanner.imageURL = await this.storage.ref(this.thisAdsBanner.imageURL).getDownloadURL().toPromise()
        }
        }
      }
    })
  }
  async clickURLInject(url:string){
    await this.analytic.logEvent(analyticsEventKeys.ads_click, {
      adsId: this.thisAdsBanner.id,
      url: this.thisAdsBanner.clickURL,
      image: this.thisAdsBanner.imageURL
    })
  }
}
