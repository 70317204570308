import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { I18n } from 'src/app/i18n/i18n';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, openSimpleMessageDialog, cloudFunctionsKeys } from '../../../class/common-objects.enum';
import { CompanyUserPermission, CompanyUserPermissionEnumItem } from '../../../class/CompanyUserPermission';
import { ChangeCompanyPermissionDialogComponent } from './change-company-permission-dialog/change-company-permission-dialog.component';
import moment from 'moment';
@Component({
  selector: 'app-list-company',
  templateUrl: './list-company.component.html',
  styleUrls: ['./list-company.component.less']
})
export class ListCompanyComponent implements OnInit {
  arrAdminUsers = null;
  i18n = new I18n();
  notifyMessage = '';
  errorMessage = '';
  sortedData: any[] = null;
  companyPermissions: Map<string, CompanyUserPermission> = null;

  constructor(
    public afFunctions: AngularFireFunctions,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore
  ) { }
  isBtnLoading = false;
  ngOnInit() {
    this.updateLists();
  }

  subCompanyPermissions: Subscription = null;
  f;
  setSelftPermissions(id: string, arrPermissions: Array<CompanyUserPermissionEnumItem>) {

  }
  getSelfPermissions(id: string) {
    if (this.companyPermissions && this.companyPermissions.size > 0) {
      const cp = this.companyPermissions.get(id);
      if (cp && cp.permissions && cp.permissions.length > 0) {
        return cp.permissions;
      } else {
        return [];
      }
    }
    return null;
  }
  updateLists() {
    let sub = this.afAuth.authState.subscribe(async auth => {
      if (auth && auth.uid) {
        let token = await auth.getIdToken(true);
        console.log(token);
        let thisTask = this.afFunctions.httpsCallable(cloudFunctionsKeys.getAllCompanies)({ token: token });
        this.firestore.collection(dbKeys.cache_list_company).valueChanges().subscribe(result => {
          console.log((result));
          this.arrAdminUsers = [];
          if (result && result.length > 0) {
            for (let o of result) {
              if (o) {
                this.arrAdminUsers.push(o);
              }
            }
          }
          this.sortedData = this.arrAdminUsers;
          this.sortedData.sort((a, b) => {
            const aCreationTime = moment(a.user.metadata.creationTime);
            const bCreationTime = moment(b.user.metadata.creationTime);

            if (aCreationTime.isAfter(bCreationTime)) {
              return -1;
            }
            if (bCreationTime.isAfter(aCreationTime)) {
              return 1;
            }

            return 0;
          });
          console.log(this.sortedData);
        });
      }
      sub.unsubscribe();
    });
  }

  async toBan(email: string, ban: boolean) {
    const currentUser = this.afAuth.auth.currentUser;
    if (email && currentUser && currentUser.uid) {
      this.isBtnLoading = true;
      const token = await currentUser.getIdToken();
      try {
        const result = await this.afFunctions.httpsCallable(cloudFunctionsKeys.banCompanyAccount)({
          token: token,
          email: email,
          ban: ban
        }).toPromise();
        if (result && result === 'success') {
          // success
          openSimpleMessageDialog(this.dialog, `操作成功！\nAction Success!`);
        } else {
          openSimpleMessageDialog(this.dialog, `Action Failed\n操作失敗\nCODE: ${result}`);
        }
      } catch (e) {
        console.error(e);
        openSimpleMessageDialog(this.dialog, `Failed!\n操作失敗！\n${e.message}`);
      }
      this.isBtnLoading = false;
    }
  }
  sortData(sort: Sort) {

    const data = this.arrAdminUsers.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'calories': return compare(a.calories, b.calories, isAsc);
        case 'fat': return compare(a.fat, b.fat, isAsc);
        case 'carbs': return compare(a.carbs, b.carbs, isAsc);
        case 'protein': return compare(a.protein, b.protein, isAsc);
        default: return 0;
      }
    });
  }
  openPermissionDialog(uid: string) {
    this.dialog.open(ChangeCompanyPermissionDialogComponent, {
      data: {
        uid: uid
      }
    });
  }
  async approved(index: number, shouldApproveUid: string) {
    let token = await this.afAuth.auth.currentUser.getIdToken(true);
    if (!token) return;
    this.isBtnLoading = true;
    let sub = this.afFunctions.httpsCallable(cloudFunctionsKeys.approveCompany)({
      token: token,
      approveUid: shouldApproveUid
    }).subscribe(result => {
      if (result === true) {
        openSimpleMessageDialog(this.dialog, 'approved');
        this.sortedData[index].isApproved = true;
      } else {
        openSimpleMessageDialog(this.dialog, 'not approved');
      }
      this.isBtnLoading = false;
      sub.unsubscribe();
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
