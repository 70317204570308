<div class="background">
  <div class="container-wrap">
    <div class="main container">
      <div class="row">
        <div class="col-12">
          <div class="talent-search-service-title">
            HAPPY-RETIRED TALENT SEARCH SERVICE
          </div>
          <div class="talent-search-service-summary">
            <p>
              Targeting Golden Age talents who are still active in the job
              market, HRCA’S seasoned search experts are here to help employers
              resolve recruitment challenges. Employers can enjoy the
              professional search services of our dedicated Talent Search team
              at a reasonable market rate.
            </p>
            <p>
              Our Talent Search experts are dedicated to offering you tailored
              services to meet your business requirements and drive successful
              placement. Through comprehensive screening processes and
              competency-based interviewing, we source and provide you with high
              calibre candidates who possess the right skills, experience,
              personalities, cultural fit and mindset.
            </p>
          </div>
          <div class="talent-search-service-sub-header">Why Golden Age</div>
          <div>
            <p>
              Personal experience and exposure cannot be taught or copied
              easily, and it takes time to build a wealth of knowledge and
              business network. A well-matched Golden Age can also be a great
              mentor to supplement and support your existing staff. All these
              can be translated into savings in terms of costs, time, training
              and resources, and hence better business results.
            </p>
          </div>
          <div flex>
            <div flex-item="grow-1"></div>
            <div flex-item="grow-2">
              <img src="/assets/service-2.png" />
            </div>

            <div flex-item="grow-1"></div>
          </div>
          <div class="talent-search-service-sub-header">Our Expertise</div>
          <div>
            <p>
              The experience of our seasoned search experts covers general
              management, sales and marketing, finance and accounting, human
              resources, engineering and information technology in business
              sectors such as retail, FMCG, telecommunication, information
              technology, property and construction, and manufacturing.
            </p>
          </div>
          <div class="talent-search-service-sub-header">Our Strengths</div>
          <div>
            <ul>
              <li>
                Dedicated team of consultants and research specialists to manage
                and drive each search assignment to completion and success
              </li>
              <li>
                Acting as your business partner and offer advice on recruitment
                strategy
              </li>
              <li>
                Excellent understanding of entrepreneur expectations and client
                needs
              </li>
              <li>Strong people network and diverse search channels</li>
            </ul>
          </div>
          <div class="contact-us">
            <div class="contact-us-title">CONTACT US NOW FOR MORE DETAILS!</div>
            <div>
              Email:
              <a href="mailto:job@happy-retired.org">job@happy-retired.org</a>
            </div>
            <div>Tel: 2833 6755</div>
          </div>
          <div
            class="talent-search-service-next-page-button"
            (click)="toModel()"
          >
            Click here to learn more about our holistic approach to be a
            socially responsible organization<br />A Holistic Approach to
            Achieve the Happy-Retired Mission
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
