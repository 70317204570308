<mat-progress-bar
    mode="buffer"
    *ngIf="!course">
</mat-progress-bar>
<div class="all" *ngIf="course" [style.backgroundColor]="bgColor">
    <div class="body">
        <h3>{{course.title}}</h3>
        <h3>{{course.subtitle}}</h3>
        <p>
            {{course.description}}
        </p>
        <div class="details">
            <div class="left">
                <ng-container *ngIf="isActiveCourse">

                    <span class="item">
                        課程：{{course.countName}}
                    </span>
                    <br>
                    <span class="item">
                        日期：{{course.holdDateText}}
                    </span>
                    <br>
                    <span class="item">
                        時間：{{course.holdTimeText}}
                    </span>
                    <br>
                    <span class="item">
                        地址：{{course.location}}
                    </span>
                </ng-container>
                <ng-container *ngIf="!isActiveCourse">
                    <br>
                    <h4>
                        {{course.comingUpNotice}}
                </h4>
                </ng-container>
                
            </div>
            <div class="right">
                <a *ngIf="isActiveCourse && !course.altReserveLink" mat-raised-button [color]="btnColor"
                    [routerLink]="'/sport-course-apply/'+course.id"
                 >
                    立&nbsp;&nbsp;即<br>參&nbsp;&nbsp;加
            </a>

                <a *ngIf="isActiveCourse && course.altReserveLink" mat-raised-button [color]="btnColor"
                    [href]="course.altReserveLink"
                  >
                    立&nbsp;&nbsp;即<br>參&nbsp;&nbsp;加
            </a>

                <button *ngIf="!isActiveCourse" mat-button style="color: white;"
                disabled>
                    即&nbsp;&nbsp;將<br>開&nbsp;&nbsp;始
                </button>
            </div>
        </div>
    </div>
</div>