import { Subscription } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { I18n } from "../../../i18n/i18n";
import {
  MemberTalentItem,
  dbKeys,
  analyticsEventKeys,
  textToArray,
} from "../../../class/common-objects.enum";
import { IndustryCatItem } from "../../../class/IndustryCatItem";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { i18n_locate } from "src/app/i18n/i18n_const";
import { MatDialog } from '@angular/material';
import { ContactUsDialogComponent } from '../contact-us-dialog/contact-us-dialog.component';
import { LoggingKey, LoggingUtilsService } from "src/app/utils/logger-utils.service";

@Component({
  selector: "app-view-single-talentpool",
  templateUrl: "./view-single-talentpool.component.html",
  styleUrls: ["./view-single-talentpool.component.less"],
})
export class ViewSingleTalentpoolComponent implements OnInit {
  initId = "";
  i18n = new I18n();
  talnet: MemberTalentItem = null;

  topIndustries: Map<string, IndustryCatItem> = new Map();
  subIndustries: Map<string, IndustryCatItem> = new Map();

  arrTalentItems: Array<MemberTalentItem> = null;
  lastDoc: firebase.firestore.QueryDocumentSnapshot = null;
  logged = false
  constructor(
    public activatedRoute: ActivatedRoute,
    public firestore: AngularFirestore,
    public analytics: AngularFireAnalytics,
    public router: Router,
    public dialog: MatDialog,
    private logger: LoggingUtilsService,
  ) {
    const data = activatedRoute.snapshot.params;
    if (data && data.id) {
      this.initId = data.id;
      this.firestore
        .collection(dbKeys.job_matching_items)
        .doc<MemberTalentItem>(this.initId)
        .valueChanges()
        .subscribe((data) => {
          if (data) {
            console.log(data);
            this.talnet = data;
          }
          if (!this.logged) {
            analytics.logEvent(analyticsEventKeys.job_matching_view, data);
            logger.log(LoggingKey.telentpool_view, this.initId)
            this.logged = true
          }
          this.restartGetViewCount(this.initId)
        });
    }
  }

  getTopIndustryNameById(id: string) {
    return this.outputIndustry(id)
  }

  private outputIndustry(id: string, locate?: i18n_locate) {
    const s = this.topIndustries.get(id);
    if (s && s.id) {
      const industry = s;
      if ((locate || this.i18n.currentLocate) == i18n_locate.hant) {
        return industry.hant_name;
      } else {
        return industry.en_name;
      }
    }
    return "其他/Other";
  }

  private outputSubIndustry(id: string, locate: i18n_locate) {
    return this._outputSubIndustry(id, locate).trim();
  }
  private _outputSubIndustry(id: string, locate: i18n_locate) {
    const s = this.subIndustries.get(id);

    if (s && s.id) {
      const industry = s;
      if (locate == i18n_locate.hant) {
        return industry.hant_name;
      } else {
        return industry.en_name;
      }
    }
    return "其他/Other";
  }

  getLocate() {
    let { talnet: talent } = this;
    return talent ? talent.locate : this.i18n.currentLocate;
  }

  getTalentDomain() {
    let { talnet: talent } = this;
    return this.outputIndustry(talent.domain, talent.locate);
  }

  getTalentExpertise() {
    let { talnet: talent } = this;
    let x = talent.expertises.map(e => this.outputSubIndustry(e, talent.locate));
    if (x.length > 0) {
      return x.join(this.i18n.getStringWithLocate('comma', talent.locate) as string);
    }
    return "";
  }

  getTalentSubIndustries() {
    let { talnet: talent } = this;
    let x = talent.subIndustries.map(e => this.outputSubIndustry(e, talent.locate));
    if (x.length > 0) {
      return x.join(this.i18n.getStringWithLocate('comma', talent.locate) as string);
    }
    return "";
  }

  sub01: Subscription = null;
  subIndustriesUpdate() {
    if (this.sub01) {
      try {
        this.sub01.unsubscribe();
      } catch (e) { }
    }
    this.firestore
      .collection(dbKeys.map_industry_cats)
      .valueChanges()
      .subscribe((d) => {
        if (d && d.length > 0) {
          for (let o of d) {
            const any0: any = <any>o;
            const industry: IndustryCatItem = <IndustryCatItem>any0;
            if (industry && industry.id) {
              if (industry.parent_id) {
                this.subIndustries.set(industry.id, industry);
              } else {
                this.topIndustries.set(industry.id, industry);
              }
            }
          }
        }
      });
  }
  ngOnInit() {
    this.subIndustriesUpdate();
  }

  textToArray(t: string) {
    return textToArray(t);
  }
  navToTalentpoolListing(id: string) {
    if (id) {
      this.router.navigate(["/talentpool", id]);
    }
  }
  openMessageDialog(id: string) {
    const message = `我有意聯絡這位求職者（${id}） \nI want to contact this candidate (${id})`
    this.dialog.open(ContactUsDialogComponent, {
      data: {
        message: message
      }
    })
  }
  tryGoBack() {
    const canGoBack = !!document.referrer
    if (canGoBack) {
      history.back()
    } else {
      this.router.navigate(['talentpool'])
    }
  }

  viewCount = 0
  sub0GetViewCount: Subscription
  restartGetViewCount(id: string) {
    if (this.sub0GetViewCount) {
      try {
        this.sub0GetViewCount.unsubscribe();
      } catch (e) {
        console.error(e)
      }
    }

    this.sub0GetViewCount = this.firestore.collection(dbKeys.logging, ref => ref.where('type', '==', LoggingKey.telentpool_view).where('dataId', '==', id))
      .valueChanges().subscribe(v => {
        if (v && v.length > 0) {
          this.viewCount = v.length || 0;
        }
      })

  }
}
