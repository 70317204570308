<app-main-page-slider [pageType]="pageType"></app-main-page-slider>
<div class="banner">
  <div class="selectContainer" flex="column">
    <div flex-item="grow-5">
      <div class="longBanner">

      {{i18n.get('select_good_job_to_get_ca_appoinment_0')}}
      </div>
    </div>
    <div flex-item="grow-1">
      <div flex="justify-center">
        <div class="input-group selectControlGroup">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">
              <i class="searchIcon fas fa-filter"></i>
            </label>
          </div>

            <select class="form-control" id="selectFilter0JobMatching"
              (change)="onFilteringSelectChange($event.target.value)" [(ngModel)]="selectValue">
              <option value=" " disabled>
                {{ i18n.get("select_job_type") }}
              </option>
              <option value="">所有/All</option>
              <option *ngFor="let o of topIndustries | keyvalue" [value]="o.key">
                {{ getIndustryNameById(o.key) }}
              </option>
            </select>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div calss="row">
    <div class="col-12 col-lg-8 offset-lg-2 formButtonContainer">
      <a class="btn btn-primary btn-block btn-lg formButton"
        href="https://docs.google.com/forms/d/e/1FAIpQLSerI8JEt9M_pgmZbhF7tW3IKVYJSZ6XDNRer8PAT-9UZNvvLA/viewform"
        target="_blank">
        {{i18n.get('select_good_job_to_get_ca_appoinment_1')}}
      </a>
    </div>
  </div>
</div>

<div class="content">
  <div *ngIf="showLoading" class="row">
    <div class="col noResultsDescription">{{ i18n.get("loading") }}...</div>
  </div>
  <div *ngIf="!showLoading && advisors.length === 0" class="row">
    <div class="col noResultsDescription">
      {{ i18n.get("nothing_to_show") }}
    </div>
  </div>
  <div *ngIf="!showLoading && advisors.length > 0" class="row">
    <div class="col-12">
      <ngx-masonry [options]="masonryOptions">
        <app-ca-advisor-card class="grid-sizer" ngxMasonryItem *ngFor="let item of advisors" [advisor]="item"
          [topIndustries]="topIndustries"></app-ca-advisor-card>
      </ngx-masonry>
    </div>
  </div>
  <div *ngIf="!showLoading && showMore" class="nextContainer row">
    <div class="col-2 offset-5">
      <a class="btn btn-block btn-primary formButton" (click)="fetchAdvisors()">{{ i18n.get("view_more") }}</a>
    </div>
  </div>
</div>