import { Component, OnDestroy, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { TheActivity, convertActivityDocumentDataToLocalVarible } from 'src/app/class/the-activity';
import { CompanyInfo } from 'src/app/class/company-info';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { dbKeys, fillOnLeft, checkIsInCareerAdvisor, getLastdayOfMonth, checkIsVlunteerEvent, volunteerKeys } from 'src/app/class/common-objects.enum';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { analyticsEventKeys } from '../../class/common-objects.enum';
import moment from 'moment';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';

@Component({
  selector: 'app-list-activities',
  templateUrl: './list-activities.component.html',
  styleUrls: ['./list-activities.component.less']
})
export class ListActivitiesComponent implements OnInit, OnDestroy {
  i18n = new I18n();
  get thisSearchTitle(){
    if(checkIsVlunteerEvent()){
      return '義工 | Volunteer'
    }else{
      return '活動 | Activity'
    }
  }
  get subSearchTitle(){
    if(checkIsVlunteerEvent()){
      return ''
    }else{
      return this.i18n.translate.ca_dot_20;
    }
  }
  activities: Map<string, {
    activity: TheActivity,
    company: CompanyInfo;
  }> = null;
  featureActivities: Map<string, {
    activity: TheActivity,
    company: CompanyInfo;
  }> = null;
  featureEndedActivities: Map<string, {
    activity: TheActivity,
    company: CompanyInfo;
  }> = null;
  private _thisId: string = undefined;
  thisListSubscription: Subscription = null;
  featureListSubscription: Subscription = null;
  _mainActivity: {
    activity: TheActivity,
    company: CompanyInfo;
  } = null;
  get mainActivity() {
    return this._mainActivity
  }
  set mainActivity(s: {
    activity: TheActivity,
    company: CompanyInfo;
  }
  ) {

    if (s) this.title.setTitle(`${s.activity.title} - ${s.company.name} - ${this.i18n.getTransate('appname')}`);
    else if (s == null) this.title.setTitle(this.i18n.getTransate('appname'))
    this._mainActivity = s
  }
  get prefixInlcudeCa() {
    if (checkIsVlunteerEvent()) {
      return '/' + volunteerKeys;
    } else if (checkIsInCareerAdvisor()) {
      return '/ca';
    } else {
      return '';
    }
  }
  _isListingPassEvent = false
  get isListingPassEvent() {
    return this._isListingPassEvent
  }
  set isListingPassEvent(v: boolean) {
    if (v != this._isListingPassEvent) {
      console.log(v)
      this._isListingPassEvent = !!v;
    } else {
      this._isListingPassEvent = !!v;
    }
  }
  get keyCollection() {
    if (checkIsVlunteerEvent()) {
      return dbKeys.vol_eve_activities;
    } else if (checkIsInCareerAdvisor()) {
      return dbKeys.ca_the_activities;
    } else {
      return dbKeys.the_activities;
    }
  }
  constructor(
    public firestore: AngularFirestore,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public router: Router,
    public analytics: AngularFireAnalytics,
    private title: Title,
    private logger: LoggingUtilsService,
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.queryParams.subscribe(q => {
      console.log('query', q)
      this.isListingPassEvent = q?.isListingPassEvent == 'y' || false
    })
    this.updateList();
    this.updateFeatureList();
    this.updateEndedFeatureList();
  }
  get isFeature() {
    const isF = location.pathname.includes('/featureActivities');
    return isF;
  }
  get listDisplay() {
    if (this.isFeature) {
      return {
        feature: '',
        main: 'none'
      };
    } else {
      return {
        feature: 'none',
        main: ''
      };
    }
  }
  safeHtml(htmlTextWithStyle: string) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
  searchStatus = '';
  get searchStatusOptions() {
    return this.i18n.getStringMap('map_list_activities_status');
  }
  searchCategories = '';
  get searchCategoriesOptions() {
    if (checkIsVlunteerEvent()) {
      return this.i18n.getStringMap('map_vol_eve_activity_categories');
    } else {
      return this.i18n.getStringMap('map_activity_categories');
    }
  }
  searchLocation = '';
  get searchLocationOptions() {
    return this.i18n.getStringMap('map_job_location');
  }
  ngOnInit() {
    this.fetchIdFromUrl();
  }
  get thisId() { return this._thisId; }
  set thisId(newId: string) {
    this._thisId = newId;
    if (this._thisId) {
      this.updateMainContent(this._thisId);
      this.updateViewCount(this._thisId)

    }
  }
  async updateMainContent(id: string) {
    if (!id) {
      this.mainActivity = undefined;
      return;
    }
    const d = await this.firestore.collection(this.keyCollection).doc(id).get().toPromise();
    if (d && d.exists) {
      const activity = convertActivityDocumentDataToLocalVarible(d.data());
      const dCompany = await this.firestore.collection(dbKeys.company_info).doc(activity.creator_uid).get().toPromise();
      if (dCompany && dCompany.exists) {
        const companyInfo = <CompanyInfo>dCompany.data();
        this.mainActivity = {
          activity: activity,
          company: companyInfo
        };
        const s = this.mainActivity

        if (s) this.title.setTitle(`${s.activity.title} - ${s.company.name} - ${this.i18n.getTransate('appname')}`);
        this.analytics.logEvent(this.analyticsViewKey, {
          activity_id: activity.id,
          acitivity_title: activity.title,
          activity_company_id: companyInfo.uid,
          activity_company_name: companyInfo.name
        });
      }
    }
  }
  get isInDeadline() {
    if (this.mainActivity && this.mainActivity.activity && this.mainActivity.activity.deadline > (new Date())) {
      return true;
    } else {
      return false;
    }
  }
  get analyticsViewKey() {
    if (checkIsInCareerAdvisor) {
      return analyticsEventKeys.vol_eve_view;
    } else
      if (checkIsInCareerAdvisor()) {
        return analyticsEventKeys.activity_view;
      } else {
        return analyticsEventKeys.ca_activity_view;
      }
  }
  getNavArrayIfCa(arr: Array<string>) {
    if (checkIsVlunteerEvent()) {
      const newArr = [volunteerKeys]
      for (const x of arr) {
        newArr.push(x)
      }
      return newArr;
    } else if (checkIsInCareerAdvisor()) {
      const x = ["ca"];
      for (const z of arr) x.push(z);
      return x;
    } else {
      return arr;
    }
  }
  searchQuery() {
    if (this.isFeature) {
      this.router.navigate(this.getNavArrayIfCa(['listActivities'],), {
        queryParams: {

          isListingPassEvent: this.isListingPassEvent ? 'y' : 'n'
        }
      });
    }
    this.updateList();
  }
  updateFeatureList() {
    if (this.featureListSubscription) {
      try {
        this.featureListSubscription.unsubscribe();
      } catch (err) { }
    }
    this.featureListSubscription = this.firestore.collection(this.keyCollection, ((r: Query) => {
      const NOW = moment().toDate();
      return r.where('status', '==', 'active').where('visible', '==', true).where('endAt', '>=', NOW);
    }))
      .valueChanges().subscribe(async data => {
        console.log('vvww',data)
        if (!data || !data.length) {
          this.featureActivities = new Map();
          return;
        }

        this.featureActivities = new Map();
        for (const d of data) {
          const doc = convertActivityDocumentDataToLocalVarible(d);
          if (checkIsVlunteerEvent() || this.isActivityStartedWithinThisMonth(doc)) {
            this.featureActivities.set(doc.id, {
              activity: doc,
              company: null
            });
            const refIn = this.firestore.collection(dbKeys.company_info).doc(doc.creator_uid)
              .get().subscribe(async cData => {
                if (cData && cData.exists) {
                  const cInfo = <CompanyInfo>cData.data();
                  this.featureActivities.set(doc.id, {
                    activity: doc,
                    company: cInfo
                  });
                }
                refIn.unsubscribe();
              });
          }
        }

      });
  }

  featureEndListSubscription: Subscription
  updateEndedFeatureList() {
    if (this.featureEndListSubscription) {
      try {
        this.featureEndListSubscription.unsubscribe();
      } catch (err) { }
    }
    this.featureEndListSubscription = this.firestore.collection(this.keyCollection, ((r: Query) => {
      const NOW = moment().toDate();
      return r.where('status', '==', 'deactive').where('visible', '==', true);
    }))
      .valueChanges().subscribe(async data => {
        if (!data || !data.length) {
          this.featureEndedActivities = new Map();
          return;
        }

        this.featureEndedActivities = new Map();
        for (const d of data) {
          const doc = convertActivityDocumentDataToLocalVarible(d);

          this.featureEndedActivities.set(doc.id, {
            activity: doc,
            company: null
          });

          const refIn = this.firestore.collection(dbKeys.company_info).doc(doc.creator_uid)
            .get().subscribe(async cData => {
              if (cData && cData.exists) {
                const cInfo = <CompanyInfo>cData.data();
                this.featureEndedActivities.set(doc.id, {
                  activity: doc,
                  company: cInfo
                });
              }
              refIn.unsubscribe();
            });
        }


      });
  }

  updateList() {
    if (this.thisListSubscription) {
      this.thisListSubscription.unsubscribe();
    }
    const currentDate = new Date();
    console.log('keyCollection', this.keyCollection)
    this.thisListSubscription = this.firestore.collection(this.keyCollection, (r => {
      let ref: Query = r.where('visible', '==', true);
      console.log('isListingPassEvent', this.isListingPassEvent)
      if (this.isListingPassEvent) {
        // ref = ref.where('endAt', '<=', currentDate)
        ref = ref.where('status', '==', 'deactive')
      } else {
        // ref = ref.where('endAt', '>=', currentDate)
        ref = ref.where('status', '==', 'active')

      }
      if (this.searchCategories) {
        ref = ref.where('category', '==', this.searchCategories);
      }
      if (this.searchLocation) {
        ref = ref.where('location', '==', this.searchLocation);
      }
      if (this.searchStatus == 'can_apply') {
        ref = ref.where('deadline', '>=', currentDate);
      } else if (this.searchStatus == 'helding') {
        ref = ref.where('endAt', '>=', currentDate);
      }

      return ref;
    }))
      .valueChanges().subscribe(async data => {
        console.log('keyc', 'a')
        this.activities = new Map();
        if (data && data.length > 0) {
          for (const d of data) {
            const doc = convertActivityDocumentDataToLocalVarible(d);
            if (this.searchStatus == 'helding' && !(doc.startAt <= currentDate && doc.endAt >= currentDate)) {
              continue;
            }
            this.activities.set(doc.id, {
              activity: doc,
              company: null
            });
            const refIn = this.firestore.collection(dbKeys.company_info).doc(doc.creator_uid)
              .get().subscribe(async cData => {
                if (cData && cData.exists) {
                  const cInfo = <CompanyInfo>cData.data();
                  this.activities.set(doc.id, {
                    activity: doc,
                    company: cInfo
                  });
                }
                refIn.unsubscribe();
              });
          }
        } else {
          this.activities = new Map();
        }
      });
  }

  clearMainContent() {
    if (history && history.length > 0) {
      history.back();
    } else {
      history.replaceState(null, null, '/' + this.getNavArrayIfCa(['listActivities']).join('/'));
    }
    this.thisId = '';
    this.updateMainContent('');
  }
  get leftRightDisplay(): { left: '' | 'none', right: '' | 'none'; } {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //console.log(width)
    if (width >= 1000) {
      return {
        left: '',
        right: ''
      };
    } else {
      if (this.mainActivity) {
        return {
          left: 'none',
          right: ''
        };
      } else {
        return {
          left: '',
          right: 'none'
        };
      }
    }
  }
  openToActivity(id: string) {
    // console.log(this.getNavArrayIfCa(['listActivities', id]))
    this.router.navigate(this.getNavArrayIfCa(['listActivities', id]));

    this.updateViewCount(id)
  }
  fetchIdFromUrl() {
    let sub: Subscription = null;
    sub = this.route.params.subscribe(params => {
      if (params && params.id) {
        this.thisId = params.id;
      }
      if (sub) sub.unsubscribe();
    });
  }
  onLeftClick(item: {
    activity: TheActivity,
    company: CompanyInfo;
  }) {
    console.log(item);
    this.mainActivity = item;
    this.updateViewCount(item.activity.id)
    history.pushState(null, null, '/' + this.getNavArrayIfCa(['listActivities', item.activity.id]).join('/'));
  }
  updateViewCount(id: string) {

    this.logger.log(LoggingKey.event_view, id)
    this.restartViewCount(id)
  }
  formatDatetimeRangeToString(start: Date | "", end: Date | "") {
    if (start == "" || end == "") {
      return "";
    }
    if (start.getMonth() == end.getMonth() && start.getDate() == end.getDate() && start.getFullYear() == end.getFullYear()) {
      return `${this.formatDatetimeToString(start)} ${this.i18n.get('to')} ${end.getHours()}:${end.getMinutes()}`;
    } else {
      return `${this.formatDatetimeToString(start)} ${this.i18n.get('to')} ${this.formatDatetimeToString(end)}`;
    }
  }
  formatDatetimeToString(d: Date | "") {
    if (d == "") {
      return "";
    }
    if (d) {
      return `${this.fillZero(d.getMonth() + 1)}/${this.fillZero(d.getDate())}/${d.getFullYear()}, ${this.fillZero(d.getHours())}:${this.fillZero(d.getMinutes())}`;

    }
  }
  fillZero(num: number) {
    return fillOnLeft(num, '0', 2);
  }

  isActivityStartedWithinThisMonth(activity: TheActivity) {
    if (activity && activity.startAt) {
      const startMoment = moment().startOf('month');
      console.log(startMoment);
      console.log(moment(activity.startAt));
      return moment(activity.startAt).isSameOrAfter(startMoment);
    }
    return false;
  }

  get isInCareerAdvisor() {
    return checkIsInCareerAdvisor();
  }

  viewCount = 0
  sub0ViewCountGet: Subscription
  restartViewCount(id?: string) {
    if (this.sub0ViewCountGet) {
      try {

        this.sub0ViewCountGet.unsubscribe()
        this.sub0ViewCountGet = null
      } catch (e) {
        console.error(e)
      }
    }

    if (this.mainActivity?.activity?.id || id) {

      this.firestore.collection(dbKeys.logging, ref => ref.where('type', '==', LoggingKey.event_view).where('dataId', '==', this.mainActivity?.activity?.id || id))
        .valueChanges().subscribe(v => {
          this.viewCount = 0;
          if (v && v.length > 0) {
            this.viewCount = v.length;
          }
        })

    }
  }
  vvvw(v: any) {
    console.log('isListingPassEvent', v)
    this.isListingPassEvent = v == 'pass'
    if (!this.isFeature && location) {
      history.pushState(null, null, `/${this.getNavArrayIfCa(['listActivities']).join('/')}?isListingPassEvent=${this.isListingPassEvent ? 'y' : 'n'}`)
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.title.setTitle(this.i18n.getTransate('appname'))
  }
}
