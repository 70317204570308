import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { checkIsInCareerAdvisor } from 'src/app/class/common-objects.enum';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.less']
})
export class BlogCardComponent implements OnInit {
  @Input()
  blog: any;

  i18n = new I18n()
  get keyNavSinglePrefix() {
    const url = this.router.url
    if (checkIsInCareerAdvisor()) {
      return 'ca/article'
    } else {
      if (url.startsWith('/sportArticle')) {
        return 'sportArticle'
      } else if (url.startsWith('/sportRewine')) {
        return 'sportRewine'
      } else if (url.startsWith('/sportRevision')) {
        return 'sportRevision'
      } else {
        return 'article'
      }
    }
  }
  get isInCareerAdvisor() {
    return checkIsInCareerAdvisor();
  }

  constructor(public router: Router) { }

  ngOnInit() {
  }

  toArticle() {
    if (this.blog) {
      return this.router.navigate([this.keyNavSinglePrefix, this.blog.id]);
    }
  }


  get thisCategoryName(){
    if(this.blog){

    return this.mapArticleCategories.get(this.blog.category) || this.blog.category
    }
    
    return ''
  }
  get mapArticleCategories() {
    const url = this.router.url
    if (url.startsWith('/sportArticle')) {
      return this.i18n.translate.map_sport_article_categories
    } else if (url.startsWith('/sportRewine')) {
      return this.i18n.translate.map_sport_rewine_categoies
    } else if (url.startsWith('/sportRevision')) {
      return this.i18n.translate.map_sport_revision_categories
    } else {
      return this.i18n.getStringMap(checkIsInCareerAdvisor() ? 'map_ca_blog_categories' : 'map_blog_categories')
    }
  }

}
