import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talent-search-model',
  templateUrl: './talent-search-model.component.html',
  styleUrls: ['./talent-search-model.component.less']
})
export class TalentSearchModelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
