export interface CompanyUserPermission{
  uid: string,
  permissions: Array<CompanyUserPermissionEnumItem>
}
export enum CompanyUserPermissionEnumItem{
  company_edit_activity= 'company_edit_activity',
  company_edit_job= "company_edit_job",
  company_edit_ca_activity= "comapny_edit_ca_activity",
  company_edit_vol_eve_activity= "comapny_edit_vol_eve_activity"
}
