import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase, { firestore } from 'firebase';
import { dbKeys } from '../class/common-objects.enum';

@Injectable({
  providedIn: 'root'
})
export class LoggingUtilsService {
currentUser :firebase.User
  constructor(
    private afFirestore:AngularFirestore,
    private afAuth:AngularFireAuth,
  ) { 
    afAuth.authState.subscribe(user=>{
      this.currentUser = user||null;
    })
  }
  log(key:LoggingKey, dataId: string, actionFinished?: boolean){
    return this.afFirestore.collection(dbKeys.logging)
    .add(<ILogging>{
      uid: this.currentUser?.uid||null,
      type: key,
      dataId: dataId,
      actionFinished: !!actionFinished,
      finishDataId:'',
    }).then(v=>{
      console.log('log success', v)
      return v.id;
    }).catch(e=>{
      console.error("log failed", e)
      return false;
    })
  }

  logFinished(logId: string, actionFinished: boolean, finishDataId: string,){
    return this.afFirestore.collection(dbKeys.logging)
    .doc(logId)
    .update(<ILogging>{
      uid: this.currentUser?.uid||null,
      actionFinished: !!actionFinished,
      finishDataId:finishDataId,
    }).then(v=>{
      console.log('log success', v)
      return true;
    }).catch(e=>{
      console.error("log failed", e)
      return false;
    })
  }
  getTheLog(key: LoggingKey, dataId: string,){
    return this.afFirestore.collection(dbKeys.logging,
      ref=>ref.where('type','==',key).where('dataId','==', dataId||null)).valueChanges()
  }
  getListLog(key: LoggingKey){
    return this.afFirestore.collection(dbKeys.logging,
      ref=>ref.where('type','==',key)).valueChanges()
  }
}
export interface ILogging{
  id?: string,
  uid: string,
  type: LoggingKey,
  dataId: string,
  actionFinished: boolean,
  finishDataId: string,
  createDateTime?: firestore.Timestamp,
  updateDateTime?:firestore.Timestamp,
  
}
export enum LoggingKey {
    career_advisor_detail_click="career_advisor_detail_click",
    // appointment_submit="appointment_submit",
    // appointment_unsubmit="appointment_unsubmit",
    ca_appointment_filling_and_submit= "appointment_filling_and_submit",
    article_view="article_view",
    article_category_change="article_category_change",
    event_view="event_view",
    telentpool_view="telentpool_view",
    telentpool_category_view="telentpool_category_view",
    job_view="job_view",
    job_search="job_search",
    job_submition_filling_and_submitted="job_submition_filling_and_submitted",
}