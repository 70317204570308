<div class="thisBody">
  <div class="thisTitle">
    <span class="closeButton" (click)="closeDialog()" style="cursor: pointer"
      >X</span
    >
    <div>
      <span>{{ thisTitle }}</span>
    </div>
  </div>
  <mat-dialog-content class="thisContent">
    <form [(id)]="formId">
      <mat-form-field class="full-width">
        <input
          matInput
          type="text"
          [placeholder]="i18n.get('add_activity_placeholder_title')"
          [(ngModel)]="activity.title"
          [ngModelOptions]="{ standalone: true }"
          required
        />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>{{
          i18n.get("add_activity_placeholder_categories")
        }}</mat-label>
        <select
          matNativeControl
          required
          class="full-width"
          [(ngModel)]="activity.category"
          [ngModelOptions]="{ standalone: true }"
        >
          <option></option>
          <option *ngFor="let cat of categories | keyvalue" [value]="cat.key">
            {{ cat.value }}
          </option>
        </select>
      </mat-form-field>
      <!-- <mat-form-field class="full-width">
                <input matInput type="text" [placeholder]="i18n.get('add_activity_placeholder_speakers')"
                    [(ngModel)]="activity.speakers" [ngModelOptions]="{standalone: true}" required>
            </mat-form-field> -->
      <!-- <mat-form-field class="full-width">
                <input matInput type="number" [placeholder]="i18n.get('add_activity_placeholder_quota')"
                    [(ngModel)]="activity.quota" [ngModelOptions]="{standalone: true}" required>
            </mat-form-field> -->
      <mat-form-field class="full-width">
        <mat-label>{{
          i18n.get("add_activity_placeholder_location")
        }}</mat-label>
        <select
          matNativeControl
          [(ngModel)]="activity.location"
          [ngModelOptions]="{ standalone: true }"
        >
          <option></option>
          <option *ngFor="let cat of locations | keyvalue" [value]="cat.key">
            {{ cat.value }}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>{{
          i18n.get("add_activity_placeholder_address")
        }}</mat-label>
        <textarea matInput [placeholder]="i18n.get('add_activity_placeholder_address')" [(ngModel)]="activity.address"
        [ngModelOptions]="{standalone: true}" rows="3"></textarea>
      </mat-form-field>
      <div class="editorOuter">
        <div>{{ i18n.get("add_activity_placeholder_description") }}</div>
        <ckeditor
          [editor]="ckeditor"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="activity.description"
          (ready)="TheUploadAdapterPlugin($event)"
          [(config)]="ckeConfig"
        ></ckeditor>
      </div>

      <div *ngIf="percentage | async as pct">
        <span *ngIf="pct <= 99">
          <progress class="progress is-info" [value]="pct" max="100"></progress>

          {{ pct | number }}%
        </span>
      </div>
      <span>
        <input
          [(id)]="uploadImageContainerId"
          type="file"
          (change)="uploadFile($event)"
          style="display: none"
        />
      </span>
      <br />

      <mat-form-field class="full-width">
        <input
          matInput
          type="text"
          [placeholder]="i18n.get('add_activity_placeholder_price_per_person')"
          [(ngModel)]="activity.price"
          [ngModelOptions]="{ standalone: true }"
          required
        />
      </mat-form-field>

      <mat-form-field [owlDateTimeTrigger]="startAt" class="full-width">
        <input
          matInput
          [placeholder]="i18n.get('add_activity_placeholder_start_at')"
          [owlDateTime]="startAt"
          [(ngModel)]="activity.startAt"
          [ngModelOptions]="{ standalone: true }"
          required
        />
        <owl-date-time [pickerMode]="pickerMode" #startAt></owl-date-time>
      </mat-form-field>
      <mat-form-field class="full-width" [owlDateTimeTrigger]="endAt">
        <input
          matInput
          [placeholder]="i18n.get('add_activity_placeholder_end_at')"
          [owlDateTime]="endAt"
          [(ngModel)]="activity.endAt"
          [ngModelOptions]="{ standalone: true }"
          required
        />
        <owl-date-time [pickerMode]="pickerMode" #endAt></owl-date-time>
      </mat-form-field>
      <mat-form-field
        class="full-width"
        [owlDateTimeTrigger]="applicationDeadline"
      >
        <input
          matInput
          [placeholder]="
            i18n.get('add_activity_placeholder_application_deadling')
          "
          [owlDateTime]="applicationDeadline"
          [(ngModel)]="activity.deadline"
          [ngModelOptions]="{ standalone: true }"
          required
        />
        <owl-date-time
          [pickerMode]="pickerMode"
          #applicationDeadline
        ></owl-date-time>
      </mat-form-field>

      <div
        class="dropzone"
        dropZone
        (dropped)="uploadFile($event)"
        [class.hovering]="isHovering"
      >
        <br />

        <h5>{{ i18n.get("cover_image") }}</h5>

        <div class="file">
          <label class="file-label">
            <input
              class="file-input"
              [(id)]="keyUploadChooseId"
              type="file"
              (change)="uploadFile($event)"
              accept=".jpg,.jpeg,.png"
            />
          </label>
          <span class="file-cta">
            <span class="file-icon">
              <i class="fa fa-upload"></i>
            </span>
            <span class="file-label">
              <span>
                <br />
                <div *ngIf="percentage | async as pct">
                  <span *ngIf="pct <= 99">
                    <progress
                      class="progress is-info"
                      [value]="pct"
                      max="100"
                    ></progress>

                    {{ pct | number }}%
                  </span>
                  <span *ngIf="pct == 100 && !downloadURL">
                    Loading... | 載入中...
                  </span>
                </div>
              </span>
            </span>
          </span>

          <img class="previewIcon" [src]="downloadURL" *ngIf="downloadURL" />
        </div>
      </div>
      <br />
      <button class="btn btn-primary" (click)="onSubmit()">
        {{ i18n.get("submit") }}
      </button>
    </form>
  </mat-dialog-content>
</div>
