<div class="all">
    <mat-spinner *ngIf="faqItem === undefined"></mat-spinner>
    <div class="nothing" *ngIf="faqItem!==undefined && !faqItem">
        {{i18n.get('nothing_to_show')}}
    </div>
    <div  class="content">
    <mat-card *ngIf="faqItem" class="card">
        <h1>{{faqItem.title}}</h1>
        <img [src]="faqItem.coverImage" style="width: 100%;"/>

        <mat-chip-list aria-label="Fish selection" *ngIf="faqItem.tags" class="tagList">
            <mat-chip *ngFor="let t of faqItem.tags" (click)="navigateToTag(t)">{{t}}</mat-chip>
            </mat-chip-list>

        <div class="list">
            <div class="item" *ngFor="let item of faqItem.contents">

                <div [class]="item.isSelfSide?'right':'left'">
                   <span [innerHTML]="renderMessageIfUrl(item.content)"></span>
                </div>
            </div>
        </div>
    </mat-card>
</div>
</div>