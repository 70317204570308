<div class="all">
    <div class="header">
        <h2>{{i18n.get('whatsapp auto responses')}}</h2>
        <div class="buttons">
            <button mat-raised-button color="primary" (click)="openAddChangeDialog()">
                ADD
            </button>
            <span class="spacer"></span>
            <button mat-raised-button color="primary" (click)="restartAutoResponse()">
                REFRESH
            </button>
        </div>
    </div>
    <div class="body">
        <div class="nothing" *ngIf="autoResponses === null">
            <mat-spinner></mat-spinner>
        </div>
        <div class="nothing" *ngIf="autoResponses && autoResponses.length <= 0">
            {{i18n.translate.nothing_to_show}}
        </div>
        <table class="thisTable" *ngIf="autoResponses && autoResponses.length > 0">
            <tr>
                <td>ID</td>
                <td>Match Rule</td>
                <td>Active?</td>
                <td>hit times</td>
                <td>Description</td>
                <td>Response Message</td>
                <td>Actions</td>
            </tr>
            <tr *ngFor="let item of autoResponses;index as i">
                <td>{{item.id}}</td>
                <td>{{item.matcher}}</td>
                <td>
                    <mat-progress-bar mode="buffer" *ngIf="isSwitchLoading">
                    </mat-progress-bar>
                    <mat-slide-toggle [checked]="item.isActive" *ngIf="!isSwitchLoading"
                        (change)="onActiveChange(item)">
                        {{item.isActive?i18n.translate.active:i18n.translate.deactive}}
                    </mat-slide-toggle>
                </td>
                <td>
                    {{item.hitTimes||0}}
                </td>
                <td style="white-space: pre-wrap;">{{item.description}}</td>
                <td style="white-space: pre-wrap;">{{item.responseMessage}}</td>

                <td>
                    <button mat-button color="primary" (click)="openAddChangeDialog(item)">
                        {{i18n.translate.edit}}
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>