<div class="all">
    <h2>{{isEditMode?i18n.translate.edit + ' ' + initId:i18n.translate.add}} </h2>
    <mat-dialog-content>
        <form #form (submit)="onSubmit();">
            <mat-form-field class="example-full-width" >
                <mat-label>Match Rules (RegExp.)</mat-label>
                <input required matInput placeholder="Match Rules (RegExp.)" name="matcher" [(ngModel)]="autoResponse.matcher">
                <mat-hint>
                    enter a word to hit this rule then reply the below message.
                    It support Regular Expression for advanced.
                    Check <a href="https://regexr.com" target="_blank">regexr.com</a>
                </mat-hint>
            </mat-form-field>


            <br><br>

            <mat-form-field class="example-full-width" >
                <mat-label>Description</mat-label>
                <textarea required matInput [(ngModel)]="autoResponse.description" name="description" placeholder="leave comment to let other admin know what it is "></textarea>
            </mat-form-field>




            <mat-form-field class="example-full-width" >
                <mat-label>Reponse message</mat-label>
                <textarea required matInput [(ngModel)]="autoResponse.responseMessage" name="responseMessage" placeholder="response message to user who trigger this..."></textarea>
            </mat-form-field>

        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button disabled *ngIf="isLoading">
           <mat-progress-bar
               mode="buffer">
           </mat-progress-bar>
        </button>
        <button *ngIf="!isLoading" mat-button mat-dialog-close [disabled]="isLoading">{{i18n.translate.cancel}}</button>

        <button mat-button color="primary" (click)="onSubmit();"
        *ngIf="!isLoading"
            [disabled]="isLoading">{{i18n.translate.submit}}</button>
    </mat-dialog-actions>
</div>