import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserInfo } from 'src/app/class/user-info';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, AdminUserProfileRemarks, UserRecordInMap, makeid, openSimpleMessageDialog } from 'src/app/class/common-objects.enum';
import { AngularFireStorage } from '@angular/fire/storage';
import { firestore as Firestore } from 'firebase/app';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { InputInfoDialogComponent } from '../../../layout/navbar/input-info-dialog/input-info-dialog.component';
import { I18n } from '../../../i18n/i18n';

@Component({
  selector: 'app-admin-show-user-profile',
  templateUrl: './admin-show-user-profile.component.html',
  styleUrls: ['./admin-show-user-profile.component.less']
})
export class AdminShowUserProfileComponent implements OnInit {
  uid = ''
  i18n = new I18n()
  record : UserRecordInMap = undefined
  user: UserInfo = undefined
  cvUrl = ''
  addDisabled: '' | 'disabled' = ''
  keyUpdateUserProfileForm = `keyUpdateUserProfileForm_${makeid(13)}`
  arrRemarks: AdminUserProfileRemarks[] = null
  newObject: AdminUserProfileRemarks = {
    id: '',
    uid: '',
    date: '',
    subject: '',
    message: '',
    createdTime: ''
  }
  
  
  constructor(
    public activatedRoute: ActivatedRoute,
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    public dialog : MatDialog
  ) { }

  ngOnInit() {
    this.uid = this.activatedRoute.snapshot.paramMap.get('uid')
    this.getUserInfo()
    this.getUserRecord()

  }
  async getUserRecord(){

    const snapshot = await this.firestore.collection(dbKeys.map_users_records).doc(this.uid).get().toPromise()
    if (snapshot && snapshot.exists) {
      const r: UserRecordInMap = <UserRecordInMap>snapshot.data()
      this.record = r
    } else {
      this.record = null
    }
  }

  get pickerMode() {
    const width = outerWidth | window.outerWidth | 0
    if (width >= 900) {
      return 'popup'
    } else {
      return 'dialog'
    }
  }
  async getUserInfo() {
    const snapshot = await this.firestore.collection(dbKeys.user_info).doc(this.uid).get().toPromise()
    if (snapshot && snapshot.exists) {
      const userInfo: UserInfo = <UserInfo>snapshot.data()
      this.user = userInfo
      console.log(this.user)
      if (userInfo.cvURL) {
        this.getDlUrl(userInfo.cvURL)
      }
      this.monitorAdminLogs()
    } else {
      this.user = null
    }
  }
  subScriptionAdminLogs : Subscription = null
  monitorAdminLogs() {
    this.subScriptionAdminLogs= this.firestore.collection(dbKeys.admin_user_remarks, ref => ref.where('uid', '==', this.uid).orderBy('createdTime','asc'))
      .valueChanges().subscribe(data => {
        this.arrRemarks = []
        if (data && data.length > 0) {
          for (const doc of data) {
            const d: AdminUserProfileRemarks = <AdminUserProfileRemarks>doc
            this.arrRemarks.push(d)
          }
        }
        
      })
  }
  refreshAdminLogsSubscription(){
    if(this.subScriptionAdminLogs){
      this.subScriptionAdminLogs.unsubscribe()
      this.monitorAdminLogs()
    }
  }
  async getDlUrl(path: string) {
    try {
      const url = await this.storage.ref(path).getDownloadURL().toPromise()
      if (url) {
        this.cvUrl = url
        return url
      }
      else return ""
    } catch (e) {
      // console.error(e)
      return null
    }
  }

  async addAdminLogs() {
    this.addDisabled = 'disabled'
    if (!this.newObject.date) {
      openSimpleMessageDialog(this.dialog, 'Select Date!')
    } else if (!this.newObject.subject) {
      openSimpleMessageDialog(this.dialog, 'Enter subject!')
    } else if (!this.newObject.message) {
      openSimpleMessageDialog(this.dialog, 'Enter message!')
    } else {
      this.newObject.uid = this.uid
      if (typeof (this.newObject.date) == 'string') {
        this.newObject.date = new Date(this.newObject.date)
      }
      this.newObject.createdTime = new Date()
      try {
        const ref = await this.firestore.collection(dbKeys.admin_user_remarks).add(this.newObject)
        await this.firestore.collection(dbKeys.admin_user_remarks).doc(ref.id).update({ id: ref.id })
        openSimpleMessageDialog(this.dialog, 'success!')
        this.clearNewObject()
      } catch (e) {
        console.error(e)
        openSimpleMessageDialog(this.dialog, `ERROR: ${e.message}`)
      }
    }
    this.addDisabled = ''
  }
  clearNewObject(){
    this.newObject = {
    id: '',
    uid: '',
    date: '',
    subject: '',
    message: '',
    createdTime: ''
  }
  
  }
  openEditUserDialog(id: string){
    const ref = this.dialog.open(InputInfoDialogComponent, {
      data: {
        initId: id
      }
    })
    ref.afterClosed().subscribe(result=>{
      if(result){
        this.getUserInfo()
      }
    })
  }
  async removeAdminLog(id: string) {
    if (id && confirm('Sure to remove?')) {
      try {
        await this.firestore.collection(dbKeys.admin_user_remarks).doc(id).delete()
        openSimpleMessageDialog(this.dialog, 'success')
        this.refreshAdminLogsSubscription()
      } catch (e) {
        console.error(e)
      }
    }
  }
}
