import { Component, OnInit } from '@angular/core';
import { TheContent } from 'src/app/class/the-content';
import { Router } from '@angular/router';
import { dbKeys } from 'src/app/class/common-objects.enum';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.less']
})
export class ContentComponent implements OnInit {
  content: TheContent = {
    title: '',
    content: '',
    id: '',
    createAt: null,
    updateAt: null,
  };

  constructor(
    private router: Router,
    private firestore: AngularFirestore,
  ) { }

  ngOnInit() {
    let key = this.router.url.substring(1);

    this.firestore.collection(dbKeys.content).doc(key).get().subscribe(snapshot => {
      if (snapshot && snapshot.exists) {
        const data = snapshot.data()
        if (data) {
          this.content = <TheContent>data
          this.content.createAt = (<firebase.firestore.Timestamp>data.createAt).toDate()
          this.content.updateAt = (<firebase.firestore.Timestamp>data.updateAt).toDate()
        }
      }
    })
  }
}
