<div class="thisBody">
  <div class="header" flex>
    <div flex-item="grow-1">
      <span class="title">
        {{ i18n.get("list_advisors") }}
      </span>
      <span class="btn btn-light add" (click)="openAddDialog()">
        {{ i18n.get("add") }}
      </span>
    </div>
    <button
      mat-raised-button
      [disabled]="isRefreshing"
      flex-item="align-center"
      class="btn btn-light"
      (click)="reloadAdvisorApplicationCount()"
    >
      {{ isRefreshing ? "Loading..." : "Refresh" }}
    </button>
  </div>
  <br />
  <div class="content">
    <div *ngIf="advisors == null">
      {{ i18n.get("loading") }}
    </div>
    <div *ngIf="advisors && advisors.length === 0">
      {{ i18n.get("nothing_to_show") }}
    </div>
    <div *ngIf="advisors && advisors.length > 0">
      <table border="1">
        <tr>
          <td>{{ i18n.get("advisor_name") }}</td>
          <td>{{ i18n.get("industry") }}</td>
          <td>{{ i18n.get("supported_appointment_format") }}</td>
          <td>{{ i18n.get("job_table_status") }}</td>
          <td>{{ i18n.get("placeholder_duration") }}</td>
          <td>{{ i18n.get("placeholder_consultion_fee") }}</td>
          <td>{{ i18n.get("admin_management") }}</td>
          <td>{{ i18n.get("edit") }}</td>
          <td>{{ i18n.get("appointment_count") }}</td>
          <td>{{ i18n.get("apply_status") }}</td>
        </tr>
        <tr *ngFor="let o of advisors">
          <td>{{ o.name }}</td>
          <td>
            {{ getIndustryNameById(o.industry) || o.industry }}
          </td>
          <td>{{ o | advisorAppointmentFormat }}</td>
          <td>{{ o.status }}</td>
          <td>{{ o.duration_text }}</td>
          <td>{{ o.fee }}</td>
          <td>
            <span
              *ngIf="o.status == 'active'"
              (click)="setStatus(o.id, 'deactive')"
              class="btn btn-light"
            >
              {{ i18n.get("set_to_deactive") }}
            </span>

            <span
              *ngIf="o.status == 'deactive'"
              (click)="setStatus(o.id, 'active')"
              class="btn btn-light"
            >
              {{ i18n.get("set_to_active") }}
            </span>
          </td>
          <td>
            <span class="btn btn-light" (click)="openAddDialog(o.id)">
              {{ i18n.get("edit") }}
            </span>
          </td>
          <td>{{ o.appointment_count }}</td>
          <td>
            <button class="btn btn-light" (click)="toAppointmentList(o.id)">
              {{ i18n.get("view") }}
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
