<mat-card class="container">
  <img
    class="previewImg"
    [src]="activity.coverURL || '/assets/default-fallback-image.png'"
  />
  <mat-card-title>{{ activity.title }}</mat-card-title>
  <mat-card-subtitle>
    <div class="leftItemLocation">
      <i class="fas fa-tag"></i>
      <span>
        {{
          i18n.getStringMap("map_activity_categories").get(activity.category) ||
            activity.category
        }}
      </span>
    </div>
    <div class="leftItemLocation">
      <i class="fas" [ngClass]="{ 'fa-location-arrow': activity.location }"></i>
      <span>
        {{
          i18n.getStringMap("map_job_location").get(activity.location) ||
            activity.location
        }}
      </span>
    </div>
  </mat-card-subtitle>
</mat-card>
