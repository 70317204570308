import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";

import { I18n } from "src/app/i18n/i18n";
import { i18n_locate } from "src/app/i18n/i18n_const";
import { IndustryCatItem } from "src/app/class/IndustryCatItem";
import { TheAdvisor } from "src/app/class/the-advisor";
import { textToArray } from 'src/app/class/common-objects.enum';
import { LoggingKey, LoggingUtilsService } from 'src/app/utils/logger-utils.service';

@Component({
  selector: 'app-ca-advisor-card',
  templateUrl: './ca-advisor-card.component.html',
  styleUrls: ['./ca-advisor-card.component.less']
})
export class CaAdvisorCardComponent implements OnInit {
  i18n = new I18n(i18n_locate.en);
  @Input()
  topIndustries: Map<string, IndustryCatItem>
  @Input()
  advisor: TheAdvisor;

  MAX_EXPERTISE_DISPLAY: number = 3;

  constructor(
    public router: Router,
    // private logger: LoggingUtilsService,
  ) { }

  ngOnInit() {
  }

  textToArray(t: string) {
    return textToArray(t);
  }

  getIndustryNameById(id: string) {
    const s = this.topIndustries.get(id);
    if (s && s.id) {
      const industry = s;
      if (this.i18n.currentLocate == i18n_locate.hant) {
        return industry.hant_name;
      } else {
        return industry.en_name;
      }
    }
    return "其他/Other";
  }

  goToDetails(id: string) {
    if (id) {
      this.router.navigate(["ca", "advisor", id]);
    
    }
  }

}
