import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { application } from 'express';
import { Subscription } from 'rxjs';
import { dbKeys, exportTableToCSVAndDownloadIt, unsubscribeAll } from 'src/app/class/common-objects.enum';
import { ISportCourseApplication } from 'src/app/class/ISportCourseApplication';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-sport-course-applications-listing',
  templateUrl: './sport-course-applications-listing.component.html',
  styleUrls: ['./sport-course-applications-listing.component.less']
})
export class SportCourseApplicationsListingComponent implements OnInit {
inId = ''
i18n = new I18n()
applications : ISportCourseApplication[] = null
  constructor(
    private activatedRoute:ActivatedRoute,
    private afFirestore:AngularFirestore,

  ) {
    activatedRoute.params.subscribe(d=>{
      if(d?.id){
        this.inId = d.id || ''
        this.loadApplications()
      }
    })
   }

  ngOnInit() {
  }

  sub0LoadApplications:Subscription
  loadApplications(){
    unsubscribeAll([this.sub0LoadApplications])
    this.sub0LoadApplications = this.afFirestore
    .collection<ISportCourseApplication>(dbKeys.sport_course_applications,
      ref=>ref
      .where('courseId','==',this.inId)
      .orderBy('createDateTime','desc')
      )
    .valueChanges().subscribe(v=>{
      this.applications = v;
    })
  }

  exportCSV(){
    exportTableToCSVAndDownloadIt('table0_sport-course-applications-listing.component','exported.csv',0)
  }

  
}
