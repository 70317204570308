<div class="thisBody">
  <div class="innerBody card">
    <h3 *ngIf="!advisor && !record">{{ i18n.get("loading") }}</h3>
    <div class="content" *ngIf="advisor && record">
      <app-appointment-card
        [advisor]="advisor"
        [record]="record"
      ></app-appointment-card>
      <hr>
      <ng-container *ngIf="record.status==='wait_confirm'">
        <div>
          <p>Remark: </p>
          <p>HRCA Career Advisory Team will further contact you for more information and to confirm the date and time of the career advisory session within 1-3 working days.</p>
          <p>Please make sure that you've sent us your resume.
          <br>If not, please send us your resume by <a href="https://api.whatsapp.com/send?phone=85266865812" rel="nofollow noreferrer noopener" target="_blank">WhatsApp: 6686 5812</a> or <a href="mailto:info@happy-retired.org">email: info@happy-retired.org</a>).</p>
          <p>Please feel free to contact us if there is any problem. </p>
        </div>
      </ng-container>
      <ng-container *ngIf="record.status==='not_paid'">
        <a
          class="btn btn-primary btn-block btn-lg formButton"
          (click)="navigateToPayment()"
          target="_blank"
        >
        Pay for Appointment
        </a>
      </ng-container>
      
    </div>
  </div>
</div>
