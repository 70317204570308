<div class="all">
    <h1>{{i18n.get('qna')}}</h1>
    <div class="tagSearch">
        <div class="ah">
            <mat-form-field  appearance="outline" style="cursor: pointer;" (click)="openSelectTagDialog();">
                <mat-label>{{i18n.translate.tag}}...</mat-label>
                <input matInput [value]="currentTag||'ALL'" readonly style="cursor: pointer;">
                <span matSuffix style="margin-top: -12px;">
                    <i class="fas fa-caret-down"></i>
                </span>
              </mat-form-field>
              <button mat-stroked-button color="primary" (click)="clearTag()">
                  {{i18n.translate.clear_tag}}
              </button>
            
        </div>
    </div>
    <mat-spinner *ngIf="faqItems === null"></mat-spinner>
    <div class="nothing" *ngIf="faqItems && faqItems.length <= 0">
        {{i18n.get('nothing_to_show')}}
    </div>
    <div class="content" *ngIf="faqItems && faqItems.length > 0">
        <mat-card class="card" *ngFor="let faq of faqItems" [routerLink]=" routeCaPrefix + '/qna/s/'+faq.id">
            <img [src]="faq.coverImage" class="imgCover" />
            <mat-card-title class="title">{{faq.title}}</mat-card-title>
            
            <mat-chip-list aria-label="Fish selection" *ngIf="faq.tags">
                <mat-chip *ngFor="let t of faq.tags" (click)="navigateToTag(t)">{{t}}</mat-chip>
                </mat-chip-list>
        </mat-card>
    </div>
</div>