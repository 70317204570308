<div class="thisOuter">
  <div class="thisBody">
    <div class="thisHeader">
      <span class="left" (click)="tryGoBack()">
        <i class="fas fa-arrow-left"></i>{{ i18n.get("back") }}</span>
      <span class="right">
        <select class="form-control" id="selectFilter0JobMatching0Inside"
          (change)="navToTalentpoolListing($event.target.value)">
          <option value="" disabled>{{ i18n.getStringWithLocate("select_job_type", getLocate()) }}</option>
          <option value="_all">所有/All</option>
          <option *ngFor="let o of topIndustries | keyvalue" [value]="o.key">
            {{ getTopIndustryNameById(o.key) }}
          </option>
        </select>
      </span>
    </div>
    <div>
      <div *ngIf="!talnet" style="text-align: center;">
        {{ i18n.get("loading") }}
      </div>
      <table class="thisContent" *ngIf="talnet">

        <div class="singleLine">
          <h3>{{ talnet.id }}</h3>
        </div>
        <div class="viewCount" style="color: gray; margin: 0px 0px 12px 0px; font-size: 0.8rem;">
          {{i18n.translate.view}}: {{viewCount}}
        </div>
        <tr class="singleLine">
          <td class="left">
            {{ i18n.getStringWithLocate("industry", talnet.locate) }}：
          </td>

          <td class="right">
            {{ getTalentDomain() }}
          </td>
        </tr>
        <tr class="singleLine" *ngIf="talnet.expertises && talnet.expertises.length > 0">
          <td class="left">
            {{ i18n.getStringWithLocate("expertise", talnet.locate) }}：
          </td>

          <td class="right">{{ getTalentExpertise() }}</td>
        </tr>

        <tr class="singleLine" *ngIf="talnet.subIndustries && talnet.subIndustries.length > 0">
          <td class="left">{{
            i18n.getStringWithLocate("suitable_position", talnet.locate)
            }}：</td>
          <td class="right">
            {{ getTalentSubIndustries() }}
          </td>
        </tr>

        <tr class="singleLine" *ngIf="talnet.languages && talnet.languages.length > 0">
          <td class="left">
            {{ i18n.getStringWithLocate("work_availability", talnet.locate) }}：
          </td>
          <td class="right">
            {{ talnet.availability }}
          </td>
        </tr>
        <div class="singleLine" *ngIf="talnet.summary && talnet.summary.length > 0">
          <span>
            <span class="largeTitle">{{
              i18n.getStringWithLocate("candidate_summary", talnet.locate)
              }}</span>
          </span>
          <br />
          <ul>
            <li *ngFor="let item of textToArray(talnet.summary)">{{ item }}</li>
          </ul>
        </div>
        <hr>
        <div class="singleLine">
          <b class="largeTitle">{{
            i18n.getStringWithLocate("placeholder_job_edu_level", talnet.locate)
            }}</b>
          <br />
          <span>
            <div *ngIf="
                talnet.academicQualification &&
                talnet.academicQualification.length &&
                talnet.academicQualification.length > 0
              ">
              <span *ngFor="let o of talnet.academicQualification">
                <div style="height: 9px;"></div>
                <span class="smallTitle">
                  {{
                  i18n
                  .getStringMapWithLocate("map_edu_level", talnet.locate)
                  .get(o) || o
                  }}
                </span>
                <ul *ngIf="o == '4certificate' && talnet.certDetails">
                  <li *ngFor="let p of textToArray(talnet.certDetails)">
                    {{ p }}
                  </li>
                </ul>
                <ul *ngIf="o == '5diploma' && talnet.diplomaDetails">
                  <li *ngFor="let p of textToArray(talnet.diplomaDetails)">
                    {{ p }}
                  </li>
                </ul>
                <ul *ngIf="o == '6adgree_hd' && talnet.hdDetails">
                  <li *ngFor="let p of textToArray(talnet.hdDetails)">
                    {{ p }}
                  </li>
                </ul>
                <ul *ngIf="o == '7bsc' && talnet.bscDetails">
                  <li *ngFor="let p of textToArray(talnet.bscDetails)">
                    {{ p }}
                  </li>
                </ul>
                <ul *ngIf="o == '8over_bsc' && talnet.masterDetails">
                  <li *ngFor="let p of textToArray(talnet.masterDetails)">
                    {{ p }}
                  </li>
                </ul>
              </span>
            </div>
          </span>
        </div>
        <hr />

        <div class="singleLine" *ngIf="talnet.professionalQualification">
          <b class="largeTitle">{{
            i18n.getStringWithLocate(
            "professional_qualification",
            talnet.locate
            )
            }}</b>
          <br />
          <span>
            <ul>
              <li *ngFor="
                  let item of textToArray(talnet.professionalQualification)
                ">
                {{ item }}
              </li>
            </ul>
          </span>
        </div>
        <hr />

        <div class="singleLine" *ngIf="talnet.experience">
          <b class="largeTitle">{{
            i18n.getStringWithLocate("work_experience", talnet.locate)
            }}</b>
          <br />
          <span>
            <ul>
              <li *ngFor="let item of textToArray(talnet.experience)">
                {{ item }}
              </li>
            </ul>
          </span>
        </div>
        <hr />
        <div class="singleLine" *ngIf="talnet.languages && talnet.languages.length > 0">
          <b class="largeTitle">{{
            i18n.getStringWithLocate("languages", talnet.locate)
            }}</b>
          <br />
          <span>
            <ul>
              <ng-container *ngFor="let item of talnet.languages">
                <li *ngIf="item != 'other'">
                  {{
                  i18n
                  .getStringMapWithLocate(
                  "map_qual_languages",
                  talnet.locate
                  )
                  .get(item) || item
                  }}
                </li>
              </ng-container>

              <ng-container *ngIf="talnet.languages && talnet.languages.includes('other')">
                <li *ngFor="let item of textToArray(talnet.languages_other)">
                  {{ item }}
                </li>
              </ng-container>
            </ul>
          </span>
        </div>
        <hr>

        <div class="singleLine" *ngIf="talnet.it_skills && talnet.it_skills.length > 0">
          <b class="largeTitle">{{
            i18n.getStringWithLocate("it_skills", talnet.locate)
            }}</b>
          <br />
          <span>
            <ul>
              <ng-container *ngFor="let item of talnet.it_skills">
                <li *ngIf="item && item != 'other'">
                  <span>{{
                    i18n
                    .getStringMapWithLocate("map_it_skills", talnet.locate)
                    .get(item) || item
                    }}</span>
                </li>
              </ng-container>

              <ng-container *ngIf="talnet.it_skills && talnet.it_skills.includes('other')">
                <li *ngFor="let item of textToArray(talnet.it_skills_other)">
                  {{ item }}
                </li>
              </ng-container>
            </ul>
          </span>
        </div>
        <button mat-raised-button color="primary"
          (click)="openMessageDialog(talnet.id)">{{i18n.getStringWithLocate('want_employ_this_candidate',
          talnet.locate)}}</button>

      </table>
      <hr>

    </div>
  </div>
</div>