<ngb-carousel *ngIf="images">
    <ng-template ngbSlide *ngFor="let item of images">

        <div class="picsum-img-wrapper">
            <img [src]="item.imageURL" class="carousel-image" alt="Random first slide">
        </div>
        <div class="carousel-caption">
            <p>
                <span class="leftRightSpacer">
                    <span class="right-content right">
                        <h1>{{item.midTitle}}</h1>
                        <span class="content">
                            <span>{{item.bottomText}}</span>
                            <br>
                        </span>
                    </span>
                </span>

            </p>
        </div>

    </ng-template>
</ngb-carousel>