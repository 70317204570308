<div class="all">
    <div class="header">
        <div matDialogClose class="close">
            X
        </div>
        <div class="title">
            <div class="titleInner">
                <h3>{{i18n.translate.tag}}...</h3>
            </div>
        </div>
    </div>
    <mat-dialog-content>

        <div class="body">
            <mat-spinner *ngIf="tags === null">
            </mat-spinner>
            <div class="nothing" style="margin: 12px;" *ngIf="tags && tags.length <= 0">
                {{i18n.translate.nothing_to_show}}
            </div>
            <mat-chip-list aria-label="Fish selection"  *ngIf="tags && tags.length > 0">
                <mat-chip *ngFor="let t of tags" (click)="tagSeleected(t)">{{t.name}}</mat-chip>
            </mat-chip-list>
        </div>

    </mat-dialog-content>
</div>