<div class="card">
  <h5>
    <span>{{ i18n.get("list_company_title") }}</span>
  </h5>
  <div>
    <div
      class="alert alert-danger errorMessage"
      role="alert"
      *ngIf="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <div
      class="alert alert-info errorMessage"
      role="alert"
      *ngIf="notifyMessage"
    >
      {{ notifyMessage }}
    </div>
  </div>
  <div *ngIf="arrAdminUsers == null">
    {{ i18n.get("loading") }}
  </div>
  <div *ngIf="arrAdminUsers != null && arrAdminUsers.length <= 0">
    {{ i18n.get("nothing_to_show") }}
  </div>
  <div class="scrolling-wrapper">
    <div *ngIf="arrAdminUsers">
      <table border="1" matSort (matSortChange)="sortData($event)">
        <tr>
          <th mat-sort-header="uid">{{ i18n.get("captial_UID") }}</th>
          <th mat-sort-header="user.name">{{ i18n.get("name") }}</th>
          <th mat-sort-header="user.email">{{ i18n.get("email") }}</th>
          <th mat-sort-header="user.email">
            {{ i18n.get("email_verified") }}?
          </th>
          <th mat-sort-header="metadata.creationTime">
            {{ i18n.get("create_time") }}
          </th>
          <th mat-sort-header="metadata.lastSignInTime">
            {{ i18n.get("last_sign_in") }}
          </th>
          <td>{{ i18n.get("permissions") }}</td>
          <td>{{ i18n.get("account_banned") }}？</td>
          <th mat-sort-header="isApproved">{{ i18n.get("account_action") }}</th>
        </tr>

        <tr *ngFor="let o of sortedData; index as i">
          <td>{{ o.uid }}</td>
          <td>{{ o.user.displayName }}</td>
          <td>{{ o.user.email }}</td>
          <td>
            <span *ngIf="o.user.emailVerified"
              ><i class="fas fa-check"></i
            ></span>
            <span *ngIf="!o.user.emailVerified"
              ><i class="fas fa-times"></i
            ></span>
          </td>
          <td>{{ o.user.metadata.creationTime | dateTimeText }}</td>
          <td>{{ o.user.metadata.lastSignInTime | dateTimeText }}</td>

          <td>
            <button
              class="btn light"
              (click)="openPermissionDialog(o.user.uid)"
            >
              {{ i18n.get("edit") }}
            </button>
          </td>
          <td>
            <span *ngIf="o.user.disabled"><i class="fas fa-check"></i></span>
            <span *ngIf="!o.user.disabled"><i class="fas fa-times"></i></span>
          </td>
          <td>
            <span class="btn-light" *ngIf="!!o.isApproved">
              <span *ngIf="!isBtnLoading">
                <span
                  *ngIf="o.user.disabled"
                  class="btn btn-light"
                  (click)="toBan(o.user.email, false)"
                >
                  {{ i18n.get("unban") }}
                </span>
                <span
                  *ngIf="!o.user.disabled"
                  class="btn btn-light"
                  (click)="toBan(o.user.email, true)"
                >
                  {{ i18n.get("ban") }}
                </span>
              </span>
            </span>
            <span
              class="btn btn-info"
              *ngIf="!o.isApproved && !isBtnLoading"
              (click)="approved(i, o.uid)"
              >{{ i18n.get("action_approve") }}
            </span>

            <span *ngIf="isBtnLoading" class="btn btn-light"> ⌛ </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
