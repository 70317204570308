<div class="all">
    <div class="header">
        <h2>WhatsApp Templates</h2>
    </div>
    <div class="body">
        <table>
            <tr>
                <td>ID</td>
                <td>Name</td>
                <td></td>
            </tr>
            <tr *ngFor="let item of i18n.translate.map_whatsapp_templates_key_translates|keyvalue">
                <td>{{item.key}}</td>
                <td>{{item.value}}</td>
                <td>
                    <button mat-raised-button color="primary" (click)="openEditWhatsappTemplateDialog(item.key)">
                        {{i18n.translate.edit}}
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>