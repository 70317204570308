<div flex="justify-center">
  <div class="thisBody" flex-item="grow-1">
    <div class="wholeContent">
      <div *ngIf="!advisor">
        <h5>{{ i18n.get("loading") }}</h5>
      </div>
      <div class="content" *ngIf="advisor">
        <div class="header" flex>
          <div class=headImg>
            <img class="coverImg" [src]="advisor.coverURL" />
          </div>
          <div class="headContent">
            <span class="name">{{ advisor.name }}</span>
            <div class="thisContainItem">
              <div class="subTitle">
                {{ i18n.get("placeholder_expertise") }}
              </div>
              <ul>
                <li *ngFor="let item of textToArray0(advisor.expertise)">
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="thisContainItem">
              <div class="subTitle">{{ i18n.get("placeholder_duration") }}</div>
              <div class="subContent">
                {{ advisor.duration_text }}
              </div>
            </div>

            <div class="thisContainItem">
              <div class="subTitle">
                {{ i18n.get("placeholder_consultion_fee") }}
              </div>
              <div class="subContent">{{ advisor.fee | advisorFee }}</div>
            </div>
            
            <div class="thisContainItem">
              <div class="subTitle">
                {{ i18n.get("supported_appointment_format") }}
              </div>
              <div class="subContent">{{ advisor | advisorAppointmentFormat }}</div>
            </div>
            <button
              class="btn btn-primary makeButton blcok"
              (click)="openMakeAppointmentDialog()"
            >
              {{ i18n.get("make_appointment") }}
            </button>
          </div>
        </div>
        <div class="content">
          <hr />
          <div class="thisContainItem singleLine">
            <div class="subTitle" *ngIf="advisor.work_experience">
              {{ i18n.get("placeholder_working_experience") }}
            </div>
            <div class="workExperience"  [innerHTML]="markdownToHtml(advisor.work_experience)" >
            </div>
          </div>

          <hr />
          <div class="thisContainItem singleLine">
            <div class="subTitle" *ngIf="advisor.work_experience">
              {{ i18n.get("placeholder_major_achievements") }}
            </div>
            <div [innerHTML]="markdownToHtml(advisor.achievements)">
              
            </div>
            <!-- <ul>
              <li *ngFor="let item of textToArray0(advisor.achievements)">
                {{ item }}
              </li>
            </ul> -->
          </div>

          <hr />
          <div class="thisContainItem singleLine">
            <div class="subTitle" *ngIf="advisor.work_experience">
              {{ i18n.get("placeholder_focus_advice") }}
            </div>
            <div [innerHTML]="markdownToHtml(advisor.adviceFocus)">
           
            </div>
            <!-- <ul>
              <li *ngFor="let item of textToArray0(advisor.adviceFocus)">
                {{ item }}
              </li>
            </ul> -->
          </div>

          <hr />
          <div class="singleLine">
            <div class="timeItem">
              <span class="before">{{ i18n.get("post_at") }}</span>
              <span class="after">
                {{ dateToText(advisor.createAt) }}
              </span>
            </div>

            <div class="timeItem">
              <span class="before">{{ i18n.get("update_at") }}</span>
              <span class="after">
                {{ dateToText(advisor.updateAt) }}
              </span>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
