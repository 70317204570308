<div class="thisBody">
    <div class="thisTitle">
        <span class="leftCloser">
            X
        </span>
        <span class="rightTItle">
            {{i18n.get('menu_profiles')}}
        </span>
    </div>
    <mat-dialog-content class="thisContent">

    <div>
        <div class="alert alert-danger errorMessage" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="alert alert-info errorMessage" role="alert" *ngIf="notifyMessage">
            {{notifyMessage}}
        </div>
    </div>
        <form class="example-form">
            <mat-form-field class="example-full-width">
                <input matInput [placeholder]="i18n.get('placeholder_company_name')" [(ngModel)]="inputRegCompanyName" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [placeholder]="i18n.get('placeholder_company_desc')" [(ngModel)]="inputRegCompanyDesc" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </form>
        <div class="dropzone" dropZone (dropped)="uploadFile($event)" [class.hovering]="isHovering">

            <br>

            <h5>{{i18n.get('your_company_logo_title')}}</h5>

            <div class="file">
                <label class="file-label">


                    <input class="file-input" type="file" (change)="uploadFile($event)" accept=".jpg,.jpeg,.png">

                </label>
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="fa fa-upload"></i>
                    </span>
                    <span class="file-label">
                        <span>
                            <br>
                            <div *ngIf="percentage | async as pct">
                                <span *ngIf="pct<=99">
                                    <progress class="progress is-info" [value]="pct" max="100">
                                    </progress>

                                    {{ pct | number }}%
                                </span>
                                <span *ngIf="pct==100 && !downloadURL">
                                    Loading... | 載入中...
                                </span>
                            </div>

                        </span>
                    </span>
                </span>

                <img class="previewIcon" [src]="downloadURL" *ngIf="downloadURL">
            </div>
        </div>
    </mat-dialog-content>
    <br>
    <button class="btn btn-primary submit-button" (click)="saveInfo()">{{i18n.get('submit')}}</button>
</div>