<div class="thisBody">
  <h4>Profile of user {{ uid }}</h4>
  <div *ngIf="record === undefined">Loading Contact Info</div>
  <div *ngIf="record !== undefined && !record">
    No contact info for this user.
  </div>
  <div *ngIf="record">
    <table border="1">
      <tr>
        <td>phoneNo.</td>
        <td>E-Mail</td>
        <td>Member Status</td>
      </tr>
      <tr>
        <td>{{ record.phoneNumber }}</td>
        <td>{{ record.email }}</td>
        <td>
          <span *ngIf="!record.disabled">Active</span>
          <span *ngIf="record.disabled">Banned</span>
        </td>
      </tr>
    </table>
  </div>
  <br />
  <div *ngIf="user === undefined">Loading user info</div>
  <div *ngIf="user !== undefined && !user">No profile for this user.</div>

  <div class="y-scroller" *ngIf="user">
    <table border="1">
      <tr>
        <td>Fullname</td>
        <td>Last Job Title</td>
        <td>Last Job Type</td>
        <td>Last Job Works Time</td>
        <td>Work Exp.</td>
        <td>Edu Level</td>
        <td>Expected Salary</td>
        <td>Register For</td>
        <td>CV</td>
        <td>Edit</td>
      </tr>
      <tr>
        <td>{{ user.fullname || "x" }}</td>
        <td>{{ user.jobTitle || "x" }}</td>
        <td>{{ user.jobType || "x" }}</td>
        <td>
          From {{ user.workedFrom | firestampTimestmpAsDate:'yyyy-MM-dd' }} To
          {{ user.workedTo | firestampTimestmpAsDate:'yyyy-MM-dd' }}
        </td>
        <td>{{ user.workedYears || "x" }} Year</td>
        <td>{{ user.educationLevel || "x" }}</td>
        <td>{{ user.expectedSalary || "x" }}/Mo</td>
        <td>
          <ul>
            <li *ngFor="let o of user.registerFor">
              {{ i18n.getStringMap("map_register_for").get(o) }}
            </li>
          </ul>
        </td>
        <td>
          <a class="btn btn-light" *ngIf="cvUrl" [href]="cvUrl" target="_blank">
            View
          </a>
          <span *ngIf="!cvUrl"></span>
        </td>
        <td>
          <button class="btn btn-light" (click)="openEditUserDialog(uid)">
            Edit
          </button>
        </td>
      </tr>
    </table>
  </div>
  <br />
  <div class="admin-logs" *ngIf="record">
    <h5>Admin's Logs</h5>
    <table border="1">
      <tr>
        <td>Date</td>
        <td>Subject</td>
        <td>Message</td>
        <td>Action</td>
      </tr>
      <tr *ngIf="arrRemarks === null">
        <td colspan="4">Loading...</td>
      </tr>
      <tr *ngIf="arrRemarks && arrRemarks.length <= 0">
        <td colspan="4">Nothing to show.</td>
      </tr>
      <ng-container *ngIf="arrRemarks && arrRemarks.length > 0">
        <tr *ngFor="let item of arrRemarks">
          <td>
            {{ item.date | firestampTimestmpAsDate }}
          </td>
          <td>
            {{ item.subject }}
          </td>
          <td style="white-space: pre-wrap">
            {{ item.message }}
          </td>
          <td>
            <button class="btn btn-light" (click)="removeAdminLog(item.id)">
              Remove
            </button>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td>
          <mat-form-field class="full-width" [owlDateTimeTrigger]="dDate02">
            <input
              matInput
              class="full-width"
              [placeholder]="'Date'"
              [owlDateTime]="dDate02"
              [(ngModel)]="newObject.date"
              [ngModelOptions]="{ standalone: true }"
              required
            />
            <owl-date-time [pickerMode]="pickerMode" #dDate02></owl-date-time>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              type="text"
              matInput
              placeholder="Subject"
              [(ngModel)]="newObject.subject"
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <textarea
              matInput
              placeholder="Leave a comment"
              [(ngModel)]="newObject.message"
            ></textarea>
          </mat-form-field>
        </td>
        <td>
          <button
            [disabled]="addDisabled"
            class="btn btn-primary"
            (click)="addAdminLogs()"
            style="color: white"
          >
            ADD
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
