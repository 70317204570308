<div class="all">
    <div class="header">
        <div class="title">
            <h1>DOWNLOAD_RAW_DATA</h1>
        </div>
    </div>
    <div class="textarea">

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>LOG........</mat-label>
            <textarea matInput placeholder="Ex. It makes me feel..." readonly rows="10">{{textFromLogsArray}}</textarea>
          </mat-form-field>
    </div>
    <div class="buttons">
        <app-dl-raw-user-info 
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"></app-dl-raw-user-info>

        <app-dl-raw-career-advisor-metadata
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-career-advisor-metadata>


        <app-dl-raw-career-advisor-appointment-logs
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-career-advisor-appointment-logs>


        <app-dl-raw-articles
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-articles>

        <app-dl-raw-events-info

        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-events-info>

        <app-dl-raw-telentpool-info
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-telentpool-info>

        <app-dl-raw-telentpool-categories
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-telentpool-categories>

        <app-dl-raw-job-info
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-job-info>

        <app-dl-raw-job-reservations
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-job-reservations>



        <hr/>


        <app-dl-raw-ca-deail-click
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-ca-deail-click>

        <app-dl-raw-article-view-logs
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-article-view-logs>

        <app-dl-raw-articles-category-change-logs
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-articles-category-change-logs>

        <app-dl-raw-event-view-click-logs
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-event-view-click-logs>

        <app-dl-raw-telentpool-view
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-telentpool-view>

        <app-dl-raw-telentpool-category-search
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-telentpool-category-search>

        <app-dl-raw-job-view-click-logs
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-job-view-click-logs>

        <app-dl-raw-job-search-logs
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-job-search-logs>

        <app-dl-raw-job-reservation-logs
        (appendLogs)="appendLine($event)"
        (downloadCsv)="downloadCSV($event)"
        ></app-dl-raw-job-reservation-logs>

    </div>
    <br/><br/><br/>
</div>