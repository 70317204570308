import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18n } from 'src/app/i18n/i18n';

@Component({
  selector: 'app-talent-search',
  templateUrl: './talent-search.component.html',
  styleUrls: ['./talent-search.component.less']
})
export class TalentSearchComponent implements OnInit {
i18n = new I18n()
  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
  }

  toService() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['talentsearch/service'])
    );

    window.open(url, '_blank');
  }

}
