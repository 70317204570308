<img class="headImg" src="/assets/job_matching_icons/Headbanner/Job Matching - Talent Search .png"/>
<div class="background">
  <div class="container-wrap">
    <div class="main container">
      <div class="row">
        <div class="col-12">
          <app-main-title [title]="i18n.translate.talent_search_service"></app-main-title>
          <div>
            <h3>
              {{i18n.translate.target_candidates_title}}
            </h3>
            <div>
              <ul>
                <li>{{i18n.translate.target_candidates_point_0}}</li>
                <li>{{i18n.translate.target_candidates_point_1}}</li>
                <li>{{i18n.translate.target_candidates_point_2}}</li>
              </ul>
            </div>
            <h3>
              {{i18n.translate.tss_why_title}}
            </h3>
            <div>
              <ul>
              <li>{{i18n.translate.tss_why_desc_0}}</li>
              <li>{{i18n.translate.tss_why_desc_1}}</li>
              <li>{{i18n.translate.tss_why_desc_2}}</li>
              <li>{{i18n.translate.tss_why_desc_3}}</li>
              </ul>
            </div>
          
            <h3>
              {{i18n.translate.hrca_professional_talent_search_team_title}}
            </h3>
            <div>
              <ul>
              <li>{{i18n.translate.hrca_professional_talent_search_team_point_0}}</li>
              <li>{{i18n.translate.hrca_professional_talent_search_team_point_1}}</li>
              <li>{{i18n.translate.hrca_professional_talent_search_team_point_2}}</li>
              <li>{{i18n.translate.hrca_professional_talent_search_team_point_3}}</li>
              <li>{{i18n.translate.hrca_professional_talent_search_team_point_4}}</li>
              </ul>
            </div>
            <h3>
              {{i18n.translate.our_expertise_title}}
            </h3>
            <div>
              <ul>
              <li>{{i18n.translate.our_expertise_desc_0}}</li>
              <li>{{i18n.translate.our_expertise_desc_1}}</li>
              </ul>
            </div>
            <h3>
              {{i18n.translate.our_strengths_title}}
            </h3>
            <div>
              <ul>
              <li>{{i18n.translate.our_strentths_desc_0}}</li>
              <li>{{i18n.translate.our_strentths_desc_1}}</li>
              <li>{{i18n.translate.our_strentths_desc_2}}</li>
              <li>{{i18n.translate.our_strentths_desc_3}}</li>
              <li>{{i18n.translate.our_strentths_desc_4}}</li>
              </ul>
            </div>
          

            <br><br>
                <div class="center">
                  <h3 class="Y" routerLink="/contacts">
                    {{i18n.translate.contact_us_and_talk_to_our_experts_today}}
                  </h3>
                  <div>
                    E-Mail: <a href="mailto:job@happy-retired.org">job@happy-retired.org</a>
                  </div>
                  <div>
                    Tel: <a href="tel:+85228336755">2833-6755</a>
                  </div>
                </div>
                <br><br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-big-button buttonInnerHtml="Click here to learn more about<br />HAPPY-RETIRED TALENT SEARCH SERVICE"
  (click)="toService()"></app-big-button> -->