<div class="container-fluid">
  <form class="form">
    <div class="row">
      <div class="col-12">
        <h2>{{header}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="full-width">
          <input matInput [placeholder]="i18n.get('title')" [(ngModel)]="content.title" required
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <ckeditor [editor]="ckeditor" [ngModelOptions]="{standalone: true}" [(ngModel)]="editorContent"
          (ready)="TheUploadAdapterPlugin($event)" [(config)]="ckeConfig"></ckeditor>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="btn btn-primary" (click)="saveContent()">{{i18n.get('submit')}}</button>
      </div>
    </div>
  </form>
</div>