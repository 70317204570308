import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, UserRecordInMap } from 'src/app/class/common-objects.enum';
import { TheAdvisor } from 'src/app/class/the-advisor';
import { IDownloadCsvMask } from '../raw-data-download.component';


@Component({
  selector: 'app-dl-raw-career-advisor-metadata',
  templateUrl: './dl-raw-career-advisor-metadata.component.html',
  styleUrls: ['./dl-raw-career-advisor-metadata.component.less']
})
export class DlRawCareerAdvisorMetadataComponent implements OnInit {

  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_career_advisor'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.the_advisors)
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
              "advisor_id",
              "name",
              "status",
              "expertise",
              "work_experience",
              "achievements",
              "adviceFocus",
              "appointment_count",
              "fee",
              "duration",
              "cover image",
              "accept face-to-face",
              "accept phone call"

            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <TheAdvisor>docs.data()
            if (record && record.id) {
              arrOutput.push([
                record?.id||'',
                record?.name||'',
                record?.status||'',
                record?.expertise||'',
                record?.work_experience||'',
                record?.achievements||'',
                record?.adviceFocus||'',
                record?.appointment_count.toString()||'',
                `${record?.fee_text} ($${record?.fee})`,
                `${record?.duration_text} (${record?.duration_minutes}mins)`,
                record?.coverURL||'',
                record?.face_to_face ? 'accept face-to-face' :'deiend face-to-face',
                record?.phone_call? 'accept phone call' : 'denied phone call'


              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }

}
