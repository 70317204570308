<div class="thisBody">
    <div class="headTitle">
    <span class="closeButton" (click)="closeThisDialog()" style="cursor: pointer;">X</span>
    <span class="thatTitle">{{i18n.get('contact_us')}}</span>
</div>
    <mat-dialog-content>
        <div class="headTitleContainer">
            <div class="flex">
                <div class="flexBlock" *ngFor="let item of contactUsHeaderBlocks">
                    <div class="leftIcon">
                        <i [(class)]="item.iconClass"></i>
                    </div>
                    <div class="rightText">
                        <span class="title">
                            {{item.title}}
                        </span>
                        <br>
                        <span class="subtitle">
                            {{item.subtitle}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <form [(id)]="fromId" class="thisForm">
            <mat-form-field class="example-full-width">
                <input type="text" matInput [placeholder]="i18n.get('placeholder_contact_name')" required [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <input type="text" matInput [placeholder]="i18n.get('placeholder_mobile_phone')" required [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input type="email" matInput [placeholder]="i18n.get('placeholder_contact_email')" required [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <br>
            <mat-form-field class="example-full-width">
                <textarea matInput [placeholder]="i18n.get('placeholder_contact_message')" required [(ngModel)]="message" [ngModelOptions]="{standalone: true}"></textarea>
            </mat-form-field>
            <button class="example-full-width btn btn-info" (click)="onSubmit()" [disabled]="isSubmitDisabled">{{i18n.get('submit')}}</button>
        </form>
    </mat-dialog-content>
</div>