import { Component, OnInit } from '@angular/core';
import { I18n } from 'src/app/i18n/i18n';
import { SnsUrl } from 'src/app/class/common-objects.enum';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {
  i18n = new I18n();
  sns = SnsUrl;
  
  constructor() { }

  ngOnInit() {
  }

}
