<div class="thisBody container">
  <div *ngIf="article === undefined" class="specHolder">
    {{ i18n.get("loading") }}
  </div>
  <div *ngIf="article === null" class="specHolder">
    {{ i18n.get("nothing_to_show") }}
  </div>
  <div *ngIf="article && article.id" class="thisOuter">
    <div class="backButtons">
      <span class="left" (click)="tryGoBack()">
        <i class="fas fa-arrow-left"></i>{{ i18n.get("back") }}</span>
    </div>

    <div class="thisContentBody">
      <h3>{{ article.title }}</h3>
      <div class="thisPostDatetime">
        <span *ngIf="article.author" class="author">{{ article.author }} </span><b>{{ i18n.get("post_at") + " "
          }}</b><span>{{ dateToDisplayString(article.createAt) }}</span>
        <br>
        <span>{{i18n.translate.view_count}}: {{articleReadCounts}}</span>
      </div>

      <br />

      <div class="thisCover" *ngIf="article && !(article.gallery && article.gallery.length>0)">
        <img *ngIf="article.coverURL" [src]="article.coverURL" />
      </div>

      <div class="thisGallery" *ngIf="article && article.gallery && article.gallery.length > 0">
        <div class="mainContainer" *ngIf="currentGalleryItem">
          <img class="imgOnly" [src]="currentGalleryItem.image" *ngIf="currentGalleryItem.type == 'image'" />
          <div class="videoWrapper" *ngIf="currentGalleryItem.type == 'youtube'">
            <iframe width="560" height="315" [src]="mainGalleryVideoUrl" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
        <br>
        <div class="rollingOuter">
          <div class="rolling">
            <ng-container class="item" *ngFor="let item of article.gallery">
              <img [src]="item.image" (click)="setCurrentGalleryItem(item)" />
            </ng-container>
          </div>
        </div>
      </div>

      <br />

      <div class="thisContent" [(id)]="articleContentId">
        <div class="conetent" [innerHTML]="htmlRestyling(article.content) | html"></div>

        <div class="adsContainer topAds">
          <app-random-ads-banner-w300h250></app-random-ads-banner-w300h250>
        </div>
      </div>

      <div class="adsContainer lastAds">
        <app-random-ads-banner-w300h250></app-random-ads-banner-w300h250>
      </div>
    </div>
    <hr />
    <div class="relatedArticles">
      <div class="relatedArticleTitle">
        <i class="fas fa-share-alt"></i>
        <span> {{i18n.get("related_articles")}}</span>
      </div>
      <div class="relatedArticleListOuter">
        <div class="relatedArticleListInner">
          <div *ngIf="sameCatOtherArticles == null">
            {{ i18n.get("loading") }}
          </div>
          <div *ngIf="
              sameCatOtherArticles != null && sameCatOtherArticles.length <= 0
            ">
            {{ i18n.get("nothing_to_show") }}
          </div>
          <div class="thisListOuter" flex="wrap">
            <app-card-container [articles]="sameCatOtherArticles"
              *ngIf="sameCatOtherArticles != null && sameCatOtherArticles.length > 0"></app-card-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>