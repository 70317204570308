import { i18n_locate } from '../i18n/i18n_const';
import { JobSearchHistroy } from '../class/common-objects.enum';

export class LocalStorageUtils {
    private storage = localStorage || window.localStorage
    private keyPrefix = 'kasopko-99a2130-__1_'
    private keyLocate = `${this.keyPrefix}_keyLocate`
    private keyCaLocate = `${this.keyPrefix}_keyCaLocate`
    private keyJobSearchHistory = `${this.keyPrefix}_keyJobSearchHistory`
    get currentLocate(){
        if(location.pathname.startsWith('/ca')){
            return this.storage.getItem(this.keyCaLocate) || i18n_locate.en
        }else{

        return this.storage.getItem(this.keyLocate) || i18n_locate.hant
        }
    }
    set currentLocate(value:string){
        if(location.pathname.startsWith('/ca')){
        this.storage.setItem(this.keyCaLocate, value)
        }else{
            this.storage.setItem(this.keyLocate, value)
        }
    }
    addJobSearchHistory(searchHistory: JobSearchHistroy):void{
        try{
            let history = this.getJobSearchHistory().reverse()
            history.push(searchHistory)
            this.storage.setItem(this.keyJobSearchHistory, JSON.stringify(history))
        }catch(e){
            console.error(e)
        }
    }
    getJobSearchHistory():Array<JobSearchHistroy>{
        let str =  this.storage.getItem(this.keyJobSearchHistory) || '[]'
        let history = <Array<JobSearchHistroy>> JSON.parse(str) || []
        return history.reverse()
    }
}
