import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { dbKeys, MemberTalentItem, UserRecordInMap } from 'src/app/class/common-objects.enum';
import { IDownloadCsvMask } from '../raw-data-download.component';


@Component({
  selector: 'app-dl-raw-telentpool-info',
  templateUrl: './dl-raw-telentpool-info.component.html',
  styleUrls: ['./dl-raw-telentpool-info.component.less']
})
export class DlRawTelentpoolInfoComponent implements OnInit {
  @Output() appendLogs = new EventEmitter<string>();
  @Output() downloadCsv = new EventEmitter<IDownloadCsvMask>();
  isLoading = false
  constructor(
    private afFirestore: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  processKey = 'DL_talentpool_info'
  startProcessing() {
    this.appendLogs.emit(`start processing ${this.processKey}`)
    this.isLoading = true;
    this.afFirestore.collection(dbKeys.job_matching_items)
      .get().toPromise()
      .then(v => {
        if (v && v.size > 0) {
          this.appendLogs.emit(`${this.processKey} have ${v.size} records`)
          const arrOutput: string[][] = [
            [
              "talent_id",
              "summary",
              "experience",
              "professional_qualification",
              "expertise",
              "emploment",
              "languages",
              "it_skills",



            ],
          ]
          for (const [i, docs] of v.docs.entries()) {
            const record = <MemberTalentItem>docs.data()
            if (record && record.id) {
              arrOutput.push([
                record?.id||'',
                record?.summary||"",
                record?.experience||'',
                `${record?.professionalQualification} ${[record?.hdDetails, record?.bscDetails, record?.certDetails, record?.certDetails, record?.masterDetails, record?.diplomaDetails].join(' | ')}`,
                record?.experience||'',
                `${record?.languages.join('，')} ${record?.languages_other}`,
                `${record?.it_skills.join('，')} ${record?.it_skills_other}`,

              ])
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} success to appended.`)
            } else {
              this.appendLogs.emit(`(${i}/${v.size}) ${this.processKey} of ${docs.id} failed since empty record`)
            }
          }

          
          
          this.appendLogs.emit(`${this.processKey} processing end. turning to download.`)
          this.downloadCsv.emit({
            filename:`${this.processKey}.csv`,
            content: arrOutput,
          })
        } else {
          this.appendLogs.emit(`zero result, stop process ${this.processKey}`)
        }
      }).catch(e => {
        console.error(e)
        this.appendLogs.emit(`processing ${this.processKey} with error ${e?.code || e.toString()}`)
      }).finally(() => {
        this.isLoading = false;
      })
  }


}
