import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { I18n } from '../../../i18n/i18n';
import { Subscription } from 'rxjs';
import { TheAdvisorBookingRecordStatus, TheAdvisorBookingRecord } from '../../../class/theAdvisorBooking';
import { TheAdvisor } from '../../../class/the-advisor';
import { dbKeys, openSimpleMessageDialog, cloudFunctionsKeys } from '../../../class/common-objects.enum';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { firestore } from 'firebase/app';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-ca-list-appointment-advisor',
  templateUrl: './ca-list-appointment-advisor.component.html',
  styleUrls: ['./ca-list-appointment-advisor.component.less']
})
export class CaListAppointmentAdvisorComponent implements OnInit {
  i18n = new I18n()
  initId = ''
  records: Array<TheAdvisorBookingRecord> = null
  advisors: Map<string, TheAdvisor> = null
  mapIsRemarkChange: Map<string, boolean> = new Map()
  constructor(
    public activatedRoute: ActivatedRoute,
    public afFirestore: AngularFirestore,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    public afFunction: AngularFireFunctions,
  ) {
    this.initId = activatedRoute.snapshot.paramMap.get('id') || ''
    this.refreshList()
  }

  ngOnInit() {

  }
  sub0: Subscription = null
  refreshList() {
    if (this.sub0) {
      try {
        this.sub0.unsubscribe()
      } catch (e) { }
    }
    this.sub0 = this.afFirestore.collection(dbKeys.advisor_appointments, (ref) => {
      return ref.where('advisor_id', '==', this.initId).orderBy('createDateTime','desc')
    }).valueChanges().subscribe(async snap => {
      this.records = []
      this.advisors = new Map()
      for (const s of snap) {
        const a = <any>s
        const res = <TheAdvisorBookingRecord>a
        res.createAt = (<firestore.Timestamp>a.createAt).toDate()
        res.expect_datetime = (<firestore.Timestamp>a.expect_datetime).toDate()
        this.records.push(res)
        if (!this.advisors.has(res.advisor_id)) {
          const snap0 = await this.afFirestore.collection(dbKeys.the_advisors).doc(res.advisor_id).get().toPromise()
          if (snap0 && snap0.exists) {
            const d0 = <any>snap0.data()
            const res0 = <TheAdvisor>d0
            res0.createAt = (<firestore.Timestamp>d0.createAt).toDate()
            res0.updateAt = (<firestore.Timestamp>d0.updateAt).toDate()
            this.advisors.set(res0.id, res0)
          }
        }
      }
    })

  }
  changeRemark(id: string, newRemark: string) {
    this.afFirestore.collection(dbKeys.advisor_appointments).doc(id).update({
      remark: newRemark
    }).then(() => {
      openSimpleMessageDialog(this.dialog, '更新成功！\nUpdate Success!')
      this.mapIsRemarkChange.set(id, false)
    }).catch(e => {
      console.error(e)
      openSimpleMessageDialog(this.dialog, `錯誤！\nERROR!\nERR_${e.message}`)
    })
  }
  changeStatus(id: string, newStatus: string) {
    this.afFirestore.collection(dbKeys.advisor_appointments).doc(id).update({
      status: newStatus
    }).then(() => {

    }).catch(e => {
      console.error(e)
      openSimpleMessageDialog(this.dialog, `錯誤！ ERROR! \n CODE:${e.message}`)
    })
  }

  async confirmAppointment(id: string) {
    try {
      let token = await this.afAuth.auth.currentUser.getIdToken(true)
      let callable = this.afFunction.httpsCallable(cloudFunctionsKeys.confirmAdvisorAppointment)({
        token,
        appointment_id: id
      }).subscribe(result => {
        if (result === true) {
          openSimpleMessageDialog(this.dialog, '更新成功！\nUpdate Success!')
        } else {
          openSimpleMessageDialog(this.dialog, `錯誤！ ERROR! \n CODE:${result}`)
        }
      })
    } catch (e) {
      openSimpleMessageDialog(this.dialog, `錯誤！ ERROR! \n CODE:${e.message}`)
    }
  }

  changeAppointmentDate(event: Event, id: string) {
    this.afFirestore.collection(dbKeys.advisor_appointments).doc(id).update({
      expect_datetime: event
    }).then(() => {

    }).catch(e => {
      console.error(e)
      openSimpleMessageDialog(this.dialog, `錯誤！ ERROR! \n CODE:${e.message}`)
    })
  }
}
