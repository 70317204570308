<div class="thisBody">
  <div class="thisHeader"></div>
  <div class="thisContent">
    <div class="search-div" [ngClass]="{ ca: isInCareerAdvisor }">
      <div class="s003 tBackgroundOutter">
        <form class="tBackgroundInnter">
          <div class="below-options">
            <div class="searchTitle">{{thisSearchTitle}}</div>
            <div class="searchDesc">{{subSearchTitle}} </div>
            <mat-form-field style="display: none">
              <mat-label>{{ i18n.get("job_table_status") }}</mat-label>
              <mat-select [(ngModel)]="searchStatus" [ngModelOptions]="{ standalone: true }">
                <mat-option> All/所有 </mat-option>
                <mat-option *ngFor="let item of searchStatusOptions | keyvalue" [value]="item.key">
                  {{ searchStatusOptions.get(item.value) || item.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span style="display: inline-block" class="selectFilterOuter">
              <div class="input-group sm-2 selectFilter0JobMatching">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">
                    <i class="fas fa-briefcase"></i>
                  </label>
                </div>

                <select class="form-control" id="selectFilter0JobMatching" [(ngModel)]="searchCategories"
                  [ngModelOptions]="{ standalone: true }">
                  <option value="" disabled>
                    {{ i18n.get("add_activity_placeholder_categories") }}
                  </option>
                  <option value="">所有/All</option>
                  <option *ngFor="let o of searchCategoriesOptions | keyvalue" [value]="o.key">
                    {{ o.value }}
                  </option>
                </select>
              </div>
            </span>
            <span style="display: inline-block" class="selectFilterOuter">
              <div class="input-group sm-2 selectFilter0JobMatching">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">
                    <i class="fas fa-briefcase"></i>
                  </label>
                </div>
                <select class="form-control" id="selectFilter0JobMatching" [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="searchLocation">
                  <option value="" disabled>
                    {{ i18n.get("option_location") }}
                  </option>
                  <option value="">所有/All</option>
                  <option *ngFor="let o of searchLocationOptions | keyvalue" [value]="o.key">
                    {{ o.value }}
                  </option>
                </select>
              </div>
            </span>


            <span style="display: inline-block" class="selectFilterOuter">
              <div class="input-group sm-2 selectFilter0JobMatching">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect02">
                    <i class="fas fa-briefcase"></i>
                  </label>
                </div>

                <select class="form-control" id="selectFilter0JobMatching" (change)="vvvw($event.target.value);">
                  <option value="" disabled>
                    {{ i18n.get("add_activity_placeholder_categories") }}
                  </option>
                  <option value="now">{{i18n.translate.now_event}}</option>
                  <option value="pass">{{i18n.translate.ended_event}}</option>
                </select>
              </div>
            </span>
            <div class="searchButtonDiv">
              <button class="searchButton btn" (click)="searchQuery()" [ngClass]="{ ca: isInCareerAdvisor }">
                <span>
                  {{ i18n.get("search") }}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="featureList" [style.display]="listDisplay.feature">
      <h5>{{ i18n.get("now_event") }}</h5>
      <div *ngIf="!featureActivities && featureActivities == null" class="leftItemList">
        <h4 class="leftItem">
          {{ i18n.get("loading") }}
        </h4>
      </div>
      <div *ngIf="featureActivities && featureActivities.size === 0" class="leftItemList">
        <h4 class="leftItem">
          {{ i18n.get("nothing_to_show") }}
        </h4>
      </div>
      <div class="listContainer" *ngIf="featureActivities && featureActivities.size > 0" flex="wrap align-stretch">
        <div class="listItem" *ngFor="let item of featureActivities | keyvalue" (click)="openToActivity(item.key)">
          <app-activity-card [activity]="item.value.activity"></app-activity-card>
        </div>
      </div>
    </div>

    <div class="featureList" [style.display]="listDisplay.feature">
      <h5>{{ i18n.get("ended_event") }}</h5>
      <div *ngIf="!featureEndedActivities && featureEndedActivities == null" class="leftItemList">
        <h4 class="leftItem">
          {{ i18n.get("loading") }}
        </h4>
      </div>
      <div *ngIf="featureEndedActivities && featureEndedActivities.size <= 0" class="leftItemList">
        <h4 class="leftItem">
          {{ i18n.get("nothing_to_show") }}
        </h4>
      </div>
      <div class="listContainer" *ngIf="featureEndedActivities && featureEndedActivities.size > 0"
        flex="wrap align-stretch">
        <div class="listItem" *ngFor="let item of featureEndedActivities | keyvalue" (click)="openToActivity(item.key)">
          <app-activity-card [activity]="item.value.activity"></app-activity-card>
        </div>
      </div>
    </div>
    <div class="mainList" [style.display]="listDisplay.main">
      <div class="leftList" [style.display]="leftRightDisplay.left">
        <div *ngIf="!activities && activities == null" class="leftItemList">
          <h4 class="leftItem card">
            {{ i18n.get("loading") }}
          </h4>
        </div>
        <div *ngIf="activities && activities.size <= 0" class="leftItemList">
          <h4 class="leftItem card">
            {{ i18n.get("nothing_to_show") }}
          </h4>
        </div>
        <div *ngIf="activities && activities.size > 0">
          <div class="leftItemList">
            <div class="leftItem card" *ngFor="let item of activities | keyvalue" (click)="onLeftClick(item.value)">
              <div class="leftItemTitle">{{ item.value.activity.title }}</div>
              <div class="leftItemLocation">
                <i class="fas fa-tag"></i>
                <span>
                  {{
                  searchCategoriesOptions
                  .get(item.value.activity.category) ||
                  item.value.activity.category
                  }}
                </span>
              </div>
              <div class="leftItemLocation" *ngIf="item.value.activity.location">
                <i class="fas fa-location-arrow"></i>
                <span>
                  {{
                  i18n
                  .getStringMap("map_job_location")
                  .get(item.value.activity.location) ||
                  item.value.activity.location
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card rightDetails" *ngIf="mainActivity" [style.display]="leftRightDisplay.right">
        <button class="btn btn-info backToJobListButton" (click)="clearMainContent()">
          <i class="fas fa-arrow-left"></i>
        </button>
        <div class="main_content">
          <div class="headCompany">
            <div class="leftCompanyIcon" *ngIf="mainActivity.company.photoURL">
              <img class="companyIcon" [src]="mainActivity.company.photoURL" />
            </div>
            <div class="rightCompanyName">
              <h3>{{ mainActivity.company.name }}</h3>
              <p class="pre-wrapped descriptionContainer" [innerHTML]="mainActivity.company.description"></p>
            </div>
          </div>
          <hr />
          <div class="flex">
            <div class="left">
              <h3>{{ mainActivity.activity.title }}</h3>
              <div>
                <div class="viewCount" style="color: gray; font-size: 0.85rem;">
                  {{i18n.translate.view}}: {{viewCount}}
                </div>
                <br />
                <div class="pre-wrapped" [innerHTML]="
                    (mainActivity.activity.description | html) || 'N/A'
                  "></div>
              </div>
              <br />
              <div class="bulletFlex">
                <div *ngIf="mainActivity.activity.category">
                  <i class="fas fa-tag"></i>
                  <span>
                    {{
                    i18n
                    .getStringMap("map_activity_categories")
                    .get(mainActivity.activity.category) ||
                    mainActivity.activity.category
                    }}
                  </span>
                </div>
                <div *ngIf="mainActivity.activity.location">
                  <i class="fas fa-location-arrow"></i>
                  <span>{{
                    i18n
                    .getStringMap("map_job_location")
                    .get(mainActivity.activity.location) ||
                    mainActivity.activity.location
                    }}</span>
                </div>
              </div>
              <br />

              <div flex *ngIf="mainActivity.activity.address">
                <label>{{ i18n.get("add_activity_placeholder_address") }}：</label>
                <span class="address">
                  {{ mainActivity.activity.address }}
                </span>
              </div>

              <div *ngIf="mainActivity.activity.price">
                <label>{{
                  i18n.get("add_activity_placeholder_price_per_person")
                  }}：</label>
                <span>
                  {{ mainActivity.activity.price }}
                </span>
              </div>

              <div>
                <label>{{ i18n.get("activity_datetime") }}：</label>

                <span>
                  {{
                  formatDatetimeRangeToString(
                  mainActivity.activity.startAt,
                  mainActivity.activity.endAt
                  )
                  }}
                </span>
              </div>

              <div>
                <label>{{ i18n.get("application_deadline_to") }}：</label>
                <span>
                  {{ formatDatetimeToString(mainActivity.activity.deadline) }}
                </span>
              </div>

              <app-apply-activity-parts *ngIf="isInDeadline" [mainActivity]="mainActivity"></app-apply-activity-parts>
            </div>
            <div class="right">
              <br />
              <app-random-ads-banner-w300h250></app-random-ads-banner-w300h250>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>