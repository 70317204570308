import { makeid } from 'src/app/class/common-objects.enum';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I18n } from '../../../i18n/i18n';
import { AngularFireUploadTask, AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { catchError, finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { dbKeys } from '../../../class/common-objects.enum';

@Component({
  selector: 'app-update-company-info-dialog',
  templateUrl: './update-company-info-dialog.component.html',
  styleUrls: ['./update-company-info-dialog.component.less']
})
export class UpdateCompanyInfoDialogComponent implements OnInit {
  i18n = new I18n()
  notifyMessage = ''
  errorMessage = ''

  currentUser: firebase.User = null
  allowFileType = ["image/png", "image/jpeg", "image/jpg"]
  allowFileSize = 10 * 1024 * 1024

  private key_storage_uploaded_logo = 'company_uploaded_logo'
  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: string;
  downloadFilename: string

  // State for dropzone CSS toggling
  isHovering: boolean;
  isRegBtnLoading = false;
  private isDbNoData = true

  inputRegCompanyName = ''
  inputRegCompanyDesc = ''
  inputPhotoURL = ''
  constructor(
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    public afFunctions: AngularFireFunctions,
    public dialogRef: MatDialogRef<UpdateCompanyInfoDialogComponent>
  ) {

  }
  ngOnInit() {
    this.afAuth.authState.subscribe(async user => {
      if (user && user.uid) {
        this.currentUser = user
        const cInfo = await this.firestore.collection(dbKeys.company_info).doc(user.uid).get().toPromise()
        if (cInfo && cInfo.exists) {
          const data = await cInfo.data()
          console.log(data)
          this.inputRegCompanyName = data.name
          this.inputRegCompanyDesc = data.description
          this.downloadURL = data.photoURL
        }
      } else {
        this.currentUser = null
      }
    })
  }
  async saveInfo() {
    console.log("save info triggered.")
    if (!this.inputRegCompanyName) {
      this.errorMessage = this.i18n.get('error_msg_invalid_company_name')
    } else if (!this.inputRegCompanyDesc) {
      this.errorMessage = this.i18n.get('error_msg_invalid_company_desc')
    } else if (this.inputRegCompanyDesc && this.inputRegCompanyDesc.length < 10) {
      this.errorMessage = this.i18n.get('error_msg_comapny_desc_too_short')
    } else {
      try {
        await this.firestore.collection(dbKeys.company_info).doc(this.currentUser.uid).update({
          name: this.inputRegCompanyName,
          description: this.inputRegCompanyDesc,
          photoURL: this.downloadURL
        })
        await this.currentUser.updateProfile({
          displayName: this.inputRegCompanyName,
          photoURL: this.downloadURL
        })
        alert('更新成功!\nUpdate success!')
        this.dialogRef.close()
      } catch (e) {
        console.error(e)
        this.notifyMessage = ''
        this.errorMessage = `更新中發生錯誤。\nError caused when updating.\nCODE:${e.message}`
      }
    }
  }
  public updateDownloadURL() {
    let path = this.inputPhotoURL
    console.log(path)
    if (!path) return
    const fileRef: AngularFireStorageReference = this.storage.ref(
      path
    )
    let sub = fileRef.getDownloadURL().subscribe(downloadURL => {
      //this.userInfo.cvURL = downloadURL
      this.downloadURL = downloadURL
      console.log(path)
      //console.log(this.userInfo.cvURL)
      console.log(downloadURL)
      fileRef.getMetadata().subscribe(d => {
        //console.log(d)
        let p = d.fullPath
        let tIndex = p.lastIndexOf('/') + 1
        if (tIndex >= 0) this.downloadFilename = p.substring(tIndex)
      })
      sub.unsubscribe()
    });

  }
  public uploadFile(event: any): void {
    if (!this.currentUser) return
    this.errorMessage = ''
    this.notifyMessage = ''
    for (let i = 0; i < event.target.files.length; i++) {
      let target: HTMLInputElement = event.target
      const file = target.files[i];
      console.log(file)
      if (file.size > this.allowFileSize) {
        this.errorMessage = this.i18n.get('err_msg_file_too_large')
        return
      } else if (!this.allowFileType.includes(file.type)) {
        this.errorMessage = this.i18n.get('err_msg_disallow_file_type')
        return
      }
      try {
        this.isRegBtnLoading = true;
        const path = `${this.key_storage_uploaded_logo}/${this.currentUser.uid}/${(new Date().toISOString())}_${makeid(10)}_${file.name}`;

        const task: AngularFireUploadTask = this.storage.upload(
          path,
          file
        );
        console.log(path)
        this.percentage = task.percentageChanges()
        task.snapshotChanges()
          .pipe(finalize(() => {
            console.log("8964")
            const fileRef: AngularFireStorageReference = this.storage.ref(
              path
            )
            fileRef.getDownloadURL().subscribe(downloadURL => {
              //this.userInfo.cvURL = downloadURL
              this.inputPhotoURL = path
              this.isRegBtnLoading = false;
              console.log(this.inputPhotoURL)
              this.downloadURL = downloadURL
              this.updateDownloadURL()
              //sub.unsubscribe()
            });
          }), catchError(err => {
            this.errorMessage = err.message
            this.isRegBtnLoading = false;
            throw err;
          })).subscribe()
      } catch (e) {
        this.isRegBtnLoading = false;
        this.errorMessage = e.message
        console.error(e)
      }
    }
  }
}
